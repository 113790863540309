<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen bg-white box-main text-gray"
  >
    <div
      class="flex flex-col items-center justify-center flex-1 w-full text-center px-27"
    >
      <h1 class="font-bold">
        {{ "INITIALIZATION.TITLE" | translate }}
      </h1>

      <h2 class="mx-auto mt-2">
        {{ "INITIALIZATION.SUBTITLE" | translate }}
      </h2>
    </div>
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'default'">
  <div class="flex flex-col items-start justify-between w-full h-screen">
    <div
      class="flex flex-row justify-center first-block bg-primaryx-bg wow bounceInDown animated"
      data-wow-delay="0"
      data-wow-duration="1s"
    >
      <div
        class="absolute bottom-0 flex flex-col items-center justify-end w-full h-full -mb-16"
      >
        <app-cloud
          class="flex flex-col justify-end w-full h-full"
          [ngClass]="fullHdHalf ? '-mb-40' : fullHd ? ' -mb-36' : ' -mb-24'"
        ></app-cloud>
        <div
          class="absolute flex flex-col items-center justify-center w-64 h-64 mx-auto -ml-6 bg-white rounded-full drop-shadow-xl"
          [ngClass]="
            fullHdHalf
              ? 'mb-4 -ml-6'
              : fullHd
              ? ' ml-[-30px] mb-[-4px]'
              : '-ml-6 -mb-12 '
          "
          style="border-radius: 9999px !important"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70%"
            height="70%"
            viewBox="0 0 83.123 139.996"
            class="wow rotateIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
            data-wow-iteration="5"
          >
            <g id="sand-clock-svgrepo-com" transform="translate(-104)">
              <path
                id="Path_13590"
                data-name="Path 13590"
                d="M184.935,135.621h-2.187V116.1a23.666,23.666,0,0,0-4.314-13.65l-22.572-32.2L178.439,37.8a23.952,23.952,0,0,0,4.309-13.741V4.375h2.187a2.187,2.187,0,0,0,0-4.375H106.187a2.187,2.187,0,1,0,0,4.375h2.187V24.062A23.951,23.951,0,0,0,112.684,37.8l22.577,32.454-22.572,32.2a23.667,23.667,0,0,0-4.314,13.651v19.517h-2.187a2.187,2.187,0,1,0,0,4.375h78.748a2.187,2.187,0,0,0,0-4.375ZM112.75,116.1a19.312,19.312,0,0,1,3.521-11.139L139.72,71.518a2.188,2.188,0,0,0,0-2.5L116.276,35.3a19.6,19.6,0,0,1-3.526-11.242V4.375h65.623V24.062A19.6,19.6,0,0,1,174.847,35.3L151.4,69.013a2.187,2.187,0,0,0,0,2.5l23.449,33.447a19.311,19.311,0,0,1,3.521,11.139v19.517H112.75Z"
                fill="#4a5568"
              />
              <path
                id="Path_13591"
                data-name="Path 13591"
                d="M286.567,464h-4.375a2.187,2.187,0,0,0,0,4.375h4.375a2.187,2.187,0,1,0,0-4.375Z"
                transform="translate(-127.879 -337.128)"
                fill="#4a5568"
              />
              <path
                id="Path_13592"
                data-name="Path 13592"
                d="M209.22,178.609a2.188,2.188,0,0,0,1.85-1.02c.485-.73,9.432-13.617,15.363-22.153a2.187,2.187,0,0,0-1.8-3.436H193.8a2.187,2.187,0,0,0-1.8,3.435c5.932,8.536,14.879,21.423,15.364,22.153A2.187,2.187,0,0,0,209.22,178.609Zm11.234-22.234c-6.261,9.012-9.525,13.72-11.234,16.191-1.708-2.471-4.973-7.179-11.234-16.191Z"
                transform="translate(-63.659 -110.439)"
                fill="#4a5568"
              />
              <path
                id="Path_13593"
                data-name="Path 13593"
                d="M195.858,336.932a2.187,2.187,0,0,0-1.791-.932H166.806a2.186,2.186,0,0,0-1.791.932L154.729,351.6A14.959,14.959,0,0,0,152,360.231v12.955a2.188,2.188,0,0,0,2.187,2.187h26.25a2.187,2.187,0,1,0,0-4.375H156.375V360.231a10.605,10.605,0,0,1,1.936-6.116l9.633-13.74H192.93l9.633,13.74a10.605,10.605,0,0,1,1.936,6.116V371h-2.187a2.187,2.187,0,1,0,0,4.375h4.375a2.188,2.188,0,0,0,2.187-2.187V360.231a14.96,14.96,0,0,0-2.729-8.628Z"
                transform="translate(-34.875 -244.127)"
                fill="#4a5568"
              />
              <path
                id="Path_13594"
                data-name="Path 13594"
                d="M327.583,89.108a2.187,2.187,0,0,0,3.045-.547l2.395-3.442a15.241,15.241,0,0,0,2.742-8.744V74.187a2.187,2.187,0,1,0-4.375,0v2.187a10.887,10.887,0,0,1-1.959,6.246l-2.395,3.442A2.187,2.187,0,0,0,327.583,89.108Z"
                transform="translate(-161.767 -52.313)"
                fill="#4a5568"
              />
              <path
                id="Path_13595"
                data-name="Path 13595"
                d="M346.187,38.562a2.188,2.188,0,0,0,2.187-2.187V34.187a2.187,2.187,0,1,0-4.375,0v2.187A2.188,2.188,0,0,0,346.187,38.562Z"
                transform="translate(-174.377 -23.25)"
                fill="#4a5568"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center text-center middle-block">
      <div class="w-9/12 mx-auto">
        <h1 class="self-end mx-auto font-bold text-center title text-gray">
          {{ "INITIALIZATION.TITLE" | translate }}
        </h1>

        <h2 class="self-end mx-auto mt-2 text-center title text-gray">
          {{ "INITIALIZATION.SUBTITLE" | translate }}
        </h2>
      </div>
    </div>

    <div
      class="flex flex-col items-start justify-start third-block bg-primaryx-bg"
    ></div>
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'ski'">
  <div class="flex flex-col items-start justify-between w-full h-screen">
    <app-ski-bg
      [isLight]="false"
      [useAnimation]="true"
      [showImg]="true"
      [clockCenter]="true"
      [bigSelection]="true"
      [hasCentral]="false"
      [hasStatus]="false"
    >
      <ng-container slot="top">
        <app-header-ski
          [title]="'INITIALIZATION.TITLE' | translate"
          [subtitle]="'INITIALIZATION.SUBTITLE' | translate"
          [big]="true"
        ></app-header-ski>
      </ng-container>

      <!-- central part -->
      <ng-container slot="central"> </ng-container>
      <!-- end central part -->

      <ng-container slot="status"> </ng-container>
    </app-ski-bg>
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'nexus'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen px-6 bg-white"
  >
    <!-- infowindow header -->
    <div
      class="flex items-center min-h-[40%] max-h-[40%] overflow-hidden justify-center w-full px-8 py-18 bg-nex-gray-light rounded-b-4xl relative z-10 wow bounceInDown animated"
      data-wow-delay="0ms"
      data-wow-duration=".4s"
    >
      <!-- sign -->
      <div
        class="flex items-center justify-center mx-auto rounded-full w-80 h-80 bg-white-20 wow bounceIn animated"
        data-wow-delay="0"
        data-wow-duration="1s"
      >
        <div
          class="flex items-center justify-center w-64 h-64 rounded-full bg-white-40 wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            class="flex flex-col items-center justify-center w-48 h-48 mx-auto bg-white rounded-full text-primaryx wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60%"
              height="60%"
              viewBox="0 0 83.123 139.996"
              class="wow rotateIn animated text-nex-black"
              data-wow-delay="0"
              data-wow-duration="1s"
              data-wow-iteration="7"
            >
              <g id="sand-clock-svgrepo-com" transform="translate(-104)">
                <path
                  id="Path_13590"
                  data-name="Path 13590"
                  d="M184.935,135.621h-2.187V116.1a23.666,23.666,0,0,0-4.314-13.65l-22.572-32.2L178.439,37.8a23.952,23.952,0,0,0,4.309-13.741V4.375h2.187a2.187,2.187,0,0,0,0-4.375H106.187a2.187,2.187,0,1,0,0,4.375h2.187V24.062A23.951,23.951,0,0,0,112.684,37.8l22.577,32.454-22.572,32.2a23.667,23.667,0,0,0-4.314,13.651v19.517h-2.187a2.187,2.187,0,1,0,0,4.375h78.748a2.187,2.187,0,0,0,0-4.375ZM112.75,116.1a19.312,19.312,0,0,1,3.521-11.139L139.72,71.518a2.188,2.188,0,0,0,0-2.5L116.276,35.3a19.6,19.6,0,0,1-3.526-11.242V4.375h65.623V24.062A19.6,19.6,0,0,1,174.847,35.3L151.4,69.013a2.187,2.187,0,0,0,0,2.5l23.449,33.447a19.311,19.311,0,0,1,3.521,11.139v19.517H112.75Z"
                  fill="currentColor"
                />
                <path
                  id="Path_13591"
                  data-name="Path 13591"
                  d="M286.567,464h-4.375a2.187,2.187,0,0,0,0,4.375h4.375a2.187,2.187,0,1,0,0-4.375Z"
                  transform="translate(-127.879 -337.128)"
                  fill="currentColor"
                />
                <path
                  id="Path_13592"
                  data-name="Path 13592"
                  d="M209.22,178.609a2.188,2.188,0,0,0,1.85-1.02c.485-.73,9.432-13.617,15.363-22.153a2.187,2.187,0,0,0-1.8-3.436H193.8a2.187,2.187,0,0,0-1.8,3.435c5.932,8.536,14.879,21.423,15.364,22.153A2.187,2.187,0,0,0,209.22,178.609Zm11.234-22.234c-6.261,9.012-9.525,13.72-11.234,16.191-1.708-2.471-4.973-7.179-11.234-16.191Z"
                  transform="translate(-63.659 -110.439)"
                  fill="currentColor"
                />
                <path
                  id="Path_13593"
                  data-name="Path 13593"
                  d="M195.858,336.932a2.187,2.187,0,0,0-1.791-.932H166.806a2.186,2.186,0,0,0-1.791.932L154.729,351.6A14.959,14.959,0,0,0,152,360.231v12.955a2.188,2.188,0,0,0,2.187,2.187h26.25a2.187,2.187,0,1,0,0-4.375H156.375V360.231a10.605,10.605,0,0,1,1.936-6.116l9.633-13.74H192.93l9.633,13.74a10.605,10.605,0,0,1,1.936,6.116V371h-2.187a2.187,2.187,0,1,0,0,4.375h4.375a2.188,2.188,0,0,0,2.187-2.187V360.231a14.96,14.96,0,0,0-2.729-8.628Z"
                  transform="translate(-34.875 -244.127)"
                  fill="currentColor"
                />
                <path
                  id="Path_13594"
                  data-name="Path 13594"
                  d="M327.583,89.108a2.187,2.187,0,0,0,3.045-.547l2.395-3.442a15.241,15.241,0,0,0,2.742-8.744V74.187a2.187,2.187,0,1,0-4.375,0v2.187a10.887,10.887,0,0,1-1.959,6.246l-2.395,3.442A2.187,2.187,0,0,0,327.583,89.108Z"
                  transform="translate(-161.767 -52.313)"
                  fill="currentColor"
                />
                <path
                  id="Path_13595"
                  data-name="Path 13595"
                  d="M346.187,38.562a2.188,2.188,0,0,0,2.187-2.187V34.187a2.187,2.187,0,1,0-4.375,0v2.187A2.188,2.188,0,0,0,346.187,38.562Z"
                  transform="translate(-174.377 -23.25)"
                  fill="currentColor"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
      <!-- end sign -->

      <div id="nhdwave">
        <div class="fill text-nex-gray-dark opacity-20">
          <svg
            version="1.1"
            id="waver"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 136 195"
            style="enable-background: new 0 0 136 195"
            xml:space="preserve"
          >
            <path
              fill="currentColor"
              id="waveShape"
              d="M300,300V2.5c0,0-0.6-0.1-1.1-0.1c0,0-25.5-2.3-40.5-2.4c-15,0-40.6,2.4-40.6,2.4
    c-12.3,1.1-30.3,1.8-31.9,1.9c-2-0.1-19.7-0.8-32-1.9c0,0-25.8-2.3-40.8-2.4c-15,0-40.8,2.4-40.8,2.4c-12.3,1.1-30.4,1.8-32,1.9
    c-2-0.1-20-0.8-32.2-1.9c0,0-3.1-0.3-8.1-0.7V300H300z"
            />
          </svg>
        </div>
      </div>
    </div>
    <!-- end infowindow header -->

    <!-- infowindow msg part -->
    <div
      class="flex flex-col vert-shadow items-center justify-center flex-1 w-11/12 mx-auto pb-24 pt-28 px-8 h-auto bg-nex-gray-light-ultra relative -top-12 z-5 rounded-b-4xl wow bounceInDown animated"
      data-wow-delay="200ms"
      data-wow-duration=".4s"
    >
      <!-- msg -->
      <div
        class="w-full h-auto flex flex-col items-center justify-center wow fadeIn animated"
        data-wow-delay="1s"
        data-wow-duration="1s"
      >
        <h1
          class="text-6xl font-bold text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="100ms"
          data-wow-duration="1s"
        >
          {{ "INITIALIZATION.TITLE" | translate }}
        </h1>

        <h2
          class="text-6xl font-medium text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="10ms"
          data-wow-duration="1s"
        >
          {{ "INITIALIZATION.SUBTITLE" | translate }}
        </h2>
      </div>
      <!-- end msg -->
    </div>
    <!-- end infowindow msg part -->

    <!-- infowindow empty space at the bottom -->
    <div class="w-full h-52 block"></div>
    <!-- end infowindow empty space at the bottom -->
  </div>
</ng-container>
