<ng-container *ngIf="buttonType === 'primary'">
  <button
    type="button"
    (click)="onClickButton($event)"
    class="btn-sanusx btn-main bg-primaryx border-primaryx group {{
      fullwidth ? 'btn-full' : ''
    }} {{ squared ? 'btn-main--squared' : '' }}  {{
      redBorder ? 'btn-main--redBorder' : ''
    }}"
    [disabled]="disabled"
  >
    <span
      class="label font-sans-sanusx text-primaryx-bg group-active:text-active"
    >
      {{ label }}
    </span>
  </button>
</ng-container>
<ng-container *ngIf="buttonType === 'secondary'">
  <button
    type="button"
    (click)="onClickButton($event)"
    class="btn-sanusx btn-main bg-primaryx-bg bg-white border-transparent group {{
      fullwidth ? 'btn-full' : ''
    }}  {{ squared ? 'btn-main--squared' : '' }}  {{
      redBorder ? 'btn-main--redBorder' : ''
    }}"
    [disabled]="disabled"
  >
    <span class="label font-sans-sanusx text-primaryx group-active:text-active">
      {{ label }}
    </span>
  </button>
</ng-container>
<ng-container *ngIf="buttonType === 'neutral'">
  <button
    type="button"
    (click)="onClickButton($event)"
    class="btn-sanusx btn-main bg-transparent border-white group {{
      fullwidth ? 'btn-full' : ''
    }}  {{ squared ? 'btn-main--squared' : '' }}  {{
      redBorder ? 'btn-main--redBorder' : ''
    }}"
    [disabled]="disabled"
  >
    <span class="text-white label font-sans-sanusx group-active:text-active">
      {{ label }}
    </span>
  </button>
</ng-container>

<ng-container *ngIf="buttonType === 'neutral-dark'">
  <button
    type="button"
    (click)="onClickButton($event)"
    class="btn-sanusx btn-main bg-transparent border-gray group {{
      fullwidth ? 'btn-full' : ''
    }}  {{ squared ? 'btn-main--squared' : '' }}  {{
      redBorder ? 'btn-main--redBorder' : ''
    }}"
    [disabled]="disabled"
  >
    <span class="label font-sans-sanusx text-gray group-active:text-active">
      {{ label }}
    </span>
  </button>
</ng-container>
