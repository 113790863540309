<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="flex flex-col items-start justify-start w-full h-screen text-white bg-primaryx box-main"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      [personPhoto]="tenant !== 'sanusx' && personPhoto"
      class="w-full wow fadeInDown"
    ></app-header>
    <div
      class="flex flex-col items-center justify-center flex-1 w-11/12 pt-16 mx-auto text-center"
    >
      <div
        class="flex flex-col items-center justify-end flex-1 w-full pl-0 text-center"
      >
        <h1
          class="flex flex-col justify-start m-0 font-bold wow fadeInDown text-8xl font-serif-sanusx"
          data-wow-delay="0"
          data-wow-duration="0.5s"
        >
          <span [innerHTML]="welcomeMsg"></span>
        </h1>

        <ng-container *ngIf="tenant === 'sanusx' && personPhoto">
          <div
            class="flex flex-col items-center justify-center w-48 h-48 mx-auto my-10 bg-white rounded-full drop-shadow-xl wow pulse animated"
            data-wow-delay="0"
            data-wow-duration="1.5s"
            data-wow-iteration="2"
            style="border-radius: 9999px !important"
          >
            <img
              *ngIf="personPhoto !== ''"
              [src]="
                (personPhoto !== ''
                  ? personPhoto
                  : '/assets/welcome-person.png'
                ) | safe
              "
              loading="lazy"
              class="w-48 h-48 mx-auto rounded-full max-h-48"
              style="border-radius: 9999px !important"
            />
          </div>
        </ng-container>
      </div>

      <div
        class="flex flex-col items-center justify-start flex-1 w-full mx-auto"
      >
        <div class="w-11/12 mx-auto h-0.5 bg-gray-light opacity-20 my-4"></div>
        <h2
          class="text-5xl font-bold leading-normal wow fadeInUp text-white-70"
          data-wow-delay="0"
          data-wow-duration="0.5s"
        >
          {{ "SELECTION.QUESTION" | translate }}
        </h2>

        <!-- <div
          class="flex flex-wrap items-center content-center justify-start flex-1 w-full h-auto pb-10 pl-10 mx-auto mt-8"
        > -->
        <!-- selection buttons only for sanusx apotheke -->
        <ng-container *ngIf="tenant === 'sanusx'">
          <div
            class="flex flex-row items-center justify-center w-full h-auto py-6 space-x-4"
          >
            <app-btn-sanusx
              (btnClick)="retailerPickup()"
              [label]="'COMMON.BTN_RETAILER_PICKUP' | translate"
              buttonType="secondary"
              class="wow slideInLeft animated"
              data-wow-delay="0s"
              data-wow-duration="1s"
            >
            </app-btn-sanusx>

            <app-btn-sanusx
              (btnClick)="retailer()"
              [label]="'COMMON.BTN_RETAILER' | translate"
              buttonType="secondary"
              class="wow slideInRight animated"
              data-wow-delay="0s"
              data-wow-duration="1s"
            >
            </app-btn-sanusx>
          </div>
        </ng-container>
        <!-- selection buttons -->
        <ng-container *ngIf="tenant !== 'sanusx'">
          <div
            class="grid h-auto grid-flow-row gap-4 pb-10 mx-auto mt-8 place-content-center"
            [ngClass]="
              numberOfModules === 1
                ? 'w-full grid-cols-1'
                : numberOfModules === 2
                ? 'w-full grid-cols-2'
                : numberOfModules === 3 && portrait
                ? 'w-full grid-cols-3'
                : numberOfModules === 3 && !portrait
                ? 'w-7/12 grid-cols-3'
                : numberOfModules === 4
                ? 'w-full grid-cols-2'
                : numberOfModules === 5 && portrait
                ? 'w-full grid-cols-3'
                : numberOfModules === 5 && !portrait
                ? 'w-7/12 grid-cols-3'
                : numberOfModules === 6
                ? 'w-full grid-cols-3'
                : numberOfModules === 7 && !portrait
                ? 'w-full grid-cols-4'
                : numberOfModules === 7 && portrait
                ? 'w-full grid-cols-4'
                : numberOfModules === 8
                ? 'w-full grid-cols-4'
                : ''
            "
          >
            <ng-container *ngFor="let item of modulesList">
              <btn-big-box
                (btnClick)="item.method()"
                [label]="item.title | translate"
                [smBtn]="true"
                [ngClass]="
                  numberOfModules === 1
                    ? 'flex justify-center'
                    : numberOfModules === 2
                    ? 'sel-btn-2x2'
                    : numberOfModules === 3
                    ? 'sel-btn-3x3'
                    : numberOfModules === 4
                    ? 'sel-btn-2x2'
                    : numberOfModules === 5
                    ? 'sel-btn-3x3'
                    : numberOfModules === 6
                    ? 'sel-btn-3x3'
                    : numberOfModules === 7
                    ? 'sel-btn-4x4'
                    : numberOfModules === 8
                    ? 'sel-btn-4x4'
                    : ''
                "
              >
                <app-svg-stripe [icon]="item.icon"></app-svg-stripe>
              </btn-big-box>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- <div
      class="self-end block w-full h-6 bg-white rounded-t-xl wow fadeInUp"
    ></div> -->
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'default'">
  <div class="flex flex-col items-start justify-between w-full h-screen">
    <div class="flex flex-row justify-center first-block bg-primaryx-bg">
      <!-- <app-header
        [showSearch]="false"
        [doubleLogos]="true"
        [showLangFlags]="true"
        [personPhoto]="tenant !== 'sanusx' && personPhoto"
        class="w-full wow fadeInDown"
      ></app-header> -->

      <div
        class="absolute bottom-0 flex flex-col items-center justify-end w-full h-full -mb-16 wow bounceInDown animated"
        data-wow-delay="0"
        data-wow-duration="1s"
      >
        <app-cloud
          class="flex flex-col justify-end w-full h-full"
          [ngClass]="fullHdHalf ? '-mb-40' : fullHd ? ' -mb-36' : ' -mb-24'"
        ></app-cloud>
        <div
          class="absolute flex flex-col items-center justify-center w-64 h-64 mx-auto -ml-6 bg-white rounded-full drop-shadow-xl wow pulse animated"
          [ngClass]="
            fullHdHalf
              ? 'mb-4 -ml-6'
              : fullHd
              ? ' ml-[-30px] mb-[-4px]'
              : '-ml-6 -mb-12 '
          "
          data-wow-delay="0"
          data-wow-duration="1.5s"
          data-wow-iteration="2"
          style="border-radius: 9999px !important"
        >
          <img
            [src]="
              (personPhoto !== '' ? personPhoto : '/assets/welcome-person.png')
                | safe
            "
            loading="lazy"
            class="mx-auto rounded-full max-h-72 h-72 w-72"
            style="border-radius: 9999px !important"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center text-center middle-block">
      <h1
        class="self-end mx-auto text-center title text-primaryx-dark"
        [innerHTML]="welcomeMsg"
      ></h1>
    </div>

    <div
      class="flex flex-col items-start justify-start third-block bg-primaryx-bg wow bounceInUp animated"
      data-wow-delay="0s"
      data-wow-duration="1s"
      [ngClass]="
        numberOfModules <= 3
          ? 'third-block--sm'
          : numberOfModules >= 4
          ? 'third-block--md'
          : ''
      "
    >
      <div
        class="grid h-auto grid-flow-row gap-4 pb-10 mx-auto -mt-32 place-content-center"
        [ngClass]="
          numberOfModules === 1
            ? 'w-full grid-cols-1'
            : numberOfModules === 2
            ? 'w-full grid-cols-2'
            : numberOfModules === 3 && portrait
            ? 'w-full grid-cols-3'
            : numberOfModules === 3 && !portrait
            ? 'w-7/12 grid-cols-3'
            : numberOfModules === 4
            ? 'w-full grid-cols-2'
            : numberOfModules === 5 && portrait
            ? 'w-full grid-cols-3'
            : numberOfModules === 5 && !portrait
            ? 'w-7/12 grid-cols-3'
            : numberOfModules === 6 && portrait
            ? 'w-full grid-cols-3'
            : numberOfModules === 6 && !portrait
            ? 'w-7/12 grid-cols-3'
            : numberOfModules === 7 && portrait
            ? 'w-10/12 grid-cols-3'
            : numberOfModules === 7 && !portrait
            ? 'w-10/12 grid-cols-3'
            : ''
        "
      >
        <ng-container *ngFor="let item of modulesList">
          <app-button
            (btnClick)="item.method()"
            [label]="item.title | translate"
            buttonType="bigroundSc"
            extraCss="{{
              numberOfModules <= 3 ? 'btn-round-sc-lg' : 'btn-round-sc-sm'
            }}"
            class="wow bounceInUp animated"
            data-wow-delay="0s"
            data-wow-duration="1s"
          >
            <app-svg-stripe
              class="flex flex-col items-center justify-center"
              [icon]="item.icon"
            ></app-svg-stripe>
          </app-button>
          <!-- <btn-big-box
            (btnClick)="item.method()"
            [label]="item.title | translate"
            [smBtn]="true"
            [ngClass]="
              numberOfModules === 1
                ? 'flex justify-center'
                : numberOfModules === 2
                ? 'sel-btn-2x2'
                : numberOfModules === 3
                ? 'sel-btn-3x3'
                : numberOfModules === 4
                ? 'sel-btn-2x2'
                : numberOfModules === 5
                ? 'sel-btn-3x3'
                : numberOfModules === 6
                ? 'sel-btn-3x3'
                : ''
            "
          >
            <app-svg-stripe [icon]="item.icon"></app-svg-stripe>
          </btn-big-box> -->
        </ng-container>
      </div>
      <!--  <div
        class="flex flex-row items-start justify-center w-9/12 mx-auto -mt-32 space-x-10"
      >
         <app-button
          (btnClick)="retailerPickup()"
          [label]="'SELECTION_MENU.ABHOLUNG' | translate"
          buttonType="biground"
          class="wow bounceInUp animated"
          data-wow-delay="0s"
          data-wow-duration="1s"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 96 96.72"
            fill="currentColor"
          >
            <g
              id="Group_7323"
              data-name="Group 7323"
              transform="translate(0 50)"
            >
              <path
                id="Path_13561"
                data-name="Path 13561"
                d="M51.848-16.787,29.874-7.714l3.675,8.937L55.524-7.83a4.83,4.83,0,0,0,3-4.468,4.816,4.816,0,0,0-.368-1.857,4.857,4.857,0,0,0-6.306-2.631"
                transform="translate(27.914 30.699)"
                fill="currentColor"
              />
              <path
                id="Path_13562"
                data-name="Path 13562"
                d="M21.885-46.961s0-.006,0-.01a5,5,0,0,0-.29-.553c-.058-.1-.1-.213-.162-.309a4.68,4.68,0,0,0-.42-.515c-.062-.07-.112-.153-.178-.221a4.841,4.841,0,0,0-5.242-1.081L3.029-44.589A4.837,4.837,0,0,0,.352-38.3a4.835,4.835,0,0,0,6.293,2.679l8.1-3.261L34.77,10.21a4.841,4.841,0,1,0,8.964-3.658Z"
                transform="translate(0)"
                fill="currentColor"
              />
              <path
                id="Path_13563"
                data-name="Path 13563"
                d="M63.771-11.887,41.74-2.836a1.632,1.632,0,0,1-2.13-.89L32.765-20.389a1.632,1.632,0,0,1,.89-2.13l22.031-9.051a1.632,1.632,0,0,1,2.13.89l6.846,16.663a1.632,1.632,0,0,1-.89,2.13m11.405-1.985-9.985-24.3a7.17,7.17,0,0,0-9.361-3.909L26.159-29.894a7.171,7.171,0,0,0-3.909,9.361l9.985,24.3A7.171,7.171,0,0,0,41.6,7.679L71.267-4.511a7.172,7.172,0,0,0,3.909-9.361"
                transform="translate(20.284 6.891)"
                fill="currentColor"
              />
              <path
                id="Path_13564"
                data-name="Path 13564"
                d="M32-15A14.508,14.508,0,0,0,17.494-.492,14.508,14.508,0,0,0,32,14.016,14.508,14.508,0,0,0,46.509-.492,14.508,14.508,0,0,0,32-15m0,9.672A4.843,4.843,0,0,1,36.837-.492,4.843,4.843,0,0,1,32,4.344,4.843,4.843,0,0,1,27.165-.492,4.843,4.843,0,0,1,32-5.328"
                transform="translate(16.346 32.704)"
                fill="currentColor"
              />
            </g>
          </svg>
        </app-button>

        <app-button
          (btnClick)="retailer()"
          [label]="'SELECTION_MENU.EINKAUFEN' | translate"
          buttonType="biground"
          class="wow bounceInUp animated"
          data-wow-delay="0s"
          data-wow-duration="1s"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="96"
            height="96.612"
            viewBox="0 0 96 96.612"
          >
            <g
              id="Group_7330"
              data-name="Group 7330"
              transform="translate(-344 -675)"
            >
              <g
                id="Group_7329"
                data-name="Group 7329"
                transform="translate(344 675)"
              >
                <path
                  id="Path_13565"
                  data-name="Path 13565"
                  d="M83.588-32.61H51.666a4.829,4.829,0,0,0-4.757,5.669,4.83,4.83,0,0,0,4.757,3.992H83.588a2.766,2.766,0,0,1,2.106.966,2.768,2.768,0,0,1,.6,2.241L78.97,15.367a2.718,2.718,0,0,1-2.686,2.261h-31.3A2.718,2.718,0,0,1,42.3,15.367L31.611-46.058A4.783,4.783,0,0,0,26.9-50H4.831A4.841,4.841,0,0,0,.145-43.966a4.987,4.987,0,0,0,4.919,3.627H22.955L32.771,17.01A12.4,12.4,0,0,0,44.982,27.289h31.3A12.4,12.4,0,0,0,88.5,17l7.321-35.12A12.4,12.4,0,0,0,83.588-32.61"
                  transform="translate(0 50)"
                  fill="currentColor"
                />
                <path
                  id="Path_13566"
                  data-name="Path 13566"
                  d="M46.169-22.339A4.83,4.83,0,0,0,51-27.169,4.83,4.83,0,0,0,46.169-32H30.711a4.83,4.83,0,0,0-4.831,4.831,4.83,4.83,0,0,0,4.831,4.831Z"
                  transform="translate(24.126 66.78)"
                  fill="currentColor"
                />
                <path
                  id="Path_13567"
                  data-name="Path 13567"
                  d="M27.643-7A6.763,6.763,0,0,0,20.88-.237a6.763,6.763,0,0,0,6.763,6.763A6.763,6.763,0,0,0,34.406-.237,6.763,6.763,0,0,0,27.643-7"
                  transform="translate(19.465 90.086)"
                  fill="currentColor"
                />
                <path
                  id="Path_13568"
                  data-name="Path 13568"
                  d="M41.643-7A6.763,6.763,0,0,0,34.88-.237a6.763,6.763,0,0,0,6.763,6.763A6.763,6.763,0,0,0,48.406-.237,6.763,6.763,0,0,0,41.643-7"
                  transform="translate(32.517 90.086)"
                  fill="#fff"
                />
                <path
                  id="Path_13569"
                  data-name="Path 13569"
                  d="M46.2-19.169A4.83,4.83,0,0,0,41.372-24H31.711a4.83,4.83,0,0,0-4.831,4.831,4.83,4.83,0,0,0,4.831,4.831h9.661A4.83,4.83,0,0,0,46.2-19.169"
                  transform="translate(25.059 74.238)"
                  fill="currentColor"
                />
              </g>
            </g>
          </svg>
        </app-button>

        <app-button
          (btnClick)="retailer()"
          [label]="'SELECTION_MENU.EINKAUFEN' | translate"
          buttonType="biground"
          class="wow bounceInUp animated"
          data-wow-delay="0s"
          data-wow-duration="1s"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="96"
            height="96.612"
            viewBox="0 0 96 96.612"
          >
            <g
              id="Group_7330"
              data-name="Group 7330"
              transform="translate(-344 -675)"
            >
              <g
                id="Group_7329"
                data-name="Group 7329"
                transform="translate(344 675)"
              >
                <path
                  id="Path_13565"
                  data-name="Path 13565"
                  d="M83.588-32.61H51.666a4.829,4.829,0,0,0-4.757,5.669,4.83,4.83,0,0,0,4.757,3.992H83.588a2.766,2.766,0,0,1,2.106.966,2.768,2.768,0,0,1,.6,2.241L78.97,15.367a2.718,2.718,0,0,1-2.686,2.261h-31.3A2.718,2.718,0,0,1,42.3,15.367L31.611-46.058A4.783,4.783,0,0,0,26.9-50H4.831A4.841,4.841,0,0,0,.145-43.966a4.987,4.987,0,0,0,4.919,3.627H22.955L32.771,17.01A12.4,12.4,0,0,0,44.982,27.289h31.3A12.4,12.4,0,0,0,88.5,17l7.321-35.12A12.4,12.4,0,0,0,83.588-32.61"
                  transform="translate(0 50)"
                  fill="currentColor"
                />
                <path
                  id="Path_13566"
                  data-name="Path 13566"
                  d="M46.169-22.339A4.83,4.83,0,0,0,51-27.169,4.83,4.83,0,0,0,46.169-32H30.711a4.83,4.83,0,0,0-4.831,4.831,4.83,4.83,0,0,0,4.831,4.831Z"
                  transform="translate(24.126 66.78)"
                  fill="currentColor"
                />
                <path
                  id="Path_13567"
                  data-name="Path 13567"
                  d="M27.643-7A6.763,6.763,0,0,0,20.88-.237a6.763,6.763,0,0,0,6.763,6.763A6.763,6.763,0,0,0,34.406-.237,6.763,6.763,0,0,0,27.643-7"
                  transform="translate(19.465 90.086)"
                  fill="currentColor"
                />
                <path
                  id="Path_13568"
                  data-name="Path 13568"
                  d="M41.643-7A6.763,6.763,0,0,0,34.88-.237a6.763,6.763,0,0,0,6.763,6.763A6.763,6.763,0,0,0,48.406-.237,6.763,6.763,0,0,0,41.643-7"
                  transform="translate(32.517 90.086)"
                  fill="#fff"
                />
                <path
                  id="Path_13569"
                  data-name="Path 13569"
                  d="M46.2-19.169A4.83,4.83,0,0,0,41.372-24H31.711a4.83,4.83,0,0,0-4.831,4.831,4.83,4.83,0,0,0,4.831,4.831h9.661A4.83,4.83,0,0,0,46.2-19.169"
                  transform="translate(25.059 74.238)"
                  fill="currentColor"
                />
              </g>
            </g>
          </svg>
        </app-button>
      </div>-->
      <!-- toggle LANG 2 switcher -->
      <ng-container *ngIf="multiLanguage">
        <div
          class="lang_switcher btn  group {{
            currentLang === 'de' ? '' : 'checked'
          }}"
          [ngClass]="'mb-4'"
          (pointerup)="setPublicLanguage()"
          [touchClick]
        >
          <div class="flex items-center cursor-pointer">
            <!-- toggle -->

            <!-- line -->
            <div
              class="flex items-center w-27 rounded-full relative
        {{ currentLang !== 'de' ? 'justify-start' : 'justify-end' }}"
              [ngClass]="fullHdHalf ? 'h-27' : 'bg-primaryx-30 h-18'"
            >
              <!-- dot -->
              <div
                class="dot w-full h-18 absolute border-4 border-primaryx group-active:border-active p-1.5 bg-white rounded-full transition-all duration-100 ease-in-out flex flex-col items-center justify-center"
              >
                <!-- [ngClass]="
              fullHdHalf ? 'w-27 h-27' : 'left-0 top-0 dot--normal w-18 h-18'
            " -->
                <div
                  class="overflow-hidden rounded-full option option-A bg-primaryx group-active:bg-active"
                  *ngIf="currentLang === 'de'"
                >
                  <img src="/assets/lang/gb_2.png" />
                </div>
                <div
                  class="overflow-hidden rounded-full option option-B bg-primaryx group-active:bg-active"
                  *ngIf="currentLang !== 'de'"
                >
                  <img src="/assets/lang/de_2.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- END: toggle LANG 2 switcher -->
    </div>
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'nexus'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen"
    (click)="start()"
  >
    <h1>nexus theme selection full</h1>

    <app-button
      (btnClick)="retailer()"
      [label]="'SELECTION_MENU.EINKAUFEN' | translate"
      buttonType="biground"
      class="wow bounceInUp animated"
      data-wow-delay="0s"
      data-wow-duration="1s"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96.612"
        viewBox="0 0 96 96.612"
      >
        <g
          id="Group_7330"
          data-name="Group 7330"
          transform="translate(-344 -675)"
        >
          <g
            id="Group_7329"
            data-name="Group 7329"
            transform="translate(344 675)"
          >
            <path
              id="Path_13565"
              data-name="Path 13565"
              d="M83.588-32.61H51.666a4.829,4.829,0,0,0-4.757,5.669,4.83,4.83,0,0,0,4.757,3.992H83.588a2.766,2.766,0,0,1,2.106.966,2.768,2.768,0,0,1,.6,2.241L78.97,15.367a2.718,2.718,0,0,1-2.686,2.261h-31.3A2.718,2.718,0,0,1,42.3,15.367L31.611-46.058A4.783,4.783,0,0,0,26.9-50H4.831A4.841,4.841,0,0,0,.145-43.966a4.987,4.987,0,0,0,4.919,3.627H22.955L32.771,17.01A12.4,12.4,0,0,0,44.982,27.289h31.3A12.4,12.4,0,0,0,88.5,17l7.321-35.12A12.4,12.4,0,0,0,83.588-32.61"
              transform="translate(0 50)"
              fill="currentColor"
            />
            <path
              id="Path_13566"
              data-name="Path 13566"
              d="M46.169-22.339A4.83,4.83,0,0,0,51-27.169,4.83,4.83,0,0,0,46.169-32H30.711a4.83,4.83,0,0,0-4.831,4.831,4.83,4.83,0,0,0,4.831,4.831Z"
              transform="translate(24.126 66.78)"
              fill="currentColor"
            />
            <path
              id="Path_13567"
              data-name="Path 13567"
              d="M27.643-7A6.763,6.763,0,0,0,20.88-.237a6.763,6.763,0,0,0,6.763,6.763A6.763,6.763,0,0,0,34.406-.237,6.763,6.763,0,0,0,27.643-7"
              transform="translate(19.465 90.086)"
              fill="currentColor"
            />
            <path
              id="Path_13568"
              data-name="Path 13568"
              d="M41.643-7A6.763,6.763,0,0,0,34.88-.237a6.763,6.763,0,0,0,6.763,6.763A6.763,6.763,0,0,0,48.406-.237,6.763,6.763,0,0,0,41.643-7"
              transform="translate(32.517 90.086)"
              fill="#fff"
            />
            <path
              id="Path_13569"
              data-name="Path 13569"
              d="M46.2-19.169A4.83,4.83,0,0,0,41.372-24H31.711a4.83,4.83,0,0,0-4.831,4.831,4.83,4.83,0,0,0,4.831,4.831h9.661A4.83,4.83,0,0,0,46.2-19.169"
              transform="translate(25.059 74.238)"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    </app-button>
  </div>
</ng-container>
