<!-- wave 1 -->
<div
  class="absolute left-0 top-[70px] w-full h-auto z-5 text-gray-ultra/20"
  [ngClass]="useAnimation && 'wow fadeInUp'"
  *ngIf="!isLight && !clientDesign"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0 0 1280 477.894"
  >
    <path
      id="Path_22841"
      data-name="Path 22841"
      d="M0-336.894V141H1280V-286.974c-122.389,44.288-287.862,108.35-612.1,49.819C282.293-306.767,121.61-329.081,0-336.894"
      transform="translate(0 336.894)"
      fill="currentColor"
    />
    <!-- fill="#f5f5fd" -->
  </svg>
</div>
<!-- end wave 1 -->

<!-- wave 2   -->
<div
  class="absolute left-0 top-[203px] z-10 w-full h-auto text-gray-ultra/40"
  [ngClass]="useAnimation && 'wow fadeInUp'"
  *ngIf="!clientDesign"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0 0 1280 336.896"
  >
    <path
      id="Path_22840"
      data-name="Path 22840"
      d="M1280-336.9V0H0V-286.973c122.39,44.289,287.868,108.349,612.112,49.816C997.721-306.765,1158.386-329.079,1280-336.9"
      transform="translate(0 336.896)"
      fill="currentColor"
    />
    <!-- fill="currentColor" class="text-gray-snow" -->
  </svg>
</div>
<!-- end wave 2   -->

<!-- mountain -->
<div
  class="absolute -left-[10%] bottom-0 w-full h-auto z-15"
  [ngClass]="useAnimation && 'wow fadeInUp'"
  *ngIf="!clientDesign"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120%"
    viewBox="0 0 1675.927 630"
  >
    <g id="mountain" transform="translate(198 -394)">
      <g id="Group_7337" data-name="Group 7337" transform="translate(-198)">
        <g
          id="Group_7335"
          data-name="Group 7335"
          transform="translate(-7.009 832.695)"
        >
          <path
            id="Path_23006"
            data-name="Path 23006"
            d="M904.207-397.546l-59.53-41.112L600.066-319.682l-96.135-56.156L7.009-192.716V2.022h897.2Z"
            transform="translate(0 0.023)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23007"
            data-name="Path 23007"
            d="M105.254-225.3C250.707-280.281,472.323-371,481.735-377.486L443.673-399.72,105.254-275.009Z"
            transform="translate(60.26 23.906)"
            fill="#fff"
          />
          <path
            id="Path_23008"
            data-name="Path 23008"
            d="M172.143-389.1S86.99-329.08,7.01-272.594v64.9A224.482,224.482,0,0,1,45.53-228.536c5.369-1.792,3.58,16.117,0,19.694-11.16,13.254-25.3,24.6-38.521,36.488v60.64c30.693-13.806,57.44-41.576,81.5-63.1,14.324-12.539,32.237-12.539,48.352-8.956,12.538,1.792,12.538,16.113,10.747,26.859-5.375,14.33-21.493,26.862-14.33,42.98,5.375,14.33,25.073,14.33,37.609,10.747,26.861-7.168,42.98-34.024,42.98-64.473,1.789-3.58,3.58-7.157,7.16-8.949,12.541-1.791,21.49,7.158,25.073,16.116,22.272,43.751,39.6,88.207,58.1,132.112H597.433Z"
            transform="translate(0 30.417)"
            fill="#e8fffc"
          />
          <path
            id="Path_23009"
            data-name="Path 23009"
            d="M415.305-399.721s-186.543,98.449-168.238,98.007,44.716-15.224,48.3-17.019-61.956,39.4-63.434,51.494c-.121,1.012.782,1.378,2.517,1.218L151.5-204.712l12.534,22.506,355.18-158.008Z"
            transform="translate(88.627 23.905)"
            fill="currentColor"
            class="text-gray-snow"
          />
          <path
            id="Path_23010"
            data-name="Path 23010"
            d="M722.538-350.068c15.224,4.132,24.117,19.694,32.549,32.354v-79.924q-16.093-11.193-32.269-22.258c-2.526-1.715-24.607-19.977-27.261-18.739-.111.05-389.9,182.21-439.372,208.975-24.6,13.3,33.443,12.868,36.378,12.67,48.641-3.364,97.7-10.359,143.778-26.753a593.252,593.252,0,0,0,66.064-27.842c12.426-6.207,22.148-17.3,34.687-23.486,22.65-11.182,1.215,30.165-1.3,36.644A63.443,63.443,0,0,0,536.629-211c4.351,10.038,20.133,33.923,33.95,27.2,12.47-6.057,9.837-57.516,12.839-69.986,4.356-18.076,32.795-86.486,55.722-48.64,17.889,29.539,19.013,65.02,24.258,98.221,3.185,20.151,9.38,50.355,24.031,65.359,23.674,24.247,42.207-23.1,46.053-40.7,8.033-36.815-7.12-72.34-17.3-106.992-2.922-9.919-21.882-71.191,6.355-63.531"
            transform="translate(149.119 0)"
            fill="#fff"
          />
          <path
            id="Path_23011"
            data-name="Path 23011"
            d="M720.179-364.626c12.647,4.48,28.607,26.98,32.432,32.727v-79.928c-.4-.273-.792-.553-1.187-.831a68.437,68.437,0,0,1-21.183,12.312c-5.374,1.794-16.118-23.279-28.653-12.533s-12.537,30.443-30.444,37.612c1.791-8.961,1.791-23.282-3.578-26.866-16.374-10.929-38.874,19.5-46.841,29.386C610-359.437,597.012-341.463,578.166-340.8c-9.543-22.974,37.041-27.732,30-51.884-3.783,6.278-13.807,9.311-20.194,12.184-44.722,20.114-91.81,34.245-137.625,51.536-17.468,6.594-34.631,13.823-51.894,20.945-30.678,12.662-76.22,29.941-104.762,46.865-35.6,21.1-52.871,12.583-27.376,31.962,14.281,10.853,43.8,1.738,60.157.189,39.758-3.761,77.461-15.842,113.847-31.874,27.187-11.981,76.457-53.94,104.739-62.936,18.675-5.936-6.028,53.83-4.622,64.783,2.146,16.724,3.722,39.211,13.488,53.594,12.734,18.76,20.336-14.582,22.137-23.4,4.838-23.678,5.673-48.373,17.621-70.07,9.783-17.771,26.428-40.991,41.784-17.19,18.134,28.09,20.528,62.166,24.905,94.261,2.754,20.215,6.775,58.11,24.165,71.885,8.606,6.81,21.558,2.2,28.8-5.177,38.374-39.1,13.133-101.855.323-145.891-2.8-9.591-21.709-73.6,6.523-63.605"
            transform="translate(151.598 14.187)"
            fill="currentColor"
            class="text-gray-snow/40"
          />
          <path
            id="Path_23012"
            data-name="Path 23012"
            d="M7.01-28.375H597.433L172.143-389.1S86.99-329.083,7.01-272.6Z"
            transform="translate(0 30.418)"
            fill="#f5f5fd"
          />
          <path
            id="Path_23013"
            data-name="Path 23013"
            d="M308.778-237.491c7.165-1.792,10.745,1.791,16.117,5.371,28.045,28.047,60.964,56.064,100.751,58.039L172.141-389.1S86.99-329.083,7.009-272.6v22.987C41.9-277.6,75.9-300.244,108.206-298.376c18.406,1.063,25.07,14.32,17.91,26.859-17.91,34.026-66.259,37.609-84.166,71.63-5.375,10.747-1.792,25.076,12.535,19.7,35.814-16.116,46.555-82.375,85.955-68.05,21.492,8.954,7.166,48.351,23.282,66.264,12.536,10.742,34.026-17.913,39.4-34.024a34.944,34.944,0,0,0,0-26.866c-7.163-12.537-16.117-25.07-8.954-35.817,8.954-16.116,34.023-5.376,37.607,7.163C246.1-201.68,321.3-90.457,369.665-146.158c27.03-31.13-46.56-55.522-60.887-91.332"
            transform="translate(0 30.418)"
            fill="currentColor"
            class="text-gray-snow/75"
          />
          <path
            id="Path_23014"
            data-name="Path 23014"
            d="M172.143-389.1S86.99-329.08,7.01-272.594v10.622c52.313-24.268,89.7-88.19,151.34-79.39,3.775.542-3.205,7.673-3.81,8.288-3.217,3.29-6.732,6.266-10.106,9.4-2.22,2.057-4.8,6.323-4.787,9.4.015,4.734,5.862,3.107,8.825,3.209,7.27.253,15.334,6.779,15.206,14.462-.132,7.9-6.637,13.765-10.817,19.835-3.4,4.943-6.795,10.285-8.019,16.248-.363,1.791.021,4.459,2.243,4.69,3.48.371,5.614-5.626,7.219-7.831a47.862,47.862,0,0,0,6.236-11.732c3.22-8.843,4.782-18.107,6.628-27.3,1.033-5.171,2.685-10.227,3.719-15.38,1.791-8.956,12.534-12.541,14.323-8.956,5.374,3.578,3.583,14.325,7.17,21.488,5.368,5.374,16.111,5.374,17.9-3.58,1.791-10.747-3.58-19.7-3.58-28.655,1.788-8.949,21.487-3.585,23.279,3.583,20.386,45.861,52.068,90.082,105.712,83.809Z"
            transform="translate(0 30.417)"
            fill="#fff"
          />
          <path
            id="Path_23015"
            data-name="Path 23015"
            d="M904.207,78.547H7.009V-93.569s179.442-43.919,680.675-152.668c152.246-33.032,216.523,39.479,216.523,39.479Z"
            transform="translate(0 112.757)"
            fill="#fff"
          />
          <path
            id="Path_23016"
            data-name="Path 23016"
            d="M102.639-136.9s93.325-46.449,190.12-28.214c0,0,107.755-64.7,185.248,16.935,0,0-181.322,101.847-375.368,11.279"
            transform="translate(58.656 152.885)"
            fill="#fff"
          />
          <path
            id="Path_23017"
            data-name="Path 23017"
            d="M313.907-146.5s83.892-68.19,159.834-5.984c-77.117-74.983-180.982-12.634-180.982-12.634-96.8-18.236-190.12,28.213-190.12,28.213,4.506,2.107,115.922-35.108,211.268-9.595"
            transform="translate(58.656 152.889)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23018"
            data-name="Path 23018"
            d="M299.169-189.74s68.579-47.567,147.371-44.279c0,0,77.911-63.683,148.718-7.943,0,0-131.913,101.595-296.089,52.221"
            transform="translate(179.2 106.642)"
            fill="#fff"
          />
          <path
            id="Path_23019"
            data-name="Path 23019"
            d="M465.444-221.712s58.6-63.694,125.939-23.169c-69.742-50.513-144.844,10.86-144.844,10.86-78.79-3.286-147.369,44.279-147.369,44.279,3.812,1.145,87.789-41.189,166.275-31.969"
            transform="translate(179.2 106.645)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23020"
            data-name="Path 23020"
            d="M249.071-128.837c-61.74-80.905-164.887-33.2-164.887-33.2-26.325-7.96-52.939-10.334-77.174-9.824v57.728c277.543,84.393,242.061-14.707,242.061-14.707"
            transform="translate(0 161.124)"
            fill="#fff"
          />
          <path
            id="Path_23021"
            data-name="Path 23021"
            d="M84.184-162.037C57.857-170,31.243-172.367,7.01-171.86v.419c12.184,1.981,68.535,11.821,94.379,28.193,0,0,82.029-53.157,144.263,10.132-62.048-74.9-161.467-28.921-161.467-28.921"
            transform="translate(0.001 161.127)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23022"
            data-name="Path 23022"
            d="M151.32-70.653c42.346,42.346,59.694,39,87.9,39.849,80.129,1.612,136.34-15.254,136.34-15.254-65.157-108.947-199.7-64.3-199.7-64.3a285.659,285.659,0,0,0-49.619-16.013c-14.615,13.359,1.644,32.285,25.08,55.72"
            transform="translate(69.724 191.566)"
            fill="#fff"
          />
          <path
            id="Path_23023"
            data-name="Path 23023"
            d="M173.749-110.359a285.326,285.326,0,0,0-49.622-16.013c46.594,12.689,68.311,41.713,68.311,41.713s109.136-54.364,177.381,32.816c-66.395-101.535-196.07-58.517-196.07-58.517"
            transform="translate(71.836 191.567)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23024"
            data-name="Path 23024"
            d="M482.381-131.265c19.42,19.418,27.38,17.886,40.318,18.273,36.746.741,62.531-6.989,62.531-6.989-29.888-49.974-91.6-29.5-91.6-29.5a130.85,130.85,0,0,0-22.761-7.338c-6.7,6.121.758,14.8,11.508,25.552"
            transform="translate(282.955 172.893)"
            fill="#fff"
          />
          <path
            id="Path_23025"
            data-name="Path 23025"
            d="M492.668-149.477a131.2,131.2,0,0,0-22.763-7.341C491.28-151,501.24-137.687,501.24-137.687s50.051-24.938,81.355,15.045c-30.45-46.563-89.927-26.835-89.927-26.835"
            transform="translate(283.922 172.893)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23026"
            data-name="Path 23026"
            d="M264.879-182.032c26.073,8.651,32.493,3.706,44.232-1.742,33.177-15.829,52.755-34.3,52.755-34.3-49.128-31.26-95.1,14.715-95.1,14.715a131.411,131.411,0,0,0-23.636,3.646c-3.238,8.485,7.317,12.9,21.745,17.682"
            transform="translate(144.47 128.64)"
            fill="#fff"
          />
          <path
            id="Path_23027"
            data-name="Path 23027"
            d="M266.547-203.361a131.386,131.386,0,0,0-23.636,3.645c21.711-4.38,36.585,3.053,36.585,3.053s33.553-44.74,79.464-23.04c-48.1-27.966-92.413,16.343-92.413,16.343"
            transform="translate(144.693 128.642)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23028"
            data-name="Path 23028"
            d="M221.643-167.016c26.07,8.646,32.493,3.708,44.23-1.747,33.179-15.822,52.754-34.3,52.754-34.3-49.125-31.252-95.093,14.724-95.093,14.724A131.236,131.236,0,0,0,199.9-184.7c-3.244,8.486,7.313,12.9,21.742,17.682"
            transform="translate(117.951 137.849)"
            fill="#fff"
          />
          <path
            id="Path_23029"
            data-name="Path 23029"
            d="M223.31-188.346a131.211,131.211,0,0,0-23.633,3.646c21.708-4.387,36.581,3.051,36.581,3.051s33.555-44.739,79.461-23.039c-48.1-27.966-92.41,16.342-92.41,16.342"
            transform="translate(118.175 137.852)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23030"
            data-name="Path 23030"
            d="M416.677-73.26a2.034,2.034,0,0,1-2.036-2.045v-25.342a2.037,2.037,0,0,1,2.036-2.043,2.041,2.041,0,0,1,2.036,2.043v25.342a2.038,2.038,0,0,1-2.036,2.045"
            transform="translate(250.026 206.093)"
            fill="#ab1e2b"
          />
          <path
            id="Path_23031"
            data-name="Path 23031"
            d="M440.072-94.149a2.425,2.425,0,0,1-2.426,2.429H407.474a2.426,2.426,0,0,1-2.425-2.429,2.426,2.426,0,0,1,2.425-2.429h30.171a2.426,2.426,0,0,1,2.426,2.429"
            transform="translate(244.142 209.841)"
            fill="#112a47"
          />
          <path
            id="Path_23032"
            data-name="Path 23032"
            d="M437.05-59.393a2.042,2.042,0,0,1-1.358-2.544l7.376-24.253a2.032,2.032,0,0,1,2.541-1.355A2.047,2.047,0,0,1,446.967-85l-7.373,24.253a2.037,2.037,0,0,1-2.544,1.355"
            transform="translate(262.883 215.328)"
            fill="#ab1e2b"
          />
          <path
            id="Path_23033"
            data-name="Path 23033"
            d="M462.753-72.6a2.427,2.427,0,0,1-3.025,1.615l-28.87-8.778a2.428,2.428,0,0,1-1.615-3.025,2.433,2.433,0,0,1,3.027-1.62l28.868,8.778a2.427,2.427,0,0,1,1.615,3.031"
            transform="translate(258.918 217.239)"
            fill="#112a47"
          />
          <path
            id="Path_23036"
            data-name="Path 23036"
            d="M525.684-16.094c-63.042,23.745-79.263,12.261-108.566.056-82.853-35.543-132.636-78.9-132.636-78.9,117.327-81.409,234.479,26.768,234.479,26.768a321.629,321.629,0,0,1,58.281,6.62c8.775,20.472-16.668,32.319-51.558,45.46"
            transform="translate(170.191 192.233)"
            fill="#fff"
          />
          <path
            id="Path_23037"
            data-name="Path 23037"
            d="M516.524-68.179a321.319,321.319,0,0,1,58.278,6.62c-53.626-8.621-89.351,11.045-89.351,11.045s-86.61-106.347-196.995-48.677C403.6-172.43,516.524-68.179,516.524-68.179"
            transform="translate(172.629 192.24)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23038"
            data-name="Path 23038"
            d="M341.156-336.432a2.4,2.4,0,0,1-2.4-2.406,2.4,2.4,0,0,1,2.4-2.4,2.394,2.394,0,0,1,2.394,2.4,2.4,2.4,0,0,1-2.394,2.406"
            transform="translate(203.479 59.78)"
            fill="#fff"
          />
          <path
            id="Path_23039"
            data-name="Path 23039"
            d="M120.766-353.623a2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4"
            transform="translate(68.301 49.234)"
            fill="#fff"
          />
          <path
            id="Path_23040"
            data-name="Path 23040"
            d="M303.5-390.8a2.394,2.394,0,0,1-2.394-2.4,2.4,2.4,0,0,1,2.394-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4"
            transform="translate(180.39 26.435)"
            fill="#fff"
          />
          <path
            id="Path_23041"
            data-name="Path 23041"
            d="M257.571-363.99a2.4,2.4,0,0,1,1.986,2.752,2.4,2.4,0,0,1-2.754,1.981,2.4,2.4,0,0,1-1.986-2.754,2.4,2.4,0,0,1,2.754-1.98"
            transform="translate(151.977 45.802)"
            fill="#fff"
          />
          <path
            id="Path_23042"
            data-name="Path 23042"
            d="M361.748-354.8a2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4"
            transform="translate(216.11 48.511)"
            fill="#fff"
          />
          <path
            id="Path_23043"
            data-name="Path 23043"
            d="M355.492-334.247a2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4"
            transform="translate(212.274 61.119)"
            fill="#fff"
          />
          <path
            id="Path_23044"
            data-name="Path 23044"
            d="M327.651-348.274a5.793,5.793,0,0,1-5.792,5.79,5.794,5.794,0,0,1-5.8-5.79,5.8,5.8,0,0,1,5.8-5.8,5.794,5.794,0,0,1,5.792,5.8"
            transform="translate(189.561 51.905)"
            fill="#fff"
          />
          <path
            id="Path_23045"
            data-name="Path 23045"
            d="M540.748-422.456a2.4,2.4,0,0,1-2.4-2.406,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.406"
            transform="translate(325.901 7.013)"
            fill="#fff"
          />
          <path
            id="Path_23046"
            data-name="Path 23046"
            d="M535.666-347.969a2.4,2.4,0,0,1-2.4-2.406,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.406"
            transform="translate(322.784 52.701)"
            fill="#fff"
          />
          <path
            id="Path_23047"
            data-name="Path 23047"
            d="M498.014-402.333a2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.406,2.4,2.4,0,0,1,2.4,2.406,2.4,2.4,0,0,1-2.4,2.4"
            transform="translate(299.693 19.356)"
            fill="#fff"
          />
          <path
            id="Path_23048"
            data-name="Path 23048"
            d="M452.082-375.533a2.4,2.4,0,0,1,1.983,2.759,2.4,2.4,0,0,1-2.751,1.981,2.4,2.4,0,0,1-1.986-2.754,2.4,2.4,0,0,1,2.754-1.986"
            transform="translate(271.282 38.722)"
            fill="#fff"
          />
          <path
            id="Path_23049"
            data-name="Path 23049"
            d="M556.255-366.345a2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4"
            transform="translate(335.416 41.431)"
            fill="#fff"
          />
          <path
            id="Path_23050"
            data-name="Path 23050"
            d="M550-345.785a2.4,2.4,0,0,1-2.4-2.406,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.406"
            transform="translate(331.58 54.04)"
            fill="#fff"
          />
          <path
            id="Path_23051"
            data-name="Path 23051"
            d="M522.164-359.82a5.8,5.8,0,0,1-5.794,5.8,5.8,5.8,0,0,1-5.8-5.8,5.8,5.8,0,0,1,5.8-5.79,5.793,5.793,0,0,1,5.794,5.79"
            transform="translate(308.865 44.828)"
            fill="#fff"
          />
          <path
            id="Path_23052"
            data-name="Path 23052"
            d="M461.761-394.454a2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4"
            transform="translate(277.454 24.191)"
            fill="#fff"
          />
          <path
            id="Path_23053"
            data-name="Path 23053"
            d="M387.548-357.281a2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4"
            transform="translate(231.934 46.99)"
            fill="#fff"
          />
          <path
            id="Path_23054"
            data-name="Path 23054"
            d="M264.615-315.8a6,6,0,0,1-5.994-6,6,6,0,0,1,5.994-6,5.994,5.994,0,0,1,6,6,5.994,5.994,0,0,1-6,6"
            transform="translate(154.329 68.024)"
            fill="#fff"
          />
          <path
            id="Path_23055"
            data-name="Path 23055"
            d="M256.121-345.344a2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4"
            transform="translate(151.324 54.312)"
            fill="#fff"
          />
          <path
            id="Path_23056"
            data-name="Path 23056"
            d="M247.8-352.513a2.4,2.4,0,0,1-2.4,2.406,2.4,2.4,0,0,1-2.4-2.406,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4"
            transform="translate(144.753 51.388)"
            fill="#fff"
          />
          <path
            id="Path_23057"
            data-name="Path 23057"
            d="M234.306-341.411a2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4"
            transform="translate(136.471 58.199)"
            fill="#fff"
          />
          <path
            id="Path_23058"
            data-name="Path 23058"
            d="M255.769-302.038a2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4"
            transform="translate(149.634 82.349)"
            fill="#fff"
          />
          <path
            id="Path_23059"
            data-name="Path 23059"
            d="M277.311-341.377a5.8,5.8,0,0,1-5.794,5.792,5.8,5.8,0,0,1-5.8-5.792,5.8,5.8,0,0,1,5.8-5.8,5.8,5.8,0,0,1,5.794,5.8"
            transform="translate(158.684 56.136)"
            fill="#fff"
          />
          <path
            id="Path_23060"
            data-name="Path 23060"
            d="M205.1-329.124a2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.406,2.4,2.4,0,0,1,2.4,2.406,2.4,2.4,0,0,1-2.4,2.4"
            transform="translate(120.029 64.259)"
            fill="#fff"
          />
          <path
            id="Path_23061"
            data-name="Path 23061"
            d="M256.912-337.758a2.4,2.4,0,0,1-2.4,2.406,2.406,2.406,0,0,1-2.4-2.406,2.4,2.4,0,0,1,2.4-2.394,2.4,2.4,0,0,1,2.4,2.394"
            transform="translate(150.335 60.443)"
            fill="#fff"
          />
          <path
            id="Path_23062"
            data-name="Path 23062"
            d="M281.638-67.935a6,6,0,0,1,6,5.995,6,6,0,0,1-6,6,5.993,5.993,0,0,1-5.994-6,5.992,5.992,0,0,1,5.994-5.995"
            transform="translate(164.77 227.41)"
            fill="#fff"
          />
          <path
            id="Path_23063"
            data-name="Path 23063"
            d="M189.929-111.682a6,6,0,0,1,6,5.994,6,6,0,0,1-6,6,5.994,5.994,0,0,1-5.994-6,5.993,5.993,0,0,1,5.994-5.994"
            transform="translate(108.519 200.577)"
            fill="#fff"
          />
          <path
            id="Path_23064"
            data-name="Path 23064"
            d="M328.565-284.507a5.994,5.994,0,0,1,6,6,5.994,5.994,0,0,1-6,6,6,6,0,0,1-6-6,6,6,0,0,1,6-6"
            transform="translate(193.553 94.573)"
            fill="#fff"
          />
          <path
            id="Path_23065"
            data-name="Path 23065"
            d="M162.014-221.778a6,6,0,0,1,6,6,6,6,0,0,1-6,6,5.992,5.992,0,0,1-6-6,5.992,5.992,0,0,1,6-6"
            transform="translate(91.397 133.049)"
            fill="#fff"
          />
          <path
            id="Path_23066"
            data-name="Path 23066"
            d="M299.134-294.633a6,6,0,0,1,6,6,6,6,0,0,1-6,6,6,6,0,0,1-6-6,6,6,0,0,1,6-6"
            transform="translate(175.501 88.362)"
            fill="#fff"
          />
          <path
            id="Path_23067"
            data-name="Path 23067"
            d="M357.275-56.657a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(213.37 234.328)"
            fill="#fff"
          />
          <path
            id="Path_23068"
            data-name="Path 23068"
            d="M208.2-133.681a2.4,2.4,0,0,1,2.4,2.394,2.4,2.4,0,0,1-2.4,2.409,2.4,2.4,0,0,1-2.4-2.409,2.4,2.4,0,0,1,2.4-2.394"
            transform="translate(121.934 187.084)"
            fill="#fff"
          />
          <path
            id="Path_23069"
            data-name="Path 23069"
            d="M185.5-293.039a2.4,2.4,0,0,1,2.4,2.406,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.406"
            transform="translate(108.007 89.34)"
            fill="#fff"
          />
          <path
            id="Path_23070"
            data-name="Path 23070"
            d="M213.284-208.168a2.4,2.4,0,0,1,2.4,2.394,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.394"
            transform="translate(125.05 141.396)"
            fill="#fff"
          />
          <path
            id="Path_23071"
            data-name="Path 23071"
            d="M140.271-114.195a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(80.265 199.036)"
            fill="#fff"
          />
          <path
            id="Path_23072"
            data-name="Path 23072"
            d="M118.57-270.256a2.4,2.4,0,0,1,2.393,2.4,2.4,2.4,0,0,1-2.393,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(66.953 103.314)"
            fill="#fff"
          />
          <path
            id="Path_23073"
            data-name="Path 23073"
            d="M136.863-203.626a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(78.177 144.182)"
            fill="#fff"
          />
          <path
            id="Path_23074"
            data-name="Path 23074"
            d="M334.326-257.7a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(199.291 111.019)"
            fill="#fff"
          />
          <path
            id="Path_23075"
            data-name="Path 23075"
            d="M288.006-228.079a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(170.879 129.184)"
            fill="#fff"
          />
          <path
            id="Path_23076"
            data-name="Path 23076"
            d="M153.324-284.5a2.4,2.4,0,0,1,2.4,2.394,2.4,2.4,0,0,1-2.4,2.409,2.4,2.4,0,0,1-2.4-2.409,2.4,2.4,0,0,1,2.4-2.394"
            transform="translate(88.272 94.576)"
            fill="#fff"
          />
          <path
            id="Path_23077"
            data-name="Path 23077"
            d="M298.741-248.84a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.406,2.4,2.4,0,0,1-2.4-2.406,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(177.467 116.45)"
            fill="#fff"
          />
          <path
            id="Path_23078"
            data-name="Path 23078"
            d="M130.356-65.62a2.407,2.407,0,0,1-1.981-2.76,2.4,2.4,0,0,1,2.754-1.981,2.4,2.4,0,0,1,1.984,2.754,2.4,2.4,0,0,1-2.757,1.986"
            transform="translate(74.422 225.902)"
            fill="#fff"
          />
          <path
            id="Path_23079"
            data-name="Path 23079"
            d="M233.491-82.128a2.4,2.4,0,0,1-1.986-2.754,2.4,2.4,0,0,1,2.756-1.986,2.4,2.4,0,0,1,1.984,2.754,2.406,2.406,0,0,1-2.754,1.986"
            transform="translate(137.678 215.778)"
            fill="#fff"
          />
          <path
            id="Path_23080"
            data-name="Path 23080"
            d="M52.517-106.057a5.993,5.993,0,0,1,5.995-6,5.994,5.994,0,0,1,6,6,6,6,0,0,1-6,5.994,6,6,0,0,1-5.995-5.994"
            transform="translate(27.913 200.35)"
            fill="#fff"
          />
          <path
            id="Path_23081"
            data-name="Path 23081"
            d="M81.452-69.763a5.993,5.993,0,0,1,5.995-5.994,6,6,0,0,1,6,5.994,6,6,0,0,1-6,5.995,5.993,5.993,0,0,1-5.995-5.995"
            transform="translate(45.661 222.612)"
            fill="#fff"
          />
          <path
            id="Path_23082"
            data-name="Path 23082"
            d="M110.559-219.8a5.992,5.992,0,0,1,5.992-6,5.993,5.993,0,0,1,6,6,5.994,5.994,0,0,1-6,6,5.993,5.993,0,0,1-5.992-6"
            transform="translate(63.513 130.582)"
            fill="#fff"
          />
          <path
            id="Path_23083"
            data-name="Path 23083"
            d="M17.658-175.181a6,6,0,0,1,5.992-6,6,6,0,0,1,6,6,6,6,0,0,1-6,6,6,6,0,0,1-5.992-6"
            transform="translate(6.531 157.949)"
            fill="#fff"
          />
          <path
            id="Path_23084"
            data-name="Path 23084"
            d="M26.492-78.35a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(11.95 219.55)"
            fill="#fff"
          />
          <path
            id="Path_23085"
            data-name="Path 23085"
            d="M65.27-150.237a2.4,2.4,0,0,1,2.4-2.394,2.4,2.4,0,0,1,2.4,2.394,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(35.735 175.461)"
            fill="#fff"
          />
          <path
            id="Path_23086"
            data-name="Path 23086"
            d="M53.257-250.53a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(28.367 113.942)"
            fill="#fff"
          />
          <path
            id="Path_23087"
            data-name="Path 23087"
            d="M66.758-261.629a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(36.647 107.133)"
            fill="#fff"
          />
          <path
            id="Path_23088"
            data-name="Path 23088"
            d="M45.3-301a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(23.483 82.984)"
            fill="#fff"
          />
          <path
            id="Path_23089"
            data-name="Path 23089"
            d="M143.069-243.328a2.4,2.4,0,0,1,2.4-2.406,2.406,2.406,0,0,1,2.406,2.406,2.4,2.4,0,0,1-2.406,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(83.454 118.355)"
            fill="#fff"
          />
          <path
            id="Path_23090"
            data-name="Path 23090"
            d="M151.956-193.074a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(88.905 149.183)"
            fill="#fff"
          />
          <path
            id="Path_23091"
            data-name="Path 23091"
            d="M30.375-210.887a5.8,5.8,0,0,1,5.8-5.8,5.792,5.792,0,0,1,5.791,5.8,5.791,5.791,0,0,1-5.791,5.79,5.8,5.8,0,0,1-5.8-5.79"
            transform="translate(14.331 136.173)"
            fill="#fff"
          />
          <path
            id="Path_23092"
            data-name="Path 23092"
            d="M26.334-259.082a5.788,5.788,0,0,1,5.791-5.792,5.791,5.791,0,0,1,5.8,5.792,5.8,5.8,0,0,1-5.8,5.8,5.792,5.792,0,0,1-5.791-5.8"
            transform="translate(11.853 106.615)"
            fill="#fff"
          />
          <path
            id="Path_23093"
            data-name="Path 23093"
            d="M105.969-97.661a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(60.698 207.706)"
            fill="#fff"
          />
          <path
            id="Path_23094"
            data-name="Path 23094"
            d="M153.434-106.521a2.4,2.4,0,0,1-2.757,1.98,2.4,2.4,0,0,1-1.98-2.752,2.4,2.4,0,0,1,2.756-1.988,2.4,2.4,0,0,1,1.981,2.76"
            transform="translate(86.887 202.031)"
            fill="#fff"
          />
          <path
            id="Path_23095"
            data-name="Path 23095"
            d="M341.9-175.181a6,6,0,0,1,5.994-6,6,6,0,0,1,6,6,6,6,0,0,1-6,6,6,6,0,0,1-5.994-6"
            transform="translate(205.411 157.949)"
            fill="#fff"
          />
          <path
            id="Path_23096"
            data-name="Path 23096"
            d="M315.878-78.35a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(189.448 219.55)"
            fill="#fff"
          />
          <path
            id="Path_23097"
            data-name="Path 23097"
            d="M354.654-150.237a2.4,2.4,0,0,1,2.4-2.394,2.4,2.4,0,0,1,2.4,2.394,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(213.232 175.461)"
            fill="#fff"
          />
          <path
            id="Path_23098"
            data-name="Path 23098"
            d="M342.641-250.53a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.406,2.4,2.406,2.406,0,0,1-2.406,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(205.864 113.942)"
            fill="#fff"
          />
          <path
            id="Path_23099"
            data-name="Path 23099"
            d="M356.141-261.629a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(214.144 107.133)"
            fill="#fff"
          />
          <path
            id="Path_23100"
            data-name="Path 23100"
            d="M334.681-301a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(200.981 82.984)"
            fill="#fff"
          />
          <path
            id="Path_23101"
            data-name="Path 23101"
            d="M319.762-210.887a5.792,5.792,0,0,1,5.79-5.8,5.8,5.8,0,0,1,5.8,5.8,5.8,5.8,0,0,1-5.8,5.79,5.791,5.791,0,0,1-5.79-5.79"
            transform="translate(191.831 136.173)"
            fill="#fff"
          />
          <path
            id="Path_23102"
            data-name="Path 23102"
            d="M315.716-259.082a5.791,5.791,0,0,1,5.8-5.792,5.791,5.791,0,0,1,5.8,5.792,5.8,5.8,0,0,1-5.8,5.8,5.8,5.8,0,0,1-5.8-5.8"
            transform="translate(189.349 106.615)"
            fill="#fff"
          />
          <path
            id="Path_23103"
            data-name="Path 23103"
            d="M476.15-79.478a6,6,0,0,1,5.992,5.995,6,6,0,0,1-5.992,6,6,6,0,0,1-6-6,6,6,0,0,1,6-5.995"
            transform="translate(284.074 220.33)"
            fill="#fff"
          />
          <path
            id="Path_23104"
            data-name="Path 23104"
            d="M523.071-296.049a6,6,0,0,1,6,6,6,6,0,0,1-6,6,6,6,0,0,1-5.992-6,6,6,0,0,1,5.992-6"
            transform="translate(312.857 87.494)"
            fill="#fff"
          />
          <path
            id="Path_23105"
            data-name="Path 23105"
            d="M493.643-306.172a6,6,0,0,1,6,6,5.991,5.991,0,0,1-6,5.992,6,6,0,0,1-6-5.992,6,6,0,0,1,6-6"
            transform="translate(294.805 81.285)"
            fill="#fff"
          />
          <path
            id="Path_23106"
            data-name="Path 23106"
            d="M516.472-84.658a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.394-2.4,2.394,2.394,0,0,1,2.394-2.4"
            transform="translate(311.016 217.153)"
            fill="#fff"
          />
          <path
            id="Path_23107"
            data-name="Path 23107"
            d="M528.833-269.234a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(318.597 103.941)"
            fill="#fff"
          />
          <path
            id="Path_23108"
            data-name="Path 23108"
            d="M482.512-239.618a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(290.185 122.106)"
            fill="#fff"
          />
          <path
            id="Path_23109"
            data-name="Path 23109"
            d="M445.445-165.347a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(267.447 167.661)"
            fill="#fff"
          />
          <path
            id="Path_23110"
            data-name="Path 23110"
            d="M491.377-192.153a2.4,2.4,0,0,1-1.983-2.754,2.4,2.4,0,0,1,2.754-1.981,2.4,2.4,0,0,1,1.986,2.754,2.4,2.4,0,0,1-2.757,1.981"
            transform="translate(295.857 148.296)"
            fill="#fff"
          />
          <path
            id="Path_23111"
            data-name="Path 23111"
            d="M536.412-117.6a5.993,5.993,0,0,1,6-5.994,5.994,5.994,0,0,1,6,5.994,5.992,5.992,0,0,1-6,6,5.992,5.992,0,0,1-6-6"
            transform="translate(324.715 193.273)"
            fill="#fff"
          />
          <path
            id="Path_23112"
            data-name="Path 23112"
            d="M501.551-186.726a6,6,0,0,1,5.994-5.994,6,6,0,0,1,6,5.994,6,6,0,0,1-6,6,6,6,0,0,1-5.994-6"
            transform="translate(303.333 150.872)"
            fill="#fff"
          />
          <path
            id="Path_23113"
            data-name="Path 23113"
            d="M510.385-89.895a2.4,2.4,0,0,1,2.4-2.394,2.4,2.4,0,0,1,2.4,2.394,2.4,2.4,0,0,1-2.4,2.406,2.4,2.4,0,0,1-2.4-2.406"
            transform="translate(308.751 212.473)"
            fill="#fff"
          />
          <path
            id="Path_23114"
            data-name="Path 23114"
            d="M549.165-161.775a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(332.537 168.381)"
            fill="#fff"
          />
          <path
            id="Path_23115"
            data-name="Path 23115"
            d="M537.151-262.069a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(325.168 106.864)"
            fill="#fff"
          />
          <path
            id="Path_23116"
            data-name="Path 23116"
            d="M550.651-273.175a2.4,2.4,0,0,1,2.4-2.394,2.4,2.4,0,0,1,2.4,2.394,2.4,2.4,0,0,1-2.4,2.406,2.4,2.4,0,0,1-2.4-2.406"
            transform="translate(333.449 100.055)"
            fill="#fff"
          />
          <path
            id="Path_23117"
            data-name="Path 23117"
            d="M514.269-222.433a5.794,5.794,0,0,1,5.8-5.79,5.792,5.792,0,0,1,5.792,5.79,5.793,5.793,0,0,1-5.792,5.8,5.8,5.8,0,0,1-5.8-5.8"
            transform="translate(311.134 129.095)"
            fill="#fff"
          />
          <path
            id="Path_23118"
            data-name="Path 23118"
            d="M428.8-225.436a6,6,0,0,1,5.989,6,5.991,5.991,0,0,1-5.989,6,5.994,5.994,0,0,1-5.994-6,6,6,0,0,1,5.994-6"
            transform="translate(255.032 130.805)"
            fill="#fff"
          />
          <path
            id="Path_23119"
            data-name="Path 23119"
            d="M474.984-137.339a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(285.566 184.84)"
            fill="#fff"
          />
          <path
            id="Path_23120"
            data-name="Path 23120"
            d="M452.279-296.694a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(271.641 87.098)"
            fill="#fff"
          />
          <path
            id="Path_23121"
            data-name="Path 23121"
            d="M480.066-211.826a2.4,2.4,0,0,1,2.4,2.4,2.393,2.393,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(288.685 139.153)"
            fill="#fff"
          />
          <path
            id="Path_23122"
            data-name="Path 23122"
            d="M407.054-117.849a2.4,2.4,0,0,1,2.4,2.393,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.393"
            transform="translate(243.898 196.795)"
            fill="#fff"
          />
          <path
            id="Path_23123"
            data-name="Path 23123"
            d="M385.346-273.913a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(230.586 101.071)"
            fill="#fff"
          />
          <path
            id="Path_23124"
            data-name="Path 23124"
            d="M403.649-207.284a2.394,2.394,0,0,1,2.393,2.4,2.4,2.4,0,0,1-2.393,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(241.811 141.939)"
            fill="#fff"
          />
          <path
            id="Path_23125"
            data-name="Path 23125"
            d="M420.106-288.161a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(251.905 92.332)"
            fill="#fff"
          />
          <path
            id="Path_23126"
            data-name="Path 23126"
            d="M336.524-90.01a6,6,0,0,1,6-5.995,5.994,5.994,0,0,1,6,5.995,5.992,5.992,0,0,1-6,5.995,5.993,5.993,0,0,1-6-5.995"
            transform="translate(202.112 210.193)"
            fill="#fff"
          />
          <path
            id="Path_23127"
            data-name="Path 23127"
            d="M377.34-223.462a5.993,5.993,0,0,1,5.994-5.994,6,6,0,0,1,6,5.994,6,6,0,0,1-6,6,6,6,0,0,1-5.994-6"
            transform="translate(227.147 128.339)"
            fill="#fff"
          />
          <path
            id="Path_23128"
            data-name="Path 23128"
            d="M333.536-265.287a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(200.279 104.889)"
            fill="#fff"
          />
          <path
            id="Path_23129"
            data-name="Path 23129"
            d="M409.851-246.989a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(247.088 116.114)"
            fill="#fff"
          />
          <path
            id="Path_23130"
            data-name="Path 23130"
            d="M418.738-196.725a2.4,2.4,0,0,1,2.4-2.406,2.4,2.4,0,0,1,2.4,2.406,2.4,2.4,0,0,1-2.4,2.394,2.4,2.4,0,0,1-2.4-2.394"
            transform="translate(252.539 146.939)"
            fill="#fff"
          />
          <path
            id="Path_23131"
            data-name="Path 23131"
            d="M372.749-101.321a2.4,2.4,0,0,1,2.4-2.393,2.4,2.4,0,0,1,2.4,2.393,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(224.331 205.464)"
            fill="#fff"
          />
          <path
            id="Path_23132"
            data-name="Path 23132"
            d="M257.177-92.181a2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4"
            transform="translate(151.972 209.593)"
            fill="#fff"
          />
          <path
            id="Path_23133"
            data-name="Path 23133"
            d="M99.017-151.226a2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4"
            transform="translate(53.491 174.851)"
            fill="#fff"
          />
          <path
            id="Path_23134"
            data-name="Path 23134"
            d="M155.726-163.16a5.993,5.993,0,0,1,5.99,6,5.993,5.993,0,0,1-5.99,6,6,6,0,0,1-6-6,6,6,0,0,1,6-6"
            transform="translate(87.538 169.003)"
            fill="#fff"
          />
          <path
            id="Path_23135"
            data-name="Path 23135"
            d="M244.21-192.117a2.4,2.4,0,0,1,2.4,2.393,2.4,2.4,0,0,1-2.4,2.409,2.407,2.407,0,0,1-2.4-2.409,2.4,2.4,0,0,1,2.4-2.393"
            transform="translate(144.018 151.242)"
            fill="#fff"
          />
          <path
            id="Path_23136"
            data-name="Path 23136"
            d="M249.294-266.6a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(147.137 105.553)"
            fill="#fff"
          />
          <path
            id="Path_23137"
            data-name="Path 23137"
            d="M228.7-248.236a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(134.506 116.82)"
            fill="#fff"
          />
          <path
            id="Path_23138"
            data-name="Path 23138"
            d="M234.954-268.792a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(138.341 104.212)"
            fill="#fff"
          />
          <path
            id="Path_23139"
            data-name="Path 23139"
            d="M265.379-252.177a5.8,5.8,0,0,1,5.787-5.8,5.8,5.8,0,0,1,5.8,5.8,5.8,5.8,0,0,1-5.8,5.79,5.792,5.792,0,0,1-5.787-5.79"
            transform="translate(158.474 110.844)"
            fill="#fff"
          />
          <path
            id="Path_23140"
            data-name="Path 23140"
            d="M88.24-164.586a6,6,0,0,1-6,5.994,6,6,0,0,1-5.995-5.994,6,6,0,0,1,5.995-6,6,6,0,0,1,6,6"
            transform="translate(42.468 164.451)"
            fill="#fff"
          />
          <path
            id="Path_23141"
            data-name="Path 23141"
            d="M197.79-71.233a2.4,2.4,0,0,1-2.4,2.406,2.4,2.4,0,0,1-2.4-2.406,2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4"
            transform="translate(114.073 223.915)"
            fill="#fff"
          />
          <path
            id="Path_23142"
            data-name="Path 23142"
            d="M253.268-124.2a2.4,2.4,0,0,1,2.4,2.394,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.394"
            transform="translate(149.571 192.898)"
            fill="#fff"
          />
          <path
            id="Path_23143"
            data-name="Path 23143"
            d="M230.027-113.232a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(135.317 199.627)"
            fill="#fff"
          />
          <path
            id="Path_23144"
            data-name="Path 23144"
            d="M257.031-190.684a2.4,2.4,0,0,1,2.4,2.406,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.406"
            transform="translate(151.881 152.12)"
            fill="#fff"
          />
          <path
            id="Path_23145"
            data-name="Path 23145"
            d="M202.9-245.758a2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4,2.4,2.4,0,0,1,2.4-2.4"
            transform="translate(118.682 118.34)"
            fill="#fff"
          />
          <path
            id="Path_23146"
            data-name="Path 23146"
            d="M52.4-68.117a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(27.842 225.827)"
            fill="#fff"
          />
          <path
            id="Path_23147"
            data-name="Path 23147"
            d="M227.818-120.755a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.406,2.4,2.4,2.4,0,0,1-2.406,2.393,2.4,2.4,0,0,1-2.4-2.393"
            transform="translate(135.436 193.54)"
            fill="#fff"
          />
          <path
            id="Path_23148"
            data-name="Path 23148"
            d="M236.675-85.175a2.4,2.4,0,0,1,2.4-2.4,2.4,2.4,0,0,1,2.4,2.4,2.4,2.4,0,0,1-2.4,2.4,2.4,2.4,0,0,1-2.4-2.4"
            transform="translate(140.868 215.364)"
            fill="#fff"
          />
          <path
            id="Path_23149"
            data-name="Path 23149"
            d="M187.869-172.447a2.4,2.4,0,0,1-2.752,1.981,2.4,2.4,0,0,1-1.984-2.754,2.392,2.392,0,0,1,2.754-1.981,2.4,2.4,0,0,1,1.983,2.754"
            transform="translate(108.008 161.598)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_7336"
          data-name="Group 7336"
          transform="translate(778.728 394)"
        >
          <path
            id="Path_23006-2"
            data-name="Path 23006"
            d="M1.269-290.569,66.539-438.658,311.15-319.682l96.135-56.156L904.207-192.716V2.022H7.009Z"
            transform="translate(-7.008 438.718)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23007-2"
            data-name="Path 23007"
            d="M481.735-225.3C336.282-280.281,114.666-371,105.254-377.486l38.062-22.234L481.735-275.009Z"
            transform="translate(256.96 462.601)"
            fill="#fff"
          />
          <path
            id="Path_23008-2"
            data-name="Path 23008"
            d="M432.3-389.1s85.152,60.023,165.133,116.51v64.9a224.481,224.481,0,0,0-38.521-20.846c-5.369-1.792-3.58,16.117,0,19.694,11.16,13.254,25.3,24.6,38.521,36.488v60.64c-30.693-13.806-57.44-41.576-81.5-63.1-14.324-12.539-32.237-12.539-48.352-8.956-12.538,1.792-12.538,16.113-10.747,26.859,5.375,14.33,21.493,26.862,14.33,42.98-5.375,14.33-25.073,14.33-37.609,10.747-26.861-7.168-42.98-34.024-42.98-64.473-1.789-3.58-3.58-7.157-7.16-8.949-12.541-1.791-21.49,7.158-25.073,16.116-22.272,43.751-39.6,88.207-58.1,132.112H7.01Z"
            transform="translate(299.766 469.113)"
            fill="#e8fffc"
          />
          <path
            id="Path_23009-2"
            data-name="Path 23009"
            d="M255.415-399.721s186.543,98.449,168.238,98.007-44.716-15.224-48.3-17.019,61.956,39.4,63.434,51.494c.121,1.012-.782,1.378-2.517,1.218l82.943,61.309-12.534,22.506L151.5-340.214Z"
            transform="translate(144.862 462.601)"
            fill="currentColor"
            class="text-gray-snow"
          />
          <path
            id="Path_23010-2"
            data-name="Path 23010"
            d="M282.677-350.068c-15.224,4.132-24.117,19.694-32.549,32.354v-79.924Q266.221-408.831,282.4-419.9c2.526-1.715,24.607-19.977,27.261-18.739.111.05,389.9,182.21,439.372,208.975,24.6,13.3-33.443,12.868-36.378,12.67-48.641-3.364-97.7-10.359-143.778-26.753a593.252,593.252,0,0,1-66.064-27.842c-12.426-6.207-22.148-17.3-34.687-23.486-22.65-11.182-1.215,30.165,1.3,36.644A63.443,63.443,0,0,1,468.586-211c-4.351,10.038-20.133,33.923-33.95,27.2-12.47-6.057-9.837-57.516-12.839-69.986-4.356-18.076-32.795-86.486-55.722-48.64-17.889,29.539-19.013,65.02-24.258,98.221-3.185,20.151-9.38,50.355-24.031,65.359-23.674,24.247-42.207-23.1-46.053-40.7-8.033-36.815,7.12-72.34,17.3-106.992,2.922-9.919,21.882-71.191-6.355-63.531"
            transform="translate(-250.126 438.695)"
            fill="#fff"
          />
          <path
            id="Path_23011-2"
            data-name="Path 23011"
            d="M286.6-364.626c-12.647,4.48-28.607,42.781-32.432,48.528l.007-70.073c.4-.273,0-2.477,0-2.477,11.777-11.751,14.368-14.573,22.364-11.7,5.374,1.794,16.118-23.279,28.653-12.533s12.537,30.443,30.444,37.612c-1.791-8.961-1.791-23.282,3.578-26.866,16.374-10.929,38.874,19.5,46.841,29.386,10.719,13.309,23.712,31.283,42.557,31.941,9.543-22.974-37.041-27.732-30-51.884,3.783,6.278,13.807,9.311,20.194,12.184,44.722,20.114,91.81,34.245,137.625,51.536,17.468,6.594,34.631,13.823,51.894,20.945,30.678,12.662,76.22,29.941,104.762,46.865,35.6,21.1,52.871,12.583,27.375,31.962-14.281,10.853-43.8,1.738-60.157.189-39.758-3.761-77.461-15.842-113.847-31.874-27.187-11.981-76.457-53.94-104.739-62.936-18.675-5.936,6.028,53.83,4.622,64.783-2.146,16.724-3.722,39.211-13.488,53.594-12.734,18.76-20.336-14.582-22.137-23.4-4.838-23.678-5.673-48.373-17.621-70.07-9.783-17.771-26.428-40.991-41.784-17.19-18.134,28.09-20.528,62.166-24.905,94.261-2.754,20.215-6.775,58.11-24.165,71.885-8.606,6.81-21.558,2.2-28.8-5.177-38.374-39.1-13.133-101.855-.323-145.891,2.8-9.591,21.709-73.6-6.523-63.605"
            transform="translate(-254.168 452.882)"
            fill="currentColor"
            class="text-gray-snow/40"
          />
          <path
            id="Path_23012-2"
            data-name="Path 23012"
            d="M597.433-28.375H7.01L432.3-389.1s85.152,60.02,165.133,116.507Z"
            transform="translate(299.766 469.113)"
            fill="#f5f5fd"
          />
          <path
            id="Path_23013-2"
            data-name="Path 23013"
            d="M123.878-237.491c-7.165-1.792-10.745,1.791-16.117,5.371C79.715-204.073,46.8-176.055,7.009-174.081L260.515-389.1s85.151,60.02,165.132,116.506v22.987c-34.89-27.992-68.892-50.634-101.2-48.765-18.406,1.063-25.07,14.32-17.91,26.859,17.91,34.026,66.259,37.609,84.166,71.63,5.375,10.747,1.792,25.076-12.535,19.7-35.814-16.116-46.555-82.375-85.955-68.05-21.492,8.954-7.166,48.351-23.282,66.264-12.536,10.742-34.026-17.913-39.4-34.024a34.944,34.944,0,0,1,0-26.866c7.163-12.537,16.117-25.07,8.954-35.817-8.954-16.116-34.023-5.376-37.607,7.163C186.557-201.68,111.355-90.457,62.991-146.158c-27.03-31.13,46.56-55.522,60.887-91.332"
            transform="translate(471.552 469.113)"
            fill="currentColor"
            class="text-gray-snow/60"
          />
          <path
            id="Path_23014-2"
            data-name="Path 23014"
            d="M170.561-389.1s85.152,60.023,165.133,116.51v10.622c-52.313-24.268-89.7-88.19-151.34-79.39-3.775.542,3.205,7.673,3.81,8.288,3.217,3.29,6.732,6.266,10.106,9.4,2.22,2.057,4.8,6.323,4.787,9.4-.015,4.734-5.862,3.107-8.825,3.209-7.27.253-15.334,6.779-15.206,14.462.132,7.9,6.637,13.765,10.817,19.835,3.4,4.943,6.795,10.285,8.019,16.248.363,1.791-.021,4.459-2.243,4.69-3.48.371-5.614-5.626-7.219-7.831a47.862,47.862,0,0,1-6.236-11.732c-3.22-8.843-4.782-18.107-6.628-27.3-1.033-5.171-2.685-10.227-3.719-15.38-1.791-8.956-12.534-12.541-14.323-8.956-5.374,3.578-3.583,14.325-7.17,21.488-5.368,5.374-16.111,5.374-17.9-3.58-1.791-10.747,3.58-19.7,3.58-28.655-1.788-8.949-21.487-3.585-23.279,3.583C92.336-288.332,60.654-244.111,7.01-250.384Z"
            transform="translate(561.505 469.113)"
            fill="#fff"
          />
          <path
            id="Path_23015-2"
            data-name="Path 23015"
            d="M7.009,78.547h897.2V-93.569S724.765-137.488,223.532-246.237C71.286-279.268-49.141-260.458-49.141-260.458Z"
            transform="translate(-7.008 551.453)"
            fill="#fff"
          />
          <path
            id="Path_23016-2"
            data-name="Path 23016"
            d="M478.007-136.9s-93.325-46.449-190.12-28.214c0,0-107.755-64.7-185.248,16.935,0,0,181.322,101.847,375.368,11.279"
            transform="translate(264.907 591.58)"
            fill="#fff"
          />
          <path
            id="Path_23017-2"
            data-name="Path 23017"
            d="M262.473-146.5s-83.892-68.19-159.834-5.984c77.117-74.983,180.982-12.634,180.982-12.634,96.8-18.236,190.12,28.213,190.12,28.213-4.506,2.107-115.922-35.108-211.268-9.595"
            transform="translate(269.173 591.584)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23018-2"
            data-name="Path 23018"
            d="M595.258-189.74s-68.579-47.567-147.371-44.279c0,0-77.911-63.683-148.718-7.943,0,0,131.913,101.595,296.089,52.221"
            transform="translate(-169.418 545.337)"
            fill="#fff"
          />
          <path
            id="Path_23019-2"
            data-name="Path 23019"
            d="M425.108-221.712s-58.6-63.694-125.939-23.169c69.742-50.513,144.844,10.86,144.844,10.86,78.79-3.286,147.369,44.279,147.369,44.279-3.812,1.145-87.789-41.189-166.275-31.969"
            transform="translate(-165.542 545.34)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23020-2"
            data-name="Path 23020"
            d="M8.481-128.837c61.74-80.905,164.887-33.2,164.887-33.2,26.325-7.96,52.939-10.334,77.174-9.824v57.728C-27-29.737,8.481-128.837,8.481-128.837"
            transform="translate(646.657 599.819)"
            fill="#fff"
          />
          <path
            id="Path_23021-2"
            data-name="Path 23021"
            d="M168.478-162.037c26.328-7.959,52.942-10.33,77.174-9.824v.419c-12.184,1.981-68.535,11.821-94.379,28.193,0,0-82.029-53.157-144.263,10.132,62.048-74.9,161.467-28.921,161.467-28.921"
            transform="translate(651.546 599.822)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23022-2"
            data-name="Path 23022"
            d="M344.927-70.653c-42.346,42.346-59.694,39-87.9,39.849-80.129,1.612-136.34-15.254-136.34-15.254,65.157-108.947,199.7-64.3,199.7-64.3a285.659,285.659,0,0,1,49.619-16.013c14.615,13.359-1.644,32.285-25.08,55.72"
            transform="translate(338.238 630.262)"
            fill="#fff"
          />
          <path
            id="Path_23023-2"
            data-name="Path 23023"
            d="M320.2-110.359a285.326,285.326,0,0,1,49.622-16.013c-46.594,12.689-68.311,41.713-68.311,41.713S192.372-139.022,124.127-51.843c66.395-101.535,196.07-58.517,196.07-58.517"
            transform="translate(338.427 630.262)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23024-2"
            data-name="Path 23024"
            d="M571.176-131.265c-19.42,19.418-27.38,17.886-40.318,18.273-36.746.741-62.531-6.989-62.531-6.989,29.888-49.974,91.6-29.5,91.6-29.5a130.85,130.85,0,0,1,22.761-7.338c6.7,6.121-.758,14.8-11.508,25.552"
            transform="translate(-432.303 611.588)"
            fill="#fff"
          />
          <path
            id="Path_23025-2"
            data-name="Path 23025"
            d="M559.832-149.477a131.2,131.2,0,0,1,22.763-7.341C561.22-151,551.26-137.687,551.26-137.687s-50.051-24.938-81.355,15.045c30.45-46.563,89.927-26.835,89.927-26.835"
            transform="translate(-432.214 611.588)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23026-2"
            data-name="Path 23026"
            d="M339.534-182.032c-26.073,8.651-32.493,3.706-44.232-1.742-33.177-15.829-52.755-34.3-52.755-34.3,49.128-31.26,95.1,14.715,95.1,14.715a131.411,131.411,0,0,1,23.636,3.646c3.238,8.485-7.317,12.9-21.745,17.682"
            transform="translate(155.326 567.335)"
            fill="#fff"
          />
          <path
            id="Path_23027-2"
            data-name="Path 23027"
            d="M335.324-203.361a131.385,131.385,0,0,1,23.636,3.645c-21.711-4.38-36.585,3.053-36.585,3.053s-33.553-44.74-79.464-23.04c48.1-27.966,92.413,16.343,92.413,16.343"
            transform="translate(157.645 567.337)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23028-2"
            data-name="Path 23028"
            d="M296.3-167.016c-26.07,8.646-32.493,3.708-44.23-1.747-33.179-15.822-52.754-34.3-52.754-34.3,49.125-31.252,95.093,14.724,95.093,14.724a131.236,131.236,0,0,1,23.633,3.645c3.244,8.486-7.313,12.9-21.742,17.682"
            transform="translate(268.319 576.544)"
            fill="#fff"
          />
          <path
            id="Path_23029-2"
            data-name="Path 23029"
            d="M292.087-188.346A131.211,131.211,0,0,1,315.72-184.7c-21.708-4.387-36.581,3.051-36.581,3.051s-33.555-44.739-79.461-23.039c48.1-27.966,92.41,16.342,92.41,16.342"
            transform="translate(270.637 576.547)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23030-2"
            data-name="Path 23030"
            d="M416.677-73.26a2.034,2.034,0,0,0,2.036-2.045v-25.342a2.037,2.037,0,0,0-2.036-2.043,2.041,2.041,0,0,0-2.036,2.043v25.342a2.038,2.038,0,0,0,2.036,2.045"
            transform="translate(-179.171 644.788)"
            fill="#ab1e2b"
          />
          <path
            id="Path_23031-2"
            data-name="Path 23031"
            d="M405.049-94.149a2.425,2.425,0,0,0,2.426,2.429h30.171a2.426,2.426,0,0,0,2.425-2.429,2.426,2.426,0,0,0-2.425-2.429H407.475a2.426,2.426,0,0,0-2.426,2.429"
            transform="translate(-185.054 648.536)"
            fill="#112a47"
          />
          <path
            id="Path_23032-2"
            data-name="Path 23032"
            d="M445.608-59.393a2.042,2.042,0,0,0,1.358-2.544L439.59-86.19a2.032,2.032,0,0,0-2.541-1.355A2.047,2.047,0,0,0,435.69-85l7.373,24.253a2.037,2.037,0,0,0,2.544,1.355"
            transform="translate(-241.332 654.023)"
            fill="#ab1e2b"
          />
          <path
            id="Path_23033-2"
            data-name="Path 23033"
            d="M429.246-72.6a2.427,2.427,0,0,0,3.025,1.615l28.87-8.778a2.428,2.428,0,0,0,1.615-3.025,2.433,2.433,0,0,0-3.027-1.62l-28.868,8.778a2.427,2.427,0,0,0-1.615,3.031"
            transform="translate(-246.709 655.934)"
            fill="#112a47"
          />
          <path
            id="Path_23036-2"
            data-name="Path 23036"
            d="M337.784-16.094c63.042,23.745,79.263,12.261,108.566.056,82.853-35.543,132.636-78.9,132.636-78.9C461.66-176.351,344.507-68.174,344.507-68.174a321.629,321.629,0,0,0-58.281,6.62c-8.775,20.472,16.668,32.319,51.558,45.46"
            transform="translate(-129.451 630.929)"
            fill="#fff"
          />
          <path
            id="Path_23037-2"
            data-name="Path 23037"
            d="M346.734-68.179a321.319,321.319,0,0,0-58.278,6.62c53.626-8.621,89.351,11.045,89.351,11.045S464.417-156.861,574.8-99.192C459.653-172.43,346.734-68.179,346.734-68.179"
            transform="translate(-131.678 630.935)"
            fill="currentColor"
            class="text-gray-snow-light"
          />
          <path
            id="Path_23038-2"
            data-name="Path 23038"
            d="M341.148-336.432a2.4,2.4,0,0,0,2.4-2.406,2.4,2.4,0,0,0-2.4-2.4,2.394,2.394,0,0,0-2.394,2.4,2.4,2.4,0,0,0,2.394,2.406"
            transform="translate(18.425 498.475)"
            fill="#fff"
          />
          <path
            id="Path_23039-2"
            data-name="Path 23039"
            d="M120.763-353.623a2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4"
            transform="translate(594.379 487.929)"
            fill="#fff"
          />
          <path
            id="Path_23040-2"
            data-name="Path 23040"
            d="M303.509-390.8a2.394,2.394,0,0,0,2.394-2.4,2.4,2.4,0,0,0-2.394-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4"
            transform="translate(116.805 465.13)"
            fill="#fff"
          />
          <path
            id="Path_23041-2"
            data-name="Path 23041"
            d="M256.8-363.99a2.4,2.4,0,0,0-1.986,2.752,2.4,2.4,0,0,0,2.754,1.981,2.4,2.4,0,0,0,1.986-2.754,2.4,2.4,0,0,0-2.754-1.98"
            transform="translate(237.858 484.497)"
            fill="#fff"
          />
          <path
            id="Path_23042-2"
            data-name="Path 23042"
            d="M361.746-354.8a2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4"
            transform="translate(-35.395 487.206)"
            fill="#fff"
          />
          <path
            id="Path_23043-2"
            data-name="Path 23043"
            d="M355.492-334.247a2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4"
            transform="translate(-19.05 499.814)"
            fill="#fff"
          />
          <path
            id="Path_23044-2"
            data-name="Path 23044"
            d="M316.062-348.274a5.793,5.793,0,0,0,5.792,5.79,5.794,5.794,0,0,0,5.8-5.79,5.8,5.8,0,0,0-5.8-5.8,5.794,5.794,0,0,0-5.792,5.8"
            transform="translate(70.935 490.6)"
            fill="#fff"
          />
          <path
            id="Path_23045-2"
            data-name="Path 23045"
            d="M540.745-422.456a2.4,2.4,0,0,0,2.4-2.406,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.406"
            transform="translate(-503.186 445.708)"
            fill="#fff"
          />
          <path
            id="Path_23046-2"
            data-name="Path 23046"
            d="M535.66-347.969a2.4,2.4,0,0,0,2.4-2.406,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.406"
            transform="translate(-489.902 491.396)"
            fill="#fff"
          />
          <path
            id="Path_23047-2"
            data-name="Path 23047"
            d="M498.016-402.333a2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.406,2.4,2.4,0,0,0-2.4,2.406,2.4,2.4,0,0,0,2.4,2.4"
            transform="translate(-391.515 458.051)"
            fill="#fff"
          />
          <path
            id="Path_23048-2"
            data-name="Path 23048"
            d="M451.311-375.533a2.4,2.4,0,0,0-1.983,2.759,2.4,2.4,0,0,0,2.751,1.981,2.4,2.4,0,0,0,1.986-2.754,2.4,2.4,0,0,0-2.754-1.986"
            transform="translate(-270.466 477.417)"
            fill="#fff"
          />
          <path
            id="Path_23049-2"
            data-name="Path 23049"
            d="M556.257-366.345a2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4"
            transform="translate(-543.719 480.126)"
            fill="#fff"
          />
          <path
            id="Path_23050-2"
            data-name="Path 23050"
            d="M550-345.785a2.4,2.4,0,0,0,2.4-2.406,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.406"
            transform="translate(-527.377 492.735)"
            fill="#fff"
          />
          <path
            id="Path_23051-2"
            data-name="Path 23051"
            d="M510.57-359.82a5.8,5.8,0,0,0,5.794,5.8,5.8,5.8,0,0,0,5.8-5.8,5.8,5.8,0,0,0-5.8-5.79,5.793,5.793,0,0,0-5.794,5.79"
            transform="translate(-437.39 483.523)"
            fill="#fff"
          />
          <path
            id="Path_23052-2"
            data-name="Path 23052"
            d="M461.757-394.454a2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4"
            transform="translate(-296.763 462.886)"
            fill="#fff"
          />
          <path
            id="Path_23053-2"
            data-name="Path 23053"
            d="M387.543-357.281a2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4"
            transform="translate(-102.818 485.686)"
            fill="#fff"
          />
          <path
            id="Path_23054-2"
            data-name="Path 23054"
            d="M264.62-315.8a6,6,0,0,0,5.994-6,6,6,0,0,0-5.994-6,5.994,5.994,0,0,0-6,6,5.994,5.994,0,0,0,6,6"
            transform="translate(220.646 506.72)"
            fill="#fff"
          />
          <path
            id="Path_23055-2"
            data-name="Path 23055"
            d="M256.123-345.344a2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4"
            transform="translate(240.641 493.007)"
            fill="#fff"
          />
          <path
            id="Path_23056-2"
            data-name="Path 23056"
            d="M243.008-352.513a2.4,2.4,0,0,0,2.4,2.406,2.4,2.4,0,0,0,2.4-2.406,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4"
            transform="translate(268.644 490.083)"
            fill="#fff"
          />
          <path
            id="Path_23057-2"
            data-name="Path 23057"
            d="M229.506-341.411a2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4"
            transform="translate(303.926 496.894)"
            fill="#fff"
          />
          <path
            id="Path_23058-2"
            data-name="Path 23058"
            d="M250.966-302.038a2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4"
            transform="translate(247.84 521.044)"
            fill="#fff"
          />
          <path
            id="Path_23059-2"
            data-name="Path 23059"
            d="M265.721-341.377a5.8,5.8,0,0,0,5.794,5.792,5.8,5.8,0,0,0,5.8-5.792,5.8,5.8,0,0,0-5.8-5.8,5.8,5.8,0,0,0-5.794,5.8"
            transform="translate(202.492 494.831)"
            fill="#fff"
          />
          <path
            id="Path_23060-2"
            data-name="Path 23060"
            d="M205.1-329.124a2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.406,2.4,2.4,0,0,0-2.4,2.406,2.4,2.4,0,0,0,2.4,2.4"
            transform="translate(373.977 502.954)"
            fill="#fff"
          />
          <path
            id="Path_23061-2"
            data-name="Path 23061"
            d="M252.109-337.758a2.4,2.4,0,0,0,2.4,2.406,2.406,2.406,0,0,0,2.4-2.406,2.4,2.4,0,0,0-2.4-2.394,2.4,2.4,0,0,0-2.4,2.394"
            transform="translate(244.853 499.138)"
            fill="#fff"
          />
          <path
            id="Path_23062-2"
            data-name="Path 23062"
            d="M281.639-67.935a6,6,0,0,0-6,5.995,6,6,0,0,0,6,6,5.993,5.993,0,0,0,5.994-6,5.992,5.992,0,0,0-5.994-5.995"
            transform="translate(176.162 666.105)"
            fill="#fff"
          />
          <path
            id="Path_23063-2"
            data-name="Path 23063"
            d="M189.93-111.682a6,6,0,0,0-6,5.994,6,6,0,0,0,6,6,5.994,5.994,0,0,0,5.994-6,5.993,5.993,0,0,0-5.994-5.994"
            transform="translate(415.83 639.272)"
            fill="#fff"
          />
          <path
            id="Path_23064-2"
            data-name="Path 23064"
            d="M328.567-284.507a5.994,5.994,0,0,0-6,6,5.994,5.994,0,0,0,6,6,6,6,0,0,0,6-6,6,6,0,0,0-6-6"
            transform="translate(53.524 533.268)"
            fill="#fff"
          />
          <path
            id="Path_23065-2"
            data-name="Path 23065"
            d="M162.016-221.778a6,6,0,0,0-6,6,6,6,0,0,0,6,6,5.992,5.992,0,0,0,6-6,5.992,5.992,0,0,0-6-6"
            transform="translate(488.782 571.744)"
            fill="#fff"
          />
          <path
            id="Path_23066-2"
            data-name="Path 23066"
            d="M299.136-294.633a6,6,0,0,0-6,6,6,6,0,0,0,6,6,6,6,0,0,0,6-6,6,6,0,0,0-6-6"
            transform="translate(130.438 527.057)"
            fill="#fff"
          />
          <path
            id="Path_23067-2"
            data-name="Path 23067"
            d="M357.281-56.657a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(-23.717 673.023)"
            fill="#fff"
          />
          <path
            id="Path_23068-2"
            data-name="Path 23068"
            d="M208.207-133.681a2.4,2.4,0,0,0-2.4,2.394,2.4,2.4,0,0,0,2.4,2.409,2.4,2.4,0,0,0,2.4-2.409,2.4,2.4,0,0,0-2.4-2.394"
            transform="translate(365.867 625.779)"
            fill="#fff"
          />
          <path
            id="Path_23069-2"
            data-name="Path 23069"
            d="M185.5-293.039a2.4,2.4,0,0,0-2.4,2.406,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.406"
            transform="translate(425.2 528.035)"
            fill="#fff"
          />
          <path
            id="Path_23070-2"
            data-name="Path 23070"
            d="M213.289-208.168a2.4,2.4,0,0,0-2.4,2.394,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.394"
            transform="translate(352.586 580.092)"
            fill="#fff"
          />
          <path
            id="Path_23071-2"
            data-name="Path 23071"
            d="M140.266-114.195a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(543.407 637.731)"
            fill="#fff"
          />
          <path
            id="Path_23072-2"
            data-name="Path 23072"
            d="M118.559-270.256a2.4,2.4,0,0,0-2.393,2.4,2.4,2.4,0,0,0,2.393,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(600.128 542.009)"
            fill="#fff"
          />
          <path
            id="Path_23073-2"
            data-name="Path 23073"
            d="M136.868-203.626a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(552.3 582.878)"
            fill="#fff"
          />
          <path
            id="Path_23074-2"
            data-name="Path 23074"
            d="M334.327-257.7a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(36.265 549.714)"
            fill="#fff"
          />
          <path
            id="Path_23075-2"
            data-name="Path 23075"
            d="M288-228.079a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(157.322 567.879)"
            fill="#fff"
          />
          <path
            id="Path_23076-2"
            data-name="Path 23076"
            d="M153.322-284.5a2.4,2.4,0,0,0-2.4,2.394,2.4,2.4,0,0,0,2.4,2.409,2.4,2.4,0,0,0,2.4-2.409,2.4,2.4,0,0,0-2.4-2.394"
            transform="translate(509.29 533.271)"
            fill="#fff"
          />
          <path
            id="Path_23077-2"
            data-name="Path 23077"
            d="M298.742-248.84a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.406,2.4,2.4,0,0,0,2.4-2.406,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(129.258 555.145)"
            fill="#fff"
          />
          <path
            id="Path_23078-2"
            data-name="Path 23078"
            d="M131.132-65.62a2.407,2.407,0,0,0,1.981-2.76,2.4,2.4,0,0,0-2.754-1.981,2.4,2.4,0,0,0-1.984,2.754,2.4,2.4,0,0,0,2.757,1.986"
            transform="translate(568.299 664.598)"
            fill="#fff"
          />
          <path
            id="Path_23079-2"
            data-name="Path 23079"
            d="M234.258-82.128a2.4,2.4,0,0,0,1.986-2.754,2.4,2.4,0,0,0-2.756-1.986,2.4,2.4,0,0,0-1.984,2.754,2.406,2.406,0,0,0,2.754,1.986"
            transform="translate(298.782 654.474)"
            fill="#fff"
          />
          <path
            id="Path_23080-2"
            data-name="Path 23080"
            d="M64.509-106.057a5.993,5.993,0,0,0-5.995-6,5.994,5.994,0,0,0-6,6,6,6,0,0,0,6,5.994,6,6,0,0,0,5.995-5.994"
            transform="translate(759.27 639.046)"
            fill="#fff"
          />
          <path
            id="Path_23081-2"
            data-name="Path 23081"
            d="M93.445-69.763a5.993,5.993,0,0,0-5.995-5.994,6,6,0,0,0-6,5.994,6,6,0,0,0,6,5.995,5.993,5.993,0,0,0,5.995-5.995"
            transform="translate(683.651 661.307)"
            fill="#fff"
          />
          <path
            id="Path_23082-2"
            data-name="Path 23082"
            d="M122.549-219.8a5.992,5.992,0,0,0-5.992-6,5.993,5.993,0,0,0-6,6,5.994,5.994,0,0,0,6,6,5.993,5.993,0,0,0,5.992-6"
            transform="translate(607.587 569.278)"
            fill="#fff"
          />
          <path
            id="Path_23083-2"
            data-name="Path 23083"
            d="M29.647-175.181a6,6,0,0,0-5.992-6,6,6,0,0,0-6,6,6,6,0,0,0,6,6,6,6,0,0,0,5.992-6"
            transform="translate(850.373 596.644)"
            fill="#fff"
          />
          <path
            id="Path_23084-2"
            data-name="Path 23084"
            d="M31.291-78.35a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(834.476 658.245)"
            fill="#fff"
          />
          <path
            id="Path_23085-2"
            data-name="Path 23085"
            d="M70.072-150.237a2.4,2.4,0,0,0-2.4-2.394,2.4,2.4,0,0,0-2.4,2.394,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(733.132 614.156)"
            fill="#fff"
          />
          <path
            id="Path_23086-2"
            data-name="Path 23086"
            d="M58.057-250.53a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(764.528 552.637)"
            fill="#fff"
          />
          <path
            id="Path_23087-2"
            data-name="Path 23087"
            d="M71.56-261.629a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(729.244 545.828)"
            fill="#fff"
          />
          <path
            id="Path_23088-2"
            data-name="Path 23088"
            d="M50.1-301a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(785.332 521.679)"
            fill="#fff"
          />
          <path
            id="Path_23089-2"
            data-name="Path 23089"
            d="M147.87-243.328a2.4,2.4,0,0,0-2.4-2.406,2.406,2.406,0,0,0-2.406,2.406,2.4,2.4,0,0,0,2.406,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(529.815 557.05)"
            fill="#fff"
          />
          <path
            id="Path_23090-2"
            data-name="Path 23090"
            d="M156.759-193.074a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(506.589 587.878)"
            fill="#fff"
          />
          <path
            id="Path_23091-2"
            data-name="Path 23091"
            d="M41.962-210.887a5.8,5.8,0,0,0-5.8-5.8,5.792,5.792,0,0,0-5.791,5.8,5.791,5.791,0,0,0,5.791,5.79,5.8,5.8,0,0,0,5.8-5.79"
            transform="translate(817.54 574.868)"
            fill="#fff"
          />
          <path
            id="Path_23092-2"
            data-name="Path 23092"
            d="M37.922-259.082a5.788,5.788,0,0,0-5.791-5.792,5.791,5.791,0,0,0-5.8,5.792,5.8,5.8,0,0,0,5.8,5.8,5.792,5.792,0,0,0,5.791-5.8"
            transform="translate(828.099 545.31)"
            fill="#fff"
          />
          <path
            id="Path_23093-2"
            data-name="Path 23093"
            d="M110.772-97.661a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(626.77 646.401)"
            fill="#fff"
          />
          <path
            id="Path_23094-2"
            data-name="Path 23094"
            d="M148.7-106.521a2.4,2.4,0,0,0,2.757,1.98,2.4,2.4,0,0,0,1.98-2.752,2.4,2.4,0,0,0-2.756-1.988,2.4,2.4,0,0,0-1.981,2.76"
            transform="translate(515.187 640.726)"
            fill="#fff"
          />
          <path
            id="Path_23095-2"
            data-name="Path 23095"
            d="M353.892-175.181a6,6,0,0,0-5.994-6,6,6,0,0,0-6,6,6,6,0,0,0,6,6,6,6,0,0,0,5.994-6"
            transform="translate(3.003 596.644)"
            fill="#fff"
          />
          <path
            id="Path_23096-2"
            data-name="Path 23096"
            d="M320.676-78.35a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(78.206 658.245)"
            fill="#fff"
          />
          <path
            id="Path_23097-2"
            data-name="Path 23097"
            d="M359.455-150.237a2.4,2.4,0,0,0-2.4-2.394,2.4,2.4,0,0,0-2.4,2.394,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(-23.133 614.156)"
            fill="#fff"
          />
          <path
            id="Path_23098-2"
            data-name="Path 23098"
            d="M347.447-250.53a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.406,2.4,2.406,2.406,0,0,0,2.406,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(8.257 552.637)"
            fill="#fff"
          />
          <path
            id="Path_23099-2"
            data-name="Path 23099"
            d="M360.944-261.629a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(-27.02 545.828)"
            fill="#fff"
          />
          <path
            id="Path_23100-2"
            data-name="Path 23100"
            d="M339.484-301a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(29.063 521.679)"
            fill="#fff"
          />
          <path
            id="Path_23101-2"
            data-name="Path 23101"
            d="M331.349-210.887a5.792,5.792,0,0,0-5.79-5.8,5.8,5.8,0,0,0-5.8,5.8,5.8,5.8,0,0,0,5.8,5.79,5.791,5.791,0,0,0,5.79-5.79"
            transform="translate(61.267 574.868)"
            fill="#fff"
          />
          <path
            id="Path_23102-2"
            data-name="Path 23102"
            d="M327.31-259.082a5.791,5.791,0,0,0-5.8-5.792,5.791,5.791,0,0,0-5.8,5.792,5.8,5.8,0,0,0,5.8,5.8,5.8,5.8,0,0,0,5.8-5.8"
            transform="translate(71.834 545.31)"
            fill="#fff"
          />
          <path
            id="Path_23103-2"
            data-name="Path 23103"
            d="M476.145-79.478a6,6,0,0,0-5.992,5.995,6,6,0,0,0,5.992,6,6,6,0,0,0,6-6,6,6,0,0,0-6-5.995"
            transform="translate(-332.161 659.025)"
            fill="#fff"
          />
          <path
            id="Path_23104-2"
            data-name="Path 23104"
            d="M523.077-296.049a6,6,0,0,0-6,6,6,6,0,0,0,6,6,6,6,0,0,0,5.992-6,6,6,0,0,0-5.992-6"
            transform="translate(-454.797 526.189)"
            fill="#fff"
          />
          <path
            id="Path_23105-2"
            data-name="Path 23105"
            d="M493.643-306.172a6,6,0,0,0-6,6,5.991,5.991,0,0,0,6,5.992,6,6,0,0,0,6-5.992,6,6,0,0,0-6-6"
            transform="translate(-377.883 519.98)"
            fill="#fff"
          />
          <path
            id="Path_23106-2"
            data-name="Path 23106"
            d="M516.477-84.658a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.394-2.4,2.394,2.394,0,0,0-2.394-2.4"
            transform="translate(-439.757 655.848)"
            fill="#fff"
          />
          <path
            id="Path_23107-2"
            data-name="Path 23107"
            d="M528.839-269.234a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(-472.06 542.636)"
            fill="#fff"
          />
          <path
            id="Path_23108-2"
            data-name="Path 23108"
            d="M482.514-239.618a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(-351.003 560.802)"
            fill="#fff"
          />
          <path
            id="Path_23109-2"
            data-name="Path 23109"
            d="M445.44-165.347a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(-254.123 606.356)"
            fill="#fff"
          />
          <path
            id="Path_23110-2"
            data-name="Path 23110"
            d="M492.151-192.153a2.4,2.4,0,0,0,1.983-2.754,2.4,2.4,0,0,0-2.754-1.981,2.4,2.4,0,0,0-1.986,2.754,2.4,2.4,0,0,0,2.757,1.981"
            transform="translate(-375.176 586.991)"
            fill="#fff"
          />
          <path
            id="Path_23111-2"
            data-name="Path 23111"
            d="M548.4-117.6a5.993,5.993,0,0,0-6-5.994,5.994,5.994,0,0,0-6,5.994,5.992,5.992,0,0,0,6,6,5.992,5.992,0,0,0,6-6"
            transform="translate(-505.323 631.968)"
            fill="#fff"
          />
          <path
            id="Path_23112-2"
            data-name="Path 23112"
            d="M513.543-186.726a6,6,0,0,0-5.994-5.994,6,6,0,0,0-6,5.994,6,6,0,0,0,6,6,6,6,0,0,0,5.994-6"
            transform="translate(-414.218 589.567)"
            fill="#fff"
          />
          <path
            id="Path_23113-2"
            data-name="Path 23113"
            d="M515.188-89.895a2.4,2.4,0,0,0-2.4-2.394,2.4,2.4,0,0,0-2.4,2.394,2.4,2.4,0,0,0,2.4,2.406,2.4,2.4,0,0,0,2.4-2.406"
            transform="translate(-430.116 651.168)"
            fill="#fff"
          />
          <path
            id="Path_23114-2"
            data-name="Path 23114"
            d="M553.963-161.775a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(-531.457 607.076)"
            fill="#fff"
          />
          <path
            id="Path_23115-2"
            data-name="Path 23115"
            d="M541.952-262.069a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(-500.063 545.56)"
            fill="#fff"
          />
          <path
            id="Path_23116-2"
            data-name="Path 23116"
            d="M555.451-273.175a2.4,2.4,0,0,0-2.4-2.394,2.4,2.4,0,0,0-2.4,2.394,2.4,2.4,0,0,0,2.4,2.406,2.4,2.4,0,0,0,2.4-2.406"
            transform="translate(-535.342 538.751)"
            fill="#fff"
          />
          <path
            id="Path_23117-2"
            data-name="Path 23117"
            d="M525.858-222.433a5.794,5.794,0,0,0-5.8-5.79,5.792,5.792,0,0,0-5.792,5.79,5.793,5.793,0,0,0,5.792,5.8,5.8,5.8,0,0,0,5.8-5.8"
            transform="translate(-447.052 567.791)"
            fill="#fff"
          />
          <path
            id="Path_23118-2"
            data-name="Path 23118"
            d="M428.792-225.436a6,6,0,0,0-5.989,6,5.991,5.991,0,0,0,5.989,6,5.994,5.994,0,0,0,5.994-6,6,6,0,0,0-5.994-6"
            transform="translate(-208.412 569.5)"
            fill="#fff"
          />
          <path
            id="Path_23119-2"
            data-name="Path 23119"
            d="M474.989-137.339a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(-331.33 623.535)"
            fill="#fff"
          />
          <path
            id="Path_23120-2"
            data-name="Path 23120"
            d="M452.281-296.694a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(-271.993 525.793)"
            fill="#fff"
          />
          <path
            id="Path_23121-2"
            data-name="Path 23121"
            d="M480.068-211.826a2.4,2.4,0,0,0-2.4,2.4,2.393,2.393,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(-344.61 577.848)"
            fill="#fff"
          />
          <path
            id="Path_23122-2"
            data-name="Path 23122"
            d="M407.049-117.849a2.4,2.4,0,0,0-2.4,2.393,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.393"
            transform="translate(-153.792 635.49)"
            fill="#fff"
          />
          <path
            id="Path_23123-2"
            data-name="Path 23123"
            d="M385.349-273.913a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(-97.072 539.766)"
            fill="#fff"
          />
          <path
            id="Path_23124-2"
            data-name="Path 23124"
            d="M403.641-207.284a2.394,2.394,0,0,0-2.393,2.4,2.4,2.4,0,0,0,2.393,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(-144.891 580.634)"
            fill="#fff"
          />
          <path
            id="Path_23125-2"
            data-name="Path 23125"
            d="M420.1-288.161a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(-187.906 531.027)"
            fill="#fff"
          />
          <path
            id="Path_23126-2"
            data-name="Path 23126"
            d="M348.519-90.01a6,6,0,0,0-6-5.995,5.994,5.994,0,0,0-6,5.995,5.992,5.992,0,0,0,6,5.995,5.993,5.993,0,0,0,6-5.995"
            transform="translate(17.054 648.888)"
            fill="#fff"
          />
          <path
            id="Path_23127-2"
            data-name="Path 23127"
            d="M389.334-223.462a5.993,5.993,0,0,0-5.994-5.994,6,6,0,0,0-6,5.994,6,6,0,0,0,6,6,6,6,0,0,0,5.994-6"
            transform="translate(-89.612 567.034)"
            fill="#fff"
          />
          <path
            id="Path_23128-2"
            data-name="Path 23128"
            d="M338.342-265.287a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(32.052 543.584)"
            fill="#fff"
          />
          <path
            id="Path_23129-2"
            data-name="Path 23129"
            d="M414.649-246.989a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(-167.379 554.809)"
            fill="#fff"
          />
          <path
            id="Path_23130-2"
            data-name="Path 23130"
            d="M423.541-196.725a2.4,2.4,0,0,0-2.4-2.406,2.4,2.4,0,0,0-2.4,2.406,2.4,2.4,0,0,0,2.4,2.394,2.4,2.4,0,0,0,2.4-2.394"
            transform="translate(-190.609 585.635)"
            fill="#fff"
          />
          <path
            id="Path_23131-2"
            data-name="Path 23131"
            d="M377.552-101.321a2.4,2.4,0,0,0-2.4-2.393,2.4,2.4,0,0,0-2.4,2.393,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(-70.423 644.16)"
            fill="#fff"
          />
          <path
            id="Path_23132-2"
            data-name="Path 23132"
            d="M257.179-92.181a2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4"
            transform="translate(237.881 648.288)"
            fill="#fff"
          />
          <path
            id="Path_23133-2"
            data-name="Path 23133"
            d="M94.218-151.226a2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4"
            transform="translate(657.482 613.546)"
            fill="#fff"
          />
          <path
            id="Path_23134-2"
            data-name="Path 23134"
            d="M155.718-163.16a5.993,5.993,0,0,0-5.99,6,5.993,5.993,0,0,0,5.99,6,6,6,0,0,0,6-6,6,6,0,0,0-6-6"
            transform="translate(505.226 607.698)"
            fill="#fff"
          />
          <path
            id="Path_23135-2"
            data-name="Path 23135"
            d="M244.213-192.117a2.4,2.4,0,0,0-2.4,2.393,2.4,2.4,0,0,0,2.4,2.409,2.407,2.407,0,0,0,2.4-2.409,2.4,2.4,0,0,0-2.4-2.393"
            transform="translate(271.767 589.937)"
            fill="#fff"
          />
          <path
            id="Path_23136-2"
            data-name="Path 23136"
            d="M249.294-266.6a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(258.484 544.249)"
            fill="#fff"
          />
          <path
            id="Path_23137-2"
            data-name="Path 23137"
            d="M228.7-248.236a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(312.302 555.516)"
            fill="#fff"
          />
          <path
            id="Path_23138-2"
            data-name="Path 23138"
            d="M234.957-268.792a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(295.956 542.907)"
            fill="#fff"
          />
          <path
            id="Path_23139-2"
            data-name="Path 23139"
            d="M276.966-252.177a5.8,5.8,0,0,0-5.787-5.8,5.8,5.8,0,0,0-5.8,5.8,5.8,5.8,0,0,0,5.8,5.79,5.792,5.792,0,0,0,5.787-5.79"
            transform="translate(203.389 549.539)"
            fill="#fff"
          />
          <path
            id="Path_23140-2"
            data-name="Path 23140"
            d="M76.248-164.586a6,6,0,0,0,6,5.994,6,6,0,0,0,5.995-5.994,6,6,0,0,0-5.995-6,6,6,0,0,0-6,6"
            transform="translate(697.253 603.146)"
            fill="#fff"
          />
          <path
            id="Path_23141-2"
            data-name="Path 23141"
            d="M192.989-71.233a2.4,2.4,0,0,0,2.4,2.406,2.4,2.4,0,0,0,2.4-2.406,2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4"
            transform="translate(399.357 662.611)"
            fill="#fff"
          />
          <path
            id="Path_23142-2"
            data-name="Path 23142"
            d="M253.26-124.2a2.4,2.4,0,0,0-2.4,2.394,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.394"
            transform="translate(248.11 631.593)"
            fill="#fff"
          />
          <path
            id="Path_23143-2"
            data-name="Path 23143"
            d="M230.024-113.232a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(308.84 638.322)"
            fill="#fff"
          />
          <path
            id="Path_23144-2"
            data-name="Path 23144"
            d="M257.026-190.684a2.4,2.4,0,0,0-2.4,2.406,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.406"
            transform="translate(238.271 590.816)"
            fill="#fff"
          />
          <path
            id="Path_23145-2"
            data-name="Path 23145"
            d="M202.9-245.758a2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4,2.4,2.4,0,0,0-2.4-2.4"
            transform="translate(379.721 557.035)"
            fill="#fff"
          />
          <path
            id="Path_23146-2"
            data-name="Path 23146"
            d="M57.2-68.117a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(766.76 664.522)"
            fill="#fff"
          />
          <path
            id="Path_23147-2"
            data-name="Path 23147"
            d="M232.621-120.755a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.406,2.4,2.4,2.4,0,0,0,2.406,2.393,2.4,2.4,0,0,0,2.4-2.393"
            transform="translate(308.334 632.235)"
            fill="#fff"
          />
          <path
            id="Path_23148-2"
            data-name="Path 23148"
            d="M241.475-85.175a2.4,2.4,0,0,0-2.4-2.4,2.4,2.4,0,0,0-2.4,2.4,2.4,2.4,0,0,0,2.4,2.4,2.4,2.4,0,0,0,2.4-2.4"
            transform="translate(285.191 654.059)"
            fill="#fff"
          />
          <path
            id="Path_23149-2"
            data-name="Path 23149"
            d="M183.131-172.447a2.4,2.4,0,0,0,2.752,1.981,2.4,2.4,0,0,0,1.984-2.754,2.392,2.392,0,0,0-2.754-1.981,2.4,2.4,0,0,0-1.983,2.754"
            transform="translate(425.201 600.293)"
            fill="#fff"
          />
          <g
            id="Group_7340"
            data-name="Group 7340"
            transform="translate(-411.561 325)"
            opacity="0.29"
            *ngIf="showImg"
          >
            <g
              id="Group_7246"
              data-name="Group 7246"
              transform="translate(272.833 155)"
            >
              <g
                id="Group_7244"
                data-name="Group 7244"
                transform="translate(0 0)"
              >
                <g id="Group_246" data-name="Group 246">
                  <g id="Group_245" data-name="Group 245">
                    <ellipse
                      id="Ellipse_28"
                      data-name="Ellipse 28"
                      cx="186"
                      cy="45.5"
                      rx="186"
                      ry="45.5"
                      transform="translate(0 0)"
                      fill="currentColor"
                      class="text-gray"
                      opacity="0.2"
                    />
                    <ellipse
                      id="Ellipse_26"
                      data-name="Ellipse 26"
                      cx="137"
                      cy="33.5"
                      rx="137"
                      ry="33.5"
                      transform="translate(49 12)"
                      fill="currentColor"
                      class="text-gray"
                      opacity="0.4"
                    />
                    <ellipse
                      id="Ellipse_51"
                      data-name="Ellipse 51"
                      cx="89.895"
                      cy="21.864"
                      rx="89.895"
                      ry="21.864"
                      transform="translate(95.705 22.603)"
                      fill="currentColor"
                      class="text-gray"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g
            id="Group_7338"
            data-name="Group 7338"
            transform="translate(-11.466 723.184)"
            *ngIf="showImg"
          >
            <path
              id="Path_23150"
              data-name="Path 23150"
              d="M81.409-281.756c.131.016.264.042.4.068,2.771.595,2.931,6.787,2.981,18.6.045,10.938.109,25.923,4.853,26.373.42.04,1.7.162,2.714-2.008,3.908-8.314-1.29-35.767-6.539-41.007-1.591-1.586-3.044-2.278-4.32-2.045-.032,0-.063.011-.092.018m8.484,46.66a3.852,3.852,0,0,1-.4-.019c-6.195-.587-6.253-14.506-6.312-27.968-.019-4.578-.037-8.9-.332-12.107-.412-4.494-1.219-4.891-1.377-4.925a2.565,2.565,0,0,0-2.847,1.163.8.8,0,0,1-1.084.344.806.806,0,0,1-.422-.853.784.784,0,0,1,.06-.462c.056-.122,1.355-2.944,4.022-3.432,1.828-.336,3.764.505,5.753,2.488,5.693,5.681,11.029,33.96,6.861,42.83-1.117,2.375-2.685,2.941-3.924,2.941"
              transform="translate(-25.832 -54.489)"
              fill="#0b1e33"
            />
            <path
              id="Path_23151"
              data-name="Path 23151"
              d="M75.637-91.562h2.889V-242.9H75.637Z"
              transform="translate(-25.338 -68.065)"
              fill="currentColor"
              class="text-gray"
              fill-rule="evenodd"
            />
            <path
              id="Path_23152"
              data-name="Path 23152"
              d="M75.637-91.562h1.08V-242.9h-1.08Z"
              transform="translate(-25.338 -68.065)"
              fill="currentColor"
              class="text-gray/80"
              fill-rule="evenodd"
            />
            <path
              id="Path_23153"
              data-name="Path 23153"
              d="M71.693-250.69h8.135v-26.564H71.693Z"
              transform="translate(-24.017 -56.557)"
              fill="#133356"
              fill-rule="evenodd"
            />
            <path
              id="Path_23154"
              data-name="Path 23154"
              d="M71.693-250.69h2.623v-26.564H71.693Z"
              transform="translate(-24.017 -56.557)"
              fill="#183d6b"
              fill-rule="evenodd"
            />
            <path
              id="Path_23155"
              data-name="Path 23155"
              d="M80.328-278.017a.7.7,0,0,1-.7.7H70.882a.7.7,0,0,1-.7-.7.7.7,0,0,1,.7-.7h8.742a.7.7,0,0,1,.7.7"
              transform="translate(-23.51 -56.066)"
              fill="#112a47"
            />
            <path
              id="Path_23156"
              data-name="Path 23156"
              d="M80.328-237.662a.7.7,0,0,1-.7.7H70.882a.7.7,0,0,1-.7-.7.7.7,0,0,1,.7-.7h8.742a.7.7,0,0,1,.7.7"
              transform="translate(-23.51 -69.583)"
              fill="#112a47"
            />
            <path
              id="Path_23157"
              data-name="Path 23157"
              d="M120.763-299.85C107.32-394.592,116.785-420.624,99.4-442.9c-1.976-2.527-5.749-3.475-9.451-3.106-3.655.677-7.016,2.634-8.208,5.613-10.5,26.231,5.835,48.6,19.278,143.343,13.657,96.228,1.292,114.812,20.181,134.712,2.081,2.2,5.762,2.992,9.377,2.622,3.575-.65,6.887-2.437,8.277-5.127,12.606-24.368-4.439-38.781-18.094-135.009"
              transform="translate(-26.262 0)"
              fill="#0b1e33"
            />
            <path
              id="Path_23158"
              data-name="Path 23158"
              d="M127.029-163.165c-18.889-19.9-6.524-38.482-20.18-134.709C93.4-392.618,77.072-414.988,87.573-441.219a9.2,9.2,0,0,1,5.353-4.787,14.8,14.8,0,0,0-2.972,0c-3.656.675-7.016,2.634-8.208,5.612-10.5,26.23,5.833,48.6,19.278,143.344,13.655,96.228,1.291,114.813,20.18,134.712,2.083,2.2,5.763,2.992,9.377,2.622a16.3,16.3,0,0,0,2.858-.8,9.846,9.846,0,0,1-6.409-2.656"
              transform="translate(-26.262 -0.001)"
              fill="#112742"
            />
            <path
              id="Path_23159"
              data-name="Path 23159"
              d="M109.687-411.637,81.426-393.7a143.037,143.037,0,0,1-2.815-18.815l25.99-16.5a60.809,60.809,0,0,1,5.087,17.369"
              transform="translate(-26.334 -5.72)"
              fill="currentColor"
              class="text-primaryx"
            />
            <path
              id="Path_23160"
              data-name="Path 23160"
              d="M114.206-381.2c.464,5.8.848,12.322,1.305,19.767L91.282-346.046c-1.445-6.645-2.8-12.621-4.015-18.056Z"
              transform="translate(-29.234 -21.736)"
              fill="currentColor"
              class="text-primaryx"
            />
            <path
              id="Path_23161"
              data-name="Path 23161"
              d="M128.708-235.2c-3.049-21.491,2.086-27.82-5.087-32.414a7.32,7.32,0,0,0-4.189-.415,7.326,7.326,0,0,0-3.908,1.563c-5.612,6.409,1.081,11.06,4.132,32.551,3.1,21.827-3.114,26.525,4.817,30.519a8.334,8.334,0,0,0,4.173.305,8.381,8.381,0,0,0,3.925-1.452c6.505-6.046-.765-8.831-3.864-30.658"
              transform="translate(-37.973 -59.614)"
              fill="#fff"
            />
            <path
              id="Path_23162"
              data-name="Path 23162"
              d="M127.75-185.182a1.563,1.563,0,0,1-1.767-1.33,1.561,1.561,0,0,1,1.327-1.766l7.687-1.09a1.56,1.56,0,0,1,1.766,1.329,1.559,1.559,0,0,1-1.327,1.766Z"
              transform="translate(-42.199 -85.993)"
              fill="#091625"
            />
            <path
              id="Path_23163"
              data-name="Path 23163"
              d="M117.983-254.01a1.563,1.563,0,0,1-1.767-1.329,1.563,1.563,0,0,1,1.329-1.768l7.685-1.091A1.564,1.564,0,0,1,127-256.869a1.564,1.564,0,0,1-1.329,1.769Z"
              transform="translate(-38.927 -62.935)"
              fill="#091625"
            />
            <path
              id="Path_23164"
              data-name="Path 23164"
              d="M36.645-283.708C45.44-379,60.661-402.139,48.888-427.825c-1.335-2.915-4.787-4.708-8.473-5.2-3.714-.186-7.437.944-9.285,3.566-16.273,23.1-5.545,48.635-14.339,143.921C7.859-188.761-8.461-173.535,5.321-149.81c1.523,2.618,4.919,4.243,8.52,4.717,3.628.192,7.264-.781,9.24-3.079,17.89-20.8,4.633-38.755,13.565-135.536"
              transform="translate(0 -4.366)"
              fill="#0b1e33"
            />
            <path
              id="Path_23165"
              data-name="Path 23165"
              d="M11.182-149.271C-2.6-172.992,13.719-188.22,22.65-285c8.795-95.286-1.933-120.823,14.34-143.921a9.205,9.205,0,0,1,6.317-3.422,14.969,14.969,0,0,0-2.892-.684c-3.715-.187-7.438.942-9.285,3.564-16.273,23.1-5.546,48.636-14.339,143.923C7.859-188.762-8.461-173.535,5.32-149.809c1.523,2.617,4.919,4.242,8.521,4.717a16.232,16.232,0,0,0,2.965-.117,9.861,9.861,0,0,1-5.624-4.062"
              transform="translate(0 -4.366)"
              fill="#112742"
            />
            <path
              id="Path_23166"
              data-name="Path 23166"
              d="M63.652-393.384l-31.64,10.931a142.929,142.929,0,0,1,1.606-18.955l29.1-10.05a60.918,60.918,0,0,1,.937,18.073"
              transform="translate(-10.724 -11.599)"
              fill="currentColor"
              class="text-primaryx"
            />
            <path
              id="Path_23167"
              data-name="Path 23167"
              d="M61.431-360.923c-.892,5.721-2.033,12.122-3.316,19.436l-27,9.378c.142-6.762.213-12.856.3-18.4Z"
              transform="translate(-10.422 -28.528)"
              fill="currentColor"
              class="text-primaryx"
            />
            <path
              id="Path_23168"
              data-name="Path 23168"
              d="M39.8-220.454c2-21.613,8.453-26.588,2.537-32.713a7.322,7.322,0,0,0-3.979-1.372,7.316,7.316,0,0,0-4.164.62c-6.941,4.94-1.5,11.011-3.5,32.624-2.026,21.953-9.154,25.089-2.359,30.808a8.329,8.329,0,0,0,3.989,1.262,8.345,8.345,0,0,0,4.155-.509c7.726-4.38,1.293-8.77,3.318-30.72"
              transform="translate(-8.532 -64.154)"
              fill="#fff"
            />
            <path
              id="Path_23169"
              data-name="Path 23169"
              d="M30.52-172.05a1.569,1.569,0,0,1-1.415-1.7,1.568,1.568,0,0,1,1.7-1.414l7.73.715a1.562,1.562,0,0,1,1.412,1.7,1.567,1.567,0,0,1-1.7,1.416Z"
              transform="translate(-9.748 -90.753)"
              fill="#091625"
            />
            <path
              id="Path_23170"
              data-name="Path 23170"
              d="M36.909-241.273a1.567,1.567,0,0,1-1.416-1.7,1.57,1.57,0,0,1,1.7-1.416l7.73.714a1.563,1.563,0,0,1,1.411,1.7,1.562,1.562,0,0,1-1.7,1.411Z"
              transform="translate(-11.888 -67.564)"
              fill="#091625"
            />
            <path
              id="Path_23171"
              data-name="Path 23171"
              d="M178.1-267.189c.12.055.242.114.364.183,2.484,1.377.831,7.346-2.557,18.66-3.137,10.482-7.432,24.835-3.028,26.645.391.164,1.578.65,3.183-1.129,6.156-6.819,9.17-34.594,5.669-41.137-1.058-1.982-2.246-3.065-3.536-3.215-.031,0-.063-.007-.094-.007m-5.456,47.112a3.6,3.6,0,0,1-.374-.135c-5.754-2.364-1.763-15.7,2.1-28.6,1.312-4.386,2.552-8.528,3.2-11.681.912-4.417.253-5.033.112-5.111a2.555,2.555,0,0,0-3.061.285.8.8,0,0,1-1.138.012.8.8,0,0,1-.154-.937.787.787,0,0,1,.191-.427c.088-.1,2.153-2.419,4.846-2.111,1.848.209,3.453,1.573,4.782,4.051,3.8,7.092.673,35.7-5.9,42.976-1.76,1.946-3.423,2.033-4.61,1.673"
              transform="translate(-56.743 -59.386)"
              fill="#0b1e33"
            />
            <path
              id="Path_23172"
              data-name="Path 23172"
              d="M141.649-232.315l2.792.849L100.388-86.683,97.6-87.533Z"
              transform="translate(-32.694 -71.611)"
              fill="currentColor"
              class="text-gray"
              fill-rule="evenodd"
            />
            <path
              id="Path_23173"
              data-name="Path 23173"
              d="M98.671-87.21l-1.033-.315,44.024-144.79L142.7-232Z"
              transform="translate(-32.709 -71.611)"
              fill="currentColor"
              class="text-gray/80"
            />
            <path
              id="Path_23174"
              data-name="Path 23174"
              d="M166.167-266.33l7.782,2.366-7.726,25.414-7.782-2.365Z"
              transform="translate(-53.077 -60.216)"
              fill="#133356"
              fill-rule="evenodd"
            />
            <path
              id="Path_23175"
              data-name="Path 23175"
              d="M166.163-266.33l2.516.765-7.732,25.413-2.516-.765Z"
              transform="translate(-53.074 -60.216)"
              fill="#183d6b"
              fill-rule="evenodd"
            />
            <path
              id="Path_23176"
              data-name="Path 23176"
              d="M178.424-264.463a.706.706,0,0,1-.877.47l-8.365-2.542a.707.707,0,0,1-.468-.881.707.707,0,0,1,.879-.469l8.363,2.545a.7.7,0,0,1,.468.877"
              transform="translate(-56.508 -59.685)"
              fill="#112a47"
            />
            <path
              id="Path_23177"
              data-name="Path 23177"
              d="M166.684-225.851a.7.7,0,0,1-.877.467l-8.365-2.541a.706.706,0,0,1-.468-.878.707.707,0,0,1,.88-.469l8.362,2.545a.7.7,0,0,1,.468.877"
              transform="translate(-52.576 -72.62)"
              fill="#112a47"
            />
          </g>
          <path
            id="Path_23034"
            data-name="Path 23034"
            d="M678.805-74.073s-76.9-38.278-156.669-23.246c0,0-88.795-53.31-152.65,13.955,0,0,149.422,83.918,309.318,9.291"
            transform="translate(-366.414 635.788)"
            fill="#fff"
          />
        </g>
      </g>
      <g id="Group_7339" data-name="Group 7339" transform="translate(38 -11)">
        <path
          id="Path_23178"
          data-name="Path 23178"
          d="M369.487-74.073s76.9-38.278,156.669-23.246c0,0,88.795-53.31,152.65,13.955,0,0-149.422,83.918-309.318,9.291"
          transform="translate(17.321 1029.788)"
          fill="#fff"
        />
        <path
          id="Path_23179"
          data-name="Path 23179"
          d="M543.58-81.984s69.131-56.189,131.707-4.929C611.743-148.7,526.154-97.319,526.154-97.319c-79.765-15.035-156.667,23.246-156.667,23.246,3.719,1.735,95.524-28.939,174.093-7.91"
          transform="translate(17.321 1029.79)"
          fill="currentColor"
          class="text-gray-snow-light"
        />
      </g>
    </g>
  </svg>
</div>
<!-- end mountain -->

<div
  class="flex w-full h-full mb-bg z-5"
  style="background-image: url({{ imgUrl }})"
></div>

<!-- top header -->
<div
  class="absolute top-0 left-0 z-20 flex flex-row items-center justify-between w-full h-auto"
  [ngClass]="useAnimation && 'wow fadeInDown'"
>
  <ng-content select="[slot=top]"></ng-content>
</div>
<!-- end top header -->

<ng-container *ngIf="hasCentral">
  <div
    class="absolute left-0 z-20 flex flex-col items-center justify-center w-full btns-top-distance"
    [ngClass]="useAnimation && 'wow bounceInUp'"
  >
    <ng-content select="[slot=central]"></ng-content>
  </div>
</ng-container>

<!-- left side actions -->
<div
  class="{{
    bigSelection ? 'w-[340px]' : 'w-[260px]'
  }} h-auto flex flex-col items-start text-left space-y-[57px] justify-start z-20 absolute left-0 btns-top-distance"
  [ngClass]="useAnimation && 'wow slideInLeft'"
>
  <div class="w-full h-15"><ng-content select="[slot=btn4]"></ng-content></div>
  <div class="w-full h-15"><ng-content select="[slot=btn3]"></ng-content></div>
  <div class="w-full h-15"><ng-content select="[slot=btn2]"></ng-content></div>
  <div class="w-full h-15"><ng-content select="[slot=btn1]"></ng-content></div>
</div>
<!-- end left side actions -->

<!-- right side actions -->
<div
  class="{{
    bigSelection ? 'w-[340px]' : 'w-[260px]'
  }} h-auto flex flex-col items-start text-right space-y-[57px] justify-end z-20 absolute right-0 btns-top-distance"
  [ngClass]="useAnimation && 'wow slideInRight'"
>
  <div class="w-full h-15"><ng-content select="[slot=btn8]"></ng-content></div>
  <div class="w-full h-15"><ng-content select="[slot=btn7]"></ng-content></div>
  <div class="w-full h-15"><ng-content select="[slot=btn6]"></ng-content></div>
  <div class="w-full h-15"><ng-content select="[slot=btn5]"></ng-content></div>
</div>
<!-- end right side actions -->

<!-- clock -->
<ng-container *ngIf="showClock">
  <div
    class="absolute bottom-0 left-0 h-auto z-15"
    [ngClass]="
      clockCenter
        ? 'w-full text-center flex flex-row items-center justify-center mx-auto'
        : 'w-auto'
    "
  >
    <app-clock [clockDark]="clockDark"></app-clock>
  </div>
</ng-container>
<!-- end clock -->

<ng-container *ngIf="hasStatus">
  <div
    class="absolute bottom-0 left-0 z-30 flex flex-col items-center justify-center w-full h-auto"
  >
    <ng-content select="[slot=status]"></ng-content>
  </div>
</ng-container>
