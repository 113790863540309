<ng-container>
  <div
    class="w-24 h-24 mb-14 relative flex items-center justify-center overflow-hidden bg-cover bg-no-repeat bg-center"
  >
    <app-svg-stripe
      [icon]="'setup-outer'"
      class="text-red z-0"
    ></app-svg-stripe>
    <span
      class="flex items-center justify-center absolute top-5 left-6 ml-px z-5 w-12 h-12"
    >
      <app-svg-stripe [icon]="'lock'" [white]="true"></app-svg-stripe>
    </span>
  </div>
  <h2
    class="w-7/12 mx-auto text-center text-5xl leading-normal font-bold text-white wow fadeInUp"
    data-wow-delay="0"
    data-wow-duration="0.5s"
  >
    <span *ngIf="action == 'move-to-box'">Select slot</span>
    <span *ngIf="action == 'push'">Select speed</span>
  </h2>
  <div
    class="w-11/12 mt-16 mb-12 mx-auto text-5xl font-bold text-primaryx-50 text-center focus:none outline-none wow fadeInUp"
    data-wow-delay="0"
    data-wow-duration="0.5s"
    *ngIf="!actionInProgress"
  >
    <input
      #passwordInput
      type="text"
      autocomplete="off"
      placeholder="{{ 'LOGIN_USER.CARD_ID' | translate }}"
      class="w-full text-center tracking-widest h-24 text-primaryx font-normal rounded-xl drop-shadow-xl bg-white uppercase focus:none outline-none"
      maxlength="10"
      (ngModelChange)="onChange($event)"
      (blur)="onBlurKeyboard($event)"
      value="{{ value }}"
      spellcheck="false"
      autofocus
    />
    <!-- org from password-dialog below -->
    <!-- <input
      #passwordInput
      type="password"
      autocomplete="off"
      placeholder="{{ 'LOGIN_USER.CARD_ID' | translate }}"
      class="w-full text-center tracking-widest h-24 text-primaryx font-normal rounded-xl drop-shadow-xl bg-white uppercase focus:none outline-none"
      [ngClass]="increaseFontSize ? 'text-8xl' : 'text-4xl'"
      maxlength="10"
      [ngModel]="password"
      (ngModelChange)="onChange($event)"
      (blur)="onBlurKeyboard($event)"
      value="{{ value }}"
      spellcheck="false"
      autofocus
    /> -->
  </div>
  <div
    class="w-11/12 flex flex-row wow fadeInUp mx-auto flex-1"
    data-wow-delay="0"
    data-wow-duration="0.5s"
    *ngIf="!actionInProgress"
  >
    <div class="setup-keyboard-input-keyboard"></div>
  </div>

  <div
    class="flex w-10/12 mx-auto h-auto flex-row space-x-16 items-center justify-between pt-4 pb-14 self-end"
    *ngIf="!actionInProgress"
  >
    <app-btn-sanusx
      (btnClick)="cancel()"
      [label]="'COMMON.BTN_CANCEL' | translate"
      buttonType="neutral"
      class="wow slideInLeft"
      data-wow-delay="0s"
      data-wow-duration="1s"
    >
    </app-btn-sanusx>
    <app-btn-sanusx
      (btnClick)="clickOk()"
      [label]="'COMMON.BTN_SUBMIT' | translate"
      buttonType="secondary"
      class="wow slideInRight"
      data-wow-delay="0s"
      data-wow-duration="1s"
    >
    </app-btn-sanusx>
  </div>
  <div
    class="w-full mx-auto h-auto flex flex-wrap justify-center items-center content-center mt-8"
    *ngIf="actionInProgress"
  >
    <h2
      class="w-7/12 mx-auto text-center text-5xl leading-normal font-bold text-white"
    >
      {{ "COMMON.PLEASE_WAIT" | translate }}
    </h2>
  </div>
</ng-container>
