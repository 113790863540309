import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Store } from '@ngxs/store';
import { CoreState, CoreStateModel, GlobalEnums } from 'src/app/core/state/core.state';
import { Navigate } from '@ngxs/router-plugin';


@Component({
  selector: 'app-header-ski',
  templateUrl: './header-ski.component.html',
  styleUrls: ['./header-ski.component.scss']
})
export class HeaderSkiComponent implements OnInit {
  @HostBinding('class') class = 'w-full h-auto flex-col flex';

  public GlobalEnums = GlobalEnums;

  logo: string;
  @Input() title: string = "";
  @Input() subtitle: string = "";
  @Input() superSubtitle: boolean = false;
  @Input() subtitleLeft: string = "";
  @Input() subtitleThird: string = "";
  @Input() showLine: boolean = true;
  @Input() longSubLeft: boolean = false;
  @Input() big: boolean = false;
  @Input() thanks: boolean = false;
  @Input() counter: boolean = false;
  @Input() countdown: number = null;
  @Input() countdownFull: number = null;
  openSetupCount = 0;

  constructor(private store: Store) {


    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.logo = this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo;
    }

  }

  ngOnInit(): void {
  }


  openSetup() {
    this.openSetupCount++;

    if (this.openSetupCount === this.GlobalEnums.openSetupCount) {
      this.store.dispatch(new Navigate(['/core', 'setup']))
    }
  }
}
