import { Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Observable, Observer, of, throwError, timer } from 'rxjs';
import {
  catchError,
  delay,
  map,
  switchMap,
  tap,
  timeout,
} from 'rxjs/operators';
import { Core } from './core.actions';
import { Navigate } from '@ngxs/router-plugin';
import { MachineError } from '../models/error';
import { PaymentResult } from 'src/app/retailer/models/payment-result';
import { PingService } from '../services/ping.service';
import { PaymentService } from '../services/payment.service';
import { PrintService } from '../services/print.service';
import { ConfigService } from '../services/config.service';
import { Retailer } from 'src/app/retailer/state/retailer.actions';
import { VendingTypes } from 'src/app/retailer/state/retailer.state';
import { LockerSkiHttpService } from 'src/app/locker/services/locker-ski-http.service';
import { LockerHttpService } from 'src/app/locker/services/locker-http.service';
import { WebsocketService } from '../services/websocket.service';
import { json } from 'stream/consumers';
import { runInThisContext } from 'vm';
import { VersionCheckService } from 'src/app/version-check.service';
import { ControlCode, ControlService } from '../services/control.service';
import { LockerSki } from 'src/app/locker/state/locker-ski.actions';
import { WwksService } from 'src/app/retailer/services/wwks.service';
import { MqttHelperService } from '../services/mqtt.service';
import { Locker } from 'src/app/locker/state/locker.actions';

export enum GlobalEnums {
  defaultLogo = '/assets/default-logo.png',
  openSetupCount = 10,
  setupPassword = '91827',
  skiLockerHoursLimit = 24,
}

export enum ModuleType {
  CORE,
  RETAILER,
  PICKUP,
}

export enum SlotType {
  NONE = 0,
  VENDING = 1,
  PICKUP = 2,
  RENT = 3,
  ORDER = 4,
  DROPOFF = 5,
  SMARTVENDING = 6,
}

export interface Config {
  tenant: string;
  printHeader: string;
  printFooter: string;
  screenSaverImage: string;
  personPhoto: string;
  modules: any;
  logo: string;
  logo2: string;
  pharmacyInfo: any;
  screenSaverType: string;
  screenSaverMode: string;
  hideSearch: boolean;
  showProductInfoScreen: boolean;
  onScreenKeyboard: boolean;
  useShoppingCart: boolean;
  controlSoftwareType: string;
  theme: string;
  isOfficeButler: boolean;
  lockerColumns: number;
  lockers: any;
  machineRows: any;
  doHealthCheck: boolean;
  defaultLanguage: string;
  multiLanguage: boolean;
  vendingType: string;
  cartQuantityLimit: number;
  cardTrimStartCount: number;
  apiVersion: number;
  doTemperatureCheck: boolean;
  useWebSockets: boolean;
  contentTxt: any;
  printerType: string;
  agb: any;
  sSaverVideo: string;
  openedMenuPanel: boolean;
  flatProductCard: boolean;
  contactInfo: string;
  defaultProductsTag: string;
  screenSaverImageCenter: string;
  innerAdsImage: string;
  wwks2ReleaseTimeout: number;
  setupPassword: string;
  fullBgColour: boolean;
  showProductPrices: boolean;
}

enum CoreError {
  COULD_NOT_LOAD_WEB_CONFIG = 150,
  UNKNOWN_ERROR = 999,
}

export class CoreStateModel {
  config: Config;
  modules: ModuleType[];
  activePayment: PaymentResult;
  finishPaymentResult: PaymentResult;
  cancelPaymentResult: PaymentResult;
  error: MachineError[];
  activeUser: any;
  lockerData: any;
}

@State<CoreStateModel>({
  name: 'core',
  defaults: {
    config: null,
    modules: [],
    activePayment: null,
    finishPaymentResult: null,
    cancelPaymentResult: null,
    error: [],
    activeUser: null,
    lockerData: null,
  },
})
@Injectable()
export class CoreState implements NgxsAfterBootstrap {
  private deviceRuleStyle: string =
    'color: #white; background: green; font-size: 15px';
  private deviceSizeRuleTitle: string = ' :::DEVICE SIZE RULE:::';
  private deviceCase: boolean = false;

  constructor(
    private store: Store,
    private pingService: PingService,
    private paymentService: PaymentService,
    private printService: PrintService,
    private configService: ConfigService,
    private lockerSkiService: LockerSkiHttpService,
    private wwksService: WwksService,
    private lockerHttpService: LockerHttpService,
    private webSocketService: WebsocketService,
    private versionCheckService: VersionCheckService,
    private controlService: ControlService,
    private mqttHelperService: MqttHelperService
  ) { }

  ngxsAfterBootstrap(ctx?: StateContext<CoreStateModel>) {
    setTimeout(() => {
      this.store
        .dispatch(new Navigate(['/core/initialization']))
        .subscribe(() => {
          this.store.dispatch(new Core.LoadWebConfig()).subscribe(() => {
            this.store.dispatch(new Core.LoadMachineData()).subscribe(() => {
              this.store.dispatch(new Core.LoadModules()).subscribe(() => {
                this.store.dispatch(new Retailer.Configure()).subscribe(() => {
                  if (ctx.getState().config.useWebSockets) {
                    console.log('Starting web socket');
                    this.webSocketService.connect();

                    this.webSocketService.subscribe((command) => {
                      this.processWebSocketCommand(command);
                    });
                  }

                  this.mqttHelperService.init();

                  if (
                    this.store.selectSnapshot<CoreStateModel>(CoreState).config
                      .vendingType === VendingTypes.SKI
                  ) {
                    setInterval(
                      () => this.store.dispatch(new LockerSki.UnlockSlots()),
                      30000
                    );
                  }
                });
              });
            });
          });
        });
    }, 1000);

    setInterval(() => {
      this.store.dispatch(new Core.Ping());
    }, 60000);
  }

  processWebSocketCommand(websocketCommand: any) {
    console.log(websocketCommand);

    if (websocketCommand) {
      if (websocketCommand.command === 'out_of_order') {
        this.callIfNotBusy(websocketCommand, () => {
          // this.store.dispatch(new Navigate(['/core/out-of-order']));
          this.store.dispatch(new Core.ExternalPaymentRequest({}));
        });
      } else if (websocketCommand.command === 'restart') {
        this.callIfNotBusy(websocketCommand, () => {
          this.controlService.restart().subscribe((x) => {
            console.log('restart finished');
          });
        });
      } else if (websocketCommand.command === 'resume') {
        this.callIfNotBusy(websocketCommand, () => {
          this.controlService.homeAxes().subscribe({
            complete: () =>
              this.store.dispatch(new Navigate(['/core/screensaver'])),
          });
        });
      } else if (websocketCommand.command === 'payment_request') {
        this.callIfNotBusy(websocketCommand, () => {
          this.store.dispatch(
            new Core.ExternalPaymentRequest(websocketCommand.data)
          );
        });
      } else if (websocketCommand.command === 'open_hatch') {
        this.callIfNotBusy(websocketCommand, () => {
          this.store.dispatch(new Core.ExternalOpenHatch());
        });
      } else if (websocketCommand.command === 'start_rent') {
        this.callIfNotBusy(websocketCommand, () => {
          this.store.dispatch(
            new Core.ExternalOpenLocker(websocketCommand.data)
          );
        });
      } else if (websocketCommand.command === 'ping') {
        console.log('ping');
      }
    }
  }

  @Action(Core.ExternalPaymentRequest)
  externalPaymentRequest(
    ctx: StateContext<CoreStateModel>,
    action: Core.ExternalPaymentRequest
  ) {
    this.store.dispatch(new Navigate(['/retailer', 'payment']));
    let grossPrice =
      action.paymentData.productGrossPrice +
      action.paymentData.serviceFeeGrossPrice;

    return this.store
      .dispatch(new Core.StartPayment(grossPrice))
      .pipe(
        catchError((error) => {
          this.webSocketService.sendMessage('PAYMENT_RESULT', {
            result: false,
            data: this.store.selectSnapshot<CoreStateModel>(CoreState)
              .activePayment,
          });
          return throwError(error);
        }),
        switchMap(() =>
          this.store.dispatch(new Core.FinishPayment(grossPrice))
        ),
        switchMap(() =>
          this.store.dispatch(new Navigate(['/retailer', 'finish']))
        ),
        tap((x) => {
          this.webSocketService.sendMessage('PAYMENT_RESULT', {
            result: true,
            data: this.store.selectSnapshot<CoreStateModel>(CoreState)
              .activePayment,
          });
        }),
        map(
          () =>
            this.store.selectSnapshot<CoreStateModel>(CoreState).activePayment
        ),
        switchMap((activePayment) =>
          this.store
            .dispatch(
              new Retailer.PrintExternalPaymentReceipt(
                action.paymentData,
                activePayment
              )
            )
            .pipe(
              catchError((error) => {
                // TODO: Warn about Printer Issue. But for now we want to continue
                return of(true);
              })
            )
        )
      )
      .subscribe({
        next: () => {
          setTimeout(() => {
            this.store.dispatch(new Navigate(['/core/screensaver']));
          }, 10000);
        },
        error: (err) => {
          this.store
            .dispatch(new Core.Error(err, ModuleType.RETAILER))
            .subscribe(() => {
              setTimeout(() => {
                this.store.dispatch(new Navigate(['/core/screensaver']));
              }, 10000);
            });
        },
      });
  }

  @Action(Core.ExternalOpenHatch)
  externalOpenHatch(
    ctx: StateContext<CoreStateModel>,
    action: Core.ExternalOpenHatch
  ) {
    let waitConfirmationObservable = new Observable(
      (observer: Observer<object>) => {
        this.wwksService.purchaseFinishedCB = () => {
          console.log('pf cb1');

          observer.next({});
          observer.complete();
        };
      }
    );

    waitConfirmationObservable.subscribe({
      next: () => {
        //console.log('now wait')
        this.webSocketService.sendMessage('OPEN_HATCH_RESULT', {
          result: true,
        });
        this.store.dispatch(new Navigate(['/core/screensaver']));
      },
      error: (err) => {
        this.webSocketService.sendMessage('OPEN_HATCH_RESULT', {
          result: false,
        });

        this.store
          .dispatch(new Core.Error(err, ModuleType.RETAILER))
          .subscribe(() => {
            setTimeout(() => {
              this.store.dispatch(new Navigate(['/core/screensaver']));
            }, 10000);
          });
      },
    });

    this.store.dispatch(new Navigate(['/retailer', 'retrieve-wwks', 'wwks']));
  }

  @Action(Core.ExternalOpenLocker)
  externalOpenLockerRequest(
    ctx: StateContext<CoreStateModel>,
    action: Core.ExternalOpenLocker
  ) {
    console.log('external open ', action.slotData);

    this.store.dispatch(new Navigate(['/locker/delivery', 'door-oppened']));
    return this.store
      .dispatch(
        new Locker.OpenLocker(
          action.slotData.slotIndex,
          action.slotData.containerCode
        )
      )
      .pipe(
        catchError((error: MachineError) => {
          if (error.errorNumber == 1) {
            this.lockerHttpService
              .blockLockerOb(
                {
                  id: action.slotData.slotId,
                  slotIndex: action.slotData.slotIndex,
                },
                error.message
              )
              .subscribe(() => {
                this.webSocketService.sendMessage('OPEN_LOCKER_RESULT', {
                  result: false,
                  data: 'DOOR OPEN FAIL',
                });
                console.log('slot set to error state');
              });
          } else {
            this.webSocketService.sendMessage('OPEN_LOCKER_RESULT', {
              result: false,
              data: 'DOOR OPEN FAIL',
            });
          }
          return throwError(error);
        }),
        tap((x) => {
          this.webSocketService.sendMessage('OPEN_LOCKER_RESULT', {
            result: true,
            data: 'DOOR OPEN SUCCESS',
          });
        })
      )
      .subscribe({
        next: () => {
          setTimeout(() => {
            this.store.dispatch(new Navigate(['/core/screensaver']));
          }, 10000);
        },
        error: (err) => {
          this.store
            .dispatch(new Core.Error(err, ModuleType.RETAILER))
            .subscribe(() => {
              setTimeout(() => {
                this.store.dispatch(new Navigate(['/core/screensaver']));
              }, 10000);
            });
        },
      });
  }

  callIfNotBusy(websocketCommand: any, callback: () => void) {
    if (this.versionCheckService.noCurentActivity()) {
      callback();
    } else {
      setTimeout(() => {
        this.processWebSocketCommand(websocketCommand);
      }, 10000);
    }
  }

  static moduleExists(type: ModuleType) {
    return createSelector([CoreState], (state: CoreStateModel) =>
      state.modules.includes(type)
    );
  }

  @Selector()
  static error(state: CoreStateModel): MachineError[] {
    return state.error;
  }

  @Action(Core.Ping)
  ping(ctx: StateContext<CoreStateModel>) {
    let error =
      ctx.getState().error[ModuleType.RETAILER] ||
      ctx.getState().error[ModuleType.CORE];

    let report = this.pingService.getReport(
      error && error.fatal ? error : null
    );

    this.pingService.ping(report).subscribe();
  }

  @Action(Core.LoadModules)
  loadModules(ctx: StateContext<CoreStateModel>) {
    this.store.dispatch(new Core.RegisterModule(ModuleType.RETAILER));
  }

  @Action(Core.RegisterModule)
  registerModule(
    ctx: StateContext<CoreStateModel>,
    action: Core.RegisterModule
  ) {
    let modules = [];
    Object.assign(modules, ctx.getState().modules);
    if (!modules.includes(action.module)) {
      modules.push(action.module);
    }

    ctx.patchState({
      modules: modules,
    });
  }

  @Action(Core.Error)
  error(ctx: StateContext<CoreStateModel>, action: Core.Error) {
    if (
      ctx.getState().error[action.module] == null ||
      ctx.getState().error[action.module] != action.machineError
    ) {
      let error = [];
      Object.assign(error, ctx.getState().error);
      error[action.module] = action.machineError;
      ctx.patchState({
        error: error,
      });
      if (action.machineError != null) {
        this.store.dispatch(new Navigate(['/core/error']));
      }
    } else if (action.machineError == null) {
      ctx.patchState({
        error: null,
      });
    }
  }

  @Action(Core.StartPayment)
  startPayment(ctx: StateContext<CoreStateModel>, action: Core.StartPayment) {
    if (ctx.getState().config.isOfficeButler) {
      of(true);
    } else {
      return this.paymentService.authorization(action.amount).pipe(
        timeout(150000), //150 Secs timeout
        tap((res) => {
          ctx.patchState({
            activePayment: res,
          });

          if (res.resultCode != 0) {
            throw new MachineError(
              res.resultCode,
              ModuleType.RETAILER,
              'ERROR.PAYMENT_FAILED',
              false
            );
          }
        }),
        catchError((error) => {
          let machineError = new MachineError(
            CoreError.UNKNOWN_ERROR,
            ModuleType.RETAILER,
            'ERROR.PAYMENT_FAILED',
            false
          );
          //this.store.dispatch(new Core.CancelPayment());
          return throwError(machineError);
        })
      );
    }
  }

  @Action(Core.FinishPayment)
  finishPayment(ctx: StateContext<CoreStateModel>, action: Core.FinishPayment) {
    if (ctx.getState().config.isOfficeButler) {
      of(true);
    } else {
      return this.paymentService
        .finishAuthorization(action.amount, ctx.getState().activePayment.data)
        .pipe(
          timeout(150000), //150 Secs timeout
          tap((res) => {
            ctx.patchState({
              finishPaymentResult: res,
            });

            if (res && res.resultCode != 0) {
              throw new MachineError(
                res.resultCode,
                ModuleType.RETAILER,
                'ERROR.PAYMENT_FAILED',
                false
              );
            }
          }),
          catchError((error) => {
            console.log('payment error', error);
            ctx.patchState({
              activePayment: null,
              finishPaymentResult: null,
            });

            let machineError = new MachineError(
              CoreError.UNKNOWN_ERROR,
              ModuleType.RETAILER,
              'ERROR.PAYMENT_FAILED',
              false
            );

            return throwError(machineError);
          })
        );
    }
  }

  @Action(Core.CancelPayment)
  cancelPayment(ctx: StateContext<CoreStateModel>) {
    if (ctx.getState().config.isOfficeButler) {
      of(true);
    } else {
      return this.paymentService
        .revertAuthorization(ctx.getState().activePayment.data)
        .pipe(
          timeout(150000), //150 Secs timeout
          tap((res) => {
            ctx.patchState({
              activePayment: null,
              finishPaymentResult: null,
              cancelPaymentResult: res,
            });
          }),
          catchError((error) => {
            ctx.patchState({
              activePayment: null,
              finishPaymentResult: null,
            });

            let machineError = new MachineError(
              CoreError.UNKNOWN_ERROR,
              ModuleType.RETAILER,
              'ERROR.PAYMENT_FAILED',
              false
            );

            return throwError(machineError);
          })
        );
    }
  }

  @Action(Core.Print)
  print(ctx: StateContext<CoreStateModel>, action: Core.Print) {
    console.log('printing started' + action.data);
    return this.printService.print(action.data).pipe(
      tap((res) => {
        /*if(!res) {
            throw new MachineError(-1, "core", "Druck konnte nicht durchgeführt werden", "", false);
        }*/
      })
    );
  }

  @Action(Core.PrintPreorder)
  printPreorder(ctx: StateContext<CoreStateModel>, action: Core.PrintPreorder) {
    return this.printService.printPreorder(action.data).pipe(
      tap((res) => {
        /*if(!res) {
            throw new MachineError(-1, "core", "Druck konnte nicht durchgeführt werden", "", false);
        }*/
      })
    );
  }
  @Action(Core.PrintV2)
  printV2(ctx: StateContext<CoreStateModel>, action: Core.PrintV2) {
    return this.printService.printV2(action.data).pipe(
      tap((res) => {
        /*if(!res) {
            throw new MachineError(-1, "core", "Druck konnte nicht durchgeführt werden", "", false);
        }*/
      })
    );
  }

  @Action(Core.CheckCardNumber)
  checkCardNumber(
    ctx: StateContext<CoreStateModel>,
    action: Core.CheckCardNumber
  ) {
    return this.lockerHttpService.checkCardNumber(action.cardNumber).pipe(
      tap((res) => {
        ctx.patchState({
          activeUser: res,
        });
      })
    );
  }

  @Action(Core.ReloadLockerData)
  updateDeliveryPositionStatus(
    ctx: StateContext<CoreStateModel>,
    action: Core.ReloadLockerData
  ) {
    return this.lockerHttpService
      .checkCardNumber(ctx.getState().activeUser.userData.authenticationId)
      .pipe(
        tap((res) => {
          ctx.patchState({
            activeUser: res,
          });
        })
      );
  }

  // @Action(Core.LoadMachineData)
  // loadMachineData(ctx: StateContext<CoreStateModel>) {
  //   console.log('loadMachineData');
  //   return this.configService.getMachine().pipe(
  //     tap((machineData) => {
  //       console.warn('machineData', machineData);
  //       let machineId = (<{ id: number }>machineData).id;
  //       localStorage.setItem('machineId', machineId.toString());
  //       if (machineData != null && machineData['status_id'] == 4) {
  //         this.store.dispatch(new Navigate(['/core/out-of-order']));
  //       }
  //     })
  //   );
  // }
  @Action(Core.LoadMachineData)
  loadMachineData(ctx: StateContext<CoreStateModel>) {
    // Intentionally left empty
  }

  // @Action(Core.LoadWebConfig)
  // loadWebConfig(ctx: StateContext<CoreStateModel>) {
  //   return this.configService.getConfig().pipe(
  //     tap((config) => {
  //       let tempColors = { primary: '#808B91', secondary: '#96B828', tertiary: '', logobg: '' }
  //       this.setThemeColors(tempColors);

  //       // this.setThemeColors(config['colors']);
  //       this.setCustomCss(config['customCSS']);
  //       this.setThemeRadius(true);

  //       // let pharmacyInfoTest = {
  //       //   "name": "SteinSee Apotheke",
  //       //   "working_hours": "08:00 - 20:00 Uhr",
  //       //   "working_hours_sat": "08:00 - 16:00 Uhr",
  //       //   "phone": "Tel.:(07942) 77 8 32"
  //       // }
  //       let parsedConfig: Config = {
  //         tenant: config['tenant'],
  //         // tenant: 'sanusx',
  //         modules: config['modules'],
  //         printHeader: config['printHeader'],
  //         printFooter: config['printFooter'],
  //         screenSaverImage: config['screenSaverImage'],
  //         personPhoto: config.hasOwnProperty('personPhoto')
  //           ? config['personPhoto']
  //           : '',
  //         // personPhoto: "/assets/temp-person-2.png",
  //         // logo: "/assets/default-logo.png",
  //         // logo: "/assets/temp/pmi-austria.png",
  //         logo: config['logo'],
  //         logo2: config['logo2'],
  //         pharmacyInfo: config.hasOwnProperty('pharmacyInfo')
  //           ? config['pharmacyInfo']
  //           : {},
  //         screenSaverType: config.hasOwnProperty('screenSaverType')
  //           ? config['screenSaverType']
  //           : 'standard',
  //         screenSaverMode: config.hasOwnProperty('screenSaverMode')
  //           ? config['screenSaverMode']
  //           : 'light', // dark, light
  //         hideSearch: config.hasOwnProperty('hideSearch')
  //           ? config['hideSearch']
  //           : false,
  //         showProductInfoScreen: config.hasOwnProperty('showProductInfoScreen')
  //           ? config['showProductInfoScreen']
  //           : false,
  //         onScreenKeyboard: config.hasOwnProperty('onScreenKeyboard')
  //           ? config['onScreenKeyboard']
  //           : false,

  //         //theme: config.hasOwnProperty('theme') ? config['theme'] : 'default',
  //         theme: 'nexus',
  //         // theme: 'ski',
  //         // theme: 'sanusx',
  //         // theme: 'pickup_landscape',
  //         // theme: 'default',
  //         isOfficeButler: config.hasOwnProperty('isOfficeButler')
  //           ? config['isOfficeButler']
  //           : false,
  //         lockerColumns: config.hasOwnProperty('lockerColumns')
  //           ? config['lockerColumns']
  //           : 4,
  //         lockers: config['lockers'],
  //         machineRows: config['machineRows'],
  //         doHealthCheck: config.hasOwnProperty('doHealthCheck')
  //           ? config['doHealthCheck']
  //           : true,
  //         defaultLanguage: config.hasOwnProperty('defaultLanguage')
  //           ? config['defaultLanguage']
  //           : 'de',
  //         multiLanguage: config.hasOwnProperty('multiLanguage')
  //           ? config['multiLanguage']
  //           : false,
  //         // multiLanguage: true,
  //         useShoppingCart: config.hasOwnProperty('useShoppingCart')
  //           ? config['useShoppingCart']
  //           : false,
  //         controlSoftwareType: config.hasOwnProperty('controlSoftwareType')
  //           ? config['controlSoftwareType']
  //           : 'standard',
  //         vendingType: config.hasOwnProperty('vendingType')
  //           ? config['vendingType']
  //           : VendingTypes.STANDARD,
  //         cartQuantityLimit: config.hasOwnProperty('cartQuantityLimit')
  //           ? config['cartQuantityLimit']
  //           : 5,
  //         cardTrimStartCount: config.hasOwnProperty('cardTrimStartCount')
  //           ? config['cardTrimStartCount']
  //           : 2,
  //         apiVersion: config.hasOwnProperty('apiVersion')
  //           ? config['apiVersion']
  //           : 1,
  //         doTemperatureCheck: config.hasOwnProperty('doTemperatureCheck')
  //           ? config['doTemperatureCheck']
  //           : false,
  //         useWebSockets: config.hasOwnProperty('useWebSockets')
  //           ? config['useWebSockets']
  //           : false,
  //         contentTxt: config.hasOwnProperty('contentTxt')
  //           ? config['contentTxt']
  //           : {},
  //         printerType: config.hasOwnProperty('printerType')
  //           ? config['printerType']
  //           : 'default',

  //         agb: config.hasOwnProperty('agb')
  //           ? config['agb']
  //           : {
  //             linkText_en: 'AGB ',
  //             linkText_de: 'AGB ',
  //             agbTitle_en: 'AGB',
  //             agbTitle_de: 'AGB',
  //             agbText_en: '-',
  //             agbText_de: '-',
  //           },
  //         sSaverVideo: config.hasOwnProperty('sSaverVideo')
  //           ? config['sSaverVideo']
  //           : '',
  //         openedMenuPanel: config.hasOwnProperty('openedMenuPanel')
  //           ? config['openedMenuPanel']
  //           : true,
  //         flatProductCard: config.hasOwnProperty('flatProductCard')
  //           ? config['flatProductCard']
  //           : false,
  //         defaultProductsTag: config.hasOwnProperty('defaultProductsTag')
  //           ? config['defaultProductsTag']
  //           : '',
  //         contactInfo: config.hasOwnProperty('contactInfo')
  //           ? config['contactInfo']
  //           : '',
  //         screenSaverImageCenter: config.hasOwnProperty(
  //           'screenSaverImageCenter'
  //         )
  //           ? config['screenSaverImageCenter']
  //           : '',
  //         innerAdsImage: config.hasOwnProperty('innerAdsImage')
  //           ? config['innerAdsImage']
  //           : '',
  //         wwks2ReleaseTimeout: config.hasOwnProperty('wwks2ReleaseTimeout')
  //           ? config['wwks2ReleaseTimeout']
  //           : 20000,
  //         setupPassword: config.hasOwnProperty('setupPassword')
  //           ? config['setupPassword']
  //           : '',
  //         fullBgColour: config.hasOwnProperty('fullBgColour')
  //           ? config['fullBgColour']
  //           : false,
  //         showProductPrices: config.hasOwnProperty('showProductPrices')
  //           ? config['showProductPrices']
  //           : true,
  //       };

  //       ctx.patchState({
  //         config: parsedConfig,
  //       });

  //       let portrait = window.innerHeight >= window.innerWidth;
  //       let smHeight = window.innerHeight <= 767 && window.innerHeight > 500;
  //       let xsHeight = window.innerHeight <= 500;
  //       let xsWidth = window.innerWidth <= 500;
  //       let screen15_landscape =
  //         window.innerWidth === 1024 && window.innerHeight === 768;
  //       let screen15_portrait =
  //         window.innerWidth === 768 && window.innerHeight === 1024;
  //       let screen17 =
  //         window.innerWidth === 1024 && window.innerHeight === 1280;
  //       let screen17_landscape =
  //         window.innerWidth === 1280 && window.innerHeight === 1024;
  //       let fullHd = window.innerWidth === 1080 && window.innerHeight === 1920;
  //       let fullHdLandscape =
  //         window.innerHeight === 1080 && window.innerWidth === 1920;
  //       let fullHdHalf =
  //         window.innerWidth === 538 && window.innerHeight === 960;
  //       let familieTheme = parsedConfig['tenant'] === 'familie';
  //       let sanusxTheme = parsedConfig['theme'] === 'sanusx';
  //       let defaultTheme = parsedConfig['theme'] === 'default';
  //       console.warn('window.innerWidth', window.innerWidth);
  //       console.warn('window.innerHeight', window.innerHeight);
  //       console.warn('screen15_landscape', screen15_landscape);
  //       console.warn('screen15_portrait', screen15_portrait);
  //       console.warn('screen17', screen17);
  //       console.warn('screen17_landscape', screen17_landscape);
  //       localStorage.setItem('theme', parsedConfig['theme']);
  //       localStorage.setItem('portrait', JSON.stringify(portrait));
  //       localStorage.setItem('landscape', JSON.stringify(!portrait));
  //       localStorage.setItem(
  //         'screen15_landscape',
  //         JSON.stringify(screen15_landscape)
  //       );
  //       localStorage.setItem(
  //         'screen15_portrait',
  //         JSON.stringify(screen15_portrait)
  //       );
  //       localStorage.setItem('screen17 portrait', JSON.stringify(screen17));
  //       localStorage.setItem(
  //         'screen17_landscape',
  //         JSON.stringify(screen17_landscape)
  //       );
  //       localStorage.setItem('fullhd', JSON.stringify(fullHd));
  //       localStorage.setItem(
  //         'fullHdLandscape',
  //         JSON.stringify(fullHdLandscape)
  //       );
  //       localStorage.setItem(
  //         'fullHdHalf',
  //         JSON.stringify(
  //           window.innerWidth === 538 && window.innerHeight === 960
  //         )
  //       );

  //       console.table({
  //         theme: parsedConfig['theme'],
  //         sanusxTheme: sanusxTheme,
  //         familieTheme: familieTheme,
  //         defaultTheme: defaultTheme,
  //         screenWidth: window.innerWidth,
  //         screenHeight: window.innerHeight,
  //         portrait: portrait,
  //         landscape: !portrait,
  //         screen15_landscape: screen15_landscape,
  //         screen15_portrait: screen15_portrait,
  //         screen17: screen17,
  //         screen17_landscape: screen17_landscape,
  //         fullHD1080x1920: fullHd,
  //         fullHdLandscape: fullHdLandscape,
  //         fullHdHalf: fullHdHalf,
  //         smHeight: smHeight,
  //         xsHeight: xsHeight,
  //         xsWidth: xsWidth,
  //       });

  //       console.table(config);
  //       console.table(parsedConfig);

  //       // // // // // // //
  //       // Device size cases:
  //       // // // // // // //
  //       // sanusx theme cases
  //       // // // // // // //
  //       let case01 =
  //         sanusxTheme &&
  //         !portrait &&
  //         !smHeight &&
  //         !xsHeight &&
  //         !familieTheme &&
  //         !fullHdHalf &&
  //         !defaultTheme &&
  //         !screen15_landscape &&
  //         !screen15_portrait;
  //       let case02 =
  //         sanusxTheme &&
  //         !defaultTheme &&
  //         portrait &&
  //         !smHeight &&
  //         !familieTheme &&
  //         !fullHdHalf &&
  //         !screen15_landscape &&
  //         screen15_portrait;
  //       let case13 =
  //         !sanusxTheme &&
  //         defaultTheme &&
  //         portrait &&
  //         !smHeight &&
  //         !familieTheme &&
  //         !fullHdHalf &&
  //         !screen15_landscape &&
  //         screen15_portrait;
  //       let case03 =
  //         sanusxTheme &&
  //         !defaultTheme &&
  //         !portrait &&
  //         smHeight &&
  //         !familieTheme &&
  //         !fullHdHalf &&
  //         !screen15_landscape &&
  //         !screen15_portrait;
  //       let case04 =
  //         sanusxTheme &&
  //         screen15_landscape &&
  //         !screen15_portrait &&
  //         !defaultTheme &&
  //         !portrait &&
  //         !familieTheme &&
  //         !fullHdHalf;
  //       let case12 =
  //         !sanusxTheme &&
  //         screen15_landscape &&
  //         !screen15_portrait &&
  //         defaultTheme &&
  //         !portrait &&
  //         !familieTheme &&
  //         !fullHdHalf;
  //       let case05 =
  //         (sanusxTheme &&
  //           !portrait &&
  //           xsHeight &&
  //           !familieTheme &&
  //           !fullHdHalf &&
  //           !defaultTheme &&
  //           !screen15_landscape &&
  //           !screen15_portrait) ||
  //         (sanusxTheme &&
  //           portrait &&
  //           xsWidth &&
  //           !familieTheme &&
  //           !fullHdHalf &&
  //           !defaultTheme &&
  //           !screen15_landscape &&
  //           !screen15_portrait);
  //       // // // // // // //
  //       // familie theme case
  //       // // // // // // //
  //       let case06 =
  //         !sanusxTheme &&
  //         !defaultTheme &&
  //         !portrait &&
  //         !fullHdHalf &&
  //         familieTheme &&
  //         !screen15_landscape &&
  //         !screen15_portrait;
  //       // // // // // // //
  //       // default theme cases
  //       // // // // // // //
  //       let case07 =
  //         defaultTheme &&
  //         !portrait &&
  //         !smHeight &&
  //         !xsHeight &&
  //         !familieTheme &&
  //         !fullHdHalf &&
  //         !sanusxTheme &&
  //         !screen15_landscape &&
  //         !screen15_portrait;
  //       let case08 =
  //         defaultTheme &&
  //         !sanusxTheme &&
  //         !portrait &&
  //         smHeight &&
  //         !familieTheme &&
  //         !fullHdHalf &&
  //         !screen15_landscape;
  //       let case09 =
  //         (defaultTheme &&
  //           !portrait &&
  //           xsHeight &&
  //           !familieTheme &&
  //           !fullHdHalf &&
  //           !sanusxTheme &&
  //           !screen15_landscape &&
  //           !screen15_portrait) ||
  //         (defaultTheme &&
  //           portrait &&
  //           xsWidth &&
  //           !familieTheme &&
  //           !fullHdHalf &&
  //           !sanusxTheme &&
  //           !screen15_landscape &&
  //           !screen15_portrait);
  //       let case11 =
  //         defaultTheme &&
  //         !sanusxTheme &&
  //         !familieTheme &&
  //         portrait &&
  //         !xsHeight &&
  //         screen17 &&
  //         !fullHdHalf &&
  //         !screen15_landscape &&
  //         !screen15_portrait;
  //       let case14 =
  //         portrait &&
  //         fullHd &&
  //         !sanusxTheme &&
  //         defaultTheme &&
  //         !familieTheme &&
  //         !xsHeight &&
  //         !fullHdHalf &&
  //         !screen15_landscape &&
  //         !screen15_portrait;
  //       // // // // // // //
  //       // default theme case at specific case ludwigsapo
  //       // // // // // // //
  //       let case10 =
  //         !sanusxTheme &&
  //         portrait &&
  //         defaultTheme &&
  //         fullHdHalf &&
  //         !familieTheme &&
  //         !screen15_landscape &&
  //         !screen15_portrait;

  //       // // // // // // //
  //       // sanusx theme cases
  //       // // // // // // //
  //       if (case01) {
  //         // CASE01
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE01 `,
  //           this.deviceRuleStyle
  //         );
  //         // if screen is in landscape mode
  //         // and screen height is between 1000px and 501px;
  //         let portraitFontSize = ':root{--fontsizeglobal:13px;}';
  //         this.setCustomCss(portraitFontSize);
  //       } else if (case02) {
  //         // CASE02
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE02 `,
  //           this.deviceRuleStyle
  //         );
  //         // if screen is in portrait mode and
  //         // 768x1024 resolution, 15''
  //         console.warn('CORE CHECK:screen15_portrait', screen15_portrait);
  //         let portraitFontSize = ':root{--fontsizeglobal:11px;}';
  //         this.setCustomCss(portraitFontSize);
  //       } else if (case03) {
  //         // CASE03
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE03 `,
  //           this.deviceRuleStyle
  //         );

  //         // if screen is in landscape mode and
  //         // screen height is between 1000px and 501px;
  //         console.warn('CORE CHECK:smHeight', smHeight);
  //         let smHeightFontSize = ':root{--fontsizeglobal:7px;}';
  //         this.setCustomCss(smHeightFontSize);
  //       } else if (case04) {
  //         // CASE04
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE04 `,
  //           this.deviceRuleStyle
  //         );

  //         // if screen is in landscape mode and
  //         // screen height is between 1000px and 501px;
  //         // console.warn('CORE CHECK:screen15_landscape', screen15_landscape);
  //         // console.warn('CORE CHECK:screen15_portrait', screen15_portrait);
  //         let smHeightFontSize = ':root{--fontsizeglobal:10px;}';
  //         this.setCustomCss(smHeightFontSize);
  //       } else if (case05) {
  //         // CASE05
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE05 `,
  //           this.deviceRuleStyle
  //         );

  //         // if screen is in landscape mode
  //         // and screen height is lower or equal than 500px
  //         // or
  //         // if screen is in portrait mode
  //         //and screen width is lower or equal than 500px
  //         let xsHeightFontSize = ':root{--fontsizeglobal:6px;}';
  //         this.setCustomCss(xsHeightFontSize);
  //       }
  //       // // // // // // //
  //       // familie theme case
  //       // // // // // // //
  //       else if (case06) {
  //         // CASE06
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE06 `,
  //           this.deviceRuleStyle
  //         );

  //         //let xsHeightFontSize = ":root{--fontsizeglobal:7px;}";
  //         let xsHeightFontSize = ':root{--fontsizeglobal:16px;}';
  //         this.setCustomCss(xsHeightFontSize);
  //       }
  //       // // // // // // //
  //       // default theme cases
  //       // // // // // // //
  //       else if (case07) {
  //         // CASE07
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE07 `,
  //           this.deviceRuleStyle
  //         );

  //         // if screen is in landscape mode
  //         // and screen height is between 1000px and 501px;
  //         let portraitFontSize = ':root{--fontsizeglobal:13px;}';
  //         this.setCustomCss(portraitFontSize);
  //       } else if (case08) {
  //         // CASE08
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE08 `,
  //           this.deviceRuleStyle
  //         );

  //         // if screen is in landscape mode and
  //         // screen height is between 1000px and 501px;
  //         let smHeightFontSize = ':root{--fontsizeglobal:7px;}';
  //         this.setCustomCss(smHeightFontSize);
  //       } else if (case09) {
  //         // CASE09
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE09 `,
  //           this.deviceRuleStyle
  //         );

  //         // if screen is in landscape mode
  //         // and screen height is lower or equal than 500px
  //         // or
  //         // if screen is in portrait mode
  //         //and screen width is lower or equal than 500px
  //         let xsHeightFontSize = ':root{--fontsizeglobal:6px;}';
  //         this.setCustomCss(xsHeightFontSize);
  //       }
  //       // // // // // // //
  //       // default theme case at specific case ludwigsapo
  //       // // // // // // //
  //       else if (case10) {
  //         // CASE10
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE10 `,
  //           this.deviceRuleStyle
  //         );

  //         let xsHeightFontSize = ':root{--fontsizeglobal:7px;}';
  //         this.setCustomCss(xsHeightFontSize);
  //       } else if (case11) {
  //         // CASE11
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE11 `,
  //           this.deviceRuleStyle
  //         );

  //         let case11Rule = ':root{--fontsizeglobal:16px;}';
  //         this.setCustomCss(case11Rule);
  //       } else if (case12) {
  //         // CASE12
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE12 `,
  //           this.deviceRuleStyle
  //         );

  //         let case12Rule = ':root{--fontsizeglobal:10px;}';
  //         this.setCustomCss(case12Rule);
  //       } else if (case13) {
  //         // CASE13
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE13 `,
  //           this.deviceRuleStyle
  //         );

  //         let case13Rule = ':root{--fontsizeglobal:11px;}';
  //         this.setCustomCss(case13Rule);
  //       } else if (case14) {
  //         // CASE14
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} CASE14 `,
  //           this.deviceRuleStyle
  //         );

  //         let case14Rule = ':root{--fontsizeglobal:16px;}';
  //         this.setCustomCss(case14Rule);
  //       } else {
  //         // nothing
  //         console.log(
  //           `%c ${this.deviceSizeRuleTitle} NOT SPECIFIED `,
  //           this.deviceRuleStyle
  //         );
  //       }

  //       let activeError = this.store.selectSnapshot<CoreStateModel>(CoreState)
  //         .error[ModuleType.CORE] as MachineError;
  //       if (
  //         activeError != undefined &&
  //         activeError.errorNumber == CoreError.COULD_NOT_LOAD_WEB_CONFIG
  //       ) {
  //         this.store.dispatch(new Core.Error(null, ModuleType.CORE));
  //       }

  //       this.store.dispatch(new Navigate(['/core/screensaver']));
  //     }),
  //     catchError((error) => {
  //       this.store.dispatch(
  //         new Core.Error(
  //           new MachineError(
  //             CoreError.COULD_NOT_LOAD_WEB_CONFIG,
  //             ModuleType.CORE,
  //             'Application configuration cannot load: ' + error.message,
  //             true
  //           ),
  //           ModuleType.CORE
  //         )
  //       );
  //       return timer(10000).pipe(switchMap(() => this.loadWebConfig(ctx)));
  //     })
  //   );
  // }
  @Action(Core.LoadWebConfig)
  loadWebConfig(ctx: StateContext<CoreStateModel>) {
    let tempColors = { primary: '#183A68', secondary: '#89CCCA', tertiary: '', logobg: '' };
    this.setThemeColors(tempColors);

    this.setCustomCss(''); // Custom CSS is empty
    this.setThemeRadius(true); // radiusStyle is true

    let parsedConfig: Config = {
      tenant: '81127b105f1b4471bb3f788174484bb8', // Machine ID as tenant
      modules: {
        retailer: true,
        retailerPickup: false,
        rent: false,
        deliveries: false,
        dropoff: false,
      },
      printHeader: '', // Empty print header
      printFooter: '', // Empty print footer
      screenSaverImage: '', // No screen saver image provided
      personPhoto: '', // Empty person photo
      logo: '/assets/default-logo.png', // Default logo
      logo2: '', // No secondary logo
      pharmacyInfo: {}, // Empty pharmacy info
      screenSaverType: 'standard', // Provided screen saver type
      screenSaverMode: 'light', // Light mode
      hideSearch: false, // Search is not hidden
      showProductInfoScreen: false, // Product info screen disabled
      onScreenKeyboard: true, // On-screen keyboard enabled
      theme: 'nexus', // Provided theme
      isOfficeButler: false, // Not an Office Butler
      lockerColumns: 4, // Default locker columns
      lockers: [], // No lockers defined
      machineRows: [], // No machine rows defined
      doHealthCheck: true, // Health check enabled
      defaultLanguage: 'de', // Provided language
      multiLanguage: false, // Multi-language disabled
      useShoppingCart: true, // Shopping cart enabled
      controlSoftwareType: 'mqtt', // MQTT control software
      vendingType: VendingTypes.STANDARD, // Standard vending type
      sSaverVideo: "../../../../assets/video.mp4",
      cartQuantityLimit: 5, // Default cart limit
      cardTrimStartCount: 2, // Default trim start count
      apiVersion: 2, // Provided API version
      doTemperatureCheck: false, // Temperature check disabled
      useWebSockets: true, // WebSockets enabled
      contentTxt: {}, // Empty content text
      printerType: 'v1', // Provided printer type
      agb: {
        linkText_en: 'AGB ',
        linkText_de: 'AGB ',
        agbTitle_en: 'AGB',
        agbTitle_de: 'AGB',
        agbText_en: '-',
        agbText_de: '-',
      }, // Default terms & conditions

      openedMenuPanel: true, // Open menu panel
      flatProductCard: false, // Flat product card disabled
      defaultProductsTag: 'promo', // Provided default products tag
      contactInfo: '', // No contact info
      screenSaverImageCenter: '', // No centered screen saver image
      innerAdsImage: '', // No inner ads image
      wwks2ReleaseTimeout: 20000, // Default release timeout
      setupPassword: '', // No setup password
      fullBgColour: false, // Full background color disabled
      showProductPrices: true, // Show product prices by default
    };

    ctx.patchState({
      config: parsedConfig,
    });

    // Handle device layout settings
    let portrait = window.innerHeight >= window.innerWidth;
    let smHeight = window.innerHeight <= 767 && window.innerHeight > 500;
    let xsHeight = window.innerHeight <= 500;
    let xsWidth = window.innerWidth <= 500;
    let screen15_landscape = window.innerWidth === 1024 && window.innerHeight === 768;
    let screen15_portrait = window.innerWidth === 768 && window.innerHeight === 1024;
    let screen17 = window.innerWidth === 1024 && window.innerHeight === 1280;
    let screen17_landscape = window.innerWidth === 1280 && window.innerHeight === 1024;
    let fullHd = window.innerWidth === 1080 && window.innerHeight === 1920;
    let fullHdLandscape = window.innerHeight === 1440 && window.innerWidth === 2560;
    let fullHdHalf = window.innerWidth === 538 && window.innerHeight === 960;

    localStorage.setItem('theme', parsedConfig['theme']);
    localStorage.setItem('portrait', JSON.stringify(portrait));
    localStorage.setItem('landscape', JSON.stringify(!portrait));
    localStorage.setItem('screen15_landscape', JSON.stringify(screen15_landscape));
    localStorage.setItem('screen15_portrait', JSON.stringify(screen15_portrait));
    localStorage.setItem('screen17', JSON.stringify(screen17));
    localStorage.setItem('screen17_landscape', JSON.stringify(screen17_landscape));
    localStorage.setItem('fullhd', JSON.stringify(fullHd));
    localStorage.setItem('fullHdLandscape', JSON.stringify(fullHdLandscape));
    localStorage.setItem('fullHdHalf', JSON.stringify(fullHdHalf));

    console.table({
      theme: parsedConfig['theme'],
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      portrait: portrait,
      landscape: !portrait,
      screen15_landscape: screen15_landscape,
      screen15_portrait: screen15_portrait,
      screen17: screen17,
      screen17_landscape: screen17_landscape,
      fullHD1080x1920: fullHd,
      fullHdLandscape: fullHdLandscape,
      fullHdHalf: fullHdHalf,
      smHeight: smHeight,
      xsHeight: xsHeight,
      xsWidth: xsWidth,
    });

    this.store.dispatch(new Navigate(['/core/screensaver']));
  }


  setThemeColors(colors: any) {
    let mainColor = colors.primary;
    let secondaryColor = colors.secondary;
    let tertiary = colors.tertiary;
    let logoBG = colors.logobg;

    let primaryColor = this.hexToHSLA(mainColor, '1');
    let primary30 = this.hexToHSLA(mainColor, '0.3');
    let primary50 = this.hexToHSLA(mainColor, '0.5');

    let primarybg = this.getOtherColorsFromPrimary(primaryColor)[0];
    let accentColor = this.getOtherColorsFromPrimary(primaryColor)[1];

    let primaryAccent = secondaryColor === '' ? accentColor : secondaryColor;
    let primaryLogoBg = logoBG === '' ? primarybg : logoBG;

    let rootCSS =
      ':root{--primary:' +
      primaryColor +
      ';--primary-30:' +
      primary30 +
      ';--primary-50:' +
      primary50 +
      ';--primary-bg:' +
      primarybg +
      ';--primary-accent:' +
      primaryAccent +
      ';--primary-logobg:' +
      primaryLogoBg +
      ';--tertiary:' +
      tertiary +
      ';}';

    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(rootCSS));
    head.appendChild(style);
  }

  setThemeRadius(useRadius: boolean) {
    let noRadius =
      '*{border-radius:0!important}:after{border-radius:0!important}:before{border-radius:0!important}';

    if (!useRadius) {
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.appendChild(document.createTextNode(noRadius));
      head.appendChild(style);
    }
  }

  setCustomCss(customCSS: string) {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(customCSS));
    head.appendChild(style);
  }

  getOtherColorsFromPrimary(color: string) {
    let result =
      /hsl\(\s*(\d+)\s*,\s*(\d+(?:\.\d+)?%)\s*,\s*(\d+(?:\.\d+)?%)\)/.exec(
        color
      );
    let h = result[1];
    let s = result[2].slice(0, -1);
    let l = result[3].slice(0, -1);
    let hminus = Number(h) - 40;
    let lplus = Number(l) + 5;
    let primaryBgColor = 'hsl(' + h + ', 5%, 95%)';
    let accentColor = 'hsl(' + hminus + ', ' + s + '%, ' + lplus + '%)';

    return [primaryBgColor, accentColor];
  }

  hexToHSLA(hex: string, opacity: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    // return HSL;

    r /= 255;
    g /= 255;
    b /= 255;

    // Find greatest and smallest channel values
    let cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

    // Calculate hue
    // No difference
    if (delta == 0) h = 0;
    // Red is max
    else if (cmax == r) h = ((g - b) / delta) % 6;
    // Green is max
    else if (cmax == g) h = (b - r) / delta + 2;
    // Blue is max
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    // Make negative hues positive behind 360°
    if (h < 0) h += 360;

    // Calculate lightness
    l = (cmax + cmin) / 2;

    // Calculate saturation
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    if (opacity === '1') {
      return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
    } else {
      return 'hsla(' + h + ', ' + s + '%, ' + l + '%, ' + opacity + ')';
    }
  }
}
