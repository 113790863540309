import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { TranslateService } from '@ngx-translate/core';

import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';
import { Retailer } from 'src/app/retailer/state/retailer.actions';
@Component({
  selector: 'app-deliveries',
  templateUrl: './deliveries.component.html',
  styleUrls: ['./deliveries.component.scss'],
})
export class DeliveriesComponent implements OnInit, OnDestroy {
  public theme: string;
  public title: string;
  public deliveries: Array<any> = [];
  private deliveryAction: string;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private idle: Idle,
    private translate: TranslateService
  ) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.route.params.subscribe((params) => {
      this.deliveryAction = params.action;
      // add option for tooldbutler to see productslist for rent
      if (params.action == 'load') {
        this.title = this.translate.instant('SELECTION_MENU.EINLAGERUNG');
        this.showDeliveriesForStoring();
      } else if (params.action == 'take-dropoff') {
        this.title = this.translate.instant('SELECTION_MENU.ABGABE_ABHOLEN');
        this.showDeliveriesForTakeDropoff();
      } else if (params.action == 'start-rent') {
        this.title = this.translate.instant('SELECTION_MENU.RENT');
        this.showRentSlots();
      } else if (params.action == 'finish-rent') {
        this.title = this.translate.instant('SELECTION_MENU.RENT_RETURN');
        this.showActiveRentSlots();
      } else {
        this.title = this.translate.instant('SELECTION_MENU.ABHOLUNG');
        this.showDeliveriesForPickup();
      }
    });
  }

  showDeliveriesForPickup() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;
    if (activeUser.pickupDeliveries) {
      this.deliveries = activeUser.pickupDeliveries;
    }
  }

  showDeliveriesForTakeDropoff() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;
    if (activeUser.dropoffDeliveries) {
      this.deliveries = activeUser.dropoffDeliveries;
      console.log(this.deliveries);
    }
  }

  showDeliveriesForStoring() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;
    if (activeUser.deliveriesToLoad) {
      this.deliveries = activeUser.deliveriesToLoad;
      console.log(this.deliveries);
    }
  }
  showRentSlots() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;
    if (activeUser.rentSlots) {
      let newArray = [];

      for (let rent of activeUser.rentSlots) {
        if (newArray.length > 0) {
          let categoryObject = newArray.find(
            (e) => e.description == rent.categoryName
          );
          if (categoryObject) {
            categoryObject.positions.push(rent);
          } else {
            newArray.push({
              description: rent.categoryName,
              positions: [rent],
            });
          }
        } else {
          newArray.push({ description: rent.categoryName, positions: [rent] });
        }
      }

      this.deliveries = newArray;
      console.log(newArray);
    }
  }
  showActiveRentSlots() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;
    if (activeUser.activeRents) {
      this.deliveries = activeUser.activeRents;
      console.log(this.deliveries);
    }
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  public selectDelivery(delivery: any) {
    if (this.deliveryAction == 'start-rent') {
      delivery = delivery.positions[0];
    }
    if (
      this.deliveryAction == 'take-dropoff' ||
      this.deliveryAction == 'start-rent' ||
      this.deliveryAction == 'finish-rent'
    ) {
      console.log(delivery);
      this.store.dispatch(
        new Navigate([
          '/locker',
          'delivery',
          'locker-preview',
          this.deliveryAction,
          delivery.id,
          delivery.positions[0].id,
        ])
      );
      //this.store.dispatch(new Navigate(['/locker', 'delivery', 'positions', this.deliveryAction, delivery.id]));
    } else {
      this.store.dispatch(
        new Navigate([
          '/locker',
          'delivery',
          'positions',
          this.deliveryAction,
          delivery.id,
        ])
      );
    }
  }

  cancel() {
    this.store.dispatch(new Retailer.ActionSelection());
  }
}
