import { Component, OnInit } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { CoreState, CoreStateModel } from '../../state/core.state';
import { LangService } from 'src/app/core/services/lang.service';
import { ContentTxtService } from 'src/app/core/services/contentTxt.service';



@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss']
})
export class SelectionComponent implements OnInit {
  theme: string;
  logo: string;
  personPhoto: string;

  currentLang: string;
  welcomeMsg: string = "";

  constructor(private store: Store, private idle: Idle, private langService: LangService, private contentTxtService: ContentTxtService) {
    this.currentLang = this.langService.getLanguage();

    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.logo = this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo;
      this.personPhoto = this.store.selectSnapshot<CoreStateModel>(CoreState).config.personPhoto;

      this.welcomeMsg = this.contentTxtService.getFieldContentTxt('welcomeMsg', 'SELECTION.WELCOME_FULL');

    }
    this.theme = localStorage.getItem('theme') ?? 'default';

    localStorage.setItem('hand', 'right-hand');
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  retailer() {
    this.store.dispatch(new Navigate(['/retailer', 'selection']));
  }

  pickup() {
    this.store.dispatch(new Navigate(['/retailer', 'pickup']));
  }
}
