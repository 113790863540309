import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { Router } from '@angular/router'
import { Location } from '@angular/common';
import { setTimeout } from 'timers/promises';

@Component({
  selector: 'app-nex-footer',
  templateUrl: './nex-footer.component.html',
  styleUrls: ['./nex-footer.component.scss']
})
export class NexFooterComponent implements OnInit {
  @HostBinding('class') class = 'w-full h-24.2 flex flex-row items-center justify-between';
  currentRoute: string;
  hideSearch: boolean = false;

  @Input() backBtnCloseInfoPanel: boolean;
  @Input() infoPanel: boolean = false;
  @Input() productPage: boolean = false;
  @Input() hideSearchButton: boolean = false;
  @Input() hideBackBtn: boolean = false;
  @Input() useShoppingCart: boolean = false;
  @Input() statusCartMessage: boolean = false;
  @Input() cartQuantity: number = 0;
  @Input() cartTotalValue: number = 0;
  @Input() searchPanelOpened: boolean = false;

  @Output() searchPanelStatus: EventEmitter<boolean> = new EventEmitter();
  @Output() rightHandStatus: EventEmitter<boolean> = new EventEmitter();
  @Output() infoPanelStatus: EventEmitter<boolean> = new EventEmitter();

  constructor(private router: Router, private location: Location) {
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
    console.log('NexFooterComponent')
    console.log(this.cartQuantity)
    console.log(this.cartTotalValue)
  }


  backPage(route: string) {
    if (route === '/retailer/selection') {
      // this.router.navigate(['/core/selection']);
      this.router.navigate(['/']);
    } else {
      this.location.back();
    }
  }

  // for demo purpose
  testCartUpdate(route: string) {

    if (!this.statusCartMessage) {
      this.cartTotalValue = 15.25;
      this.cartQuantity = 5;
      this.statusCartMessage = true;
    } else if (this.statusCartMessage) {
      this.statusCartMessage = false;
    }
  }

  openCart() {
    this.router.navigate(['/retailer/cart']);
  }

  openSearchPanel() {
    this.searchPanelOpened = !this.searchPanelOpened;
    this.searchPanelStatus.emit(this.searchPanelOpened);
  }
  backCloseAction() {
    this.infoPanel = false;
    this.infoPanelStatus.emit(false);
  }
}
