import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { LockerSkiState, LockerSkiStateModel } from 'src/app/locker/state/locker-ski.state';
import { Idle } from '@ng-idle/core';
import { LockerSki } from 'src/app/locker/state/locker-ski.actions';

@Component({
  selector: 'app-unlock-selection',
  templateUrl: './unlock-selection.component.html',
  styleUrls: ['./unlock-selection.component.scss']
})
export class SkiUnlockSelectionComponent implements OnInit, OnDestroy {
  unlockActive: boolean = false;

  lockers: Array<any> = []
  saving: boolean;
  rentStart: string;

  constructor(private store: Store, private idle: Idle) {
    let currentRent = this.store.selectSnapshot<LockerSkiStateModel>(LockerSkiState).customerData.currentRent;

    this.rentStart = this.formatDate(new Date(currentRent.start_date));

    currentRent.rent_items.forEach(rentItem => {
      if (rentItem.locker_rent_item_status_id === 1) {
        this.lockers.push({
          id: rentItem.id,
          slotIndex: rentItem.slot_index,
          selected: false,
          startTime: new Date(rentItem.start_date),
          pricePerHour: rentItem.price_per_hour
        });
      }
    });
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);

    switch (event.key) {
      case "4":
        if (this.lockers[0]) {
          this.selectLockerToUnlock(0);
        }
        break;
      case "3":
        if (this.lockers[1]) {
          this.selectLockerToUnlock(1);
        }
        break;
      case "2":
        if (this.lockers[2]) {
          this.selectLockerToUnlock(2);
        }
        break;
      case "1":
        if (this.lockers[3]) {
          this.selectLockerToUnlock(3);
        }
        break;
      case "8":

        break;
      case "7":
        //
        break;
      case "6":
        if (this.unlockActive) {
          this.onFinish();
        }
        break;
      case "5":
        this.onCancel();
        break;
    }
  }

  selectLockerToUnlock(index: number) {
    this.lockers[index].selected = !this.lockers[index].selected;
    this.isAnyLockerSelected();
  }

  onFinish() {
    if (!this.saving) {
      this.saving = true;
      this.store.dispatch(new LockerSki.FinishReclaimProcess(this.lockers.filter(x => x.selected)));
    }
  }

  onCancel() {
    this.store.dispatch(new LockerSki.ResetRentProcess());
  }

  isAnyLockerSelected() {
    let status = this.lockers.some(v => v.selected);
    this.unlockActive = status;
  }

  formatDate(date: Date): string {
    return date.getDate().toString().padStart(2, '0') + "." +
      (date.getMonth() + 1).toString().padStart(2, '0') + "." +
      date.getFullYear() + " " +
      date.getHours().toString().padStart(2, '0') + ":" +
      date.getMinutes().toString().padStart(2, '0') + ":" +
      date.getSeconds().toString().padStart(2, '0');
  }
}
