import { Component, OnInit, HostListener } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { CoreState, CoreStateModel } from '../../state/core.state';
import { LangService } from 'src/app/core/services/lang.service';
import { VendingTypes } from 'src/app/retailer/state/retailer.state';
import { LockerSki } from 'src/app/locker/state/locker-ski.actions';

@Component({
  selector: 'app-selection-buttons',
  templateUrl: './selection-buttons.component.html',
  styleUrls: ['./selection-buttons.component.scss']
})
export class SelectionButtonsComponent implements OnInit {
  theme: string;
  logo: string;
  personPhoto: string;
  tenant: string;
  modules: any;
  portrait: boolean;
  fullHd: boolean = false;
  fullHdHalf: boolean = false;
  modulesAvailableList: Array<any> = []
  modulesList: Array<any> = []
  numberOfModules: number = 6
  beiText: string = 'bei der';
  machineText: string = '24/7 Automat!';
  multiLanguage: boolean = false;
  currentLang: string;



  public availableLanguages = [];

  constructor(private store: Store, private langService: LangService) {
    this.currentLang = this.langService.getLanguage();
    this.availableLanguages = this.langService.getAvailableLanguages();

    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.logo = this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo;
      this.personPhoto = this.store.selectSnapshot<CoreStateModel>(CoreState).config.personPhoto;

      this.modules = this.store.selectSnapshot<CoreStateModel>(CoreState).config.modules;
      this.tenant = this.store.selectSnapshot<CoreStateModel>(CoreState).config.tenant;
      this.multiLanguage = this.store.selectSnapshot<CoreStateModel>(CoreState).config.multiLanguage;

      this.theme = localStorage.getItem('theme') ?? 'default';

      if (this.store.selectSnapshot<CoreStateModel>(CoreState).config.isOfficeButler) {
        this.beiText = 'SELECTION.BY_ISOFFICEBUTLER';
        this.machineText = 'SELECTION.TO_MACHINE_ISOFFICEBUTLER';
      }

      if (this.store.selectSnapshot<CoreStateModel>(CoreState).config.vendingType == VendingTypes.PREORDER ||
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.vendingType == VendingTypes.WWKS2) {
        this.machineText = 'SELECTION.TO_MACHINE_TERMINAL';
      }

      this.modulesAvailableList = [

      ];

      this.modulesList = this.modulesAvailableList.filter(module => module.enabled);
      // this.modulesList = this.modulesAvailableList.splice(1, 4);
      this.numberOfModules = this.modulesList.length;
    }

    this.portrait = JSON.parse(localStorage.getItem('portrait')) ?? true;
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    this.fullHd = JSON.parse(localStorage.getItem('fullhd')) ?? false;
    localStorage.setItem('hand', 'right-hand');
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  setPublicLanguage() {
    switch (this.currentLang) {
      case 'de':
        this.langService.setPublicLanguage('en');
        this.langService.getNewLanguage('en');
        this.langService.setLanguage();
        this.langService.emitChangeLang('en');
        break;
      case 'en':
        this.langService.setPublicLanguage('de');
        this.langService.getNewLanguage('de');
        this.langService.setLanguage();
        this.langService.emitChangeLang('de');
        break;

      default:

    }
    this.currentLang = this.langService.getLanguage();
  }
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);

    switch (event.key) {
      case "1":
        this.onRentSelection();
        break;
      case "5":
        this.onReclaimSelection();
        break;
    }
  }

  onRentSelection() {
    this.store.dispatch(new LockerSki.RentProcess('rent'));

  }

  onReclaimSelection() {
    this.store.dispatch(new LockerSki.RentProcess('reclaim'));
  }
}
