import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  trigger,
  transition,
  style,
  animate,
  keyframes,
  query,
  group,
  animateChild,
} from '@angular/animations';

import * as Hammer from 'hammerjs';
import { Idle } from '@ng-idle/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  CoreStateModel,
  CoreState,
  GlobalEnums,
} from 'src/app/core/state/core.state';
import { SubSink } from 'subsink';
import { Item } from '../../../core/models/item';
import { Category } from '../../models/category';
import { ImageService } from '../../services/image.service';
import { CategoryService } from '../../services/category.service';
import { Retailer } from '../../state/retailer.actions';
import {
  RetailerState,
  RetailerStateModel,
  VendingTypes,
} from '../../state/retailer.state';
import Keyboard from 'simple-keyboard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-retailer-selection',
  templateUrl: './retailer-selection.component.html',
  styleUrls: ['./retailer-selection.component.scss'],
  animations: [
    trigger('slide', [
      transition(':increment', [
        style({
          transform: 'translateX(100%)',
          position: 'absolute',
          width: '100%',
        }),
        animate(
          '0.3s ease-in-out',
          style({ transform: 'translateX(0%)', position: 'static', width: '*' })
        ),
      ]),
      transition(':decrement', [
        style({
          transform: 'translateX(-100%)',
          position: 'absolute',
          width: '100%',
        }),
        animate(
          '0.3s ease-in-out',
          style({ transform: 'translateX(0%)', position: 'static', width: '*' })
        ),
      ]),
    ]),
  ],
})
export class RetailerSelectionComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public GlobalEnums = GlobalEnums;
  logo: string;
  private subs = new SubSink();
  showPickupOption: boolean = false;
  hideSelectionSearch: boolean = true;
  openedMenuPanel: boolean = true;
  flatProductCard: boolean = false;
  menuPanelOpened: boolean = false;
  searchPanelOpened: boolean = false;
  rightHand: boolean = true;
  onScreenKeyboard: boolean = false;
  useShoppingCart: boolean = false;
  statusCartMessage: boolean = false;
  cartIsFull: boolean = false;
  activeCategory: string = '';
  activeTagFilter: string = '';
  defaultFilterProductsTag: string = '';
  activeParentCategory: object | null = null;
  theme: string;
  isRetailerOnly: boolean = false;
  fullHdHalf: boolean = false;
  fullHd: boolean = false;
  valueStatusActive: boolean = false;
  cartItems: Array<any> = [];
  cartTotalValue: Number = 0;
  cartQuantityLimit: Number = 5;
  public value: string = '';
  public keyboard: Keyboard;
  private _rows: number; // private property _item
  Math = Math;
  products: any = [];
  productsFull: any = [];
  activeIndex: number = 0;
  previousIndex: number = 0;
  numOfItemsPerScreen: number = 6;
  indicatorLimit: number = 6;
  numOfChunks: number = 0;
  activeProductId: number = null;
  isCategoryActiveParent: boolean = false;
  multiLanguage: boolean = false;
  showProductPrices: boolean = true;
  get rows(): number {
    return this._rows;
  }

  @ViewChild('target')
  scrollTarget: ElementRef;

  @ViewChild('target2')
  scrollTarget2: ElementRef;

  @ViewChild('targetMenu')
  scrollTargetMenu: ElementRef;

  private setRows(val: number) {
    this._rows = val;
    let height = 94 / this._rows;
    this.productCardHeight = '0 0 ' + height;
  }
  productCardHeight = '0 0 94%';
  visibleCategories: Category[] = [];
  isTopLevel = true;
  currentMenuId: number = null;
  categoryIds: any = [];
  private images = [];

  @Select(RetailerState.categories) categories$: Observable<Category[]>;
  @Select(RetailerState.ads) ads$: Observable<boolean[]>;
  @Select(RetailerState.selectedCategory)
  selectedCategory$: Observable<Category>;
  @Select(RetailerState.items) items$: Observable<Item[]>;

  constructor(
    private store: Store,
    private idle: Idle,
    private router: Router,
    private imageService: ImageService,
    private categoryService: CategoryService,
    private renderer: Renderer2
  ) {
    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.multiLanguage =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.multiLanguage;
      this.logo =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo;
      this.onScreenKeyboard =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.onScreenKeyboard;
      this.hideSelectionSearch =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.hideSearch;
      this.defaultFilterProductsTag =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.defaultProductsTag;
      this.openedMenuPanel =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.openedMenuPanel;
      this.flatProductCard =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.flatProductCard;
      this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
      this.fullHd = JSON.parse(localStorage.getItem('fullhd')) ?? false;

      this.useShoppingCart =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.useShoppingCart;
      this.cartQuantityLimit =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.cartQuantityLimit;
      this.menuPanelHandler(this.openedMenuPanel);
      this.isRetailerOnly =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.modules
          .retailer === true;
    }
    this.showProductPrices =
      this.store.selectSnapshot<CoreStateModel>(
        CoreState
      ).config.showProductPrices;

    this.cartItems =
      this.store.selectSnapshot<RetailerStateModel>(RetailerState).shoppingCart;

    this.payAmountCount(this.cartItems);
    this.theme = localStorage.getItem('theme') ?? 'default';

    this.getHandDataFromStorage();

    this.setRows(3);

    this.store.dispatch(new Retailer.Load()).subscribe(() => {
      //this.store.dispatch(new Retailer.SelectCategory(null))
    });

    this.subs.add(
      this.items$
        .pipe(mergeMap((items) => (items != undefined ? items : [])))
        .subscribe((item) => {
          if (item.product.imagePaths && item.product.imagePaths.length > 0) {
            if (!this.images[item.product.id]) {
              this.imageService
                .getImage(item.product.imagePaths[0])
                .subscribe((image) => {
                  this.images[item.product.id] = image;
                });
            }
          }
        })
    );

    this.selectedCategory$.subscribe({
      next: (cat) => {
        console.log(cat);
      },
    });

    this.getRetailerItems();
    this.categories$.subscribe((categories) => {
      // this.visibleCategories = this.joinImagePathsToCategories(this.products, categories);
      if (this.products) {
        this.visibleCategories = this.updateCategoriesWithProductIds(
          this.products,
          categories
        );
      }
      for (let i = 0; i < 4; i++) {
        this.visibleCategories = this.visibleCategories.concat(this.visibleCategories);
      }
      this.categoryService.setVisibleCategories(this.visibleCategories);

      this.afterBackFromProductCategory();
    });
  }

  updateCategoriesWithProductIds(products: any[], categories: any[]): any[] {
    const categoryIdToProductIdMap = new Map();

    // Create a map of category IDs to corresponding product IDs
    for (const product of products) {
      for (const productCategory of product.product.categories) {
        const categoryId = productCategory.id;
        if (
          categoryIdToProductIdMap.size == 0 ||
          !categoryIdToProductIdMap.has(categoryId)
        ) {
          categoryIdToProductIdMap.set(categoryId, product.product.id);
        }
      }
    }

    // Create a deep copy of categories with corresponding product ID
    const createUpdatedCategory = (category) => {
      const updatedCategory = { ...category };

      if (
        categoryIdToProductIdMap.size > 0 &&
        categoryIdToProductIdMap.has(updatedCategory.id)
      ) {
        updatedCategory.productId = categoryIdToProductIdMap.get(
          updatedCategory.id
        );
      } else if (
        updatedCategory.children &&
        updatedCategory.children.length > 0
      ) {
        const firstChildProductId = categoryIdToProductIdMap.get(
          updatedCategory.children[0].id
        );
        if (firstChildProductId) {
          updatedCategory.productId = firstChildProductId;
        }
      }

      if (updatedCategory.children) {
        updatedCategory.children = updatedCategory.children.map(
          createUpdatedCategory
        );
      }

      return updatedCategory;
    };

    const updatedCategories = categories.map(createUpdatedCategory);

    return updatedCategories;
  }

  toggleSubCategories(
    category: Category,
    hasChildren: boolean,
    isFromClonedCategory: boolean = false
  ) {
    this.currentMenuId = category.id;
    // console.warn('this.currentMenuId', this.currentMenuId);
    // console.warn('category', category);
    // console.warn('hasChildren', hasChildren);
    this.onCategorySelected(category.id);

    if (hasChildren && !isFromClonedCategory) {
      this.isTopLevel = false; //hide toplevel menu

      this.visibleCategories = this.visibleCategories.map((c) => ({
        ...c,
        showChildren: c.id === category.id,
      }));
    } else if (hasChildren && isFromClonedCategory) {
      this.isTopLevel = false; //hide toplevel menu
      this.visibleCategories = this.visibleCategories.map((c) => ({
        ...c,
        showChildren: c.id === category.parentId,
      }));
    }
  }

  goHome() {
    this.isTopLevel = true;
    this.currentMenuId = null;
    this.visibleCategories = this.visibleCategories.map((c) => ({
      ...c,
      showChildren: false,
    }));
    this.categoryService.setCategoryId(null);
    this.onCategorySelected(-1);
    this.numOfChunksCalc();
  }

  goBack() {
    this.categoryService.setCategoryId(null);

    if (this.isCategoryActiveParent) {
      this.isTopLevel = true;
      this.currentMenuId = null;
      this.visibleCategories = this.visibleCategories.map((c) => ({
        ...c,
        showChildren: false,
      }));
      this.onCategorySelected(-1);
      this.numOfChunksCalc();
    } else if (!this.isCategoryActiveParent) {
      this.isCategoryActiveParent = true;
      let categoryParentId = this.findParentId(
        this.visibleCategories,
        this.currentMenuId
      );
      this.onCategorySelected(categoryParentId);
    }
  }

  getParentCategory(categoryIds: string[]): number[] {
    if (categoryIds.length > 0) {
      const categoryParent = parseInt(categoryIds[0]);
      return [categoryParent];
    }
    return [];
  }

  findParentId(categories: any[], childId: number): number | null {
    for (const category of categories) {
      const child = category.children.find((c) => c.id === childId);
      if (child) {
        return category.id;
      }
    }
    return null;
  }

  findCategoryNameById(categories: any[], categoryId: number): string | null {
    for (const category of categories) {
      if (category.id === categoryId) {
        return category.name;
      }

      if (category.children) {
        const childCategory = category.children.find(
          (child) => child.id === categoryId
        );
        if (childCategory) {
          return childCategory.name;
        }
      }
    }

    return null;
  }

  getRetailerItems() {
    this.items$.subscribe({
      next: (response) => {
        this.products = response;
        this.productsFull = response;
        this.numOfChunksCalc();
        // this.products = [...response, ...response, ...response];
        // this.menuPanelHandler(true);
        this.setItemsNumberOnStart();
      },
      error: (err) => {
        console.error(err, 'Could not get items');
      },
    });
  }

  // sortProductsByName(order: 'asc' | 'desc') {

  //   let reversedSortProducts = this.productsFull.sort((a, b) => {
  //     const nameA = a.product.name.toUpperCase(); // ignore case
  //     const nameB = b.product.name.toUpperCase(); // ignore case

  //     if (order === 'asc') {
  //       return nameA.localeCompare(nameB);
  //     } else if (order === 'desc') {
  //       return nameB.localeCompare(nameA);
  //     } else {
  //       throw new Error('Invalid sorting order. Use "asc" or "desc".');
  //     }
  //   });
  //   this.products = reversedSortProducts;
  //   this.numOfChunksCalc();
  // }

  loadData(): void { }

  ngOnDestroy(): void {
    this.idle.stop();
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.idle.watch();
    this.checkCartQuantityLimit();

    const hammer = new Hammer(document.body);
    hammer.get('swipe').set({ direction: Hammer.DIRECTION_ALL });

    hammer.on('swiperight', () => {
      this.prevSlide();
    });
    hammer.on('swipeleft', () => {
      this.nextSlide();
    });

    hammer.on('swipeup', () => {
      this.scrollUpMenu();
    });
    hammer.on('swipedown', () => {
      this.scrollDownMenu();
    });
  }

  afterBackFromProductCategory() {
    this.categoryService.currentCategoryId.subscribe((id) => {
      if (id !== null) {
        let categoryObj = this.findCategoryById(id, this.visibleCategories);
        if (!categoryObj.children) {
          let parentCategory = this.categoryService.findCategoryParentById(
            this.visibleCategories,
            id
          );
          let clonedCategory = {
            id: categoryObj.id,
            parentId: parentCategory.id,
            name: parentCategory.name,
            children: parentCategory.children,
            productId: parentCategory.productId,
            description: parentCategory.description,
            products: parentCategory.products,
            showChildren: parentCategory.showChildren,
          };
          this.toggleSubCategories(
            clonedCategory,
            parentCategory.children && parentCategory.children.length > 0,
            true
          );
        } else {
          this.toggleSubCategories(
            categoryObj,
            categoryObj.children && categoryObj.children.length > 0
          );
        }
      }
    });
  }

  ngAfterViewInit() {
    if (this.theme != 'sanusx') this.keyboardInit();
  }

  @ViewChild('searchInput')
  public searchInput: ElementRef;

  findCategoryById(id: number, dataArray: any[]): any | undefined {
    for (const obj of dataArray) {
      if (obj.id === id) {
        return obj;
      }
      if (obj.children) {
        const childResult = this.findCategoryById(id, obj.children);
        if (childResult) {
          return childResult;
        }
      }
    }
    return undefined;
  }

  onCategorySelected(id: number) {
    console.log('category selected: ' + id);
    this.activeIndex = 0;
    this.currentMenuId = id;
    if (id === -1) {
      this.activeCategory = 'Alle';
    } else {
      this.activeCategory = this.findCategoryNameById(
        this.visibleCategories,
        id
      );
    }
    this.setActiveIndex(0);

    this.isCategoryActiveParent =
      this.generateCategoryAndChildrenIds(this.visibleCategories, id).length >
      1;
    if (id === -1) {
      this.store.dispatch(new Retailer.SelectCategory(id));
    } else {
      if (this.theme !== 'nexus') {
        this.store.dispatch(new Retailer.SelectCategory(id));
      }
      this.filterProductsByCategory(id);
    }

    this.activeParentCategory = this.categoryService.findCategoryParentById(
      this.visibleCategories,
      id
    );
  }

  filterProductsByCategory(categoryId: number | null): void {
    if (categoryId === null) {
      this.products = this.products;
      this.numOfChunksCalc();
    } else {
      this.categoryIds = this.generateCategoryAndChildrenIds(
        this.visibleCategories,
        categoryId
      );
      console.log('products full size', this.productsFull.length);
      this.products = this.filterProductsByCategoryIds(
        this.productsFull,
        this.categoryIds
      );
      this.numOfChunksCalc();
    }
  }

  filterProductsByTag(tagToFilter: string) {
    this.activeTagFilter = tagToFilter;

    const lowerCaseTagToFilter = tagToFilter.toLowerCase();

    this.products = this.productsFull.filter((product) => {
      const tags = product.product.tags;
      if (tags !== null) {
        try {
          const tagsArray = JSON.parse(tags);
          return (
            Array.isArray(tagsArray) && tagsArray.includes(lowerCaseTagToFilter)
          );
        } catch (error) {
          console.error('Error parsing tags:', error);
          this.products = this.productsFull;
        }
      }
    });
    this.activeIndex = 0;
    this.numOfChunksCalc();
  }

  resetFilterProductsByTag() {
    this.activeTagFilter = '';
    this.products = this.productsFull;
    this.activeIndex = 0;
    this.numOfChunksCalc();
  }

  generateCategoryAndChildrenIds = (
    categories: any[],
    categoryId: number
  ): number[] => {
    const result: number[] = [];

    const findCategoryAndChildren = (category: any) => {
      result.push(category.id);
      if (category.children) {
        category.children.forEach((childCategory) => {
          findCategoryAndChildren(childCategory);
        });
      }
    };

    const targetCategory = categories.find(
      (category) => category.id === categoryId
    );

    if (targetCategory) {
      findCategoryAndChildren(targetCategory);
    } else {
      result.push(categoryId);
    }

    return result;
  };

  filterProductsByCategoryIds = (
    products: any[],
    categoryIds: number[]
  ): any[] => {
    return products.filter((productSlot) => {
      const hasMatchingCategory = productSlot.product.categories.some(
        (category) => categoryIds.includes(category.id)
      );

      return hasMatchingCategory;
    });
  };

  onProductSelected(productId: number) {
    console.log('Product selected: ' + productId);
    this.subs.add(
      this.store
        .dispatch(new Retailer.SelectProduct(productId))
        .subscribe(() =>
          this.store.dispatch(new Navigate(['/retailer', 'product', productId]))
        )
    );
  }

  addProductToCart(productId: number) {
    console.log('Add Product to cart selected: ' + productId);
    this.store
      .dispatch(new Retailer.AddProductToCart(productId, 1))
      .subscribe((x) => {
        this.activeProductId = productId;
        this.statusCartMessage = true;
        this.cartItems =
          this.store.selectSnapshot<RetailerStateModel>(
            RetailerState
          ).shoppingCart;
        this.payAmountCount(this.cartItems);
        this.checkCartQuantityLimit();
        setTimeout(() => {
          this.activeProductId = null;
          this.statusCartMessage = false;
        }, 800);
      });
  }

  hasNoMoreItemsLeftInStock(item: any) {
    let productCartItems = this.cartItems.filter(
      (x) => x.product.id == item.product.id
    );
    let quantityInStock = 9999; // TODO check stock
    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config
        .vendingType === VendingTypes.WWKS2
    ) {
      quantityInStock = item.wwksArticle.quantity;
    }

    return productCartItems.length >= quantityInStock;
  }

  checkCartQuantityLimit() {
    this.cartIsFull = this.cartItems.length === this.cartQuantityLimit;

    if (this.cartIsFull) {
      this.router.navigate(['/retailer/cart']);
    }
  }

  payAmountCount(cartItems: any): void {
    this.cartTotalValue = Number(
      cartItems
        .map((item) => item.product.grossPrice)
        .reduce((a, b) => a + b, 0)
        .toFixed(2)
    );
  }
  scrollForward() {
    let pages =
      this.scrollTarget.nativeElement.scrollWidth /
      this.scrollTarget.nativeElement.offsetWidth;
    // let left = this.scrollTarget.nativeElement.offsetWidth * 50 / 100;

    let left = this.fullHdHalf ? 840 : 896;
    (this.scrollTarget.nativeElement as HTMLElement).scrollBy({
      left,
      top: 0,
      behavior: 'smooth',
    });
  }

  scrollBack() {
    // let left = this.scrollTarget.nativeElement.offsetWidth * -50 / 100;
    let left = this.fullHdHalf ? -840 : -896;
    (this.scrollTarget.nativeElement as HTMLElement).scrollBy({
      left,
      top: 0,
      behavior: 'smooth',
    });
  }

  scrollForward2() {
    let left = (this.scrollTarget2.nativeElement.offsetWidth * 50) / 100;
    (this.scrollTarget2.nativeElement as HTMLElement).scrollBy({
      left,
      top: 0,
      behavior: 'smooth',
    });
  }
  scrollBack2() {
    let left = (this.scrollTarget2.nativeElement.offsetWidth * -50) / 100;
    (this.scrollTarget2.nativeElement as HTMLElement).scrollBy({
      left,
      top: 0,
      behavior: 'smooth',
    });
  }

  scrollDownMenu() {
    let top = (this.scrollTargetMenu.nativeElement.offsetHeight * 50) / 100;
    (this.scrollTargetMenu.nativeElement as HTMLElement).scrollBy({
      top,
      left: 0,
      behavior: 'smooth',
    });
  }
  scrollUpMenu() {
    let top = (this.scrollTargetMenu.nativeElement.offsetHeight * -50) / 100;
    (this.scrollTargetMenu.nativeElement as HTMLElement).scrollBy({
      top,
      left: 0,
      behavior: 'smooth',
    });
  }

  trackById(index, item) {
    return item.id;
  }

  numOfItemsPerScreenCase() {
    if (this.fullHd && this.flatProductCard) {
      return 16;
    } else if (!this.fullHd && this.flatProductCard) {
      return 4;
    } else if (this.fullHd && !this.flatProductCard) {
      return 9;
    } else if (!this.fullHd && !this.flatProductCard) {
      return 4;
    } else {
      return 3;
    }
  }

  setItemsNumberOnStart() {
    if (this.menuPanelOpened) {
      // this.numOfItemsPerScreen = this.fullHd && this.flatProductCard ? 16 : 4;
      // this.numOfItemsPerScreen = this.fullHd && !this.flatProductCard ? 9 : 3;

      if (this.theme === 'nexus')
        this.numOfItemsPerScreen = this.numOfItemsPerScreenCase();
      else {
        this.numOfItemsPerScreen = this.fullHd || this.fullHdHalf ? 6 : 4;
      }
      this.numOfChunksCalc();
      this.setActiveIndex(0);
    } else if (!this.menuPanelOpened) {
      if (this.theme === 'nexus')
        this.numOfItemsPerScreen = this.fullHd ? 16 : 6;
      else {
        this.numOfItemsPerScreen = this.fullHd || this.fullHdHalf ? 9 : 6;
      }
      this.numOfChunksCalc();
      this.setActiveIndex(0);
    }
  }

  menuPanelHandler(status: boolean) {
    this.menuPanelOpened = status;
    console.log('menu,', this.menuPanelOpened);
    if (status) {
      // this.numOfItemsPerScreen = this.fullHd && this.flatProductCard ? 16 : 4;
      // this.numOfItemsPerScreen = this.fullHd && !this.flatProductCard ? 9 : 3;

      if (this.theme === 'nexus')
        this.numOfItemsPerScreen = this.numOfItemsPerScreenCase();
      else {
        this.numOfItemsPerScreen = this.fullHd || this.fullHdHalf ? 6 : 4;
      }

      console.log(this.numOfItemsPerScreen);
      this.numOfChunksCalc();

      this.setActiveIndex(0);
    } else if (!status) {
      if (this.theme === 'nexus')
        this.numOfItemsPerScreen = this.fullHd ? 16 : 6;
      else {
        this.numOfItemsPerScreen = this.fullHd || this.fullHdHalf ? 9 : 6;
      }
      this.numOfChunksCalc();
      this.setActiveIndex(0);
    }
  }

  numOfChunksCalc() {
    if (this.products) {
      this.numOfChunks = Math.ceil(
        this.products.length / this.numOfItemsPerScreen
      );
    }
  }

  searchPanelHandler(status: boolean) {
    this.searchPanelOpened = status;
    this.onCategorySelected(-1);
    this.setActiveIndex(0);
    if (status) {
      // setTimeout(() => this.searchInput.nativeElement.focus(), 1000);
      this.searchInput.nativeElement.focus();
    } else {
      this.closeSearchPanel();
    }
  }

  rightHandHandler(status: boolean) {
    this.rightHand = status;
  }

  toggleMenuPanelAndResetCategory() {
    this.menuPanelHandler(!this.menuPanelOpened);
    this.onCategorySelected(-1);
    this.setActiveIndex(0);
  }

  closeSearchPanel() {
    console.log('Close search clicked');
    this.searchPanelOpened = false;

    this.store.dispatch(new Retailer.Search(''));
    this.valueStatusActive = false;
    this.value = '';
    this.keyboard.clearInput();
    this.goHome();
  }

  getHandDataFromStorage() {
    let hand = localStorage.getItem('hand');

    if (hand === 'right-hand') {
      this.rightHand = true;
    } else if (hand === 'left-hand') {
      this.rightHand = false;
    } else {
      this.rightHand = true;
    }
  }

  search($event) {
    this.store.dispatch(new Retailer.Search($event.target.value));
    this.value = $event.target.value;
  }

  handleBackspaceForRealKeyboard() {
    this.onChange(this.value.slice(0, -1));
  }

  onChange(input: string) {
    this.store.dispatch(new Retailer.Search(input));
    this.value = input;
    if (input.length >= 1) {
      this.valueStatusActive = true;
    }
    if (input.length >= 1) {
    } else if (input.length <= 1) {
      this.keyboard.clearInput();
    }
  }

  keyboardInit() {
    setTimeout(() => this.searchInput.nativeElement.focus(), 1000);
    this.keyboard = new Keyboard('.simple-keyboard-search', {
      onChange: (input) => this.onChange(input),
      onKeyPress: (button) => {
        if (button === '{bksp}') this.handleBackspaceForRealKeyboard();
      },
    });

    this.keyboard.setOptions({
      physicalKeyboardHighlightPress: true,
      physicalKeyboardHighlight: true,
      layout: {
        default: [
          '1 2 3 4 5 6 7 8 9 0 ß +',
          'Q W E R T Z U I O P Ü -',
          'A S D F G H J K L Ö Ä .',
          'Y X C V B N M {space} {bksp} ;',
        ],
        shift: [
          '1 2 3 4 5 6 7 8 9 0 ß +',
          'Q W E R T Z U I O P Ü -',
          'A S D F G H J K L Ö Ä .',
          'Y X C V B N M {space} {bksp} ;',
        ],
      },
      display: {
        '{bksp}': '    ',
        '{space}': 'SPACE',
        ß: '   ',
      },
      theme: 'nex-theme-keyboard',
      buttonTheme: [
        {
          class: 'backspace',
          buttons: '{bksp}',
        },
        {
          class: 'ss',
          buttons: 'ß',
        },
        {
          class: 'space-new',
          buttons: '{space}',
        },
      ],
    });
  }

  onBlurKeyboard(event) {
    this.searchInput.nativeElement.focus();
  }

  setActiveIndex(index: number) {
    this.activeIndex = index;
  }

  prevSlide() {
    if (this.activeIndex > 0) {
      this.previousIndex = this.activeIndex; // Update the previous index
      this.activeIndex--; // Decrement the active index
    }
  }

  nextSlide() {
    if (
      this.activeIndex <
      this.products.length / this.numOfItemsPerScreen - 1
    ) {
      this.previousIndex = this.activeIndex; // Update the previous index
      this.activeIndex++; // Increment the active index
    }
  }

  // closeMenuPanel() {
  //   this.menuPanelOpened = false;
  //   this.onCategorySelected(-1, 'all');
  //   this.setActiveIndex(0);
  // }
  // handleChildClick(event: Event) {
  //   event.stopPropagation();
  // }
}
