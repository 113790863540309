<ng-container *ngIf="theme === 'ski'">
  <div
    class="relative flex items-center justify-center w-24 h-24 overflow-hidden bg-center bg-no-repeat bg-cover my-8"
  >
    <app-svg-stripe
      [icon]="'setup-outer'"
      class="z-0 text-red"
    ></app-svg-stripe>
    <span
      class="absolute flex items-center justify-center w-12 h-12 ml-px top-5 left-6 z-5"
    >
      <app-svg-stripe [icon]="'select'" class="text-white"></app-svg-stripe>
    </span>
  </div>
  <h2
    class="w-7/12 mx-auto text-3xl font-bold leading-normal text-center text-white wow fadeInUp"
    data-wow-delay="0"
    data-wow-duration="0.5s"
  >
    <ng-container>
      {{ "SETUP.SELECT_LOCKERS" | translate }}
    </ng-container>
  </h2>
  <div
    class="grid gap-4 w-11/12 h-auto mx-auto mt-8 place-items-center"
    [ngClass]="'grid-cols-3'"
  >
    <ng-container>
      <ng-container *ngFor="let item of activeRents; let i = index">
        <div
          (click)="selectItem(item)"
          class="min-w-77 max-w-77 h-20 flex flex-row focus:ring-4 focus:ring-white-90 focus:scale-95 duration-75 relative border-2 overflow-hidden items-center justify-center rounded-lg ring-2 ring-white-30 outline-none transition-all ease-in-out disabled:opacity-30 disabled:blur-sm"
          [class.border-orange-500]="item.rentedHours >= lockerLimit"
          [class.bg-orange-500]="item.rentedHours >= lockerLimit"
          [class.border-success]="item.rentedHours < lockerLimit"
          [class.bg-success]="item.rentedHours < lockerLimit"
        >
          <!-- tray number -->
          <div
            class="relative w-14 h-full top-0 left-0 flex flex-col items-center justify-center"
          >
            <span
              class="w-full h-auto text-2xl font-semibold text-white py-2 px-2 flex justify-center items-center text-center"
              [ngClass]="
                item.rentedHours >= lockerLimit
                  ? 'text-yellow-300'
                  : 'text-white'
              "
            >
              {{ item.cardNumber }}
            </span>
          </div>
          <div
            class="border-l border-l-white-30 rounded-l-lg w-auto h-full flex flex-1 flex-col items-center justify-start px-1 py-1"
          >
            <!-- icon -->
            <div class="w-full h-auto flex flex-1 items-center justify-center">
              <span
                class="w-full h-auto text-2xl font-semibold text-white py-2 px-2 flex justify-center items-center text-center"
                [ngClass]="
                  item.rentedHours >= lockerLimit
                    ? 'text-yellow-300'
                    : 'text-white'
                "
              >
                Slots: {{ item.trayNumbers }}
              </span>
              <!-- <ng-container
                *ngIf="(item.rentedTime | date : 'HH') < lockerLimit"
              >
                <span class="w-6 h-6 text-white">
                  <app-svg-stripe
                    [icon]="item.isRented ? 'checkmark-outlined' : 'x-outlined'"
                  ></app-svg-stripe>
                </span>
              </ng-container>

              <ng-container
                *ngIf="item.rentedHours >= lockerLimit"
              >
                <span class="text-yellow-300 w-6 h-6 animate-pulse">
                  <app-svg-stripe [icon]="'danger'"></app-svg-stripe>
                </span>
              </ng-container> -->
            </div>

            <!-- time -->
            <div
              class="text-lg text-white font-normal text-center w-full uppercase"
              [ngClass]="
                item.rentedHours >= lockerLimit ? 'text-yellow-300' : ''
              "
            >
              <ng-container> Hours: {{ item.rentedHours }} </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- <div class="flex flex-row items-center self-end justify-between w-11/12 h-auto pt-8 pb-2 mx-auto space-x-16">
    <app-btn-sanusx (btnClick)="reset()" [label]="'COMMON.BTN_RESET' | translate" buttonType="neutral"
      class="wow slideInLeft" data-wow-delay="0s" data-wow-duration="1s">
    </app-btn-sanusx>

    <app-btn-sanusx
      (btnClick)="submitSelected()"
      [label]="'COMMON.BTN_SUBMIT' | translate"
      [disabled]="!isThereAnySelected"
      buttonType="secondary"
      class="wow slideInRight"
      data-wow-delay="0s"
      data-wow-duration="1s"
    >
    </app-btn-sanusx>
  </div> -->

  <div
    class="flex flex-wrap items-center content-center justify-center w-full h-auto mx-auto mt-8"
    *ngIf="actionInProgress"
  >
    <h2
      class="w-7/12 mx-auto text-5xl font-bold leading-normal text-center text-white"
    >
      {{ "COMMON.PLEASE_WAIT" | translate }}
    </h2>
  </div>
</ng-container>
