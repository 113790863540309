<button
  class="btn-ski bg-primaryx {{ withAnimation ? 'wow fadeInUp' : '' }} {{
    extraCss
  }}"
  data-wow-delay="0.5s"
  data-wow-duration="0.5s"
  [class.btn-ski--default]="type === 'default'"
  [class.btn-ski--super]="type === 'super'"
  [class.btn-ski--selected]="type === 'selected'"
  [class.btn-ski--selectednumber]="type === 'selectednumber'"
  [class.btn-ski--error]="type === 'error'"
  [class.btn-ski--wait]="type === 'wait'"
  [class.btn-ski--subaction]="type === 'subaction'"
  [class.btn-ski--subaction-finish]="subActionType === 'finish'"
  [class.btn-ski--right]="right"
  [class.btn-ski--left]="!right"
  [class.btn-ski--big]="big"
  (click)="onClickButton($event)"
>
  <ng-container *ngIf="type !== 'super'">
    <div class="label">{{ label }}</div>
  </ng-container>

  <div class="icon border-primaryx">
    <div class="letter-in text-primaryx" *ngIf="!big">
      <ng-container *ngIf="type === 'super'">
        <div class="label-super text-primaryx">{{ label }}</div>
      </ng-container>

      <ng-container *ngIf="type !== 'wait' || number !== null">
        {{ number }}
      </ng-container>

      <ng-container *ngIf="type === 'wait'">
        <svg viewBox="0 0 50 50" class="btn-loader-mini">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </ng-container>

      <div class="icon-subaction" *ngIf="type === 'subaction'">
        <app-svg-stripe [icon]="subActionType"></app-svg-stripe>
      </div>

      <div
        class="checkmark bg-primaryx"
        *ngIf="type === 'selected' || type === 'selectednumber'"
      >
        <app-svg-stripe [icon]="'checkmark'"></app-svg-stripe>
      </div>

      <div class="errormark" *ngIf="type === 'error'">
        <app-svg-stripe [icon]="'danger'"></app-svg-stripe>
      </div>
      <!-- circle -->
      <div
        class="circle bg-primaryx-30"
        [ngClass]="type === 'wait' ? 'animate-pulse' : ''"
      >
        <span class="circle-in bg-primaryx-50"></span>
      </div>
      <!-- end circle -->
    </div>
    <div class="icon-in bg-primaryx" *ngIf="big">
      <ng-content></ng-content>

      <!-- circle -->
      <div class="circle bg-primaryx-30">
        <span class="circle-in bg-primaryx-50"></span>
      </div>
      <!-- end circle -->
    </div>
  </div>
</button>

<ng-container *ngIf="big && desc !== ''">
  <div
    class="block w-8/12 py-4 text-2xl font-semibold border-primaryx text-primaryx"
    [ngClass]="
      right
        ? 'ml-auto pr-4 mr-2 text-right border-r-4'
        : 'mr-auto pl-4 ml-2 text-left border-l-4'
    "
  >
    {{ desc }}
  </div>
</ng-container>
