import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LockerState } from './state/locker.state';
import { NgxsModule } from '@ngxs/store';

import { SharedModule } from '../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgImageSliderModule } from 'ng-image-slider';
import { RentStartScannerComponent } from './components/rent/start-scanner/start-scanner.component';
import { RentCodeCorrectComponent } from './components/rent/code-correct/code-correct.component';
import { RentWarningComponent } from './components/rent/warning/warning.component';
import { RentCompleteComponent } from './components/rent/complete/complete.component';
import { RentSelectionComponent } from './components/rent/selection/selection.component';
import { RentLockerSelectionComponent } from './components/rent/locker-selection/locker-selection.component';
import { DeliveriesComponent } from './components/delivery/deliveries/deliveries.component';
import { DeliveryPositionsComponent } from './components/delivery/delivery-positions/delivery-positions.component';
import { LockerPreviewComponent } from './components/delivery/locker-preview/locker-preview.component';
import { LockerMessageComponent } from './components/delivery/locker-message/locker-message.component';
import { DoneDeliveryComponent } from './components/delivery/done-delivery/done-delivery.component';
import { SkiLockerSelectionComponent } from './components/ski/locker-selection/locker-selection.component';
import { SkiCardScannerComponent } from './components/ski/card-scanner/card-scanner.component';
import { SkiLockWaitComponent } from './components/ski/lock-wait/lock-wait.component';
import { SkiThanksComponent } from './components/ski/thanks/thanks.component';
import { SkiUnlockSelectionComponent } from './components/ski/unlock-selection/unlock-selection.component';
import { SkiReclaimSummaryComponent } from './components/ski/reclaim-summary/reclaim-summary.component';
import { LockerSkiState } from './state/locker-ski.state';
import { DoorOpenedComponent } from './components/delivery/door-oppened/door-oppened.component';

export const routes: Routes = [
  {
    path: 'rent/selection',
    component: RentSelectionComponent,
  },
  {
    path: 'rent/start-scanner',
    component: RentStartScannerComponent,
  },
  {
    path: 'rent/code-correct',
    component: RentCodeCorrectComponent,
  },
  {
    path: 'rent/warning',
    component: RentWarningComponent,
  },
  {
    path: 'rent/complete',
    component: RentCompleteComponent,
  },
  {
    path: 'rent/locker-selection',
    component: RentLockerSelectionComponent,
  },
  {
    path: 'delivery/deliveries/:action',
    component: DeliveriesComponent,
  },
  {
    path: 'delivery/positions/:action/:deliveryId',
    component: DeliveryPositionsComponent,
  },
  {
    path: 'delivery/locker-preview/:action/:deliveryId/:deliveryPositionId',
    component: LockerPreviewComponent,
  },
  {
    path: 'delivery/locker-message/:action/:deliveryId/:slotId/:slotIndex/:containerCode',
    component: LockerMessageComponent,
  },
  {
    path: 'delivery/release/:action/:deliveryId/:deliveryPositionId/:slotId/:slotIndex/:message',
    component: DoneDeliveryComponent,
  },
  {
    path: 'delivery/door-oppened',
    component: DoorOpenedComponent,
  },
  {
    path: 'ski/selection',
    component: SkiLockerSelectionComponent,
  },
  {
    path: 'ski/card-scanner',
    component: SkiCardScannerComponent,
  },
  {
    path: 'ski/lock-wait',
    component: SkiLockWaitComponent,
  },
  {
    path: 'ski/unlock-selection',
    component: SkiUnlockSelectionComponent,
  },
  {
    path: 'ski/reclaim-summary',
    component: SkiReclaimSummaryComponent,
  },
  {
    path: 'ski/thanks',
    component: SkiThanksComponent,
  },
];

@NgModule({
  declarations: [
    RentStartScannerComponent,
    RentCodeCorrectComponent,
    RentWarningComponent,
    RentCompleteComponent,
    RentSelectionComponent,
    RentLockerSelectionComponent,
    DeliveriesComponent,
    DeliveryPositionsComponent,
    LockerPreviewComponent,
    LockerMessageComponent,
    DoneDeliveryComponent,
    DoorOpenedComponent,
    SkiLockerSelectionComponent,
    SkiCardScannerComponent,
    SkiLockWaitComponent,
    SkiThanksComponent,
    SkiUnlockSelectionComponent,
    SkiReclaimSummaryComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgxsModule.forFeature([LockerState, LockerSkiState]),
    FormsModule,
    NgImageSliderModule,
    SharedModule,
  ],
  exports: [],
})
export class LockerModule {
  constructor() {}
}
