import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {
  theme: string;
  fullHdHalf: boolean = false;
  fullHd: boolean = false;

  constructor() {
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    this.fullHd = JSON.parse(localStorage.getItem('fullhd')) ?? false;
  }

  ngOnInit(): void {
  }

}
