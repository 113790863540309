<!-- theme === 'sanusx' or theme === 'default' -->
<ng-container *ngIf="theme !== 'ski'">
  <div
    class="flex flex-col items-start justify-start w-full h-screen text-white bg-primaryx box-main"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full wow fadeInDown"
      *ngIf="!lockerActive"
    ></app-header>

    <div
      class="flex flex-col items-center justify-start w-11/12 pt-16 mx-auto text-center"
      [ngClass]="locked ? 'flex-1' : ''"
    >
      <ng-container *ngIf="locked">
        <div
          class="relative flex items-center justify-center w-24 h-24 overflow-hidden bg-center bg-no-repeat bg-cover mb-14"
        >
          <app-svg-stripe
            [icon]="'setup-outer'"
            class="z-0 text-red"
          ></app-svg-stripe>
          <span
            class="absolute flex items-center justify-center w-12 h-12 ml-px top-5 left-6 z-5"
          >
            <app-svg-stripe [icon]="'lock'" [white]="true"></app-svg-stripe>
          </span>
        </div>
        <h2
          class="w-7/12 mx-auto text-5xl font-bold leading-normal text-white wow fadeInUp"
          data-wow-delay="0"
          data-wow-duration="0.5s"
        >
          {{ "SETUP.TITLE_MENU" | translate }}
        </h2>
        <h2
          class="w-full mx-auto text-4xl font-normal leading-normal text-white-70 wow fadeInUp"
          data-wow-delay="0"
          data-wow-duration="0.5s"
        >
          {{ "SETUP.SUBTITLE" | translate }}
        </h2>
        <password-dialog
          (lockedStatus)="lockedStatusHandler($event)"
          (errorStatus)="errorStatusHandler($event)"
          (valueStatus)="valueStatusHandler($event)"
          [loginSubmitted]="loginSubmitted"
          *ngIf="locked"
          class="w-full"
        ></password-dialog>
      </ng-container>
    </div>

    <div
      *ngIf="locked"
      class="flex flex-row items-center self-end justify-between w-10/12 h-auto pt-4 mx-auto space-x-16 pb-14"
    >
      <app-btn-sanusx
        (btnClick)="cancel()"
        [label]="'COMMON.BTN_CANCEL' | translate"
        buttonType="neutral"
        class="wow slideInLeft"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>

      <app-btn-sanusx
        (btnClick)="loginWithPassword()"
        [label]="'COMMON.BTN_SIGNIN' | translate"
        [disabled]="!loginEnabled"
        buttonType="secondary"
        class="wow slideInRight"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>
    </div>

    <ng-container *ngIf="!locked">
      <router-outlet></router-outlet>
    </ng-container>

    <div
      class="self-end block w-full bg-white rounded-t-xl wow fadeInUp"
      [ngClass]="
        locked ? 'h-6 ' : 'h-25 flex flex-row items-center justify-center py-2'
      "
      *ngIf="!lockerActive"
      style="z-index: 0"
    >
      <app-btn-sanusx
        *ngIf="!locked"
        (btnClick)="cancel()"
        [label]="'COMMON.BTN_LOGOUT' | translate"
        buttonType="neutral-dark"
        class="wow slideInUp"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>
    </div>
  </div>

  <!-- SUCCESS modal notify -->
  <app-modal-notify
    *ngIf="loginSuccess"
    [iconname]="'success'"
    [iconCssClass]="'text-green'"
    [useRedirect]="false"
    [redirectAfter]="1.5"
    [theme]="'sanusx'"
  >
    <p>{{ "LOGIN_USER.CODE_CORRECT" | translate }}</p>
  </app-modal-notify>
  <!-- END SUCCESS modal notify -->
</ng-container>

<ng-container *ngIf="theme === 'ski'">
  <div
    class="flex flex-col items-start justify-start w-full h-screen text-white bg-primaryx box-main"
  >
    <div
      class="flex flex-col items-center justify-start flex-1 w-11/12 pt-6 mx-auto text-center z-up z-90"
      *ngIf="locked"
    >
      <ng-container *ngIf="locked">
        <div class="flex flex-row items-center justify-between w-11/12 mx-auto">
          <div
            class="w-1/2 text-3xl font-bold leading-normal text-left text-white"
          >
            {{ "SETUP.TITLE_MENU" | translate }}
          </div>
          <div
            class="w-1/2 text-2xl font-normal leading-normal text-right text-white-70"
          >
            {{ "SETUP.SUBTITLE" | translate }}
          </div>
        </div>
        <password-dialog
          (lockedStatus)="lockedStatusHandler($event)"
          (errorStatus)="errorStatusHandler($event)"
          (valueStatus)="valueStatusHandler($event)"
          [loginSubmitted]="loginSubmitted"
          *ngIf="locked"
          class="w-full"
        ></password-dialog>
      </ng-container>
    </div>

    <div
      *ngIf="locked"
      class="flex flex-row items-center self-end justify-between w-10/12 h-auto pt-4 pb-6 mx-auto space-x-16"
    >
      <app-btn-sanusx
        (btnClick)="cancel()"
        [label]="'COMMON.BTN_CANCEL' | translate"
        buttonType="neutral"
        class="wow slideInLeft"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>

      <app-btn-sanusx
        (btnClick)="loginWithPassword()"
        [label]="'COMMON.BTN_SIGNIN' | translate"
        [disabled]="!loginEnabled"
        buttonType="secondary"
        class="wow slideInRight"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>
    </div>

    <ng-container *ngIf="!locked">
      <router-outlet></router-outlet>
    </ng-container>

    <div
      class="self-end block w-full bg-white rounded-t-xl wow fadeInUp"
      [ngClass]="
        locked ? 'h-6 ' : 'h-25 flex flex-row items-center justify-center py-2'
      "
      *ngIf="!lockerActive && !locked"
      style="z-index: 0"
    >
      <app-btn-sanusx
        *ngIf="!locked"
        (btnClick)="cancel()"
        [label]="'COMMON.BTN_LOGOUT' | translate"
        buttonType="neutral-dark"
        class="wow slideInUp"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>
    </div>
  </div>

  <!-- SUCCESS modal notify -->

  <app-modal-notify
    *ngIf="loginSuccess"
    [iconname]="'success'"
    [iconCssClass]="'text-green'"
    [useRedirect]="false"
    [redirectAfter]="1.5"
    [theme]="'ski'"
  >
    <p>{{ "LOGIN_USER.CODE_CORRECT" | translate }}</p>
  </app-modal-notify>
  <!-- END SUCCESS modal notify -->
</ng-container>

<!-- <ng-container *ngIf="theme === 'default'">
  <div class="flex flex-col items-start justify-between w-full h-screen">
    <div class="flex flex-col justify-between first-block bg-primaryx-bg">
      <div
        class="relative z-30 flex items-center justify-center w-full h-auto py-14"
      >
        <button
          class="mx-auto btn btn-action border-gray-light group active:border-active"
          (click)="cancel()"
        >
          <span class="icon bg-gray group-active:bg-active">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49.498"
              height="49.498"
              viewBox="0 0 49.498 49.498"
            >
              <path
                id="close_icon"
                data-name="close_icon"
                d="M-3922.81,2626.037v-12h-12a3,3,0,0,1-3-3,3,3,0,0,1,3-3h12v-11a3,3,0,0,1,3-3,3,3,0,0,1,3,3v11h11a3,3,0,0,1,3,3,3,3,0,0,1-3,3h-11v12a3,3,0,0,1-3,3A3,3,0,0,1-3922.81,2626.037Z"
                transform="translate(950.191 -4593.964) rotate(-45)"
                fill="currentColor"
              />
            </svg>
          </span>
          <span class="label text-gray group-active:text-active"
            >
            {{ "COMMON.BTN_CANCEL" | translate }}
            </span
          >
        </button>
      </div>
      <div
        class="absolute bottom-0 flex flex-col items-center justify-end w-full h-full -mb-16 wow bounceInDown animated"
        data-wow-delay="0"
        data-wow-duration="1s"
      >
        <app-cloud></app-cloud>
        <div
          class="absolute flex flex-col items-center justify-center mx-auto -mb-12 -ml-6 bg-white rounded-full w-80 h-80 drop-shadow-xl text-primaryx wow pulse animated"
          data-wow-delay="0"
          data-wow-duration="1.5s"
          data-wow-iteration="2"
          style="border-radius: 9999px !important"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="150"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6 -6a6 6 0 0 1 -8 -8l3.5 3.5"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center text-center middle-block">
      <h1 class="self-end mx-auto text-center title text-primaryx-dark">
        Setup Menü<br />
        <strong>Wählen Sie die auszuführende Aktion</strong>
      </h1>
    </div>
    <password-dialog
      (lockedStatus)="lockedStatusHandler($event)"
      *ngIf="locked"
    ></password-dialog>

    <setup-dialog
      [hatchData]="hatchDataExport"
      (hatchLoaderStatus)="hatchLoaderStatusHandler($event)"
      *ngIf="showHatch"
    >
    </setup-dialog>
    <div
      class="flex flex-col items-start justify-start third-block bg-primaryx-bg wow bounceInUp animated"
      data-wow-delay="0s"
      data-wow-duration="1s"
    >
      <div
        class="flex flex-row items-start justify-between w-9/12 mx-auto -mt-32"
      >
        <app-button
          *ngIf="!locked"
          (btnClick)="openHatch()"
          [label]="'Klappe öffnen (90 Sekunden)'"
          buttonType="biground"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="96"
            height="96"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 4h-6a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h8" />
            <path d="M18 20v-17" />
            <path d="M15 6l3 -3l3 3" />
          </svg>
        </app-button>

        <app-button
          *ngIf="!locked"
          (btnClick)="runSetup()"
          [label]="'Run setup'"
          buttonType="biground"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="96"
            height="96"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M7 4v16l13 -8z" />
          </svg>
        </app-button>
      </div>
    </div>
  </div>
</ng-container> -->
