import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Item } from '../../../core/models/item';
import { Retailer } from '../../state/retailer.actions';
import { RetailerState, RetailerStateModel } from '../../state/retailer.state';
import { Idle } from '@ng-idle/core';
import { MachineError } from 'src/app/core/models/error';
import { Core } from 'src/app/core/state/core.actions';
import { Navigate } from '@ngxs/router-plugin';
import {
  CoreState,
  CoreStateModel,
  ModuleType,
} from 'src/app/core/state/core.state';
import { ImageService } from '../../services/image.service';
import { Category } from '../../models/category';
import { CategoryService } from '../../services/category.service';
import { NgImageSliderComponent } from 'ng-image-slider';
import { Location } from '@angular/common';
import * as Hammer from 'hammerjs';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, OnDestroy {
  @ViewChild('nav') slider: NgImageSliderComponent;
  @Select(RetailerState.selectedItem)
  item$: Observable<Item>;
  logo: string;
  menuPanelOpened: boolean = false;
  searchPanelOpened: boolean = false;
  infoPanelOpened: boolean = false;
  rightHand: boolean = true;
  imgStep: number = 0;
  stepLimit: number = 0;
  selectedItem: any;
  selectedImage: SafeUrl;
  theme: string;
  fullHdHalf: boolean = false;
  fullHd: boolean = false;
  useShoppingCart: boolean = false;
  priceType: string = '';
  statusCartMessage: boolean = false;
  cartIsFull: boolean = false;
  cartItems: Array<any> = [];
  productCategories: Array<any> = [];
  visibleCategories: Category[] = [];
  activeParentCategory: object | null = null;
  cartTotalValue: number = 0;
  cartQuantityLimit: number = 5;
  activeProductId: number = null;
  priceDifference: number = null;
  percentDifference: number = null;
  itemCartQuantity: number = 1;
  showProductPrices: boolean = true;
  imageSize: object = {
    width: '120px',
    height: '120px',
    space: 20,
  };
  suggestedProductId: number = null;
  cartItemsForDisplay: Array<any> = [];
  imageObject: Array<any> = [];
  multiLanguage: boolean = false;

  @ViewChild('targetProductInfo')
  scrollProductInfo: ElementRef;

  constructor(
    private store: Store,
    private idle: Idle,
    private location: Location,
    private imageService: ImageService,
    private categoryService: CategoryService,
    private router: Router
  ) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    this.fullHd = JSON.parse(localStorage.getItem('fullhd')) ?? false;
    this.selectedItem = store.selectSnapshot(RetailerState.selectedItem);
    // console.warn('this.selectedItem', this.selectedItem);
    this.getHandDataFromStorage();
    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.multiLanguage =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.multiLanguage;
      this.logo =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo;

      this.useShoppingCart =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.useShoppingCart;

      this.showProductPrices =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.showProductPrices;
      // this.useShoppingCart = true;
      this.cartQuantityLimit =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.cartQuantityLimit;

      this.cartItems =
        this.store.selectSnapshot<RetailerStateModel>(
          RetailerState
        ).shoppingCart;

      this.cartTotalValue = Number(
        this.cartItems
          .map((item) => item.product.grossPrice)
          .reduce((a, b) => a + b, 0)
          .toFixed(2)
      );
      // console.warn('this.cartItems', this.cartItems);
    }

    if (!this.selectedItem) {
      this.store.dispatch(new Navigate(['/core/screensaver']));
    } else {
      this.imageObject.splice(0, this.imageObject.length);

      if (this.selectedItem.product.imagePaths) {
        for (let i = 0; i < this.selectedItem.product.imagePaths.length; i++) {
          this.imageService
            .getImage(this.selectedItem.product.imagePaths[i])
            .subscribe((image) => {
              this.imageObject.push({
                image: image,
                thumbImage: image,
                alt: this.selectedItem.product.name,
              });
              this.selectedImage = this.imageObject[0].image;
            });
        }
      }
      this.productCategories = this.selectedItem.product.categories;
      this.visibleCategories = this.categoryService.getVisibleCategories();
      this.activeParentCategory = this.categoryService.findCategoryParentById(
        this.visibleCategories,
        this.productCategories[0].id
      );
    }
  }

  ngOnDestroy(): void {
    this.idle.stop();
    this.imgStep = 0;
    this.itemCartQuantity = 0;
    // this.visibleCategories = [];
    // this.productCategories = [];
  }

  ngOnInit(): void {
    this.idle.watch();
    this.priceType = this.selectedItem.product.priceType;
    if (this.priceType === 'SALE') {
      this.priceDifference =
        this.selectedItem.product.standardGrossPrice -
        this.selectedItem.product.grossPrice;
      this.percentDifference =
        (this.priceDifference / this.selectedItem.product.standardGrossPrice) *
        100;
    }

    this.loadCartItems();

    const hammer = new Hammer(document.body);
    hammer.get('swipe').set({ direction: Hammer.DIRECTION_ALL });

    hammer.on('swiperight', () => {
      this.prevImageClick();
    });
    hammer.on('swipeleft', () => {
      this.nextImageClick();
    });
  }

  onCategorySelected(id: number) {
    this.categoryService.setCategoryId(id);
    this.location.back();
  }

  onImageClicked($event): void {
    this.selectedImage = this.imageObject[$event].image;
    this.imgStep = $event;
  }

  onBuyProduct(): void {
    if (this.useShoppingCart) {
      // console.log('test 1');
      let cartNotEmpty = this.cartItems.length > 0;

      this.store
        .dispatch(
          new Retailer.AddProductToCart(
            this.selectedItem.product.id,
            this.itemCartQuantity
          )
        )
        .subscribe((x) => {
          // console.log('test 2');
          // this.router.navigate(['/retailer/selection']);
          if (cartNotEmpty) {
            this.router.navigate(['/retailer/cart']);
          } else {
            this.store.dispatch(new Retailer.BuyCart(null)).subscribe({
              next: (v) => {
                console.log('end 1');
                setTimeout(() => {
                  this.store.dispatch(new Navigate(['/core/screensaver']));
                }, 2000);
              },
              error: (error) => {
                console.log('end 2');
                this.store
                  .dispatch(new Core.Error(error, ModuleType.RETAILER))
                  .subscribe(() => {
                    setTimeout(() => {
                      this.store.dispatch(new Navigate(['/core/screensaver']));
                    }, 10000);
                  });
              },
            });
          }
        });
    } else {
      console.log('buy');
      this.store
        .dispatch(new Retailer.BuyProduct(this.selectedItem.product.id))
        .subscribe(
          () => {
            setTimeout(() => {
              this.store.dispatch(new Navigate(['/core/screensaver']));
            }, 10000);
          },
          (error: MachineError) => {
            this.store
              .dispatch(new Core.Error(error, ModuleType.RETAILER))
              .subscribe(() => {
                setTimeout(() => {
                  this.store.dispatch(new Navigate(['/core/screensaver']));
                }, 10000);
              });
          }
        );
    }
  }

  cancel(): void {
    this.store.dispatch(new Retailer.ActionSelection());
  }

  back(): void {
    this.location.back();
  }

  menuPanelHandler(status: boolean) {
    this.menuPanelOpened = status;
  }

  searchPanelHandler(status: boolean) {
    this.searchPanelOpened = status;
  }

  rightHandHandler(status: boolean) {
    this.rightHand = status;
  }

  infoPanelHandler(status: boolean) {
    this.infoPanelOpened = status;
  }

  closeMenuPanel() {
    this.menuPanelOpened = !this.menuPanelOpened;
  }
  closeSearchPanel() {
    this.searchPanelOpened = !this.searchPanelOpened;
  }

  productInfo() {
    this.infoPanelOpened = !this.infoPanelOpened;
  }

  prevImageClick() {
    this.imgStep = Math.max(this.imgStep - 1, 0);

    this.selectedImage = this.imageObject[this.imgStep].image;
  }

  nextImageClick() {
    this.stepLimit = this.imageObject.length - 1;
    this.imgStep = Math.min(this.imgStep + 1, this.stepLimit);

    this.selectedImage = this.imageObject[this.imgStep].image;
  }

  getHandDataFromStorage() {
    let hand = localStorage.getItem('hand');

    if (hand === 'right-hand') {
      this.rightHand = true;
    } else if (hand === 'left-hand') {
      this.rightHand = false;
    } else {
      this.rightHand = true;
    }
  }

  addProductToCart() {
    this.store
      .dispatch(
        new Retailer.AddProductToCart(
          this.selectedItem.product.id,
          this.itemCartQuantity
        )
      )
      .subscribe((x) => {
        /* this.itemCartQuantity = 1;
        this.activeProductId = this.selectedItem.product.id;
        this.statusCartMessage = true;
        this.cartItems =
          this.store.selectSnapshot<RetailerStateModel>(
            RetailerState
          ).shoppingCart;
        this.loadCartItems();
        this.checkCartQuantityLimit();*/
        this.statusCartMessage = true;
        setTimeout(() => {
          this.statusCartMessage = false;
          this.router.navigate(['/retailer/selection']);
        }, 500);
      });
  }

  hasNoMoreItemsLeftInStock(item: any) {
    // let productCartItems = this.cartItems.filter(x => x.product.id == item.product.id);
    // let quantityInStock = item.wwksArticle.quantity;
    // return productCartItems.length >= quantityInStock;
  }

  checkCartQuantityLimit() {
    this.cartIsFull =
      this.cartItems.length + this.itemCartQuantity >= this.cartQuantityLimit;

    if (this.cartItems.length >= this.cartQuantityLimit) {
      this.router.navigate(['/retailer/cart']);
    }
  }

  increaseProductQuantity(): void {
    this.itemCartQuantity++;
    this.checkCartQuantityLimit();
    /*this.store
      .dispatch(new Retailer.AddProductToCart(this.selectedItem.product.id))
      .subscribe((x) => {
        this.loadCartItems();
        this.statusCartMessage = true;

        setTimeout(() => {
          this.statusCartMessage = false;
        }, 500);
      });*/
  }

  decreaseProductQuantity(): void {
    if (this.itemCartQuantity > 0) {
      this.itemCartQuantity--;
      this.checkCartQuantityLimit();
    }
    /*this.store
      .dispatch(
        new Retailer.RemoveProductFromCart(this.selectedItem.product.id)
      )
      .subscribe((x) => {
        this.loadCartItems();
        this.statusCartMessage = true;
        setTimeout(() => {
          this.statusCartMessage = false;
        }, 500);
      });*/
  }

  loadCartItems(): void {
    this.cartItems =
      this.store.selectSnapshot<RetailerStateModel>(RetailerState).shoppingCart;
    // console.warn("this.cartItems::loadCartItems", this.cartItems);
    this.cartItemsForDisplay.splice(0, this.cartItemsForDisplay.length);

    /*const matchingProduct = this.cartItems.find(
      (item) => item.product.id === this.selectedItem.product.id
    );

    if (matchingProduct) {
      this.itemCartQuantity = this.cartItems.reduce((total, item) => {
        if (item.product.id === this.selectedItem.product.id) {
          return total + 1;
        }
        return total;
      }, 0);
    }*/

    this.checkCartQuantityLimit();
  }

  removeFormatting(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  scrollDownText() {
    let top = (this.scrollProductInfo.nativeElement.offsetHeight * 50) / 100;
    (this.scrollProductInfo.nativeElement as HTMLElement).scrollBy({
      top,
      left: 0,
      behavior: 'smooth',
    });
  }
  scrollUpText() {
    let top = (this.scrollProductInfo.nativeElement.offsetHeight * -50) / 100;
    (this.scrollProductInfo.nativeElement as HTMLElement).scrollBy({
      top,
      left: 0,
      behavior: 'smooth',
    });
  }
}
