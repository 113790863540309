import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';
import { LockerState, LockerStateModel } from '../../../state/locker.state';
import { Navigate } from '@ngxs/router-plugin';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss']
})
export class RentCompleteComponent implements OnInit {
  back: boolean = false;
  logo: string;
  theme: string;
  tenant: string;
  constructor(private store: Store) {
    this.theme = localStorage.getItem('theme') ?? 'default';

    const rentAction =
      this.store.selectSnapshot<LockerStateModel>(LockerState).rentAction;

    this.back = rentAction === 'return';

    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.logo = this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo;

      this.tenant = this.store.selectSnapshot<CoreStateModel>(CoreState).config.tenant;
    }

    setTimeout(() => this.store.dispatch(new Navigate(['/core', 'screensaver'])), 10000);
  }

  ngOnInit(): void {
  }
}
