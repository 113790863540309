<ng-container *ngIf="theme !== 'nex'">
  <div
    class="flex flex-col items-center justify-between w-full h-auto"
    (click)="onOpenDetails($event)"
  >
    <!-- ==================== product image ==================== -->
    <div
      class="overflow-hidden pi-image"
      [ngClass]="useShoppingCart ? 'h-56' : fullHd ? 'h-72' : 'h-64'"
    >
      <img
        loading="lazy"
        [src]="image"
        alt="{{ name }}"
        class="mx-auto my-2 max-w-[90%]"
        [ngClass]="
          fullHdHalf
            ? 'max-h-56'
            : useShoppingCart
            ? 'max-h-56'
            : fullHd
            ? 'max-h-72'
            : 'max-h-64'
        "
      />

      <!-- product badge -->
      <div class="absolute top-0 left-0 w-full h-full rounded-card">
        <!-- badge new -->
        <span class="pi-img-badge pi-new" *ngIf="new">
          {{ "COMMON.NEW" | translate }}
        </span>
        <!-- end badge new -->

        <!-- badge action -->
        <span class="pi-img-badge pi-action" *ngIf="action">
          <!-- circular text -->
          <svg
            class="circle-text"
            viewBox="0 0 100 100"
            width="100%"
            height="100%"
          >
            <defs>
              <path
                id="circle"
                d="
        M 50, 50
        m -25, 0
        a 25,25 0 1,1 50,0
        a 25,25 0 1,1 -50,0"
              />
            </defs>
            <text font-size="32">
              <textPath xlink:href="#circle">
                {{ "COMMON.ACTION" | translate }}
              </textPath>
            </text>
          </svg>
          <!-- END circular text -->

          <!-- fire icon -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="32"
            viewBox="0 0 28 32"
            class="action-icon"
          >
            <path
              d="M21.79,3.106a2,2,0,0,0-2.9-.77A5.993,5.993,0,0,0,17.246,4.1a13.965,13.965,0,0,0-1.14,2.232,36.178,36.178,0,0,0-1.68,5.468,62.727,62.727,0,0,0-1.226,7.16,5.28,5.28,0,0,1-1.89-2.134,12.329,12.329,0,0,1-.8-5.308A2,2,0,0,0,7.1,10.1a14,14,0,1,0,19.8,0,28.831,28.831,0,0,1-2.7-2.934,24.626,24.626,0,0,1-2.414-4.06ZM21.24,28.24A6,6,0,0,1,11,24a10.835,10.835,0,0,0,5,1c0-2,1-8,2.5-9a11.557,11.557,0,0,0,2.742,3.758,6,6,0,0,1,0,8.484Z"
              transform="translate(-3 -2)"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </svg>
          <!-- END fire icon -->
        </span>
        <!-- end badge action -->

        <!-- badge discount -->
        <ng-container *ngIf="priceType === 'SALE'">
          <span class="pi-img-badge pi-discount bg-primaryx">
            -{{ percentDifference.toFixed(2).slice(0, -3) }}%
          </span>
        </ng-container>
        <!-- end badge discount -->

        <!-- badge for special brand (not for all products) max size image 56x56px -->
        <span class="pi-img-badge pi-brand-badge" *ngIf="badgeImg !== ''">
          <img loading="lazy" [src]="badgeImg" />
        </span>
        <!-- badge brand or subbrand -->
      </div>
      <!-- end product badge -->
    </div>
    <!-- ==================== END product image ==================== -->

    <!-- ==================== product title ==================== -->
    <div class="pi-title">
      <span class="w-full h-0.5 bg-gray-ultra block mb-2"></span>
      <span class="text-xl text-left text-gray">
        <ng-container *ngIf="!brandName || !brandName.trim()">
          <span class="invisible">---</span>
        </ng-container>
        {{ brandName | ellipsis : 20 }}
      </span>
      <span
        class="font-semibold text-left text-primaryx-dark min-h-24x"
        [ngClass]="
          fullHdHalf
            ? 'text-4xl min-h-48 max-h-48'
            : 'text-[1.60rem] min-h-16 max-h-16'
        "
      >
        {{ name | ellipsis : 33 }}
      </span>
      <div
        class="flex flex-row items-center justify-between w-full text-2xl text-gray"
      >
        <span class="mr-auto text-left">
          <ng-container *ngIf="!quantity || !quantity.toString().trim()">
            <span class="invisible">---</span>
          </ng-container>
          {{ quantity }}
        </span>
        <span class="ml-auto text-right">
          <ng-container
            *ngIf="!pricePerItem || !pricePerItem.toString().trim()"
          >
            <span class="invisible">---</span>
          </ng-container>
          {{ pricePerItem }}
          <!-- 0,41 € / 1 st -->
        </span>
      </div>
    </div>
    <!-- ==================== END product image ==================== -->

    <!-- ==================== product price ==================== -->

    <!-- regular price type -->
    <ng-container *ngIf="!priceType || priceType === 'REGULAR'">
      <div
        class="flex flex-col items-center justify-center w-full h-auto px-2 pt-3 pb-2 text-right"
      >
        <div
          class="flex flex-row h-16 min-h-16 max-h-16 items-center w-full px-6 py-4 jusftify-between bg-gray-ultra rounded-[0.65rem]"
        >
          <span
            class="ml-auto font-bold text-right text-primaryx-dark"
            [ngClass]="fullHdHalf ? 'text-4xl' : 'text-2xl'"
          >
            {{ grossPrice.toFixed(2) }} €
          </span>
        </div>
      </div>
    </ng-container>
    <!-- END regular price type -->

    <!-- sale price type -->
    <ng-container *ngIf="priceType === 'SALE'">
      <div
        class="grid w-full grid-cols-2 grid-rows-1 gap-2 px-2 pt-3 pb-2 text-right"
      >
        <!-- old price and difference -->
        <div
          class="flex flex-col w-full h-16 leading-snug min-h-16 max-h-16 justify-center items-end rounded-l-[0.65rem] bg-red-ultra-light ml-auto font-medium text-right text-gray pl-1 pr-2 py-1"
          [ngClass]="fullHdHalf ? 'text-xl' : 'text-xl'"
        >
          <span class="line-through decoration-[red] decoration-double"
            >{{ standardGrossPrice.toFixed(2) }} €</span
          >
          <span> - {{ priceDifference.toFixed(2) }} € </span>
        </div>
        <!-- END old price and difference -->

        <!-- sale price -->
        <div
          class="flex flex-col w-full h-16 min-h-16 max-h-16 justify-center items-center rounded-r-[0.65rem] bg-gray-ultra mr-auto font-bold text-center text-red pr-1 pl-2 py-1"
          [ngClass]="fullHdHalf ? 'text-4xl' : 'text-2xl'"
        >
          {{ grossPrice.toFixed(2) }} €
        </div>
        <!-- END sale price -->
      </div>
    </ng-container>
    <!-- END sale price type -->
  </div>
  <!-- ==================== END product price ==================== -->

  <!-- ==================== add to cart ==================== -->
  <!--  -->
  <div
    *ngIf="useShoppingCart"
    class="flex flex-col items-center justify-center w-full h-auto px-2 pb-2 text-right"
  >
    <button
      (click)="!cartIsFull && onAddToCard($event)"
      class="transition-all btn btn-card-cart-with-label"
      [ngClass]="
        statusCartMessage
          ? 'bg-green'
          : 'bg-primaryx disabled:bg-gray active:bg-active'
      "
      [disabled]="cartIsFull || hasEmptyStock || statusCartMessage"
    >
      <span class="icon">
        <ng-container *ngIf="!statusCartMessage">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M6.75,24a2.3,2.3,0,0,1-1.593-.622,2.043,2.043,0,0,1-.657-1.5,2.19,2.19,0,0,1,2.249-2.112A2.121,2.121,0,1,1,6.75,24Zm12-.006A2.12,2.12,0,1,1,21,21.878,2.189,2.189,0,0,1,18.749,23.994Zm.75-5.646H6.622A3,3,0,0,1,3.85,16.607a2.692,2.692,0,0,1,.65-3.076l1.34-1.259L3.8,4.607c-.006-.019-.012-.04-.016-.059L3.331,2.824H1.5A1.46,1.46,0,0,1,0,1.412,1.46,1.46,0,0,1,1.5,0h3A1.482,1.482,0,0,1,5.955,1.072l.465,1.75H22.5a1.515,1.515,0,0,1,1.276.67,1.33,1.33,0,0,1,.066,1.373l-4.5,8.468A1.506,1.506,0,0,1,18,14.115H8.121l-1.5,1.411H19.5a1.414,1.414,0,1,1,0,2.823ZM10.359,7.927a.751.751,0,1,0,0,1.5H12.75v2.25a.8.8,0,0,0,1.594,0V9.427h2.39a.751.751,0,1,0,0-1.5h-2.39V5.678a.8.8,0,0,0-1.594,0V7.927Z"
            />
          </svg>
        </ng-container>
        <ng-container *ngIf="statusCartMessage">
          <svg
            class="w-6 h-6 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </ng-container>
      </span>
      <span class="label">
        {{ "COMMON.ADD_TO_CART" | translate }}
      </span>
    </button>
  </div>
  <!-- ==================== END add to cart ==================== -->
</ng-container>

<!-- temp option, later we need to change this -->
<ng-container *ngIf="theme === 'nex'">
  <ng-container *ngIf="flatProductCard">
    <div
      class="w-full h-full absolute left-0 top-0 flex z-20"
      (click)="onOpenDetails($event)"
    >
      <!-- regular price type -->
      <ng-container *ngIf="!priceType || priceType === 'REGULAR'">
        <div
          class="absolute z-10 left-0 top-0 flex flex-col items-center justify-center w-full h-auto px-2 pt-2 pb-2 text-right"
        >
          <div
            class="flex flex-row h-6 min-h-6 max-h-6 mr-auto items-center w-auto px-4 py-2 justify-start bg-nex-gray-light rounded-md"
          >
            <span class="mr-auto font-bold text-left text-nex-black text-lg">
              {{ grossPrice.toFixed(2) }} €
            </span>
          </div>
        </div>
      </ng-container>
      <!-- END regular price type -->
      <!-- regular price type -->
      <ng-container *ngIf="!priceType || priceType === 'SALE'">
        <div
          class="absolute z-10 left-0 top-0 flex flex-col items-center justify-center w-full h-auto px-2 pt-2 pb-2 text-right"
        >
          <div
            class="flex flex-row h-auto min-h-6 mr-auto items-center w-auto px-4 py-2 justify-start bg-yellow-400 rounded-md"
          >
            <div
              class="flex flex-col items-start justify-start mr-auto font-bold text-left text-nex-black text-lg"
            >
              <span> {{ grossPrice.toFixed(2) }} € </span>
              <span class="line-through decoration-[red] decoration-double">
                {{ standardGrossPrice.toFixed(2) }} €
              </span>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- END regular price type -->
    </div>

    <img loading="lazy" [src]="image" />

    <!-- product badge -->
    <div class="absolute top-0 left-0 w-full h-full rounded-card">
      <!-- badge new -->
      <span class="pi-img-badge pi-new pi-new--nex" *ngIf="new">
        {{ "COMMON.NEW" | translate }}
      </span>
      <!-- end badge new -->

      <!-- badge promo -->
      <!-- <span class="pi-img-badge pi-promo pi-promo--nex" *ngIf="promo">
        {{ "COMMON.PROMO" | translate }}
      </span> -->
      <!-- end badge new -->

      <!-- badge action -->
      <span class="pi-img-badge pi-action pi-action--nex" *ngIf="action">
        <!-- circular text -->
        <svg
          class="circle-text"
          viewBox="0 0 100 100"
          width="100%"
          height="100%"
        >
          <defs>
            <path
              id="circle"
              d="
        M 50, 50
        m -25, 0
        a 15,10 0 1,1 50,0
        a 10,10 0 1,1 -45,0"
            />
          </defs>
          <text font-size="32">
            <textPath xlink:href="#circle">
              {{ "COMMON.ACTION" | translate }}
            </textPath>
          </text>
        </svg>
        <!-- END circular text -->

        <!-- fire icon -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="32"
          viewBox="0 0 28 32"
          class="action-icon"
        >
          <path
            d="M21.79,3.106a2,2,0,0,0-2.9-.77A5.993,5.993,0,0,0,17.246,4.1a13.965,13.965,0,0,0-1.14,2.232,36.178,36.178,0,0,0-1.68,5.468,62.727,62.727,0,0,0-1.226,7.16,5.28,5.28,0,0,1-1.89-2.134,12.329,12.329,0,0,1-.8-5.308A2,2,0,0,0,7.1,10.1a14,14,0,1,0,19.8,0,28.831,28.831,0,0,1-2.7-2.934,24.626,24.626,0,0,1-2.414-4.06ZM21.24,28.24A6,6,0,0,1,11,24a10.835,10.835,0,0,0,5,1c0-2,1-8,2.5-9a11.557,11.557,0,0,0,2.742,3.758,6,6,0,0,1,0,8.484Z"
            transform="translate(-3 -2)"
            fill="currentColor"
            fill-rule="evenodd"
          />
        </svg>
        <!-- END fire icon -->
      </span>
      <!-- end badge action -->

      <!-- badge discount -->
      <ng-container *ngIf="priceType === 'SALE'">
        <span class="pi-img-badge pi-discount pi-discount--nex bg-primaryx">
          -{{ percentDifference.toFixed(2).slice(0, -3) }}%
        </span>
      </ng-container>
      <!-- end badge discount -->

      <!-- badge for special brand (not for all products) max size image 56x56px -->
      <span class="pi-img-badge pi-brand-badge" *ngIf="badgeImg !== ''">
        <img loading="lazy" [src]="badgeImg" />
      </span>
      <!-- badge brand or subbrand -->
    </div>
    <!-- end product badge -->
  </ng-container>
  <ng-container *ngIf="!flatProductCard">
    <div
      class="flex flex-col items-center justify-between w-full h-auto"
      (click)="onOpenDetails($event)"
    >
      <!-- ==================== product image ==================== -->
      <div class="overflow-hidden pi-image h-36">
        <img
          loading="lazy"
          [src]="image"
          alt="{{ name }}"
          class="mx-auto max-w-[90%] max-h-36"
        />

        <!-- product badge -->
        <div class="absolute top-0 left-0 w-full h-full rounded-card">
          <!-- badge new -->
          <span class="pi-img-badge pi-new" *ngIf="new">
            {{ "COMMON.NEW" | translate }}
          </span>
          <!-- end badge new -->

          <!-- badge promo -->
          <!-- <span class="pi-img-badge pi-promo" *ngIf="promo">
            {{ "COMMON.PROMO" | translate }}
          </span> -->
          <!-- end badge new -->

          <!-- badge action -->
          <span class="pi-img-badge pi-action" *ngIf="action">
            <!-- circular text -->
            <svg
              class="circle-text"
              viewBox="0 0 100 100"
              width="100%"
              height="100%"
            >
              <defs>
                <path
                  id="circle"
                  d="
                    M 50, 50
                    m -25, 0
                    a 25,25 0 1,1 50,0
                    a 25,25 0 1,1 -50,0"
                />
              </defs>
              <text font-size="32">
                <textPath xlink:href="#circle">
                  {{ "COMMON.ACTION" | translate }}
                </textPath>
              </text>
            </svg>
            <!-- END circular text -->

            <!-- fire icon -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="32"
              viewBox="0 0 28 32"
              class="action-icon"
            >
              <path
                d="M21.79,3.106a2,2,0,0,0-2.9-.77A5.993,5.993,0,0,0,17.246,4.1a13.965,13.965,0,0,0-1.14,2.232,36.178,36.178,0,0,0-1.68,5.468,62.727,62.727,0,0,0-1.226,7.16,5.28,5.28,0,0,1-1.89-2.134,12.329,12.329,0,0,1-.8-5.308A2,2,0,0,0,7.1,10.1a14,14,0,1,0,19.8,0,28.831,28.831,0,0,1-2.7-2.934,24.626,24.626,0,0,1-2.414-4.06ZM21.24,28.24A6,6,0,0,1,11,24a10.835,10.835,0,0,0,5,1c0-2,1-8,2.5-9a11.557,11.557,0,0,0,2.742,3.758,6,6,0,0,1,0,8.484Z"
                transform="translate(-3 -2)"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
            <!-- END fire icon -->
          </span>
          <!-- end badge action -->

          <!-- badge discount -->
          <ng-container *ngIf="priceType === 'SALE'">
            <span class="pi-img-badge pi-discount bg-primaryx">
              -{{ percentDifference.toFixed(2).slice(0, -3) }}%
            </span>
          </ng-container>
          <!-- end badge discount -->

          <!-- badge for special brand (not for all products) max size image 56x56px -->
          <span class="pi-img-badge pi-brand-badge" *ngIf="badgeImg !== ''">
            <img loading="lazy" [src]="badgeImg" />
          </span>
          <!-- badge brand or subbrand -->
        </div>
        <!-- end product badge -->
      </div>
      <!-- ==================== END product image ==================== -->

      <!-- ==================== product title ==================== -->
      <div class="pi-title">
        <!-- <span class="w-full h-0.5 bg-gray-ultra block mb-2"></span> -->
        <span class="text-lg text-left text-nex-gray-dark">
          <ng-container *ngIf="!brandName || !brandName.trim()">
            <span class="invisible">---</span>
          </ng-container>
          {{ brandName | ellipsis : 26 }}
        </span>

        <span
          class="font-semibold text-left text-nex-gray-ultra text-2xl min-h-16 h-auto"
        >
          <ng-container *ngIf="priceType !== 'SALE'">
            {{ name | ellipsis : 56 }}
          </ng-container>
          <ng-container *ngIf="priceType === 'SALE'">
            {{ name | ellipsis : 36 }}
          </ng-container>
        </span>

        <div
          class="flex flex-row items-center justify-between w-full text-lg text-nex-gray-dark"
        >
          <span class="mr-auto text-left text-lg">
            <ng-container *ngIf="!quantity || !quantity.toString().trim()">
              <span class="invisible">---</span>
            </ng-container>
            {{ quantity }}
            <!-- 25kg -->
          </span>
          <span class="ml-auto text-right">
            <ng-container
              *ngIf="!pricePerItem || !pricePerItem.toString().trim()"
            >
              <span class="invisible">---</span>
            </ng-container>
            {{ pricePerItem }}
            <!-- 0,41 € / 1 st -->
          </span>
        </div>
      </div>
      <!-- ==================== END product image ==================== -->

      <!-- ==================== product price ==================== -->

      <!-- regular price type -->
      <!-- <ng-container *ngIf="!priceType || priceType === 'REGULAR'">
        <div
          class="flex flex-col items-center justify-center w-full h-auto px-2 pt-3 pb-2 text-right"
        >
          <div
            class="flex flex-row h-16 min-h-16 max-h-16 items-center w-full px-6 py-4 jusftify-between bg-gray-ultra rounded-[0.65rem]"
          >
            <span
              class="ml-auto font-bold text-right text-primaryx-dark"
              [ngClass]="fullHdHalf ? 'text-4xl' : 'text-2xl'"
            >
              {{ grossPrice.toFixed(2) }} €
            </span>
          </div>
        </div>
      </ng-container> -->
      <!-- END regular price type -->
    </div>
    <!-- ==================== END product price ==================== -->

    <!-- sale price type -->
    <ng-container *ngIf="priceType === 'SALE'">
      <div class="w-full flex flex-col justify-start items-center z-20">
        <!-- old price and difference -->
        <div
          class="-bottom-4 relative flex flex-row w-full h-14 pb-4 px-4 pt-1 text-xl min-h-14 max-h-14 justify-between items-center rounded-t-[0.65rem] bg-red-ultra-light ml-auto font-medium text-nex-gray-dark"
        >
          <span class="line-through decoration-[red] decoration-double"
            >{{ standardGrossPrice.toFixed(2) }} €</span
          >
          <span> - {{ priceDifference.toFixed(2) }} € </span>
        </div>
        <!-- END old price and difference -->
      </div>
    </ng-container>
    <!-- END sale price type -->
    <!-- ==================== add to cart and price ==================== -->
    <!--  -->
    <div
      class="relative z-40 border-2 border-white text-center w-full h-16 min-h-16 max-h-16 flex flex-row bg-nex-gray-light items-center jusftify-between px-2 py-4 rounded-[0.65rem]"
    >
      <!-- sale price -->
      <div
        class="flex flex-col items-center text-nex-gray-ultra justify-center text-2xl font-bold"
        [ngClass]="useShoppingCart ? 'w-1/2' : 'w-full'"
      >
        {{ grossPrice.toFixed(2) }} €
      </div>
      <!-- END sale price -->
      <div class="w-1/2" *ngIf="useShoppingCart">
        <button
          (click)="!cartIsFull && onAddToCard($event)"
          class="transition-all btn btn-card-cart"
          [ngClass]="
            statusCartMessage
              ? 'bg-green'
              : 'bg-primaryx disabled:bg-gray active:bg-active'
          "
          [disabled]="cartIsFull || hasEmptyStock || statusCartMessage"
        >
          <span class="icon">
            <ng-container *ngIf="!statusCartMessage">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M6.75,24a2.3,2.3,0,0,1-1.593-.622,2.043,2.043,0,0,1-.657-1.5,2.19,2.19,0,0,1,2.249-2.112A2.121,2.121,0,1,1,6.75,24Zm12-.006A2.12,2.12,0,1,1,21,21.878,2.189,2.189,0,0,1,18.749,23.994Zm.75-5.646H6.622A3,3,0,0,1,3.85,16.607a2.692,2.692,0,0,1,.65-3.076l1.34-1.259L3.8,4.607c-.006-.019-.012-.04-.016-.059L3.331,2.824H1.5A1.46,1.46,0,0,1,0,1.412,1.46,1.46,0,0,1,1.5,0h3A1.482,1.482,0,0,1,5.955,1.072l.465,1.75H22.5a1.515,1.515,0,0,1,1.276.67,1.33,1.33,0,0,1,.066,1.373l-4.5,8.468A1.506,1.506,0,0,1,18,14.115H8.121l-1.5,1.411H19.5a1.414,1.414,0,1,1,0,2.823ZM10.359,7.927a.751.751,0,1,0,0,1.5H12.75v2.25a.8.8,0,0,0,1.594,0V9.427h2.39a.751.751,0,1,0,0-1.5h-2.39V5.678a.8.8,0,0,0-1.594,0V7.927Z"
                />
              </svg>
            </ng-container>
            <ng-container *ngIf="statusCartMessage">
              <svg
                class="w-6 h-6 text-white animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </ng-container>
          </span>
        </button>
      </div>
    </div>
    <!-- ==================== END add to cart and price ==================== -->
  </ng-container>
</ng-container>
