import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable, forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CoreStateModel, CoreState, GlobalEnums } from 'src/app/core/state/core.state';
import { SubSink } from 'subsink';
import { Item } from '../../../core/models/item';
import { Category } from '../../models/category';
import { ImageService } from '../../services/image.service';
import { Retailer } from '../../state/retailer.actions';
import { RetailerState, RetailerStateModel, VendingTypes } from '../../state/retailer.state';
import Keyboard from "simple-keyboard";
import { Router } from '@angular/router'
import test from 'node:test';
import { Product } from '../../models/product';

@Component({
  selector: 'app-categories-selection',
  templateUrl: './categories-selection.component.html',
  styleUrls: ['./categories-selection.component.scss']
})
export class CategoriesSelectionComponent implements OnInit {
  public GlobalEnums = GlobalEnums;

  private subs = new SubSink();
  showPickupOption: boolean = false;
  hideSelectionSearch: boolean = true;
  menuPanelOpened: boolean = false;
  searchPanelOpened: boolean = false;
  rightHand: boolean = true;
  allCategories: boolean = true;
  onScreenKeyboard: boolean = false;
  useShoppingCart: boolean = false;
  statusCartMessage: boolean = false;
  cartIsFull: boolean = false;
  activeCategory: string = "";
  theme: string;
  fullHdHalf: boolean = false;
  fullHd: boolean = false;
  valueStatusActive: boolean = false;
  cartItems: Array<any> = [];
  cartQuantityLimit: Number = 5;
  public value: string = '';
  public keyboard: Keyboard;

  @Select(RetailerState.categories) categories$: Observable<Category[]>;
  @Select(RetailerState.items) products$: Observable<Item[]>;
  products: Item[];

  private _rows: number; // private property _item

  get rows(): number {
    return this._rows;
  }

  @ViewChild("target")
  scrollTarget: ElementRef;

  @ViewChild("target2")
  scrollTarget2: ElementRef;

  @ViewChild("targetMenu")
  scrollTargetMenu: ElementRef;

  private setRows(val: number) {
    this._rows = val;
    let height = 94 / this._rows;
    this.productCardHeight = "0 0 " + height;
  }

  productCardHeight = "0 0 94%";

  private images = [];
  items$: any;


  constructor(private store: Store, private idle: Idle, private router: Router, private imageService: ImageService) {
    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {

      this.onScreenKeyboard =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.onScreenKeyboard;
      this.hideSelectionSearch = this.store.selectSnapshot<CoreStateModel>(CoreState).config.hideSearch;
      this.useShoppingCart = this.store.selectSnapshot<CoreStateModel>(CoreState).config.useShoppingCart;
      this.cartQuantityLimit = this.store.selectSnapshot<CoreStateModel>(CoreState).config.cartQuantityLimit;

    }

    this.cartItems = this.store.selectSnapshot<RetailerStateModel>(RetailerState).shoppingCart;

    this.theme = localStorage.getItem('theme') ?? 'default';
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    this.fullHd = JSON.parse(localStorage.getItem('fullhd')) ?? false;
    this.getHandDataFromStorage();

    this.setRows(3);

    this.items$ = [];

    this.store.dispatch(new Retailer.Load()).subscribe(() => {
      console.log('asa')
      let categories = this.store.selectSnapshot<RetailerStateModel>(RetailerState).categories;
      let items = this.store.selectSnapshot<RetailerStateModel>(RetailerState).items;

      console.log(categories);
      console.log(items);

      categories.forEach(category => {
        let firstProduct = this.findFirstProductForCategory(items, category.id);
        console.log('product', firstProduct)
        if (firstProduct != null && firstProduct.imagePaths && firstProduct.imagePaths.length > 0) {
          if (!this.images[category.id]) {
            console.log('loading image')
            this.imageService.getImage(firstProduct.imagePaths[0]).subscribe(image => {
              console.log('loaded image')
              console.log(image)
              this.images[category.id] = image;
            })
          }
        }

        this.items$.push({
          id: category.id,
          title: category.name
        })
      });
    });


    /*this.products$.subscribe({
      next: (loadedProducts) => {
        this.products = loadedProducts;

        this.categories$.subscribe({
          next: (categories) => {
            console.log(categories)
            categories.forEach(category => {
              let firstProduct = this.findFirstProductForCategory(this.products, category.id);
              console.log('product', firstProduct)
              if (firstProduct != null && firstProduct.imagePaths && firstProduct.imagePaths.length > 0) {
                if (!this.images[category.id]) {
                  console.log('loading image')
                  this.imageService.getImage(firstProduct.imagePaths[0]).subscribe(image => {
                    console.log('loaded image')
                    console.log(image)
                    this.images[category.id] = image;
                  })
                }
              }

              this.items$.push({
                id: category.id,
                title: category.name
              })
            });
          }
        });
      }
    });*/

    // console.log(this.images)

    /*this.items$ = [

      {
        id: 0,
        title: "Allergie & Asthma",
        image: "/assets/cat_img_temp/x1.png",
      },
      {
        id: 1,
        title: "Auge, Nase & Ohr",
        image: "/assets/cat_img_temp/x2.png",
      },
      {
        id: 2,
        title: "Beruhigung, Schlaf & Nerven",
        image: "/assets/cat_img_temp/x3.png",
      },
      {
        id: 3,
        title: "Diabetes",
        image: "/assets/cat_img_temp/x4.png",
      },
      {
        id: 4,
        title: "Erkältung & Grippe",
        image: "/assets/cat_img_temp/x5.png",
      },
      {
        id: 5,
        title: "Frauengesundheit",
        image: "/assets/cat_img_temp/x6.png",
      },
      {
        id: 6,
        title: "Haus- & Reiseapotheke",
        image: "/assets/cat_img_temp/x7.png",
      },
      {
        id: 7,
        title: "Herz, Kreislauf & Gefäße",
        image: "/assets/cat_img_temp/x8.png",
      },
      {
        id: 8,
        title: "Vitamine & Mineralstoffe",
        image: "/assets/cat_img_temp/x9.png",
      },
      {
        id: 0,
        title: "Allergie & Asthma",
        image: "/assets/cat_img_temp/x2.png",
      },
      {
        id: 1,
        title: "Auge, Nase & Ohr",
        image: "/assets/cat_img_temp/x3.png",
      },
      {
        id: 2,
        title: "Beruhigung, Schlaf & Nerven",
        image: "/assets/cat_img_temp/x4.png",
      },
      {
        id: 3,
        title: "Diabetes",
        image: "/assets/cat_img_temp/x5.png",
      },
      {
        id: 4,
        title: "Erkältung & Grippe",
        image: "/assets/cat_img_temp/x6.png",
      },
      {
        id: 5,
        title: "Frauengesundheit",
        image: "/assets/cat_img_temp/x7.png",
      },
      {
        id: 6,
        title: "Haus- & Reiseapotheke",
        image: "/assets/cat_img_temp/x1.png",
      },
      {
        id: 7,
        title: "Herz, Kreislauf & Gefäße",
        image: "/assets/cat_img_temp/x8.png",
      },
      {
        id: 8,
        title: "Vitamine & Mineralstoffe",
        image: "/assets/cat_img_temp/x4.png",
      },
    ]*/
  }

  findFirstProductForCategory(products: Item[], categoryId: number): Product {
    for (let j = 0; j < products.length; j++) {
      const product = products[j];
      if (product.product.categories != null) {
        for (let i = 0; i < product.product.categories.length; i++) {
          if (product.product.categories[i].id == categoryId) {
            return product.product;
          }
        }
      }
    }

    return null;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.keyboardInit();
  }
  menuPanelHandler(status: boolean) {
    this.menuPanelOpened = status;
  }

  hasNoMoreItemsLeftInStock(item: any) {
    let productCartItems = this.cartItems.filter(x => x.product.id == item.product.id);
    let quantityInStock = 9999; // TODO check stock
    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config.vendingType === VendingTypes.WWKS2) {
      quantityInStock = item.wwksArticle.quantity;
    }

    return productCartItems.length >= quantityInStock;
  }

  checkCartQuantityLimit() {
    this.cartIsFull = this.cartItems.length === this.cartQuantityLimit;

    if (this.cartIsFull) {
      this.router.navigate(['/retailer/cart']);
    }
  }

  scrollForward() {
    let pages = this.scrollTarget.nativeElement.scrollWidth / this.scrollTarget.nativeElement.offsetWidth;
    // let left = this.scrollTarget.nativeElement.offsetWidth * 50 / 100;

    let left = this.fullHdHalf ? 840 : 896;
    (this.scrollTarget.nativeElement as HTMLElement).scrollBy({ left, top: 0, behavior: "smooth" })
  }

  scrollBack() {
    // let left = this.scrollTarget.nativeElement.offsetWidth * -50 / 100;
    let left = this.fullHdHalf ? -840 : -896;
    (this.scrollTarget.nativeElement as HTMLElement).scrollBy({ left, top: 0, behavior: "smooth" })
  }

  scrollForward2() {
    let left = this.scrollTarget2.nativeElement.offsetWidth * 50 / 100;
    (this.scrollTarget2.nativeElement as HTMLElement).scrollBy({ left, top: 0, behavior: "smooth" })
  }
  scrollBack2() {
    let left = this.scrollTarget2.nativeElement.offsetWidth * -50 / 100;
    (this.scrollTarget2.nativeElement as HTMLElement).scrollBy({ left, top: 0, behavior: "smooth" })
  }

  scrollDownMenu() {
    let top = this.scrollTargetMenu.nativeElement.offsetHeight * 50 / 100;
    (this.scrollTargetMenu.nativeElement as HTMLElement).scrollBy({ top, left: 0, behavior: "smooth" })
  }
  scrollUpMenu() {
    let top = this.scrollTargetMenu.nativeElement.offsetHeight * -50 / 100;
    (this.scrollTargetMenu.nativeElement as HTMLElement).scrollBy({ top, left: 0, behavior: "smooth" })
  }
  rightHandHandler(status: boolean) {
    this.rightHand = status;
  }
  onCategorySelected(id: number, name: string) {
    this.store.dispatch(new Retailer.SelectCategory(id));

    this.activeCategory = name;
    this.allCategories = false;

    if (id < 0) {
      this.allCategories = !this.allCategories;
    }
  }
  toggleMenuPanelAndResetCategory() {
    this.toggleMenuPanel();
    this.onCategorySelected(-1, 'Alle');
  }

  toggleMenuPanel() {
    this.menuPanelOpened = !this.menuPanelOpened;
  }
  closeSearchPanel() {
    this.searchPanelOpened = !this.searchPanelOpened;

    this.store.dispatch(new Retailer.Search(''));
    this.valueStatusActive = false;
    this.value = '';
    this.keyboard.clearInput();
  }
  getHandDataFromStorage() {
    let hand = localStorage.getItem('hand');

    if (hand === 'right-hand') {
      this.rightHand = true;
    } else if (hand === 'left-hand') {
      this.rightHand = false;
    } else {
      this.rightHand = true;
    }
  }
  search($event) {
    this.store.dispatch(new Retailer.Search($event.target.value));
    this.value = $event.target.value;
  }

  @ViewChild('searchInput')
  public searchInput: ElementRef;

  searchPanelHandler(status: boolean) {
    this.searchPanelOpened = status;

    if (status) {
      // setTimeout(() => this.searchInput.nativeElement.focus(), 1000);
      this.searchInput.nativeElement.focus()
    }

  }

  onChange(input: string) {
    this.store.dispatch(new Retailer.Search(input));
    this.value = input;
    if (input.length >= 1) {
      this.valueStatusActive = true;
    }
    if (input.length >= 1) {
    } else if (input.length <= 1) {

      this.keyboard.clearInput();
    }
  };
  handleBackspaceForRealKeyboard() {
    this.onChange(this.value.slice(0, -1))
  };

  keyboardInit() {
    setTimeout(() => this.searchInput.nativeElement.focus(), 1000);
    this.keyboard = new Keyboard(".simple-keyboard-search", {
      onChange: input => this.onChange(input),
      onKeyPress: button => {
        if (button === "{bksp}") this.handleBackspaceForRealKeyboard()
      }
    });




    this.keyboard.setOptions({
      physicalKeyboardHighlightPress: true,
      physicalKeyboardHighlight: true,
      layout: {
        'default': [
          '1 2 3 4 5 6 7 8 9 0 ß',
          'Q W E R T Z U I O P Ü',
          'A S D F G H J K L Ö Ä',
          'Y X C V B N M {space} {bksp}',
        ],
        'shift': [
          '1 2 3 4 5 6 7 8 9 0 ß',
          'Q W E R T Z U I O P Ü',
          'A S D F G H J K L Ö Ä',
          'Y X C V B N M {space} {bksp}',
        ],
      },
      display: {
        '{bksp}': '    ',
        '{space}': 'SPACE',
        'ß': '   '
      },
      theme: "default-theme-keyboard",
      buttonTheme: [
        {
          class: "backspace",
          buttons: "{bksp}"
        },
        {
          class: "ss",
          buttons: "ß"
        },
        {
          class: "space-new",
          buttons: "{space}"
        }
      ]
    });
  }

  onBlurKeyboard(event) {
    // this.searchInput.nativeElement.focus()
  }

  onCategorySelect(item: any) {
    this.store.dispatch(new Navigate(['/retailer', 'selection']));
  }

}
