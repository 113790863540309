import { Component, OnInit, HostBinding } from '@angular/core';
import { Store } from '@ngxs/store';
import { CoreState, CoreStateModel } from '../../../core/state/core.state';
@Component({
  selector: 'app-nex-central-ad',
  templateUrl: './nex-central-ad.component.html',
  styleUrls: ['./nex-central-ad.component.scss']
})
export class NexCentralAdComponent implements OnInit {
  @HostBinding('class') class = 'w-full h-auto flex items-center justify-center overflow-hidden wow slideInDown animated';
  innerAdsImage: string = "";

  constructor(private store: Store) {
    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.innerAdsImage =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.innerAdsImage;
    }
  }

  ngOnInit(): void {
  }

}
