import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';
import { environment } from 'src/environments/environment';
import { Category } from '../models/category';
import { BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private visibleCategories: any[] = [];

  constructor(private httpClient: HttpClient, private store: Store) {
  }

  private categoryIdSource = new BehaviorSubject<number>(null);
  currentCategoryId = this.categoryIdSource.asObservable();

  setCategoryId(id: number) {
    this.categoryIdSource.next(id);
  }

  setVisibleCategories(data: any[]) {
    this.visibleCategories = data;
  }

  getVisibleCategories() {
    return this.visibleCategories;
  }

  getCategoriesHierarchy(): Observable<Category[]> {
    let apiVersion = this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      return this.getCategoriesHierarchyV2();
    } else {
      return this.getCategoriesHierarchyV1();
    }
  }

  findCategoryParentById(data: any[], targetId: number): any | null {
    let parent: any | null = null;

    function recursiveSearch(nodes: any[]) {
      for (const node of nodes) {
        if (node.id === targetId) {
          return null;
        }

        if (node.children) {
          const childMatch = node.children.find((child: any) => child.id === targetId);
          if (childMatch) {
            parent = node;
            return;
          }

          recursiveSearch(node.children);
        }
      }
    }

    recursiveSearch(data);

    return parent;
  }

  private getCategoriesHierarchyV1(): Observable<Category[]> {
    return this.httpClient.post(environment.api + "/vendor/getCategoriesHierarchy", {}).pipe(
      map(res => res as Category[])
    );
  }

  // private getCategoriesHierarchyV2(): Observable<Category[]> {
  //   const url = environment.configApi + `/machine/product-categories/hierarchical`;

  //   return this.httpClient.get(url).pipe(
  //     map(res => res as Category[])
  //   );
  // }

  private getCategoriesHierarchyV2(): Observable<Category[]> {
    const exampleCategories: Category[] = [
      {
        id: 1,
        parentId: null,
        name: 'Cigarettes',
        description: 'All cigarette products',
        products: [], // This will be populated with relevant products
        showChildren: false,
      },
      {
        id: 2,
        parentId: 1,
        name: 'Premium Cigarettes',
        description: 'High-quality cigarette brands',
        products: [], // This will be populated with relevant products
        showChildren: false,
      }
    ];

    return of(exampleCategories);
  }




}
