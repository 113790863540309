<div
  class="w-screen h-screen bg-gray-light-80 backdrop-blur-sm fixed z-90 top-0 left-0 flex flex-col items-center justify-center"
  style="z-index: 99"
>
  <ng-container *ngIf="showLoaderNumbers">
    <div
      *ngIf="!error"
      id="countdown{{ '-sanusx' }}"
      class="flex w-64 h-64 drop-shadow-xl bg-sanusx-light mx-auto rounded-full flex-col items-center justify-center text-primaryx text-7xl font-extrabold text-center wow bounceOut animated"
      style="border-radius: 9999px !important"
      data-wow-delay="60s"
      data-wow-duration="1s"
    >
      <svg
        xmlns="http:www.w3.org/2000/svg"
        viewBox="-1 -1 34 34"
        *ngIf="countdown >= 0"
      >
        <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

        <circle
          cx="16"
          cy="16"
          r="15.9155"
          class="progress-bar__progress"
          style="
             stroke-dashoffset:{{ 100 - (countdown - 0.1) * 100 }};
             transition: stroke-dashoffset {{ countdownFull }}s ease-in-out;"
        />
      </svg>
      <span
        class="absolute block text-9xl font-black w-full h-auto text-gray wow pulse animated"
        data-wow-delay="0"
        data-wow-duration="1s"
        data-wow-iteration="60"
      >
        {{ countdown }}
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="!showLoaderNumbers">
    <div
      *ngIf="!error"
      id="loader{{ '-sanusx' }}"
      class="flex flex-grow flex-col items-center justify-center text-primaryx-50 relative z-20"
    >
      <svg xmlns="http:www.w3.org/2000/svg" viewBox="-5 -5 40 40">
        <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

        <circle cx="16" cy="16" r="15.9155" class="progress-bar__progress" />
      </svg>
      <h3 class="uppercase tracking-wider my-10 text-gray font-semibold">
        {{ title }}
      </h3>
    </div>
  </ng-container>
  <h3
    class="uppercase tracking-wider my-10 text-gray font-semibold"
    *ngIf="showLoaderNumbers"
  >
    {{ title }}
  </h3>
  <div
    *ngIf="error"
    class="w-full h-auto mt-10 mx-auto text-red text-4xl font-semibold text-center flex-1 self-center"
  >
    {{ "COMMON.ERROR" | translate }}
  </div>
</div>
