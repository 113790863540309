import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'btn-big-box',
  templateUrl: './btn-big-box.component.html',
  styleUrls: ['./btn-big-box.component.scss']
})
export class BtnBigBoxComponent implements OnInit {

  @Input() label: string;
  @Input() smBtn: boolean = false;
  @Output() btnClick = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  onClickButton(event) {
    this.btnClick.emit(event);
  }
}
