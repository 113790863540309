<div class="flex flex-row items-start justify-between w-full h-screen bg-primaryx-bg">
  <!-- first column -->
  <div class="
      bg-white
      h-screen
      shadow-md
      py-10
      px-10
      relative animated
      z-50
      transition-all delay-75 ease-in-out
      flex flex-col items-start justify-start


      {{
      items$ && items$.length > 0
        ? !menuPanelOpened
          ? 'w-main-normal'
          : 'w-main-short'
        : 'w-full'
    }}

      {{
      items$ && items$.length > 0
        ? rightHand
          ? 'rounded-r-4xl shadow-md order-1'
          : 'rounded-l-4xl shadow-md-inv order-2'
        : ''
    }}

    ">
    <!-- cat title -->
    <div class="flex flex-row items-start justify-start w-full h-auto" *ngIf="items$ && items$.length > 0">
      <h2 class="mb-8 font-semibold tracking-wider text-primaryx-dark" [ngClass]="fullHdHalf ? 'text-5xl' : 'text-3xl'">
        <!-- {{ "RETAILER_RETAILER_SELECTION.PRODUCTS" | translate }} -->
        Wählen Sie eine Kategorie
      </h2>
      <h2 class="mb-8 ml-auto text-3xl font-semibold tracking-wider text-gray-light">
        {{ activeCategory }}
      </h2>
    </div>
    <!-- END: cat title -->

    <!-- top buttons/ aktionene - neue im shop - custom -->

    <div class="flex flex-row items-center justify-between w-full h-auto pr-4" *ngIf="items$ && items$.length > 0">
      <a class="btn btn-top active:border-active {{
          menuPanelOpened && 'btn-top-short'
        }}">
        <span class="icon text-red">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 28 32">
            <path id="icon_fire" data-name="icon_fire"
              d="M21.79,3.106a2,2,0,0,0-2.9-.77A5.993,5.993,0,0,0,17.246,4.1a13.965,13.965,0,0,0-1.14,2.232,36.178,36.178,0,0,0-1.68,5.468,62.727,62.727,0,0,0-1.226,7.16,5.28,5.28,0,0,1-1.89-2.134,12.329,12.329,0,0,1-.8-5.308A2,2,0,0,0,7.1,10.1a14,14,0,1,0,19.8,0,28.831,28.831,0,0,1-2.7-2.934,24.626,24.626,0,0,1-2.414-4.06ZM21.24,28.24A6,6,0,0,1,11,24a10.835,10.835,0,0,0,5,1c0-2,1-8,2.5-9a11.557,11.557,0,0,0,2.742,3.758,6,6,0,0,1,0,8.484Z"
              transform="translate(-3 -2)" fill="currentColor" fill-rule="evenodd" />
          </svg>
        </span>
        <span class="label">
          <!-- {{ "RETAILER_RETAILER_SELECTION.AKTIONS" | translate }} -->
          Aktionen
        </span>
      </a>

      <a class="btn btn-top active:border-active {{
          menuPanelOpened && 'btn-top-short'
        }}">
        <span class="text-yellow-400 icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="33.411" height="32" viewBox="0 0 33.411 32">
            <path id="icon_star" data-name="icon_star"
              d="M17.134,3.788a2.247,2.247,0,0,1,4.274,0l2.4,7.4a2.247,2.247,0,0,0,2.135,1.551h7.78A2.249,2.249,0,0,1,35.048,16.8l-6.292,4.571a2.247,2.247,0,0,0-.818,2.512l2.4,7.4A2.248,2.248,0,0,1,26.881,33.8L20.59,29.226a2.247,2.247,0,0,0-2.64,0L11.657,33.8A2.247,2.247,0,0,1,8.2,31.284l2.4-7.4a2.247,2.247,0,0,0-.818-2.512L3.5,16.806a2.249,2.249,0,0,1,1.321-4.067h7.777a2.247,2.247,0,0,0,2.137-1.551l2.4-7.4Z"
              transform="translate(-2.566 -2.236)" fill="currentColor" />
          </svg>
        </span>
        <span class="label">
          {{ "RETAILER_RETAILER_SELECTION.NEW_PRODUCTS" | translate }}
        </span>
      </a>

      <a class="btn btn-top active:border-active">
        <span class="text-yellow-400 icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="27.315" viewBox="0 0 32 27.315">
            <path id="icon_heart" data-name="icon_heart"
              d="M4.343,6.344a8,8,0,0,1,11.313,0L18,8.686l2.344-2.342A8,8,0,1,1,31.658,17.658L18,31.315,4.343,17.657a8,8,0,0,1,0-11.313Z"
              transform="translate(-2.001 -4)" fill="currentColor" fill-rule="evenodd" />
          </svg>
        </span>
        <span class="label">
          {{ "RETAILER_RETAILER_SELECTION.PRESENTED" | translate }}
        </span>
      </a>
    </div>
    <!-- END: top buttons/ aktionene - neue im shop - custom -->

    <!-- shop items -->

    <div class="flex flex-col items-start justify-start flex-grow w-full h-full mt-8">
      <!-- EMPTY STATE -->
      <div *ngIf="!items$ || items$.length === 0"
        class="absolute top-0 left-0 z-20 flex flex-col items-center justify-center w-full h-full">
        <h4 class="px-12 title" [ngClass]="fullHdHalf && 'text-5xl'">
          {{ "COMMON.NO_PRODUCTS" | translate }}
        </h4>
      </div>
      <!-- END EMPTY STATE -->

      <!-- LOADER PLACE -->
      <div *ngIf="!items$" class="absolute top-0 left-0 z-50 flex flex-col justify-between w-full h-full bg-white">
        <div id="loader" class="relative z-20 flex flex-col items-center justify-center flex-grow text-primaryx-50">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 40 40">
            <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

            <circle cx="16" cy="16" r="15.9155" class="progress-bar__progress" />
          </svg>
          <h3 class="my-10 font-semibold tracking-wider uppercase text-gray">
            {{ "COMMON.LOAD_PRODUCTS" | translate }}
          </h3>
        </div>
      </div>
      <!-- END LOADER PLACE -->

      <router-outlet name="categories-outlet"></router-outlet>

      <div #target
        class="grid w-full h-full grid-flow-col grid-rows-2 overflow-x-scroll grid-col-3 gap-y-3 hideScrollbar"
        [ngClass]="
          fullHdHalf
            ? 'grid-rows-3 grid-flow-col gap-x-9 grid-col-3'
            : fullHd
            ? 'grid-rows-5 grid-flow-col gap-x-10 grid-col-3'
            : 'grid-rows-2 grid-flow-col gap-x-9 grid-col-3'
        " *ngIf="items$ && items$.length > 0">
        <!-- category item  -->
        <div *ngFor="let item of items$; trackBy: trackById">
          <div class="btn category_item" [ngClass]="
              fullHdHalf
                ? 'category_item--middle'
                : fullHd
                ? 'category_item--fullhd'
                : ''
            ">
            <!--  image -->
            <div class="pi-image" (click)="onCategorySelect(item)">
              <!-- <img
                loading="lazy"
                [src]="
                  (images[item.product.id] != null
                    ? images[item.product.id]
                    : '/assets/placeholder.png'
                  ) | safe
                "
                alt="{{ item.product.name }}"
                class="mx-auto my-2"
                [ngClass]="fullHdHalf ? 'max-h-56' : 'max-h-36'"
                style="max-width: 90%"
              /> -->
              <img [src]="(images[item.id] != null
                ? images[item.id]
                : '/assets/placeholder.png'
              ) | safe" alt="{{ item.title }}" class="mx-auto" [ngClass]="fullHdHalf ? 'max-h-56x' : 'max-h-36x'" />
            </div>
            <!--  title -->
            <div class="pi-title" (click)="onCategorySelect(item)">
              <!-- <span class="w-full h-0.5 bg-gray-ultra block mb-2"></span> -->

              <span class="font-semibold text-center text-primaryx-dark flex flex-col items-center justify-center"
                [ngClass]="
                  fullHdHalf
                    ? 'text-4xl min-h-48 max-h-48'
                    : 'text-2xl min-h-24 max-h-24'
                ">
                {{ item.title }}
              </span>
            </div>
            <!-- price -->
          </div>
        </div>
        <!-- END: category item -->
      </div>
      <div class="flex flex-row items-center self-end justify-between w-full h-auto min-h-18">
        <button class="mr-auto btn btn-mini-action btn-mini-action--primary border-primaryx active:border-active group"
          [ngClass]="fullHdHalf ? 'btn-mini-action--middle' : ''" (click)="scrollBack()" *ngIf="
            items$ &&
            items$.length > 0 &&
            (!menuPanelOpened ? items$.length > 6 : items$.length > 4)
          ">
          <span class="-rotate-90 icon bg-primaryx group-active:bg-active">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.086" height="27.993" viewBox="0 0 28.086 27.993">
              <path id="arrow_up" data-name="arrow_up"
                d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                transform="translate(0 27.993)" fill="currentColor" fill-rule="evenodd" />
            </svg>
          </span>
        </button>

        <button class="ml-auto btn btn-mini-action btn-mini-action--primary border-primaryx active:border-active group"
          [ngClass]="fullHdHalf ? 'btn-mini-action--middle' : ''" (click)="scrollForward()" *ngIf="
            items$ &&
            items$.length > 0 &&
            (!menuPanelOpened ? items$.length > 6 : items$.length > 4)
          ">
          <span class="rotate-90 icon bg-primaryx group-active:bg-active">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.086" height="27.993" viewBox="0 0 28.086 27.993">
              <path id="arrow_up" data-name="arrow_up"
                d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                transform="translate(0 27.993)" fill="currentColor" fill-rule="evenodd" />
            </svg>
          </span>
        </button>
      </div>
    </div>

    <!-- END: shop items -->
  </div>
  <!-- END: first column -->
  <ng-container *ngIf="items$ && items$.length > 0">
    <!-- side column -->
    <app-sidebar [productPage]="false" [hideCategories]="true" [hideBackBtn]="true" [useShoppingCart]="useShoppingCart"
      [statusCartMessage]="statusCartMessage" [cartQuantity]="cartItems.length" [menuPanelOpened]="menuPanelOpened"
      [fullHdHalf]="fullHdHalf" (menuPanelStatus)="menuPanelHandler($event)" [searchPanelOpened]="searchPanelOpened"
      (searchPanelStatus)="searchPanelHandler($event)" [rightHand]="rightHand"
      (rightHandStatus)="rightHandHandler($event)" class="{{ rightHand ? 'order-2 pr-3' : 'order-1 pl-3 ' }}"
      *ngIf="!menuPanelOpened"></app-sidebar>
    <!-- END: side column -->
  </ng-container>
  <!-- menu panel -->
  <ng-container *ngIf="menuPanelOpened">
    <div class="w-side-menu-expand h-screen flex flex-row relative z-20  {{
        rightHand ? 'order-2' : 'order-1'
      }}">
      <div class="menu_bar  h-full flex-1 z-15 px-8 py-10 flex flex-col items-start justify-between
    {{
          rightHand
            ? 'rounded-r-4xl shadow-md-soft order-1 menu_bar--r'
            : 'rounded-l-4xl shadow-md-soft-inv order-2  menu_bar--l'
        }}">
        <h2 class="mb-8 font-semibold tracking-wider uppercase text-primaryx-dark"
          [ngClass]="fullHdHalf ? 'text-5xl' : 'text-3xl'">
          {{ "COMMON.CATEGORIES" | translate }}
        </h2>

        <!-- menu items -->
        <div #targetMenu class="flex flex-col justify-start w-full h-full category-vertical-scroll">
          <button class="btn btn-category border-gray-light btn-category--parent text-gray"
            (click)="onCategorySelected(-1, 'Alle')" [class.border-active]="allCategories"
            [ngClass]="fullHdHalf && 'btn-category--middle'">
            <span class="label" [class.text-active]="allCategories">
              {{ "COMMON.ALL" | translate }}
            </span>
            <span class="icon text-active" *ngIf="allCategories">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <path id="Path_13578" data-name="Path 13578"
                  d="M18,34A16,16,0,1,0,2,18,16,16,0,0,0,18,34Zm7.414-18.586a2,2,0,1,0-2.828-2.828L16,19.172l-2.586-2.586a2,2,0,1,0-2.828,2.828l4,4a2,2,0,0,0,2.828,0l8-8Z"
                  transform="translate(-2 -2)" fill="currentColor" fill-rule="evenodd" />
              </svg>
            </span>
          </button>

          <!-- <button
              class=" btn btn-category border-gray-light text-gray active:border-active active:text-active"
              *ngFor="let category of categories$"
              (click)="onCategorySelected(category.id, category.name)"
              [class.border-active]="
                (selectedCategory$)?.id == category.id
              "
            >
              <span
                class="label"
                [class.text-active]="
                  (selectedCategory$)?.id == category.id
                "
                >{{ category.name }}</span
              >
              <span
                class="icon text-active"
                *ngIf="(selectedCategory$)?.id == category.id"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <path
                    id="Path_13578"
                    data-name="Path 13578"
                    d="M18,34A16,16,0,1,0,2,18,16,16,0,0,0,18,34Zm7.414-18.586a2,2,0,1,0-2.828-2.828L16,19.172l-2.586-2.586a2,2,0,1,0-2.828,2.828l4,4a2,2,0,0,0,2.828,0l8-8Z"
                    transform="translate(-2 -2)"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
              </span>
            </button> -->

          <ul>
            <li *ngFor="let category of categories$" class="pb-3">
              <button
                class="btn btn-category btn-category--parent border-gray-light text-gray active:border-active active:text-active"
                (click)="onCategorySelected(category.id, category.name)"
                [class.border-active]="selectedCategory$?.id == category.id"
                [ngClass]="fullHdHalf && 'btn-category--middle'">
                <span class="label" [class.text-active]="selectedCategory$?.id == category.id">{{ category.name
                  }}</span>
                <span class="icon text-active" *ngIf="selectedCategory$?.id == category.id">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <path id="Path_13578" data-name="Path 13578"
                      d="M18,34A16,16,0,1,0,2,18,16,16,0,0,0,18,34Zm7.414-18.586a2,2,0,1,0-2.828-2.828L16,19.172l-2.586-2.586a2,2,0,1,0-2.828,2.828l4,4a2,2,0,0,0,2.828,0l8-8Z"
                      transform="translate(-2 -2)" fill="currentColor" fill-rule="evenodd" />
                  </svg>
                </span>
              </button>

              <ul *ngIf="category.children">
                <li *ngFor="let subcategory of category.children">
                  <button class="btn btn-category border-gray-light text-gray active:border-active active:text-active"
                    (click)="
                      onCategorySelected(subcategory.id, subcategory.name)
                    " [class.border-active]="
                      selectedCategory$?.id == subcategory.id
                    " [ngClass]="fullHdHalf && 'btn-category--middle'">
                    <span class="label" [class.text-active]="
                        selectedCategory$?.id == subcategory.id
                      ">{{ subcategory.name }}</span>
                    <span class="icon text-active" *ngIf="selectedCategory$?.id == subcategory.id">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <path id="Path_13578" data-name="Path 13578"
                          d="M18,34A16,16,0,1,0,2,18,16,16,0,0,0,18,34Zm7.414-18.586a2,2,0,1,0-2.828-2.828L16,19.172l-2.586-2.586a2,2,0,1,0-2.828,2.828l4,4a2,2,0,0,0,2.828,0l8-8Z"
                          transform="translate(-2 -2)" fill="currentColor" fill-rule="evenodd" />
                      </svg>
                    </span>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- END: menu items -->
      </div>
      <div class="w-22 h-full flex flex-col z-10 py-5 justify-start items-start {{
          rightHand ? 'order-2 pl-2' : 'order-1 pr-2'
        }}">
        <div class="flex-col items-start self-start justify-start flex-1 w-full h-auto">
          <button *ngIf="!items$ || items$.length === 0; else normalToggleMenu"
            class="self-start mx-auto btn btn-mini-action border-gray-light active:border-active group"
            (click)="toggleMenuPanelAndResetCategory()">
            <span class="icon bg-gray group-active:bg-active">
              <svg xmlns="http://www.w3.org/2000/svg" width="49.498" height="49.498" viewBox="0 0 49.498 49.498">
                <path id="close_icon" data-name="close_icon"
                  d="M-3922.81,2626.037v-12h-12a3,3,0,0,1-3-3,3,3,0,0,1,3-3h12v-11a3,3,0,0,1,3-3,3,3,0,0,1,3,3v11h11a3,3,0,0,1,3,3,3,3,0,0,1-3,3h-11v12a3,3,0,0,1-3,3A3,3,0,0,1-3922.81,2626.037Z"
                  transform="translate(950.191 -4593.964) rotate(-45)" fill="currentColor" />
              </svg>
            </span>
          </button>
          <ng-template #normalToggleMenu>
            <button class="self-start mx-auto btn btn-mini-action border-gray-light active:border-active group"
              (click)="toggleMenuPanel()">
              <span class="icon bg-gray group-active:bg-active">
                <svg xmlns="http://www.w3.org/2000/svg" width="49.498" height="49.498" viewBox="0 0 49.498 49.498">
                  <path id="close_icon" data-name="close_icon"
                    d="M-3922.81,2626.037v-12h-12a3,3,0,0,1-3-3,3,3,0,0,1,3-3h12v-11a3,3,0,0,1,3-3,3,3,0,0,1,3,3v11h11a3,3,0,0,1,3,3,3,3,0,0,1-3,3h-11v12a3,3,0,0,1-3,3A3,3,0,0,1-3922.81,2626.037Z"
                    transform="translate(950.191 -4593.964) rotate(-45)" fill="currentColor" />
                </svg>
              </span>
            </button>
          </ng-template>
        </div>

        <div class="flex-col items-center self-center justify-center flex-grow w-full h-auto">
          <!-- vertical limit 15 menu items-->
          <button class="self-center mx-auto mb-6 btn btn-mini-action border-gray-light active:border-active group"
            (click)="scrollUpMenu()" *ngIf="categories$ && categories$.length > 8">
            <span class="icon bg-gray group-active:bg-active">
              <svg xmlns="http://www.w3.org/2000/svg" width="28.086" height="27.993" viewBox="0 0 28.086 27.993">
                <path id="arrow_up" data-name="arrow_up"
                  d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                  transform="translate(0 27.993)" fill="currentColor" fill-rule="evenodd" />
              </svg>
            </span>
          </button>
          <button class="self-center mx-auto btn btn-mini-action border-gray-light active:border-active group"
            (click)="scrollDownMenu()" *ngIf="categories$ && categories$.length > 8">
            <span class="icon bg-gray group-active:bg-active">
              <svg xmlns="http://www.w3.org/2000/svg" width="28.086" height="27.993" viewBox="0 0 28.086 27.993"
                class="rotate-180">
                <path id="arrow_up" data-name="arrow_up"
                  d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                  transform="translate(0 27.993)" fill="currentColor" fill-rule="evenodd" />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- END: menu panel -->
</div>