import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-ski-bg',
  templateUrl: './ski-bg.component.html',
  styleUrls: ['./ski-bg.component.scss']
})
export class SkiBgComponent implements OnInit {
  @HostBinding('class') class = 'w-full h-full relative z-5 bg-white';

  @Input() isLight: boolean = false;
  @Input() useAnimation: boolean = true;
  @Input() showImg: boolean = true;
  @Input() clockCenter: boolean = false;
  @Input() clockDark: boolean = false;
  @Input() bigSelection: boolean = false;
  @Input() hasCentral: boolean = false;
  @Input() hasStatus: boolean = false;
  @Input() showClock: boolean = true;
  @Input() clientDesign: boolean = true;
  @Input() imgUrl: string = "/assets/mb-mountains.jpeg";

  constructor() { }

  ngOnInit(): void {
  }

}
