<ng-container *ngIf="theme === 'pickup_landscape'">
  <div
    class="w-full h-screen flex flex-col items-start justify-between bg-primaryx text-white"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full"
    ></app-header>
    <div
      class="w-full flex flex-col flex-1 items-center justify-center text-center"
    >
      <h1 class="text-7xl font-semibold mb-0">
        {{ "LOCKER_RENT_SELECTION.HELLO" | translate }} <br />
        {{ "LOCKER_RENT_SELECTION.WELCOME" | translate }}
      </h1>

      <h2 class="text-6xl font-semibold mb-8">
        {{ "LOCKER_RENT_SELECTION.BY" | translate }}
        <br />
        {{ "LOCKER_RENT_SELECTION.TO_MACHINE." + tenant | translate }}
      </h2>

      <h2 class="text-5xl font-bold mb-4 mt-12">
        {{ "LOCKER_RENT_SELECTION.QUESTION" | translate }}
      </h2>
    </div>
    <div
      class="w-full h-auto flex flex-row space-x-16 items-center justify-center py-14 self-end"
    >
      <app-btn-sanusx
        (btnClick)="keyCollect()"
        [label]="'COMMON.BTN_COLLECT_KEYS' | translate"
        [squared]="true"
        [redBorder]="true"
        buttonType="secondary"
        class="wow slideInLeft animated"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>

      <app-btn-sanusx
        (btnClick)="handBack()"
        [label]="'COMMON.BTN_RETURN_KEY' | translate"
        [squared]="true"
        [redBorder]="true"
        buttonType="secondary"
        class="wow slideInRight animated"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="theme === 'pickup_portrait'">
  <div
    class="w-full h-screen flex flex-col items-start justify-between bg-primaryx text-white"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full"
    ></app-header>
    <div
      class="w-full flex flex-col flex-1 items-center justify-center text-center"
    >
      <h1 class="text-8xl font-bold mb-0">
        {{ "LOCKER_RENT_SELECTION.HELLO" | translate }} <br />
        {{ "LOCKER_RENT_SELECTION.WELCOME" | translate }}
      </h1>
      <h2 class="text-7xl font-bold mb-8">
        {{ "LOCKER_RENT_SELECTION.BY" | translate }} <br />
        {{ "LOCKER_RENT_SELECTION.TO_MACHINE" | translate }}
      </h2>

      <h2 class="text-6xl font-bold mb-4 mt-12">
        {{ "LOCKER_RENT_SELECTION.QUESTION" | translate }}
      </h2>

      <div class="w-full h-auto flex flex-row items-center justify-center pb-6">
        <app-btn-sanusx
          (btnClick)="keyCollect()"
          [label]="'COMMON.BTN_COLLECT_KEYS' | translate"
          [squared]="true"
          [redBorder]="true"
          buttonType="secondary"
          class="wow slideInLeft animated"
          data-wow-delay="0s"
          data-wow-duration="1s"
        >
        </app-btn-sanusx>

        <app-btn-sanusx
          (btnClick)="handBack()"
          [label]="'COMMON.BTN_RETURN_KEY' | translate"
          [squared]="true"
          [redBorder]="true"
          buttonType="secondary"
          class="wow slideInRight animated"
          data-wow-delay="0s"
          data-wow-duration="1s"
        >
        </app-btn-sanusx>
      </div>
    </div>
  </div>
</ng-container>
