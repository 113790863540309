<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen bg-white"
  >
    <!-- <a class="block m-5" [routerLink]="'/retailer/release'">release - ok</a> -->
    <!-- <div>
      <a class="block m-5" [routerLink]="'/retailer/info'">product info - ok</a>
      <a class="block m-5" [routerLink]="'/core/initialization'">init - ok</a>
      <a class="block m-5" [routerLink]="'/retailer/payment'">payment - ok</a>
      <a class="block m-5" [routerLink]="'/retailer/release'">release - ok</a>
      <a class="block m-5" [routerLink]="'/core/error'">error - ok</a>
      <a class="block m-5" [routerLink]="'/retailer/retrieve'">retrieve - ok</a>
      <a class="block m-5" [routerLink]="'/retailer/finish'">finish - ok</a>
      <a class="block m-5" [routerLink]="'/retailer/pickup-detail'"
        >pickup-detail - ok</a
      >
    </div> -->

    <app-header
      [showSearch]="!hideSelectionSearch"
      [showBackButton]="true"
      class="w-full"
    ></app-header>

    <!-- product and menu part -->
    <div class="flex flex-col items-center justify-start flex-grow w-full">
      <!-- menu part -->
      <div
        class="flex flex-row items-start justify-between w-full px-4 h-25 max-h-25"
      >
        <!-- menu -->
        <div class="relative flex flex-col items-start justify-start w-5/12">
          <a
            class="btn-sanusx btn-menu btn-menu--category bg-primaryx active:bg-active"
            (click)="menuPanelHandler(!menuPanelOpened)"
          >
            <span class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.067"
                height="22.534"
                viewBox="0 0 13.067 22.534"
                class="transition-all duration-75 ease-in-out"
                [class.rotate-90]="menuPanelOpened"
              >
                <g
                  id="Group_7441"
                  data-name="Group 7441"
                  transform="translate(0 12.463)"
                >
                  <path
                    id="Path_13617"
                    data-name="Path 13617"
                    d="M11.267-2.937,11.326-3l1.74,1.74-.059.059.06.06L11.327.6l-.06-.06L1.74,10.07,0,8.33,9.527-1.2,0-10.723l1.74-1.74Z"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
            </span>
            <span class="text-white label">
              {{ "COMMON.PRODUCT_CATEGORIES" | translate }}
            </span>
          </a>

          <div
            class="absolute left-0 w-full h-auto bg-white border-2 top-14 border-primaryx z-90"
            *ngIf="menuPanelOpened"
          >
            <!-- menu items -->
            <div
              #targetMenu
              class="flex flex-col justify-start w-full h-full pr-2"
            >
              <ul
                class="category-vertical-scroll-sanusx"
                style="max-height: 85vh"
              >
                <li>
                  <button
                    class="font-bold uppercase btn-sanusx btn-category text-primaryx active:text-active"
                    (click)="onCategorySelected(-1)"
                    [class.bg-primaryx-30]="
                      (selectedCategory$ | async) === null
                    "
                  >
                    <span
                      class="label"
                      [class.text-primaryx]="
                        (selectedCategory$ | async) === null
                      "
                    >
                      {{ "COMMON.ALL" | translate }}
                    </span>
                  </button>
                </li>
                <li
                  *ngFor="let category of categories$ | async"
                  class="border-primaryx"
                  style="
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    border-top-width: 1px;
                    border-top-style: solid;
                  "
                >
                  <button
                    class="font-bold uppercase btn-sanusx btn-category text-primaryx active:text-active"
                    (click)="onCategorySelected(category.id)"
                    [class.bg-primaryx-30]="
                      (selectedCategory$ | async)?.id === category.id
                    "
                  >
                    <span
                      class="label"
                      [class.text-primaryx]="
                        (selectedCategory$ | async)?.id === category.id
                      "
                      >{{ category.name }}</span
                    >
                  </button>

                  <ul *ngIf="category.children">
                    <li
                      *ngFor="let subcategory of category.children"
                      (click)="onCategorySelected(subcategory.id)"
                    >
                      <button
                        class="btn-sanusx btn-category text-primaryx font-boldx active:text-active"
                        [class.bg-primaryx-30]="
                          (selectedCategory$ | async)?.id === subcategory.id
                        "
                      >
                        <span
                          class="label"
                          [class.text-primaryx]="
                            (selectedCategory$ | async)?.id === subcategory.id
                          "
                        >
                          {{ subcategory.name }}
                        </span>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- END: menu items -->
          </div>

          <div
            class="flex flex-row items-start justify-start w-full pl-8 text-xl font-bold tracking-tight uppercase text-primaryx"
            *ngIf="activeCategory !== ''"
          >
            <span
              class="block w-4 mr-4 bg-white h-7 border-l-3 border-b-3 border-primaryx"
            ></span>
            <span class="pt-3">{{ activeCategory }}</span>
          </div>
        </div>
        <!-- END menu -->

        <!-- top buttons -->
        <div class="flex items-center justify-start flex-grow w-7/12">
          <!-- <a class="mr-2 bg-white btn-sanusx btn-menu group">
            <span class="label text-primaryx-dark group-active:text-active"
              >Aktionen</span
            >
          </a>

          <a class="mr-2 bg-white btn-sanusx btn-menu group">
            <span class="label text-primaryx-dark group-active:text-active"
              >Neue im shop</span
            >
          </a>

          <a class="bg-white btn-sanusx btn-menu group">
            <span class="label text-primaryx-dark group-active:text-active"
              >Erste hilfe</span
            >
          </a> -->
        </div>
        <!-- END top buttons -->
      </div>
      <!-- END menu part -->

      <!-- product list -->
      <div
        class="relative flex flex-col items-start justify-start flex-grow w-full h-full px-16 py-8"
      >
        <!-- EMPTY STATE -->
        <div
          *ngIf="!(items$ | async) || (items$ | async).length === 0"
          class="absolute top-0 left-0 z-20 flex flex-col items-center justify-center w-full h-full"
        >
          <h4 class="px-12 title" [ngClass]="fullHdHalf && 'text-5xl'">
            {{ "COMMON.NO_PRODUCTS" | translate }}
          </h4>
        </div>
        <!-- END EMPTY STATE -->

        <!-- LOADER PLACE -->
        <div
          *ngIf="!(items$ | async)"
          class="absolute top-0 left-0 z-50 flex flex-col justify-between w-full h-full bg-white"
        >
          <div
            id="loader-sanusx"
            class="relative z-20 flex flex-col items-center justify-center flex-grow text-primaryx-50"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 40 40">
              <circle
                cx="16"
                cy="16"
                r="15.9155"
                class="progress-bar__background"
              />

              <circle
                cx="16"
                cy="16"
                r="15.9155"
                class="progress-bar__progress"
              />
            </svg>
            <h3 class="my-10 font-semibold tracking-wider uppercase text-gray">
              {{ "COMMON.LOAD_PRODUCTS" | translate }}
            </h3>
          </div>
        </div>
        <!-- END LOADER PLACE -->
        <div
          #target
          class="grid w-full h-full grid-flow-col grid-rows-2 ml-2 overflow-x-scroll place-items-center gap-y-3 gap-x-7 hideScrollbar"
          *ngIf="(items$ | async) && (items$ | async).length > 0"
        >
          <!-- product item -->
          <div *ngFor="let item of items$ | async; trackBy: trackById">
            <div class="btn-sanusx product_item_sanusx border-primaryx">
              <!--  image -->
              <div
                class="pi-image"
                (click)="onProductSelected(item.product.id)"
              >
                <img
                  loading="lazy"
                  [src]="
                    (images[item.product.id] !== null
                      ? images[item.product.id]
                      : '/assets/placeholder.png'
                    ) | safe
                  "
                  alt="{{ item.product.name }}"
                  class="mx-auto my-1 max-h-52"
                  style="max-width: 90%"
                />
              </div>
              <!--  title -->
              <div
                class="pi-title"
                (click)="onProductSelected(item.product.id)"
              >
                <span class="hidden text-2xl font-semibold text-left text-gray"
                  >brand
                </span>
                <span
                  class="my-2 text-2xl font-semibold text-left text-primaryx-dark min-h-24x min-h-32 max-h-32"
                >
                  {{ item.product.name }}
                </span>
                <div
                  class="flex flex-row items-center justify-between hidden w-full text-2xl font-semibold text-gray"
                >
                  <span class="mr-auto text-left">290 g</span>
                </div>
              </div>
              <!-- price -->
              <div
                *ngIf="showProductPrices"
                class="flex flex-col items-center justify-center w-full h-auto text-right"
              >
                <div
                  class="flex flex-col items-center w-full h-auto px-6 py-4 jusftify-end bg-gray-ultra"
                >
                  <span
                    class="ml-auto text-3xl font-bold text-right text-primaryx-dark"
                  >
                    {{ item.product.grossPrice.toFixed(2) }} €
                  </span>
                  <div
                    class="flex flex-row items-center justify-between hidden w-full text-2xl font-semibold text-gray"
                  >
                    <span class="ml-auto text-base text-right"
                      >0,97 € je 100 g</span
                    >
                  </div>
                </div>
              </div>
              <!-- add to cart -->
              <div
                class="flex flex-col items-center justify-center hidden w-full h-auto text-right"
              >
                <button
                  class="btn-sanusx btn-card-cart bg-primaryx-30 active:bg-active"
                >
                  <span class="icon text-primaryx-dark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="31.256"
                      viewBox="0 0 32 31.256"
                    >
                      <g
                        id="Group_7444"
                        data-name="Group 7444"
                        transform="translate(0 19.412)"
                      >
                        <path
                          id="Path_13620"
                          data-name="Path 13620"
                          d="M23.6,5.416A3.214,3.214,0,1,0,26.814,8.63,3.215,3.215,0,0,0,23.6,5.416Zm-8.293,0A3.214,3.214,0,1,0,18.521,8.63,3.215,3.215,0,0,0,15.307,5.416ZM23.6,7.831a.8.8,0,1,1-.8.8A.8.8,0,0,1,23.6,7.831Zm-8.293,0a.8.8,0,1,1-.8.8A.8.8,0,0,1,15.307,7.831ZM6.283-11.3v.008l0,0,.024.1a.6.6,0,0,0,.06.156l-.029-.023L9.954,4.375a.6.6,0,0,0,.588.466H28.075a.6.6,0,0,0,.585-.454L31.981-8.587a.6.6,0,0,0-.082-.483.6.6,0,0,0-.413-.263L8.412-12.8,6.974-18.946a.6.6,0,0,0-.588-.466H.6a.6.6,0,0,0-.6.6V-17.6a.6.6,0,0,0,.6.6H4.95ZM29.144-7.244l-2.475,9.67H11.978l-2.973-12.7Z"
                          fill="currentColor"
                          fill-rule="evenodd"
                        />
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <!-- END: product item -->
        </div>
        <div
          class="absolute left-0 flex flex-row items-center self-end justify-between w-full h-auto min-h-18"
          style="top: 50%"
        >
          <button
            class="self-start mr-auto rounded-r-full btn-sanusx btn-mini-action bg-primaryx active:bg-active group"
            (click)="scrollBack()"
            *ngIf="
              (items$ | async) &&
              (items$ | async).length > 0 &&
              (!menuPanelOpened
                ? (items$ | async).length > 6
                : (items$ | async).length > 4)
            "
          >
            <span class="mr-2 -rotate-180 icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31.872"
                height="57.951"
                viewBox="0 0 31.872 57.951"
              >
                <g
                  id="Group_7442"
                  data-name="Group 7442"
                  transform="translate(0 33.948)"
                >
                  <path
                    id="Path_13618"
                    data-name="Path 13618"
                    d="M2.9,24,31.872-4.971,2.9-33.948,0-31.051,26.078-4.972,0,21.106Z"
                    transform="translate(0 0)"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
            </span>
          </button>

          <button
            class="self-end ml-auto rounded-l-full btn-sanusx btn-mini-action bg-primaryx active:bg-active group"
            (click)="scrollForward()"
            *ngIf="
              (items$ | async) &&
              (items$ | async).length > 0 &&
              (!menuPanelOpened
                ? (items$ | async).length > 6
                : (items$ | async).length > 4)
            "
          >
            <span class="ml-2 icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31.872"
                height="57.951"
                viewBox="0 0 31.872 57.951"
              >
                <g
                  id="Group_7442"
                  data-name="Group 7442"
                  transform="translate(0 33.948)"
                >
                  <path
                    id="Path_13618"
                    data-name="Path 13618"
                    d="M2.9,24,31.872-4.971,2.9-33.948,0-31.051,26.078-4.972,0,21.106Z"
                    transform="translate(0 0)"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
            </span>
          </button>
        </div>
      </div>
      <!-- END product list -->
    </div>
    <!-- END product and menu part -->
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'default'">
  <div
    class="flex flex-row items-start justify-between w-full h-screen bg-primaryx-bg"
  >
    <!-- <div>
      <a class="block m-5" [routerLink]="'/retailer/info'">product info - ok</a>
      <a class="block m-5" [routerLink]="'/core/initialization'">init - ok</a>
      <a class="block m-5" [routerLink]="'/retailer/payment'">payment - ok</a>
      <a class="block m-5" [routerLink]="'/retailer/release'">release - ok</a>
      <a class="block m-5" [routerLink]="'/core/error'">error - ok</a>
      <a class="block m-5" [routerLink]="'/retailer/retrieve'">retrieve - ok</a>
      <a class="block m-5" [routerLink]="'/retailer/retrieve-wwks'">retrieve wwks- ok</a>
      <a class="block m-5" [routerLink]="'/retailer/finish'">finish - ok</a>
      <a class="block m-5" [routerLink]="'/retailer/pickup-detail'"
        >pickup-detail - ok</a
      >
    </div> -->
    <!-- first column -->
    <div
      class="
      bg-white
      h-screen
      shadow-md
      py-10
      px-10
      relative animated
      z-50
      transition-all delay-75 ease-in-out
      flex flex-col items-start justify-start
      overflow-hidden

      {{
        (items$ | async) && (items$ | async).length > 0
          ? !menuPanelOpened
            ? 'w-main-normal'
            : 'w-main-short'
          : 'w-full'
      }}

      {{
        (items$ | async) && (items$ | async).length > 0
          ? rightHand
            ? 'rounded-r-4xl shadow-md order-1'
            : 'rounded-l-4xl shadow-md-inv order-2'
          : ''
      }}

    "
    >
      <!-- <div>
        <a class="block m-5" [routerLink]="'/retailer/info'"
          >product info - ok</a
        >
        <a class="block m-5" [routerLink]="'/core/initialization'">init - ok</a>
        <a class="block m-5" [routerLink]="'/retailer/payment'">payment - ok</a>
        <a class="block m-5" [routerLink]="'/retailer/release'">release - ok</a>
        <a class="block m-5" [routerLink]="'/core/error'">error - ok</a>
        <a class="block m-5" [routerLink]="'/retailer/retrieve'"
          >retrieve - ok</a
        >
        <a class="block m-5" [routerLink]="'/retailer/finish'">finish - ok</a>
        <a class="block m-5" [routerLink]="'/retailer/pickup-detail'"
          >pickup-detail - ok</a
        >
      </div> -->
      <!-- <app-header
      [hide-search]="hideSelectionSearch"
      class="flex flex-col w-full mb-2"
    ></app-header> -->
      <!-- cat title -->
      <div
        class="flex flex-row items-start justify-start w-full h-auto"
        *ngIf="(items$ | async) && (items$ | async).length > 0"
      >
        <h2
          class="mb-8 font-semibold tracking-wider text-primaryx-dark"
          [ngClass]="fullHdHalf ? 'text-5xl' : 'text-3xl'"
        >
          {{ "RETAILER_RETAILER_SELECTION.SELECT_PRODUCT" | translate }}
        </h2>
        <h2
          class="mb-8 ml-auto text-3xl font-semibold tracking-wider text-gray"
        >
          <ng-container *ngIf="menuPanelOpened">
            {{ activeCategory | ellipsis : 24 }}
          </ng-container>
          <ng-container *ngIf="!menuPanelOpened">
            {{ activeCategory | ellipsis : 36 }}
          </ng-container>
        </h2>
      </div>
      <!-- END: cat title -->

      <!-- top buttons/ aktionene - neue im shop - custom -->

      <div
        class="flex-row items-center justify-between hidden w-full h-auto"
        *ngIf="(items$ | async) && (items$ | async).length > 0"
      >
        <a
          class="btn btn-top active:border-active {{
            menuPanelOpened && 'btn-top-short'
          }}"
        >
          <span class="icon text-red">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="32"
              viewBox="0 0 28 32"
            >
              <path
                id="icon_fire"
                data-name="icon_fire"
                d="M21.79,3.106a2,2,0,0,0-2.9-.77A5.993,5.993,0,0,0,17.246,4.1a13.965,13.965,0,0,0-1.14,2.232,36.178,36.178,0,0,0-1.68,5.468,62.727,62.727,0,0,0-1.226,7.16,5.28,5.28,0,0,1-1.89-2.134,12.329,12.329,0,0,1-.8-5.308A2,2,0,0,0,7.1,10.1a14,14,0,1,0,19.8,0,28.831,28.831,0,0,1-2.7-2.934,24.626,24.626,0,0,1-2.414-4.06ZM21.24,28.24A6,6,0,0,1,11,24a10.835,10.835,0,0,0,5,1c0-2,1-8,2.5-9a11.557,11.557,0,0,0,2.742,3.758,6,6,0,0,1,0,8.484Z"
                transform="translate(-3 -2)"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </span>
          <span class="label">
            {{ "RETAILER_RETAILER_SELECTION.AKTIONS" | translate }}
          </span>
        </a>

        <a
          class="btn btn-top active:border-active {{
            menuPanelOpened && 'btn-top-short'
          }}"
        >
          <span class="text-yellow-400 icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33.411"
              height="32"
              viewBox="0 0 33.411 32"
            >
              <path
                id="icon_star"
                data-name="icon_star"
                d="M17.134,3.788a2.247,2.247,0,0,1,4.274,0l2.4,7.4a2.247,2.247,0,0,0,2.135,1.551h7.78A2.249,2.249,0,0,1,35.048,16.8l-6.292,4.571a2.247,2.247,0,0,0-.818,2.512l2.4,7.4A2.248,2.248,0,0,1,26.881,33.8L20.59,29.226a2.247,2.247,0,0,0-2.64,0L11.657,33.8A2.247,2.247,0,0,1,8.2,31.284l2.4-7.4a2.247,2.247,0,0,0-.818-2.512L3.5,16.806a2.249,2.249,0,0,1,1.321-4.067h7.777a2.247,2.247,0,0,0,2.137-1.551l2.4-7.4Z"
                transform="translate(-2.566 -2.236)"
                fill="currentColor"
              />
            </svg>
          </span>
          <span class="label">
            {{ "RETAILER_RETAILER_SELECTION.NEW_PRODUCTS" | translate }}
          </span>
        </a>

        <a class="btn btn-top active:border-active">
          <span class="text-yellow-400 icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="27.315"
              viewBox="0 0 32 27.315"
            >
              <path
                id="icon_heart"
                data-name="icon_heart"
                d="M4.343,6.344a8,8,0,0,1,11.313,0L18,8.686l2.344-2.342A8,8,0,1,1,31.658,17.658L18,31.315,4.343,17.657a8,8,0,0,1,0-11.313Z"
                transform="translate(-2.001 -4)"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </span>
          <span class="label">
            {{ "RETAILER_RETAILER_SELECTION.PRESENTED" | translate }}
          </span>
        </a>
      </div>
      <!-- END: top buttons/ aktionene - neue im shop - custom -->

      <!-- shop items -->

      <div
        class="flex flex-col items-start justify-start flex-grow w-full h-full mt-8x"
      >
        <!-- EMPTY STATE -->
        <div
          *ngIf="!(items$ | async) || (items$ | async).length === 0"
          class="absolute top-0 left-0 z-20 flex flex-col items-center justify-center w-full h-full"
        >
          <h4 class="px-12 title" [ngClass]="fullHdHalf && 'text-5xl'">
            {{ "COMMON.NO_PRODUCTS" | translate }}
          </h4>
        </div>
        <!-- END EMPTY STATE -->

        <!-- LOADER PLACE -->
        <div
          *ngIf="!(items$ | async)"
          class="absolute top-0 left-0 z-50 flex flex-col justify-between w-full h-full bg-white"
        >
          <div
            id="loader"
            class="relative z-20 flex flex-col items-center justify-center flex-grow text-primaryx-50"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 40 40">
              <circle
                cx="16"
                cy="16"
                r="15.9155"
                class="progress-bar__background"
              />

              <circle
                cx="16"
                cy="16"
                r="15.9155"
                class="progress-bar__progress"
              />
            </svg>
            <h3 class="my-10 font-semibold tracking-wider uppercase text-gray">
              {{ "COMMON.LOAD_PRODUCTS" | translate }}
            </h3>
          </div>
        </div>
        <!-- END LOADER PLACE -->

        <!-- <div
          #target
          class="grid w-full h-full grid-flow-col grid-rows-2 overflow-x-scroll grid-col-3 gap-y-3 hideScrollbar"
          [ngClass]="
            fullHdHalf
              ? 'grid-rows-3 grid-flow-col gap-x-9 grid-col-3'
              : fullHd
              ? 'grid-rows-3 grid-flow-col gap-x-10 grid-col-3'
              : 'grid-rows-2 grid-flow-col gap-x-9 grid-col-3'
          "
          *ngIf="(items$ | async) && (items$ | async).length > 0"
        > -->
        <!-- <div
          #target
          class="grid w-full h-full grid-flow-col grid-rows-2 overflow-x-scroll grid-col-3 gap-y-3 hideScrollbar"
          [ngClass]="
            fullHdHalf
              ? 'grid-rows-3 grid-flow-col gap-x-9 grid-col-3'
              : fullHd
              ? 'grid-rows-3 grid-flow-col gap-x-10 grid-col-3'
              : 'grid-rows-2 grid-flow-col gap-x-9 grid-col-3'
          "
          *ngIf="(items$ | async) && (items$ | async).length > 0"
        > </div> -->
        <!-- product item -->

        <div
          class="relative flex w-full h-full -mt-4 mb-9"
          (swiperight)="prevSlide()"
          (swipeleft)="nextSlide()"
        >
          <div
            [@slide]="activeIndex"
            *ngFor="
              let pair of products | chunk : numOfItemsPerScreen;
              let i = index
            "
            class="w-full h-full grid-flow-col gap-x-5 gap-y-5 grid-col-3 place-content-start {{
              fullHdHalf
                ? 'grid-rows-3 grid-flow-col gap-x-9 grid-col-3'
                : fullHd
                ? 'grid-rows-3 gap-x-10 grid-col-3'
                : 'grid-rows-2 gap-x-5 grid-col-3'
            }}"
            [ngClass]="{ grid: i === activeIndex, hidden: i !== activeIndex }"
          >
            <!-- product card -->
            <ng-container *ngFor="let item of pair; let i = index">
              <app-product-card
                [fullHdHalf]="fullHdHalf"
                [fullHd]="fullHd"
                [useShoppingCart]="useShoppingCart"
                [statusCartMessage]="activeProductId === item.product.id"
                [cartIsFull]="cartIsFull"
                [id]="item.product.id"
                [image]="
                  (images[item.product.id] !== null
                    ? images[item.product.id]
                    : '/assets/placeholder.png'
                  ) | safe
                "
                [badgeImg]=""
                [brandName]="item.product.name"
                [name]="item.product.name"
                [new]="
                  item.product.tags !== null &&
                  item.product.tags.toUpperCase().includes('NEU')
                "
                [action]="
                  item.product.tags !== null &&
                  item.product.tags.toUpperCase().includes('AKTION')
                "
                [quantity]="item.product.packagingQuantity"
                [grossPrice]="item.product.grossPrice"
                [price]="item.product.price"
                [pricePerItem]="item.product.pricePerItem"
                [priceType]="item.product.priceType"
                [standardGrossPrice]="item.product.standardGrossPrice"
                [standardSalePrice]="item.product.standardSalePrice"
                [standardSaleVatAmount]="item.product.standardSaleVatAmount"
                [standardVatPercent]="item.product.standardVatPercent"
                [vatAmount]="item.product.vatAmount"
                [vatPercent]="item.product.vatPercent"
                (btnOpenDetails)="onProductSelected(item.product.id)"
                (btnAddToCard)="addProductToCart(item.product.id)"
                [hasEmptyStock]="
                  useShoppingCart && hasNoMoreItemsLeftInStock(item)
                "
              >
              </app-product-card>
            </ng-container>
            <!-- end product card -->
          </div>
        </div>
        <!-- END: product item -->

        <!-- prev/next slide buttons -->
        <div
          (swiperight)="prevSlide()"
          (swipeleft)="nextSlide()"
          class="flex flex-row items-center self-end justify-between w-full h-auto min-h-18"
        >
          <!-- carousel prev -->
          <button
            class="mr-auto btn btn-mini-action btn-mini-action--primary disabled:grayscale border-primaryx active:border-active group"
            [ngClass]="fullHdHalf ? 'btn-mini-action--middle' : ''"
            (pointerup)="prevSlide()"
            [touchClick]
            *ngIf="
              (items$ | async) &&
              (items$ | async).length > 0 &&
              (!menuPanelOpened
                ? (items$ | async).length > 6
                : (items$ | async).length > 4)
            "
            [disabled]="activeIndex === 0"
          >
            <span class="-rotate-90 icon bg-primaryx group-active:bg-active">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28.086"
                height="27.993"
                viewBox="0 0 28.086 27.993"
              >
                <path
                  id="arrow_up"
                  data-name="arrow_up"
                  d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                  transform="translate(0 27.993)"
                  fill="currentColor"
                  fill-rule="evenodd"
                />
              </svg>
            </span>
          </button>
          <!-- end carousel prev -->

          <!-- carousel indicator -->
          <ng-container *ngIf="!menuPanelOpened">
            <div
              class="flex flex-row items-center justify-center flex-1 px-12 space-x-4"
              *ngIf="
                (items$ | async) &&
                (items$ | async).length > 0 &&
                (!menuPanelOpened
                  ? (items$ | async).length > 6
                  : (items$ | async).length > 4)
              "
            >
              <!-- <span
              class="flex items-center justify-center w-16 h-8 text-xl font-bold text-center text-white transition-all rounded-full opacity-90 bg-primaryx"
              [ngClass]="
                products.length > numOfItemsPerScreen && activeIndex > 0
                  ? 'visible'
                  : 'invisible'
              "
            >
              <app-svg-stripe [icon]="'outer'" class="z-0"></app-svg-stripe>
            </span> -->

              <span
                *ngFor="
                  let item of products
                    | chunk : numOfItemsPerScreen
                    | slice : 0 : indicatorLimit;
                  let i = index
                "
                class="flex items-center justify-center h-8 text-xl font-bold text-center transition-all rounded-full bg-primaryx"
                [ngClass]="{
                  'w-16 text-white opacity-90': i === activeIndex,
                  'w-8 text-primaryx opacity-40': i !== activeIndex
                }"
                (pointerup)="setActiveIndex(i)"
                [touchClick]
              >
                {{ i + 1 }}
              </span>
            </div>
          </ng-container>
          <!-- end carousel indicator -->

          <!-- carousel next -->
          <button
            class="ml-auto btn btn-mini-action btn-mini-action--primary border-primaryx disabled:grayscale active:border-active group"
            [ngClass]="fullHdHalf ? 'btn-mini-action--middle' : ''"
            (pointerup)="nextSlide()"
            [touchClick]
            *ngIf="
              (items$ | async) &&
              (items$ | async).length > 0 &&
              (!menuPanelOpened
                ? (items$ | async).length > 6
                : (items$ | async).length > 4)
            "
            [disabled]="
              menuPanelOpened
                ? activeIndex + 1 === numOfChunks
                : activeIndex === numOfChunks
            "
          >
            <span class="rotate-90 icon bg-primaryx group-active:bg-active">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28.086"
                height="27.993"
                viewBox="0 0 28.086 27.993"
              >
                <path
                  id="arrow_up"
                  data-name="arrow_up"
                  d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                  transform="translate(0 27.993)"
                  fill="currentColor"
                  fill-rule="evenodd"
                />
              </svg>
            </span>
          </button>
          <!-- end carouse next -->
        </div>
        <!-- end prev/next slide buttons -->
        <div
          *ngIf="(ads$ | async).length > 0"
          fxFlex="0 0 30%"
          fxLayout="column"
          style="padding: 10px 20px"
        >
          <!-- <h2 style="color: #333;">Angebote</h2>
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-card *ngFor="let ad of (ads$ | async)" fxFlex="0 1 400px" fxLayout="row" fxLayoutAlign="center center" style="margin: 20px; padding: 0; height: 200px; overflow:hidden">
                <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%; height: 100%; background-image: url('/assets/compeed_product.png'); background-size: cover;">
                    <div fxFlex="0 0 50%" fxLayout="row" fxLayoutAlign="center center" style="background-color: #fff; height: 100%; padding: 8px; text-align: center; box-shadow: inset rgba(0,0,0,0.2) 2px 0px 2px 0px;">
                        <span style="font-size: 28px; color: #333;"><b>-10%</b><br><br>ABVERKAUF</span>
                    </div>
                </div>
            </mat-card>
        </div> -->
        </div>
      </div>

      <!-- END: shop items -->
    </div>
    <!-- END: first column -->
    <ng-container *ngIf="(items$ | async) && (items$ | async).length > 0">
      <!-- side column -->
      <app-sidebar
        [productPage]="false"
        [useShoppingCart]="useShoppingCart"
        [statusCartMessage]="statusCartMessage"
        [cartQuantity]="cartItems.length"
        [menuPanelOpened]="menuPanelOpened"
        [fullHdHalf]="fullHdHalf"
        [hideBackBtn]="true"
        (menuPanelStatus)="menuPanelHandler($event)"
        [searchPanelOpened]="searchPanelOpened"
        (searchPanelStatus)="searchPanelHandler($event)"
        (resetCategoryStatus)="onCategorySelected(-1)"
        [rightHand]="rightHand"
        (rightHandStatus)="rightHandHandler($event)"
        class="{{ rightHand ? 'order-2 pr-3' : 'order-1 pl-3' }}"
        *ngIf="!menuPanelOpened"
      ></app-sidebar>
      <!-- END: side column -->
    </ng-container>
    <!-- menu panel -->

    <ng-container *ngIf="(items$ | async) && (items$ | async).length > 0">
      <ng-container *ngIf="menuPanelOpened">
        <div
          class="w-side-menu-expand h-screen flex flex-row relative z-20  {{
            rightHand ? 'order-2' : 'order-1'
          }}"
          (swipeup)="scrollUpMenu()"
          (swipedown)="scrollDownMenu()"
        >
          <div
            class="menu_bar wow h-full flex-1 z-15 pl-4 pr-4 py-10 flex flex-col items-start justify-between
    {{
              rightHand
                ? 'rounded-r-4xl shadow-md-soft order-1 menu_bar--r bounceInLeft'
                : 'rounded-l-4xl shadow-md-soft-inv order-2  menu_bar--l bounceInRight'
            }}"
          >
            <h2
              class="mb-8 font-semibold tracking-wider uppercase text-primaryx-dark"
              [ngClass]="fullHdHalf ? 'text-5xl' : 'text-3xl'"
            >
              {{ "COMMON.CATEGORIES" | translate }}
            </h2>
            <button
              class="text-gray  btn  btn-category border-green btn-category--parent {{
                (selectedCategory$ | async) == null
                  ? 'border-green'
                  : 'border-green bg-primaryx text-white'
              }}"
              [class.text-primaryx]="(selectedCategory$ | async) == null"
              (pointerup)="onCategorySelected(-1)"
              [touchClick]
              [ngClass]="fullHdHalf && 'btn-category--middle'"
            >
              <span class="label">
                <ng-container *ngIf="(selectedCategory$ | async) == null">
                  {{ "COMMON.ALL" | translate }}
                </ng-container>
                <ng-container *ngIf="(selectedCategory$ | async) != null">
                  {{ "COMMON.ALL_PRODUCTS" | translate }}
                </ng-container>
              </span>
              <span
                class="text-primaryx icon"
                *ngIf="(selectedCategory$ | async) == null"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <path
                    id="Path_13578"
                    data-name="Path 13578"
                    d="M18,34A16,16,0,1,0,2,18,16,16,0,0,0,18,34Zm7.414-18.586a2,2,0,1,0-2.828-2.828L16,19.172l-2.586-2.586a2,2,0,1,0-2.828,2.828l4,4a2,2,0,0,0,2.828,0l8-8Z"
                    transform="translate(-2 -2)"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
              </span>
            </button>
            <!-- menu items -->
            <div
              #targetMenu
              class="flex flex-col justify-start flex-1 w-full h-full category-vertical-scroll"
            >
              <!-- <button
              class=" btn btn-category border-gray-light text-gray active:border-active active:text-active"
              *ngFor="let category of categories$ | async"
              (click)="onCategorySelected(category.id, category.name)"
              [class.border-active]="
                (selectedCategory$ | async)?.id == category.id
              "
            >
              <span
                class="label"
                [class.text-active]="
                  (selectedCategory$ | async)?.id == category.id
                "
                >{{ category.name }}</span
              >
              <span
                class="icon text-active"
                *ngIf="(selectedCategory$ | async)?.id == category.id"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <path
                    id="Path_13578"
                    data-name="Path 13578"
                    d="M18,34A16,16,0,1,0,2,18,16,16,0,0,0,18,34Zm7.414-18.586a2,2,0,1,0-2.828-2.828L16,19.172l-2.586-2.586a2,2,0,1,0-2.828,2.828l4,4a2,2,0,0,0,2.828,0l8-8Z"
                    transform="translate(-2 -2)"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
              </span>
            </button> -->

              <ul>
                <li *ngFor="let category of categories$ | async" class="pb-1">
                  <button
                    class="btn btn-category btn-category--parent  text-gray active:border-primaryx active:text-primaryx {{
                      category.children.length > 0 ? 'cat-sideline' : ''
                    }} {{
                      (selectedCategory$ | async)?.id === category.id
                        ? 'border-primaryx'
                        : 'border-gray/30'
                    }}"
                    [class.text-primaryx]="
                      (selectedCategory$ | async)?.id === category.id
                    "
                    (pointerup)="onCategorySelected(category.id)"
                    [touchClick]
                    [ngClass]="fullHdHalf && 'btn-category--middle'"
                  >
                    <span class="label">{{ category.name }}</span>
                    <span
                      class="text-primaryx icon"
                      *ngIf="(selectedCategory$ | async)?.id === category.id"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          id="Path_13578"
                          data-name="Path 13578"
                          d="M18,34A16,16,0,1,0,2,18,16,16,0,0,0,18,34Zm7.414-18.586a2,2,0,1,0-2.828-2.828L16,19.172l-2.586-2.586a2,2,0,1,0-2.828,2.828l4,4a2,2,0,0,0,2.828,0l8-8Z"
                          transform="translate(-2 -2)"
                          fill="currentColor"
                          fill-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </button>

                  <ul
                    *ngIf="category.children.length > 0"
                    class="relative bg-gray/10 border-3 border-t-0 rounded-b-xl mb-2 {{
                      (selectedCategory$ | async)?.id === category.id
                        ? 'border-gray/40'
                        : 'border-gray/10'
                    }}"
                  >
                    <li
                      *ngFor="let subcategory of category.children"
                      class="subcat-list"
                    >
                      <span
                        class="cat-point"
                        [class.bg-primaryx]="
                          (selectedCategory$ | async)?.id === subcategory.id
                        "
                      ></span>
                      <button
                        class="btn btn-category btn-category--child border-gray-light text-gray active:border-primaryx active:text-primaryx"
                        (click)="onCategorySelected(subcategory.id)"
                        [class.text-primaryx]="
                          (selectedCategory$ | async)?.id === subcategory.id
                        "
                        [ngClass]="fullHdHalf && 'btn-category--middle'"
                      >
                        <span class="label">{{ subcategory.name }}</span>
                        <!-- <span
                          class="text-primaryx icon"
                          *ngIf="
                            (selectedCategory$ | async)?.id === subcategory.id
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                            viewBox="0 0 32 32"
                          >
                            <path
                              id="Path_13578"
                              data-name="Path 13578"
                              d="M18,34A16,16,0,1,0,2,18,16,16,0,0,0,18,34Zm7.414-18.586a2,2,0,1,0-2.828-2.828L16,19.172l-2.586-2.586a2,2,0,1,0-2.828,2.828l4,4a2,2,0,0,0,2.828,0l8-8Z"
                              transform="translate(-2 -2)"
                              fill="currentColor"
                              fill-rule="evenodd"
                            />
                          </svg>
                        </span> -->
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- END: menu items -->

            <!-- scroll buttons on the bottom position -->
            <div
              class="flex flex-row items-center justify-between w-full pt-3"
              *ngIf="(categories$ | async) && (categories$ | async).length > 8"
            >
              <button
                class="self-center mx-auto btn btn-mini-action border-gray-light active:border-active group"
                (pointerup)="scrollDownMenu()"
                [touchClick]
                *ngIf="
                  (categories$ | async) && (categories$ | async).length > 8
                "
              >
                <span class="icon bg-gray group-active:bg-active">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28.086"
                    height="27.993"
                    viewBox="0 0 28.086 27.993"
                    class="rotate-180"
                  >
                    <path
                      id="arrow_up"
                      data-name="arrow_up"
                      d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                      transform="translate(0 27.993)"
                      fill="currentColor"
                      fill-rule="evenodd"
                    />
                  </svg>
                </span>
              </button>
              <button
                class="self-center mx-auto btn btn-mini-action border-gray-light active:border-active group"
                (pointerup)="scrollUpMenu()"
                [touchClick]
                *ngIf="
                  (categories$ | async) && (categories$ | async).length > 8
                "
              >
                <span class="icon bg-gray group-active:bg-active">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28.086"
                    height="27.993"
                    viewBox="0 0 28.086 27.993"
                  >
                    <path
                      id="arrow_up"
                      data-name="arrow_up"
                      d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                      transform="translate(0 27.993)"
                      fill="currentColor"
                      fill-rule="evenodd"
                    />
                  </svg>
                </span>
              </button>
            </div>
            <!-- END scroll buttons on the bottom position -->
          </div>
          <div
            class="w-40 h-full flex flex-col z-10  justify-start items-start wow {{
              rightHand
                ? 'order-2 pl-0 bounceInLeft'
                : 'order-1 pr-0 bounceInRight'
            }}"
            data-wow-delay=".05s"
          >
            <!-- menu panel opened sidebar -->
            <ng-container
              *ngIf="(items$ | async) && (items$ | async).length > 0"
            >
              <!-- side column MENUPANEL OPENED -->
              <app-sidebar
                [productPage]="false"
                [useShoppingCart]="useShoppingCart"
                [statusCartMessage]="statusCartMessage"
                [cartQuantity]="cartItems.length"
                [menuPanelOpened]="menuPanelOpened"
                [fullHdHalf]="fullHdHalf"
                (menuPanelStatus)="menuPanelHandler($event)"
                [searchPanelOpened]="searchPanelOpened"
                (searchPanelStatus)="searchPanelHandler($event)"
                (resetCategoryStatus)="onCategorySelected(-1)"
                [rightHand]="rightHand"
                [hideBackBtn]="true"
                (rightHandStatus)="rightHandHandler($event)"
                class="{{
                  rightHand ? 'order-2 pr-3' : 'order-1 pl-3 ml-auto'
                }}"
              ></app-sidebar>
              <!-- END: side column MENUPANEL OPENED -->
            </ng-container>
            <!-- END menu panel opened sidebar -->
          </div>
        </div>
      </ng-container>
    </ng-container>
    <!-- END: menu panel -->
  </div>

  <!-- search panel -->
  <div
    class="fixed top-0 left-0 z-50 block w-full h-screen bg-white"
    [ngClass]="searchPanelOpened ? 'block' : 'hidden'"
  >
    <div
      class="relative flex flex-col items-start justify-start w-full h-screen"
    >
      <div
        class="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-auto mt-4 z-60"
        *ngIf="!onScreenKeyboard"
      >
        <button
          class="mx-auto btn btn-action border-gray-light active:border-active group"
          (pointerup)="closeSearchPanel()"
          [touchClick]
        >
          <span class="icon bg-gray active:bg-active group-active:bg-active">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49.498"
              height="49.498"
              viewBox="0 0 49.498 49.498"
            >
              <path
                id="close_icon"
                data-name="close_icon"
                d="M-3922.81,2626.037v-12h-12a3,3,0,0,1-3-3,3,3,0,0,1,3-3h12v-11a3,3,0,0,1,3-3,3,3,0,0,1,3,3v11h11a3,3,0,0,1,3,3,3,3,0,0,1-3,3h-11v12a3,3,0,0,1-3,3A3,3,0,0,1-3922.81,2626.037Z"
                transform="translate(950.191 -4593.964) rotate(-45)"
                fill="currentColor"
              />
            </svg>
          </span>
          <span class="label text-gray group-active:text-active">
            {{ "COMMON.BTN_CANCEL" | translate }}
          </span>
        </button>
      </div>

      <div
        class="flex-row justify-center first-block bg-primaryx-bg {{
          valueStatusActive ? 'h-[3.125rem]' : fullHdHalf ? 'h-[26.5rem]' : ''
        }}"
        [ngClass]="fullHd ? 'hidden' : 'hidden'"
      >
        <div
          class="absolute bottom-0 flex flex-col items-center justify-end w-full h-full -mb-16"
        >
          <!-- <ng-container *ngIf="onScreenKeyboard">
            <app-cloud
              class="flex flex-col justify-end w-full h-full"
              [ngClass]="onScreenKeyboard ? ' -mb-28' : '-mb-32'"
              *ngIf="!valueStatusActive"
            ></app-cloud>
          </ng-container> -->
          <!-- <ng-container *ngIf="!onScreenKeyboard">
            <app-cloud
              class="flex flex-col justify-end w-full h-full"
              [ngClass]="fullHdHalf ? '-mb-40' : fullHd ? ' -mb-36' : ' -mb-24'"
              *ngIf="!valueStatusActive"
            ></app-cloud>
          </ng-container> -->

          <div
            class="absolute flex flex-col items-center justify-center mx-auto -ml-6 bg-white rounded-full text-primaryx"
            [ngClass]="
              valueStatusActive
                ? 'w-24 h-24 mb-4'
                : onScreenKeyboard
                ? 'w-24 h-24 mb-4'
                : 'w-24 h-24 mb-4'
            "
            style="border-radius: 9999px !important"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60%"
              height="60%"
              viewBox="0 0 135.07 135.088"
            >
              <g
                id="icon_big_search"
                data-name="icon_big_search"
                transform="translate(-6219 7808.122)"
              >
                <path
                  id="Path_13440"
                  data-name="Path 13440"
                  d="M133.085,73.651,104.5,45.05a7.523,7.523,0,0,0-10.6.033,47.3,47.3,0,0,1-37.85,13.141A47.527,47.527,0,0,1,13.83,16.352,47.406,47.406,0,0,1,107.215.779a47.859,47.859,0,0,1-.477,22.388,6.788,6.788,0,1,0,13.114,3.5,61.533,61.533,0,0,0,1.8-20.884A61.108,61.108,0,0,0,67.645-49.518,60.953,60.953,0,0,0,.315,17.327a61.152,61.152,0,0,0,55.6,54.493A60.841,60.841,0,0,0,98.949,58.668l24.56,24.56a6.772,6.772,0,0,0,9.577-9.577"
                  transform="translate(6219 -7758.245)"
                  fill="#4a5568"
                />
                <path
                  id="Path_13441"
                  data-name="Path 13441"
                  d="M56.455-18.679a6.629,6.629,0,0,0-8.244-.791,22.946,22.946,0,0,1-12.123,3.445,22.883,22.883,0,0,1-11.34-3,6.536,6.536,0,0,0-7.776.9l-.409.39a6.5,6.5,0,0,0,1.143,10.3A36.41,36.41,0,0,0,36.088-2.483,36.428,36.428,0,0,0,55.7-8.176a6.533,6.533,0,0,0,1.127-10.129Z"
                  transform="translate(6243.839 -7708.13)"
                  fill="currentColor"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
      <!-- middle column search -->
      <div
        class="flex flex-col items-center justify-start flex-1 w-full text-center"
        [ngClass]="
          fullHdHalf ? 'pt-6' : valueStatusActive ? 'pt-6' : 'pt-27 pb-15'
        "
      >
        <div
          class="w-9/12 mx-auto mb-12 text-6xl text-center uppercase border-t-0 border-b-8 border-l-0 border-r-0 outline-none z-80 text-primaryx-dark border-gray-ultra focus:none"
        >
          <input
            #searchInput
            (keyup)="search($event)"
            type="text"
            class="w-full text-center uppercase bg-transparent outline-none focus:none"
            placeholder="{{ 'COMMON.SEARCH_ALL_CATEGORIES' | translate }}"
            (ngModelChange)="search($event)"
            (blur)="onBlurKeyboard($event)"
            value="{{ value }}"
            autocomplete="off"
            spellcheck="false"
            autofocus
          />
        </div>

        <div
          class="flex flex-col justify-between w-11/12 h-full mx-auto mt-5"
          *ngIf="onScreenKeyboard"
        >
          <div
            *ngIf="!(items$ | async) || (items$ | async).length === 0"
            class="flex flex-col items-center justify-center flex-grow w-10/12 h-full mx-auto"
            [ngClass]="fullHdHalf && 'mt-32'"
          >
            <h5 class="text-5xl">
              {{ "COMMON.NO_PRODUCTS" | translate }}
            </h5>
          </div>
          <div
            #target2
            class="flex flex-row justify-start w-10/12 h-full mx-auto space-x-3 overflow-x-scroll hideScrollbar"
            *ngIf="
              (items$ | async) &&
              (items$ | async).length > 0 &&
              valueStatusActive
            "
          >
            <!-- product item -->
            <ng-container
              *ngFor="let item of items$ | async; trackBy: trackById"
            >
              <div>
                <app-product-card
                  [fullHdHalf]="fullHdHalf"
                  [fullHd]="fullHd"
                  [useShoppingCart]="useShoppingCart"
                  [statusCartMessage]="activeProductId === item.product.id"
                  [cartIsFull]="cartIsFull"
                  [id]="item.product.id"
                  [image]="
                    (images[item.product.id] !== null
                      ? images[item.product.id]
                      : '/assets/placeholder.png'
                    ) | safe
                  "
                  [badgeImg]=""
                  [brandName]="item.product.name"
                  [name]="item.product.name"
                  [new]="
                    item.product.tags !== null &&
                    item.product.tags.toUpperCase().includes('NEU')
                  "
                  [action]="
                    item.product.tags !== null &&
                    item.product.tags.toUpperCase().includes('AKTION')
                  "
                  [quantity]="item.product.packagingQuantity"
                  [grossPrice]="item.product.grossPrice"
                  [price]="item.product.price"
                  [pricePerItem]="item.product.pricePerItem"
                  [priceType]="item.product.priceType"
                  [standardGrossPrice]="item.product.standardGrossPrice"
                  [standardSalePrice]="item.product.standardSalePrice"
                  [standardSaleVatAmount]="item.product.standardSaleVatAmount"
                  [standardVatPercent]="item.product.standardVatPercent"
                  [vatAmount]="item.product.vatAmount"
                  [vatPercent]="item.product.vatPercent"
                  (btnOpenDetails)="onProductSelected(item.product.id)"
                  (btnAddToCard)="addProductToCart(item.product.id)"
                  [hasEmptyStock]="
                    useShoppingCart && hasNoMoreItemsLeftInStock(item)
                  "
                >
                </app-product-card>
              </div>
            </ng-container>
            <!-- END: product item -->
          </div>
          <div
            class="absolute left-0 flex flex-row items-center self-end justify-between w-full h-auto px-8 my-10 mt-18"
            *ngIf="
              (items$ | async) &&
              (items$ | async).length > 0 &&
              valueStatusActive
            "
          >
            <button
              class="self-start mr-auto btn btn-mini-action btn-mini-action--primary border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50"
              [disabled]="!(items$ | async) && (items$ | async).length < 4"
              (pointerup)="scrollBack2()"
              [touchClick]
            >
              <span class="-rotate-90 icon bg-primaryx group-active:bg-active">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.086"
                  height="27.993"
                  viewBox="0 0 28.086 27.993"
                >
                  <path
                    id="arrow_up"
                    data-name="arrow_up"
                    d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                    transform="translate(0 27.993)"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
              </span>
            </button>

            <button
              class="self-end ml-auto btn btn-mini-action btn-mini-action--primary border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50"
              [disabled]="!(items$ | async) && (items$ | async).length < 4"
              (pointerup)="scrollForward2()"
              [touchClick]
            >
              <span class="rotate-90 icon bg-primaryx group-active:bg-active">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.086"
                  height="27.993"
                  viewBox="0 0 28.086 27.993"
                >
                  <path
                    id="arrow_up"
                    data-name="arrow_up"
                    d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                    transform="translate(0 27.993)"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <!-- END middle column search -->

      <div
        class="flex flex-col items-start justify-start third-block bg-primaryx-bg"
        style="height: 30.5rem"
      >
        <div
          class="w-9/12 mx-auto mt-6 mb-2"
          [ngClass]="onScreenKeyboard ? 'flex' : 'hidden'"
        >
          <div class="simple-keyboard-search"></div>
        </div>
        <div
          class="flex-col justify-between w-11/12 h-full mx-auto mt-5"
          *ngIf="!onScreenKeyboard"
        >
          <div
            *ngIf="!(items$ | async) || (items$ | async).length === 0"
            class="flex flex-col items-center justify-center flex-grow w-10/12 h-full mx-auto"
            [ngClass]="fullHdHalf && 'mt-32'"
          >
            <h5 class="text-5xl">{{ "COMMON.NO_PRODUCTS" | translate }}</h5>
          </div>
          <div
            #target2
            class="flex flex-row justify-between w-10/12 h-full mx-auto space-x-3 overflow-x-scroll hideScrollbar"
            *ngIf="(items$ | async) && (items$ | async).length > 0"
          >
            <!-- product item -->
            <div
              *ngFor="let item of items$ | async; trackBy: trackById"
              (pointerup)="onProductSelected(item.product.id)"
              [touchClick]
            >
              <div
                class="btn product_item"
                [ngClass]="fullHdHalf && 'product_item--middle'"
              >
                <!--  image -->
                <div class="pi-image">
                  <img
                    loading="lazy"
                    [src]="
                      (images[item.product.id] !== null
                        ? images[item.product.id]
                        : '/assets/placeholder.png'
                      ) | safe
                    "
                    alt="{{ item.product.name }}"
                    class="mx-auto my-1 max-h-52"
                    style="max-width: 90%"
                  />
                </div>
                <!--  title -->
                <div class="pi-title">
                  <span
                    class="hidden text-2xl font-semibold text-left text-gray"
                  >
                    brand
                  </span>
                  <span
                    class="text-2xl font-semibold text-left text-primaryx-dark min-h-24x min-h-32 max-h-32"
                    [ngClass]="fullHdHalf ? 'text-4xl' : 'text-2xl'"
                  >
                    {{ item.product.name }}
                  </span>
                  <div
                    class="flex flex-row items-center justify-between hidden w-full text-2xl font-semibold text-gray"
                  >
                    <span class="mr-auto text-left">290 g</span>
                  </div>
                </div>
                <!-- price -->
                <div
                  class="flex flex-col items-center justify-center w-full h-auto px-2 py-3 text-right"
                >
                  <div
                    class="flex flex-row items-center w-full h-auto px-6 py-4 jusftify-between bg-gray-ultra rounded-card"
                  >
                    <span
                      class="ml-auto text-2xl font-bold text-right text-primaryx-dark"
                      [ngClass]="fullHdHalf ? 'text-4xl' : 'text-2xl'"
                    >
                      {{ item.product.grossPrice.toFixed(2) }} €
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- END: product item -->
          </div>
          <div
            class="absolute left-0 flex flex-row items-center self-end justify-between w-full h-auto px-8 my-10 top-24 mt-18"
            *ngIf="(items$ | async) && (items$ | async).length > 0"
          >
            <button
              class="self-start mr-auto btn btn-mini-action btn-mini-action--primary border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50"
              (pointerup)="scrollBack2()"
              [touchClick]
            >
              <span class="-rotate-90 icon bg-primaryx group-active:bg-active">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.086"
                  height="27.993"
                  viewBox="0 0 28.086 27.993"
                >
                  <path
                    id="arrow_up"
                    data-name="arrow_up"
                    d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                    transform="translate(0 27.993)"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
              </span>
            </button>

            <button
              class="self-end ml-auto btn btn-mini-action btn-mini-action--primary border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50"
              (pointerup)="scrollForward2()"
              [touchClick]
            >
              <span class="rotate-90 icon bg-primaryx group-active:bg-active">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.086"
                  height="27.993"
                  viewBox="0 0 28.086 27.993"
                >
                  <path
                    id="arrow_up"
                    data-name="arrow_up"
                    d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                    transform="translate(0 27.993)"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div
          class="flex flex-row items-start justify-center w-9/12 mx-auto mb-6"
          *ngIf="onScreenKeyboard"
        >
          <button
            class="mx-auto btn btn-action border-gray-light group active:border-active"
            [ngClass]="fullHdHalf && 'btn-action--middle'"
            (pointerup)="closeSearchPanel()"
            [touchClick]
          >
            <span class="icon bg-gray group-active:bg-active">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="49.498"
                height="49.498"
                viewBox="0 0 49.498 49.498"
              >
                <path
                  id="close_icon"
                  data-name="close_icon"
                  d="M-3922.81,2626.037v-12h-12a3,3,0,0,1-3-3,3,3,0,0,1,3-3h12v-11a3,3,0,0,1,3-3,3,3,0,0,1,3,3v11h11a3,3,0,0,1,3,3,3,3,0,0,1-3,3h-11v12a3,3,0,0,1-3,3A3,3,0,0,1-3922.81,2626.037Z"
                  transform="translate(950.191 -4593.964) rotate(-45)"
                  fill="currentColor"
                />
              </svg>
            </span>
            <span class="label text-gray group-active:text-active">
              {{ "COMMON.BTN_CANCEL" | translate }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: search panel -->
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'nexus'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen bg-white"
  >
    <!-- central adv banner -->
    <app-nex-central-ad
      data-wow-delay="0ms"
      data-wow-duration=".4s"
    ></app-nex-central-ad>
    <!-- end central adv banner -->

    <!-- nex cetral wrapper -->
    <div class="flex flex-row flex-1 w-full h-full" *ngIf="!searchPanelOpened">
      <!-- nex product area -->
      <div
        class="flex flex-col items-start justify-between flex-1 h-full px-6 py-6 space-y-6 bg-nex-white wow slideInLeft animated"
        data-wow-delay="0ms"
        data-wow-duration=".6s"
      >
        <!-- header area -->
        <div class="flex flex-row items-center justify-between w-full h-auto">
          <!-- logo -->
          <div class="w-1/3">
            <img
              class="object-contain h-16 max-h-16"
              *ngIf="logo"
              [src]="logo"
            />
          </div>
          <!-- end logo -->

          <!-- active category -->
          <div
            class="flex items-center justify-end flex-1 w-auto text-2xl font-medium text-right text-nex-gray"
          >
            <ng-container
              *ngIf="(items$ | async) && (items$ | async).length > 0"
            >
              <ng-container *ngIf="activeTagFilter === ''">
                <ng-container
                  *ngIf="activeCategory === '' || activeCategory === 'all'"
                >
                  {{ "RETAILER_RETAILER_SELECTION.ALL_PRODUCTS" | translate }}
                </ng-container>

                <ng-container
                  *ngIf="activeCategory !== '' && activeCategory !== 'all'"
                >
                  {{ activeCategory | ellipsis : 64 }}
                </ng-container>
              </ng-container>
              <ng-container *ngIf="activeTagFilter !== ''">
                {{ activeTagFilter | uppercase }}
              </ng-container>
            </ng-container>
          </div>
          <!-- end active category -->
        </div>
        <!-- end header area -->

        <!-- breadcrumbs area -->
        <div class="flex flex-row items-center justify-between w-full h-13">
          <div class="flex flex-row items-center justify-start flex-1 w-auto">
            <button
              (click)="goHome()"
              class="relative z-50 flex flex-row items-center justify-start border-2 border-transparent w-13 h-13 bg-nex-gray-light text-nex-gray-dark active:bg-nex-gray-light/70 rounded-xl active:border-nex-gray-light active:shadow-inner-light"
            >
              <div class="flex items-center justify-center w-full h-13">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.6667 26.6667C26.6667 27.0203 26.5262 27.3594 26.2762 27.6095C26.0261 27.8595 25.687 28 25.3333 28H6.66668C6.31305 28 5.97392 27.8595 5.72387 27.6095C5.47382 27.3594 5.33334 27.0203 5.33334 26.6667V14.6667H1.33334L15.1027 2.14934C15.3482 1.92597 15.6681 1.80219 16 1.80219C16.3319 1.80219 16.6519 1.92597 16.8973 2.14934L30.6667 14.6667H26.6667V26.6667ZM10 17.3333C10 18.9246 10.6322 20.4508 11.7574 21.576C12.8826 22.7012 14.4087 23.3333 16 23.3333C17.5913 23.3333 19.1174 22.7012 20.2426 21.576C21.3679 20.4508 22 18.9246 22 17.3333H19.3333C19.3333 18.2174 18.9822 19.0652 18.357 19.6904C17.7319 20.3155 16.8841 20.6667 16 20.6667C15.116 20.6667 14.2681 20.3155 13.643 19.6904C13.0179 19.0652 12.6667 18.2174 12.6667 17.3333H10Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </button>
            <!-- activeParentCategory -->
            <ng-container *ngIf="activeParentCategory !== null">
              <button
                (click)="onCategorySelected(activeParentCategory.id)"
                class="relative z-40 flex flex-row items-center justify-start w-auto pl-6 pr-2 -ml-4 text-2xl bg-white border-2 min-w-13 h-13 border-nex-gray-light text-nex-gray-dark active:bg-nex-gray-light/70 rounded-r-xl active:border-nex-gray-light active:shadow-inner-light"
              >
                <div class="flex items-center justify-center w-full h-13">
                  {{ activeParentCategory.name | ellipsis : 64 }}
                </div>
              </button>
            </ng-container>
            <!-- END activeParentCategory -->

            <!-- activeCategory -->
            <ng-container
              *ngIf="activeCategory !== '' && activeCategory !== 'all'"
            >
              <button
                class="relative z-30 flex flex-row items-center justify-start w-auto pl-6 pr-2 -ml-4 text-2xl bg-white border-2 min-w-13 h-13 border-nex-gray-light text-nex-gray-dark active:bg-nex-gray-light/70 rounded-r-xl"
              >
                <div class="flex items-center justify-center w-full h-13">
                  {{ activeCategory | ellipsis : 64 }}
                </div>
              </button>
            </ng-container>
            <!-- END activeCategory -->
          </div>

          <!-- sorting tools -->
          <div
            class="flex flex-row items-center justify-end w-auto pl-3 space-x-3 max-w-72"
          >
            <!-- <ng-container *ngIf="activeTagFilter === ''">
              <button
                (click)="sortProductsByName('asc')"
                class="flex flex-row items-center justify-start border-2 border-transparent w-13 h-13 bg-nex-gray-light text-nex-gray-dark active:bg-nex-gray-light/70 rounded-xl active:border-nex-gray-light active:shadow-inner-light"
              >
                <div class="flex items-center justify-center w-full h-13">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 10.6667H10.668L10.6667 26.6667H7.99999V10.6667H2.66666L9.33332 4L16 10.6667ZM29.3333 21.3333L22.6667 28L16 21.3333H21.3333V5.33333H24V21.3333H29.3333Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </button>
            </ng-container> -->
            <ng-container *ngIf="activeTagFilter !== ''">
              <button
                (click)="resetFilterProductsByTag()"
                class="flex flex-row items-center justify-start text-white bg-orange-700 border-2 border-transparent w-13 h-13 active:bg-orange-700/70 rounded-xl active:border-orange-600 active:shadow-inner-light"
              >
                <div class="flex items-center justify-center w-full h-13">
                  <svg width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12 4C14.7486 4 17.1749 5.38626 18.6156 7.5H16V9.5H22V3.5H20V5.99936C18.1762 3.57166 15.2724 2 12 2C6.47715 2 2 6.47715 2 12H4C4 7.58172 7.58172 4 12 4ZM20 12C20 16.4183 16.4183 20 12 20C9.25144 20 6.82508 18.6137 5.38443 16.5H8V14.5H2V20.5H4V18.0006C5.82381 20.4283 8.72764 22 12 22C17.5228 22 22 17.5228 22 12H20Z"
                    ></path>
                  </svg>
                </div>
              </button>
            </ng-container>
            <!-- select language -->
            <ng-container *ngIf="!multiLanguage">
              <app-nex-lang-switcher></app-nex-lang-switcher>
            </ng-container>
            <!-- END select language -->
          </div>
          <!-- end sorting tools -->
        </div>
        <!-- end breadcrumbs area -->

        <!-- products area -->
        <div class="flex flex-1 w-full h-full">
          <!-- product item -->
          <div
            class="relative flex w-full h-full -mt-4 mb-9"
            (swiperight)="prevSlide()"
            (swipeleft)="nextSlide()"
          >
            <div
              [@slide]="activeIndex"
              *ngFor="
                let pair of products | chunk : numOfItemsPerScreen;
                let i = index
              "
              class="w-full h-full grid-flow-row pt-4  content-between gap-x-6 gap-y-4  {{
                products && products.length >= numOfItemsPerScreen
                  ? 'place-content-between'
                  : 'place-content-start'
              }} {{
                flatProductCard
                  ? 'grid-rows-4 grid-cols-4'
                  : 'grid-rows-3 grid-cols-3'
              }}"
              [ngClass]="{
                grid: i === activeIndex,
                hidden: i !== activeIndex
              }"
            >
              <!-- product card -->
              <ng-container *ngFor="let item of pair; let i = index">
                <app-product-card
                  [fullHdHalf]="fullHdHalf"
                  [fullHd]="fullHd"
                  [theme]="'nex'"
                  [useShoppingCart]="useShoppingCart"
                  [flatProductCard]="flatProductCard"
                  [statusCartMessage]="activeProductId === item.product.id"
                  [cartIsFull]="cartIsFull"
                  [id]="item.product.id"
                  [image]="
                    (images[item.product.id] !== null
                      ? images[item.product.id]
                      : '/assets/placeholder.png'
                    ) | safe
                  "
                  [badgeImg]=""
                  [brandName]="item.product.name"
                  [name]="item.product.name"
                  [new]="
                    item.product.tags !== null &&
                    item.product.tags.toUpperCase().includes('NEU')
                  "
                  [promo]="
                    item.product.tags !== null &&
                    item.product.tags.toUpperCase().includes('PROMO')
                  "
                  [action]="
                    item.product.tags !== null &&
                    item.product.tags.toUpperCase().includes('AKTIONEN')
                  "
                  [quantity]="item.product.packagingQuantity"
                  [grossPrice]="item.product.grossPrice"
                  [price]="item.product.price"
                  [pricePerItem]="item.product.pricePerItem"
                  [priceType]="item.product.priceType"
                  [standardGrossPrice]="item.product.standardGrossPrice"
                  [standardSalePrice]="item.product.standardSalePrice"
                  [standardSaleVatAmount]="item.product.standardSaleVatAmount"
                  [standardVatPercent]="item.product.standardVatPercent"
                  [vatAmount]="item.product.vatAmount"
                  [vatPercent]="item.product.vatPercent"
                  (btnOpenDetails)="onProductSelected(item.product.id)"
                  (btnAddToCard)="addProductToCart(item.product.id)"
                  [hasEmptyStock]="
                    useShoppingCart && hasNoMoreItemsLeftInStock(item)
                  "
                >
                </app-product-card>
              </ng-container>
              <!-- end product card -->
            </div>
          </div>
          <!-- END: product item -->
        </div>
        <!-- end products area -->

        <!-- nex carousel indicator -->
        <div class="flex flex-row items-center justify-between w-full h-16">
          <!-- carousel prev -->
          <button
            (click)="prevSlide()"
            *ngIf="
              (items$ | async) &&
              products.length > 0 &&
              (!menuPanelOpened ? products.length > 6 : products.length > 4)
            "
            [disabled]="activeIndex === 0"
            class="flex flex-row items-center justify-start w-24 h-16 text-white bg-primaryx disabled:invisible active:opacity-70 rounded-xl active:shadow-inner-light"
          >
            <div
              class="flex items-center justify-center w-full h-16 p-4 rotate-180"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22 16L10 28L10 4L22 16Z" fill="currentColor" />
              </svg>
            </div>
          </button>
          <!-- end carousel prev -->

          <!-- carousel indicator -->
          <div
            class="flex flex-row items-center justify-center flex-1 px-12 space-x-4"
            *ngIf="products && products.length > 0"
          >
            <span
              *ngFor="
                let item of products
                  | chunk : numOfItemsPerScreen
                  | slice : 0 : indicatorLimit;
                let i = index
              "
              class="flex items-center justify-center h-6 text-xl font-medium text-center transition-all rounded-full bg-nex-gray-light"
              [ngClass]="{
                'w-12 text-transparent opacity-90': i === activeIndex,
                'w-6 text-transparent opacity-40': i !== activeIndex
              }"
              (click)="setActiveIndex(i)"
            >
              <!-- {{ i + 1 }} -->
            </span>
          </div>
          <!-- end carousel indicator -->

          <!-- carousel next -->
          <button
            (click)="nextSlide()"
            *ngIf="products && products.length > 0 && products.length > 16"
            [disabled]="activeIndex === numOfChunks"
            class="flex flex-row items-center justify-start w-24 h-16 text-white bg-primaryx disabled:invisible active:opacity-70 rounded-xl active:shadow-inner-light"
          >
            <div class="flex items-center justify-center w-full h-16 p-4">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22 16L10 28L10 4L22 16Z" fill="currentColor" />
              </svg>
            </div>
          </button>
          <!-- end carouse next -->
        </div>
        <!-- end nex carousel indicator -->
      </div>
      <!-- end nex product area -->

      <!-- nex side area -->
      <div
        class="w-[255px] pl-3 h-full bg-nex-gray-light-ultra py-6 relative side-area wow slideInRight animated"
        data-wow-delay="0ms"
        data-wow-duration=".6s"
      >
        <div class="flex flex-col items-stretch justify-between w-full h-full">
          <div class="flex-col justify-start w-full">
            <!-- category panel header items -->
            <ul class="flex flex-col w-full mb-3 space-y-3">
              <li *ngIf="isTopLevel">
                <button
                  (click)="
                    activeTagFilter === 'aktionen'
                      ? resetFilterProductsByTag()
                      : filterProductsByTag('aktionen')
                  "
                  class="flex flex-row items-center justify-start w-full h-16 border-2 border-r-0 border-transparent rounded-l-xl active:border-nex-gray-light active:shadow-inner-light"
                  [ngClass]="
                    activeTagFilter === 'aktionen' ? 'bg-nex-red' : 'bg-white'
                  "
                >
                  <div
                    class="flex items-start justify-start w-16 h-16 p-4"
                    [ngClass]="
                      activeTagFilter === 'aktionen'
                        ? 'text-white'
                        : 'text-nex-red'
                    "
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 30.6667C13.9962 30.6665 12.0387 30.0643 10.3812 28.9383C8.72375 27.8122 7.44281 26.2142 6.70446 24.3514C5.96612 22.4886 5.80444 20.4469 6.24038 18.4911C6.67632 16.5353 7.68977 14.7556 9.14934 13.3827C10.9387 11.6987 15.3333 8.66667 14.6667 2C22.6667 7.33333 26.6667 12.6667 18.6667 20.6667C20 20.6667 22 20.6667 25.3333 17.3733C25.6933 18.404 26 19.512 26 20.6667C26 23.3188 24.9464 25.8624 23.0711 27.7377C21.1957 29.6131 18.6522 30.6667 16 30.6667Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>

                  <div
                    class="flex flex-row items-center justify-start flex-1 w-auto h-full pr-4 text-2xl font-medium uppercase"
                    [ngClass]="
                      activeTagFilter === 'aktionen'
                        ? 'text-white'
                        : 'text-nex-gray-dark'
                    "
                  >
                    {{ "RETAILER_RETAILER_SELECTION.ACTIONS" | translate }}
                  </div>
                </button>
              </li>
              <li *ngIf="isTopLevel">
                <button
                  (click)="
                    activeTagFilter === 'neu'
                      ? resetFilterProductsByTag()
                      : filterProductsByTag('neu')
                  "
                  class="flex flex-row items-center justify-start w-full h-16 border-2 border-r-0 border-transparent rounded-l-xl active:border-nex-gray-light active:shadow-inner-light"
                  [ngClass]="
                    activeTagFilter === 'neu' ? 'bg-nex-green' : 'bg-white'
                  "
                >
                  <div
                    class="flex items-start justify-start w-16 h-16 p-4"
                    [ngClass]="
                      activeTagFilter === 'neu'
                        ? 'text-white'
                        : 'text-nex-green'
                    "
                  >
                    <svg
                      width="32"
                      height="30"
                      viewBox="0 0 32 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 24.3467L6.596 29.6107L8.696 19.04L0.783997 11.7227L11.4867 10.4533L16 0.666664L20.5133 10.4533L31.216 11.7227L23.304 19.04L25.404 29.6107L16 24.3467Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>

                  <div
                    class="flex flex-row items-center justify-start flex-1 w-auto h-full pr-4 text-2xl font-medium uppercase"
                    [ngClass]="
                      activeTagFilter === 'neu'
                        ? 'text-white'
                        : 'text-nex-gray-dark'
                    "
                  >
                    {{ "RETAILER_RETAILER_SELECTION.NEW_PRODUCTS" | translate }}
                  </div>
                </button>
              </li>
              <li *ngIf="!isTopLevel">
                <button
                  (click)="goBack()"
                  class="flex flex-row items-center justify-start w-full h-16 bg-transparent border-2 border-r-0 border-transparent text-nex-gray-dark active:bg-white/70 rounded-l-xl active:border-nex-gray-light active:shadow-inner-light"
                >
                  <div class="flex items-start justify-start w-16 h-16 p-4">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.3333 18.6667V6.66675H22.6667V16.0001H12.552V8.78141L4 17.3334L12.552 25.8854V18.6667H25.3333Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>

                  <div
                    class="flex flex-row items-center justify-start flex-1 w-auto h-full pr-4 text-2xl font-medium uppercase"
                  >
                    {{ "COMMON.BACK" | translate }}
                  </div>
                </button>
              </li>
            </ul>
            <!-- end category panel header items -->

            <!-- categories list -->
            <ng-container
              *ngIf="(items$ | async) && (items$ | async).length > 0"
            >
              <div
                class="w-full h-[1279px] flex flex-col relative z-10"
                (swipeup)="scrollUpMenu()"
                (swipedown)="scrollDownMenu()"
              >
                <div
                  #targetMenu
                  class="flex flex-col justify-start flex-1 w-full h-full nex-category-vertical-scroll"
                >
                  <ul class="w-full space-y-1">
                    <li
                      *ngFor="let category of visibleCategories; let i = index"
                    >
                      <button
                        class="btn bg-white  w-full h-auto min-h-14 pl-4 pr-2 flex flex-col items-center justify-between rounded-l-xl text-gray border-2 border-r-0 active:border-primaryx active:text-primaryx active:shadow-inner-light disabled:cursor-not-allowed uppercase  {{
                          currentMenuId === category.id
                            ? 'border-nex-gray-light shadow-inner-light bg-gradient-to-r from-white via-white to-nex-gray-light/20'
                            : 'border-transparent'
                        }}"
                        (click)="
                          toggleSubCategories(
                            category,
                            category.children.length > 0
                          )
                        "
                        [class.hidden]="!isTopLevel"
                      >
                        <span
                          class="flex items-end justify-start w-16 h-16 mr-auto rounded-lg"
                        >
                          <img
                            loading="lazy"
                            [src]="
                              (images[category.productId] !== null
                                ? images[category.productId]
                                : '/assets/placeholder.png'
                              ) | safe
                            "
                            class="object-contain object-left w-14 h-14"
                          />
                        </span>
                        <span
                          class="flex items-center justify-start w-full h-auto pb-2 text-xl font-semibold text-left text-nex-gray-dark min-h-14"
                        >
                          {{ category.name | ellipsis : 40 }}
                        </span>
                      </button>

                      <ng-container *ngIf="category.children.length > 0">
                        <ul
                          *ngIf="category.showChildren"
                          class="w-full space-y-1"
                        >
                          <li *ngFor="let subcategory of category.children">
                            <button
                              class="btn w-full h-auto min-h-14 pl-4 pr-2 flex flex-col items-center justify-between rounded-l-xl text-gray border-2 border-r-0 active:border-primaryx active:text-primaryx active:shadow-inner-light uppercase bg-white {{
                                currentMenuId === subcategory.id
                                  ? 'border-nex-gray-light shadow-inner-light bg-gradient-to-r from-white via-white to-nex-gray-light/20'
                                  : 'border-transparent'
                              }}"
                              (click)="onCategorySelected(subcategory.id)"
                            >
                              <span
                                class="flex items-end justify-start w-16 h-16 mr-auto rounded-lg"
                              >
                                <img
                                  loading="lazy"
                                  [src]="
                                    (images[subcategory.productId] !== null
                                      ? images[subcategory.productId]
                                      : '/assets/placeholder.png'
                                    ) | safe
                                  "
                                  class="object-contain object-left w-14 h-14"
                                />
                              </span>
                              <span
                                class="flex items-center justify-start w-full h-auto pb-2 text-xl font-semibold text-left text-nex-gray-dark min-h-14"
                              >
                                {{ subcategory.name | ellipsis : 40 }}
                              </span>
                            </button>
                          </li>
                        </ul>
                      </ng-container>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
            <!-- end ategories list -->
          </div>

          <!-- scroll buttons on the bottom position -->
          <div
            class="flex flex-row items-center justify-between w-full pt-3 pr-3"
          >
            <button
              (click)="scrollDownMenu()"
              *ngIf="visibleCategories && visibleCategories.length > 8"
              class="flex flex-row items-center justify-start w-24 h-16 bg-transparent border-2 text-nex-gray-dark active:bg-white/70 rounded-xl border-nex-gray-dark active:border-nex-gray-light active:shadow-inner-light"
            >
              <div class="flex items-center justify-center w-full h-16 p-4">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 21.3334L8 13.3334H24L16 21.3334Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </button>

            <button
              (click)="scrollUpMenu()"
              *ngIf="visibleCategories && visibleCategories.length > 8"
              class="flex flex-row items-center justify-start w-24 h-16 bg-transparent border-2 text-nex-gray-dark active:bg-white/70 rounded-xl border-nex-gray-dark active:border-nex-gray-light active:shadow-inner-light"
            >
              <div class="flex items-center justify-center w-full h-16 p-4">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 10.6666L24 18.6666H8L16 10.6666Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </button>
          </div>
          <!-- END scroll buttons on the bottom position -->
        </div>
      </div>
      <!-- end nex side area -->
    </div>
    <!-- end nex cetral wrapper -->

    <!-- nex search panel -->
    <div
      class="flex-row flex-1 w-full h-full px-6 py-6 bg-white"
      [ngClass]="searchPanelOpened ? 'flex' : 'hidden'"
    >
      <div
        class="relative flex flex-col items-start justify-start w-full h-full"
      >
        <!-- middle column search -->
        <div
          class="flex flex-col items-center justify-start flex-1 w-full text-center"
        >
          <div
            class="flex items-center justify-center w-full px-6 py-2 mx-auto text-5xl border-4 h-22 rounded-2xl border-nex-gray-light bg-nex-gray-light-ultra focus:none"
          >
            <input
              #searchInput
              (keyup)="search($event)"
              type="text"
              class="w-full text-center bg-transparent outline-none focus:none"
              placeholder="{{ 'COMMON.SEARCH_ALL_CATEGORIES' | translate }}"
              (ngModelChange)="search($event)"
              (blur)="onBlurKeyboard($event)"
              value="{{ value }}"
              autocomplete="off"
              spellcheck="false"
              autofocus
            />
          </div>

          <div
            class="flex flex-col justify-between w-full h-full py-6 mx-auto"
            *ngIf="onScreenKeyboard"
          >
            <div
              *ngIf="!(items$ | async) || (items$ | async).length === 0"
              class="flex flex-col items-center justify-center flex-grow w-full h-full mx-auto"
              [ngClass]="fullHdHalf && 'mt-32'"
            >
              <h5 class="text-5xl">
                {{ "COMMON.NO_PRODUCTS" | translate }}
              </h5>
            </div>
            <div
              #target2
              class="flex flex-row items-center justify-start w-full h-full mx-auto space-x-3 overflow-x-scroll hideScrollbar"
              *ngIf="
                (items$ | async) &&
                (items$ | async).length > 0 &&
                valueStatusActive
              "
            >
              <!-- product item -->
              <ng-container
                *ngFor="let item of items$ | async; trackBy: trackById"
              >
                <div>
                  <app-product-card
                    [fullHdHalf]="fullHdHalf"
                    [fullHd]="fullHd"
                    [theme]="'nex'"
                    [useShoppingCart]="useShoppingCart"
                    [flatProductCard]="flatProductCard"
                    [statusCartMessage]="activeProductId === item.product.id"
                    [cartIsFull]="cartIsFull"
                    [id]="item.product.id"
                    [image]="
                      (images[item.product.id] !== null
                        ? images[item.product.id]
                        : '/assets/placeholder.png'
                      ) | safe
                    "
                    [badgeImg]=""
                    [brandName]="item.product.name"
                    [name]="item.product.name"
                    [new]="
                      item.product.tags !== null &&
                      item.product.tags.toUpperCase().includes('NEU')
                    "
                    [promo]="
                      item.product.tags !== null &&
                      item.product.tags.toUpperCase().includes('PROMO')
                    "
                    [action]="
                      item.product.tags !== null &&
                      item.product.tags.toUpperCase().includes('AKTIONEN')
                    "
                    [quantity]="item.product.packagingQuantity"
                    [grossPrice]="item.product.grossPrice"
                    [price]="item.product.price"
                    [pricePerItem]="item.product.pricePerItem"
                    [priceType]="item.product.priceType"
                    [standardGrossPrice]="item.product.standardGrossPrice"
                    [standardSalePrice]="item.product.standardSalePrice"
                    [standardSaleVatAmount]="item.product.standardSaleVatAmount"
                    [standardVatPercent]="item.product.standardVatPercent"
                    [vatAmount]="item.product.vatAmount"
                    [vatPercent]="item.product.vatPercent"
                    (btnOpenDetails)="onProductSelected(item.product.id)"
                    (btnAddToCard)="addProductToCart(item.product.id)"
                    [hasEmptyStock]="
                      useShoppingCart && hasNoMoreItemsLeftInStock(item)
                    "
                  >
                  </app-product-card>
                </div>
              </ng-container>
              <!-- END: product item -->
            </div>

            <!-- nex SEARCH carousel indicator -->
            <div
              class="flex flex-row items-center justify-between w-full h-16"
              *ngIf="
                (items$ | async) &&
                (items$ | async).length > 0 &&
                valueStatusActive
              "
            >
              <!-- search carousel prev -->
              <button
                [disabled]="!(items$ | async) && (items$ | async).length <= 6"
                (click)="scrollBack2()"
                class="flex flex-row items-center justify-start w-24 h-16 disabled:invisible active:opacity-70 rounded-xl"
                [ngClass]="
                  (items$ | async).length <= 6
                    ? 'bg-white border-2 border-nex-gray-light text-nex-gray-light'
                    : 'bg-primaryx text-white  active:shadow-inner-light'
                "
              >
                <div
                  class="flex items-center justify-center w-full h-16 p-4 rotate-180"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22 16L10 28L10 4L22 16Z" fill="currentColor" />
                  </svg>
                </div>
              </button>
              <!-- end search carousel prev -->

              <!-- search carousel next -->
              <button
                [disabled]="!(items$ | async) && (items$ | async).length < 5"
                (click)="scrollForward2()"
                class="flex flex-row items-center justify-start w-24 h-16 text-white bg-primaryx disabled:invisible active:opacity-70 rounded-xl active:shadow-inner-light"
              >
                <div class="flex items-center justify-center w-full h-16 p-4">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22 16L10 28L10 4L22 16Z" fill="currentColor" />
                  </svg>
                </div>
              </button>
              <!-- end search carouse next -->
            </div>
            <!-- end nex SEARCH carousel indicator -->
          </div>
        </div>
        <!-- END middle column search -->

        <div class="relative z-50 flex flex-col items-start justify-end w-full">
          <div
            class="w-full mx-auto"
            [ngClass]="onScreenKeyboard ? 'flex' : 'hidden'"
          >
            <div class="simple-keyboard-search"></div>
          </div>
          <div
            class="flex-col justify-between w-11/12 h-full mx-auto mt-5"
            *ngIf="!onScreenKeyboard"
          >
            <div
              *ngIf="!(items$ | async) || (items$ | async).length === 0"
              class="flex flex-col items-center justify-center flex-grow w-10/12 h-full mx-auto"
              [ngClass]="fullHdHalf && 'mt-32'"
            >
              <h5 class="text-5xl">{{ "COMMON.NO_PRODUCTS" | translate }}</h5>
            </div>
            <div
              #target2
              class="flex flex-row justify-between w-10/12 h-full mx-auto space-x-3 overflow-x-scroll hideScrollbar"
              *ngIf="(items$ | async) && (items$ | async).length > 0"
            >
              <!-- product item -->
              <div
                *ngFor="let item of items$ | async; trackBy: trackById"
                (click)="onProductSelected(item.product)"
              >
                <div
                  class="btn product_item"
                  [ngClass]="fullHdHalf && 'product_item--middle'"
                >
                  <!--  image -->
                  <div class="pi-image">
                    <img
                      loading="lazy"
                      [src]="
                        (images[item.product.id] !== null
                          ? images[item.product.id]
                          : '/assets/placeholder.png'
                        ) | safe
                      "
                      alt="{{ item.product.name }}"
                      class="mx-auto my-1 max-h-52"
                      style="max-width: 90%"
                    />
                  </div>
                  <!--  title -->
                  <div class="pi-title">
                    <span
                      class="hidden text-2xl font-semibold text-left text-gray"
                    >
                      brand
                    </span>
                    <span
                      class="text-2xl font-semibold text-left text-primaryx-dark min-h-24x min-h-32 max-h-32"
                      [ngClass]="fullHdHalf ? 'text-4xl' : 'text-2xl'"
                    >
                      {{ item.product.name }}
                    </span>
                    <div
                      class="flex flex-row items-center justify-between w-full text-2xl font-semibold text-gray"
                    >
                      <span class="mr-auto text-left">290 g</span>
                    </div>
                  </div>
                  <!-- price -->
                  <div
                    class="flex flex-col items-center justify-center w-full h-auto px-2 py-3 text-right"
                  >
                    <div
                      class="flex flex-row items-center w-full h-auto px-6 py-4 jusftify-between bg-gray-ultra rounded-card"
                    >
                      <span
                        class="ml-auto text-2xl font-bold text-right text-primaryx-dark"
                        [ngClass]="fullHdHalf ? 'text-4xl' : 'text-2xl'"
                      >
                        {{ item.product.grossPrice.toFixed(2) }} €
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END: product item -->
            </div>
            <div
              class="absolute left-0 flex flex-row items-center self-end justify-between w-full h-auto px-8 my-10 top-24 mt-18"
              *ngIf="(items$ | async) && (items$ | async).length > 0"
            >
              <button
                class="self-start mr-auto btn btn-mini-action btn-mini-action--primary border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50"
                (click)="scrollBack2()"
              >
                <span
                  class="-rotate-90 icon bg-primaryx group-active:bg-active"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28.086"
                    height="27.993"
                    viewBox="0 0 28.086 27.993"
                  >
                    <path
                      id="arrow_up"
                      data-name="arrow_up"
                      d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                      transform="translate(0 27.993)"
                      fill="currentColor"
                      fill-rule="evenodd"
                    />
                  </svg>
                </span>
              </button>

              <button
                class="self-end ml-auto btn btn-mini-action btn-mini-action--primary border-primaryx active:border-active group disabled:cursor-not-allowed disabled:opacity-50"
                (click)="scrollForward2()"
              >
                <span class="rotate-90 icon bg-primaryx group-active:bg-active">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28.086"
                    height="27.993"
                    viewBox="0 0 28.086 27.993"
                  >
                    <path
                      id="arrow_up"
                      data-name="arrow_up"
                      d="M6.017-11.526A3.5,3.5,0,0,1,3.5-10.458a3.517,3.517,0,0,1-3.5-3.5,3.5,3.5,0,0,1,1.069-2.517l10.5-10.5a3.517,3.517,0,0,1,4.949,0l10.5,10.5a3.5,3.5,0,0,1,1.069,2.517,3.517,3.517,0,0,1-3.5,3.5,3.5,3.5,0,0,1-2.517-1.068l-4.525-4.525V-3.5a3.517,3.517,0,0,1-3.5,3.5,3.517,3.517,0,0,1-3.5-3.5V-16.051Z"
                      transform="translate(0 27.993)"
                      fill="currentColor"
                      fill-rule="evenodd"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end nex search panel -->

    <!-- nex action footer area -->
    <app-nex-footer
      [productPage]="false"
      [hideBackBtn]="isRetailerOnly"
      [useShoppingCart]="useShoppingCart"
      [statusCartMessage]="statusCartMessage"
      [cartQuantity]="cartItems.length"
      [cartTotalValue]="cartTotalValue"
      [searchPanelOpened]="searchPanelOpened"
      (searchPanelStatus)="searchPanelHandler($event)"
    ></app-nex-footer>
    <!-- end nex action footer area -->
  </div>
</ng-container>
