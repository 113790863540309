
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Control } from '../../state/control.actions';
import { Navigate } from '@ngxs/router-plugin';
import { Router, NavigationEnd } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { SubSink } from 'subsink';
import { Navigation } from 'selenium-webdriver';


@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit, OnDestroy, AfterViewInit {

  theme: string;
  hatchDataExport: any;
  public loginSuccess = false;
  public lockerActive = false;
  public loginSubmitted = false;
  public loginEnabled = false;
  uncompletedPickupItemsActive: boolean = false;
  loadLockerPreviewActive: boolean = false;

  public locked: boolean = true;

  subs = new SubSink();


  constructor(private store: Store, private idle: Idle, private router: Router) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    router.events.subscribe((val) => {


      this.lockerActive = val instanceof NavigationEnd && this.router.url === '/core/setup/locker-select';


    });
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

  }

  lockedStatusHandler(status: boolean) {
    this.locked = false;
    this.loginSuccessModal();


  }

  errorStatusHandler(status: boolean) {
    this.loginSubmitted = false;
  }

  valueStatusHandler(status: boolean) {
    this.loginEnabled = status;
  }

  loginSuccessModal() {
    this.loginSuccess = true;
    setTimeout(() => {
      this.loginSuccess = false;
    }, 1200)
  }

  loginWithPassword() {
    this.loginSubmitted = !this.loginSubmitted;

  }



  uncompletedPickupItems(): void {
    this.store.dispatch(new Navigate(['/core/setup', 'uncompleted-pickup-items']));

  }

  cancel(): void {
    this.store.dispatch(new Navigate(['/core/screensaver']));
  }
}

