import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-big',
  templateUrl: './cloud-big.component.svg'
})
export class CloudBigComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
