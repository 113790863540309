<div class="flex flex-col items-start justify-between w-full h-screen">
  <app-ski-bg
    [isLight]="false"
    [useAnimation]="true"
    [showImg]="true"
    [imgUrl]="'/assets/mb-mountains-people.jpeg'"
    [clockCenter]="true"
    [bigSelection]="true"
    [showImg]="true"
  >
    <ng-container slot="top">
      <app-header-ski
        [title]="'LOCKER_SKI.SELECTION_TITLE' | translate"
        [subtitle]="'LOCKER_SKI.SELECTION_SUBTITLE' | translate"
        [subtitleLeft]="'LOCKER_SKI.SELECTION_SUBTITLE_3' | translate"
        [superSubtitle]="false"
        [longSubLeft]="true"
        [big]="false"
      ></app-header-ski>
    </ng-container>

    <!-- left btns -->
    <ng-container slot="btn4"> </ng-container>
    <ng-container slot="btn3">
      <ng-container *ngIf="lockers[0].enabled">
        <app-btn-ski
          (btnClick)="onSelectNumOfLockers(lockers[0])"
          [label]="lockers[0].label | translate"
          [big]="false"
          [type]="'super'"
          [right]="false"
          [number]="lockers[0].number"
        >
        </app-btn-ski>
      </ng-container>
    </ng-container>
    <ng-container slot="btn2">
      <ng-container *ngIf="lockers[1].enabled">
        <app-btn-ski
          (btnClick)="onSelectNumOfLockers(lockers[1])"
          [label]="lockers[1].label | translate"
          [big]="false"
          [type]="'super'"
          [right]="false"
          [number]="lockers[1].number"
        >
        </app-btn-ski>
      </ng-container>
    </ng-container>
    <ng-container slot="btn1"> </ng-container>
    <!-- end left btns -->

    <!-- right btns -->
    <ng-container slot="btn8"> </ng-container>
    <ng-container slot="btn7">
      <ng-container *ngIf="lockers[2].enabled">
        <app-btn-ski
          (btnClick)="onSelectNumOfLockers(lockers[2])"
          [label]="lockers[2].label | translate"
          [big]="false"
          [type]="'super'"
          [right]="true"
          [number]="lockers[2].number"
        >
        </app-btn-ski>
      </ng-container>
    </ng-container>
    <ng-container slot="btn6">
      <ng-container *ngIf="lockers[3].enabled">
        <app-btn-ski
          (btnClick)="onSelectNumOfLockers(lockers[3])"
          [label]="lockers[3].label | translate"
          [big]="false"
          [type]="'super'"
          [right]="true"
          [number]="lockers[3].number"
        >
        </app-btn-ski>
      </ng-container>
    </ng-container>
    <ng-container slot="btn5">
      <app-btn-ski
        (btnClick)="onCancel()"
        [label]="'COMMON.CANCEL' | translate"
        [big]="false"
        [type]="'subaction'"
        [subActionType]="'cancel'"
        [right]="true"
      >
      </app-btn-ski>
    </ng-container>
    <!-- end right btns -->
  </app-ski-bg>
</div>
