import { Component, OnInit, HostListener } from '@angular/core';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { LockerSkiState, LockerSkiStateModel } from 'src/app/locker/state/locker-ski.state';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class SkiThanksComponent implements OnInit {
  skiServiceType: string = "";
  slots: string = "";

  lockers: Array<any> = []

  redirectTimeout: any;

  constructor(private store: Store) {
    let selectedSlots = this.store.selectSnapshot<LockerSkiStateModel>(LockerSkiState).selectedSlots;

    console.log(selectedSlots)
    this.skiServiceType = this.store.selectSnapshot<LockerSkiStateModel>(LockerSkiState).action;

    selectedSlots.forEach(slot => {
      this.lockers.push({
        id: 0,
        number: slot.number
      });

      this.slots += slot.number + ", ";
    });

    this.slots = this.slots.substring(0, this.slots.length - 2);

    console.log(this.lockers);

    this.redirectTimeout = setTimeout(() => {
      this.store.dispatch(new Navigate(['/core', 'selection-buttons']));
    }, 7000)
  }

  ngOnInit(): void {
  }
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);

    switch (event.key) {
      case "4":
        this.goToStart();
        break;
      case "3":
        this.goToStart();
        break;
      case "2":
        this.goToStart();
        break;
      case "1":
        this.goToStart();
        break;
      case "8":
        this.goToStart();
        break;
      case "7":
        this.goToStart();
        break;
      case "6":
        this.goToStart();
        break;
      case "5":
        this.goToStart();
        break;
    }
  }

  goToStart() {
    clearTimeout(this.redirectTimeout);
    this.store.dispatch(new Navigate(['/core', 'selection-buttons']));
  }
}
