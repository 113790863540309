<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="w-11/12 mx-auto mt-16 mb-12 text-5xl font-bold text-center outline-none text-primaryx-50 focus:none wow fadeInUp"
    data-wow-delay="0"
    data-wow-duration="0.5s"
  >
    <input
      #passwordInput
      type="password"
      autocomplete="new-password"
      placeholder="{{ 'LOGIN_USER.CARD_ID' | translate }}"
      class="w-full h-24 font-normal tracking-widest text-center uppercase bg-white outline-none text-primaryx rounded-xl drop-shadow-xl focus:none"
      [ngClass]="increaseFontSize ? 'text-8xl pb-4' : 'text-4xl'"
      maxlength="10"
      [ngModel]="password"
      (ngModelChange)="onChange($event)"
      (blur)="onBlurKeyboard($event)"
      value="{{ value }}"
      spellcheck="false"
      autofocus
    />
  </div>
  <div
    class="flex flex-row w-11/12 mx-auto wow fadeInUp"
    data-wow-delay="0"
    data-wow-duration="0.5s"
  >
    <div class="simple-keyboard-setup"></div>
  </div>

  <!-- FAILED modal notify -->
  <app-modal-notify
    *ngIf="error"
    [iconname]="'error'"
    [iconCssClass]="'text-red'"
    [useRedirect]="false"
    [theme]="'ski'"
  >
    <p>{{ "LOGIN_USER.LOGIN_FAILED" | translate }}</p>

    <div
      class="flex flex-row items-start self-end justify-between w-10/12 mx-auto mt-10"
    >
      <app-btn-sanusx
        (btnClick)="errorClose()"
        [label]="'COMMON.BTN_BACK' | translate"
        buttonType="neutral-dark"
        [fullwidth]="true"
        class="mx-auto"
      >
      </app-btn-sanusx>
    </div>
  </app-modal-notify>
  <!-- END FAILED modal notify -->
</ng-container>

<ng-container *ngIf="theme === 'default'">
  <div
    class="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-screen bg-gray-light-80 backdrop-blur-sm z-90"
  >
    <div class="w-full px-6">
      <div
        class="flex flex-col justify-between w-full px-10 pb-10 mx-auto bg-white h-97 min-h-97 rounded-4xl wow bounceIn animated"
        data-wow-delay="0s"
        data-wow-duration=".3s"
      >
        <div class="w-full h-auto mb-10">
          <div
            class="flex items-center justify-center w-24 h-24 mx-auto -mt-12 text-white border-white rounded-full bg-primaryx border-10"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="68"
              height="68"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="8" cy="15" r="4" />
              <line x1="10.85" y1="12.15" x2="19" y2="4" />
              <line x1="18" y1="5" x2="20" y2="7" />
              <line x1="15" y1="8" x2="17" y2="10" />
            </svg>
          </div>
        </div>

        <div
          class="w-full mx-auto text-6xl text-center uppercase border-t-0 border-b-8 border-l-0 border-r-0 outline-none text-primaryx-dark border-gray-ultra focus:none"
        >
          <input
            type="password"
            class="text-center uppercase outline-none orangebg focus:none"
            [(ngModel)]="password"
          />
        </div>

        <div
          *ngIf="error"
          class="self-center flex-1 w-full h-auto mx-auto mt-10 text-2xl font-semibold text-center text-red"
        >
          {{ "SETUP.ERROR_PASSWORD" | translate }}
        </div>

        <div
          class="flex flex-row items-start self-end justify-between w-10/12 mx-auto mt-10"
        >
          <button
            class="mx-auto btn btn-action border-gray-light group active:border-active"
            (pointerup)="cancel()"
            [touchClick]
          >
            <span class="icon bg-gray group-active:bg-active">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="49.498"
                height="49.498"
                viewBox="0 0 49.498 49.498"
              >
                <path
                  id="close_icon"
                  data-name="close_icon"
                  d="M-3922.81,2626.037v-12h-12a3,3,0,0,1-3-3,3,3,0,0,1,3-3h12v-11a3,3,0,0,1,3-3,3,3,0,0,1,3,3v11h11a3,3,0,0,1,3,3,3,3,0,0,1-3,3h-11v12a3,3,0,0,1-3,3A3,3,0,0,1-3922.81,2626.037Z"
                  transform="translate(950.191 -4593.964) rotate(-45)"
                  fill="currentColor"
                />
              </svg>
            </span>
            <span class="label text-gray group-active:text-active">
              {{ "COMMON.BTN_CANCEL" | translate }}
            </span>
          </button>
          <button
            class="mx-auto btn btn-action btn-action--success border-primaryx-success active:border-active group"
            (pointerup)="login()"
            [touchClick]
          >
            <span class="icon bg-primaryx-success group-active:bg-active">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28.086"
                height="21.035"
                viewBox="0 0 28.086 21.035"
              >
                <path
                  id="icon_check"
                  data-name="icon_check"
                  d="M27.017-15.018a3.5,3.5,0,0,0,1.069-2.517,3.517,3.517,0,0,0-3.5-3.5,3.5,3.5,0,0,0-2.517,1.069L10.543-8.441,6.017-12.967A3.5,3.5,0,0,0,3.5-14.035a3.517,3.517,0,0,0-3.5,3.5A3.5,3.5,0,0,0,1.069-8.018l7,7a3.517,3.517,0,0,0,4.949,0Z"
                  transform="translate(0 21.035)"
                  fill="currentColor"
                  fill-rule="evenodd"
                />
              </svg>
            </span>
            <span class="label text-primaryx-success group-active:text-active">
              {{ "COMMON.BTN_SIGNIN" | translate }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
