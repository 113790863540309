<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="w-full h-screen flex flex-col items-start justify-start bg-primaryx text-white box-main"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full wow fadeInDown"
    ></app-header>
    <div
      class="w-11/12 mx-auto flex flex-col flex-1 items-center justify-center text-center pt-16"
    >
      <div
        class="w-36 h-36 mb-14 relative flex items-center justify-center overflow-hidden bg-cover bg-no-repeat bg-center"
      >
        <app-svg-stripe [icon]="'outer'" class="z-0"></app-svg-stripe>
        <span class="flex items-center justify-center absolute z-5 w-20 h-20">
          <app-svg-stripe [icon]="'card'" [white]="true"></app-svg-stripe>
        </span>
      </div>
      <h2
        class="w-7/12 mx-auto text-5xl leading-normal font-bold text-white-70 wow fadeInUp"
        data-wow-delay="0"
        data-wow-duration="0.5s"
      >
        {{ "LOGIN_USER.SCAN_CARD" | translate }}
      </h2>

      <div
        class="w-11/12 mt-16 mb-12 mx-auto text-5xl font-bold text-primaryx-50 text-center focus:none outline-none wow fadeInUp"
        data-wow-delay="0"
        data-wow-duration="0.5s"
      >
        <!--  -->

        <ng-container *ngIf="loginFailed">
          <div class="w-full h-auto flex flex-col space-y-2 wow fadeInUp">
            <div
              class="w-20 h-20 flex items-center justify-center mx-auto text-red"
            >
              <app-svg-stripe [icon]="'error'"></app-svg-stripe>
            </div>
            <div class="text-4xl leading-normal font-semibold text-white">
              {{ "LOGIN_USER.CARD_NOT_VALID" | translate }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="w-10/12 mx-auto h-auto flex flex-row space-x-16 items-center justify-between pt-4 pb-14 self-end"
    >
      <!-- <app-btn-sanusx
        (btnClick)="cancel()"
        [label]="'cancel' | translate"
        buttonType="neutral"
        class="wow slideInLeft"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx> -->

      <!-- <app-btn-sanusx
        (btnClick)="loginWithCard()"
        [label]="'SCANNER.login' | translate"
        buttonType="secondary"
        class="wow slideInRight"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>     -->
      <!-- <app-btn-sanusx
        (btnClick)="loginWithCard()"
        [label]="'Anmelden'"
        buttonType="secondary"
        class="wow slideInRight"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx> -->
    </div>
    <!-- <div class="w-full h-6 block bg-white self-end rounded-t-xl wow fadeInUp"></div> -->
  </div>
  <input
    #cardInput
    type="text"
    autocomplete="off"
    placeholder="{{ 'LOGIN.CARD_ID' | translate }}"
    class="w-full text-center text-4xl tracking-widest h-24 text-primaryx font-normal rounded-xl drop-shadow-xl bg-white uppercase focus:none outline-none"
    maxlength="50"
    style="position: 'absolute'; top: '-100px'"
    (keyup.enter)="onEnter()"
    (blur)="onBlur($event)"
    autofocus
  />
</ng-container>

<!-- FAILED modal notify -->
<!-- <app-modal-notify
  *ngIf="loginFailed"
  [iconname]="'error'"
  [iconCssClass]="'text-red'"
  [useRedirect]="false"
>
  <p>{{ "SCANNER.login-failed" | translate }}</p>

  <div
    class="self-end w-10/12 mt-10 flex flex-row justify-between items-start mx-auto"
  >
    <app-btn-sanusx
      (btnClick)="errorClose()"
      [label]="'Zurück'"
      buttonType="neutral-dark"
      [fullwidth]="true"
      class="mx-auto"
    >
    </app-btn-sanusx>
  </div>
</app-modal-notify> -->
<!-- END FAILED modal notify -->
<!-- SUCCESS modal notify -->
<app-modal-notify
  *ngIf="loginSuccess"
  [iconname]="'success'"
  [iconCssClass]="'text-green'"
  [useRedirect]="true"
  [redirectTo]="successRedirectTo"
  [redirectAfter]="1.5"
>
  <p>
    {{ "LOGIN_USER.CODE_CORRECT" | translate }}
  </p>
</app-modal-notify>
<!-- END SUCCESS modal notify -->

<div
  class="w-screen h-screen bg-white-80 backdrop-blur-sm fixed z-90 top-0 left-0 flex flex-col items-center justify-center wow fadeIn animated"
  data-wow-delay="0s"
  data-wow-duration="0.2s"
  *ngIf="loaderActive"
>
  <div
    id="loader-sanusx"
    class="flex flex-grow flex-col items-center justify-center text-primaryx-50 relative z-20"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 40 40">
      <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

      <circle cx="16" cy="16" r="15.9155" class="progress-bar__progress" />
    </svg>
    <h3 class="uppercase tracking-wider my-10 text-gray font-semibold">
      {{ "COMMON.PLEASE_WAIT" | translate }}
    </h3>
  </div>
</div>
