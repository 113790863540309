import { Component, OnDestroy, OnInit, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MachineError } from 'src/app/core/models/error';
import { Core } from 'src/app/core/state/core.actions';
import {
  CoreState,
  CoreStateModel,
  ModuleType,
  SlotType,
} from 'src/app/core/state/core.state';
import { LockerHttpService } from 'src/app/locker/services/locker-http.service';
import { Locker } from 'src/app/locker/state/locker.actions';
import { Retailer } from 'src/app/retailer/state/retailer.actions';

@Component({
  selector: 'app-locker-preview',
  templateUrl: './locker-preview.component.html',
  styleUrls: ['./locker-preview.component.scss'],
})
export class LockerPreviewComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'w-full';
  public theme: string;

  public selected: number = null;
  public lockers: Array<any> = [];
  public loaderActive: boolean = false;

  deliveryAction: string;
  deliveryId: number;
  deliveryPositionId: number;
  deliveryPosition: any;
  selectedSlotForLoad: any;
  description: string;
  reopenOption: boolean = false;
  completeButtonTitle = 'COMMON.BTN_SUBMIT';
  lockerColClass: string;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private idle: Idle,
    private lockerHttpSerice: LockerHttpService
  ) {
    this.theme = localStorage.getItem('theme') ?? 'default';
    this.route.params.subscribe((params) => {
      this.deliveryAction = params.action;
      this.deliveryId = params.deliveryId;
      this.deliveryPositionId = params.deliveryPositionId;
    });
  }

  showSlotsForLoading() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;
    if (activeUser.deliveriesToLoad) {
      activeUser.deliveriesToLoad.forEach((delivery) => {
        if (delivery.id == this.deliveryId) {
          delivery.positions.forEach((deliveryPosition) => {
            if (deliveryPosition.id == this.deliveryPositionId) {
              this.deliveryPosition = deliveryPosition;
              this.description = this.deliveryPosition.description;
            }
          });
        }
      });
    }

    let containerSlots =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser
        .containerSlots;

    this.lockers.forEach((locker) => {
      containerSlots.forEach((containerSlot) => {
        if (locker.index == containerSlot.slotIndex) {
          locker.isSlotFree =
            containerSlot.isSlotFree &&
            (!containerSlot.slotType ||
              containerSlot.slotType == SlotType.ORDER);
          locker.containerCode = +containerSlot.containerCode;
          locker.id = containerSlot.slotId;
        }
      });
    });
  }

  showSlotsForDropOff() {
    let containerSlots =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser
        .containerSlots;
    this.lockers.forEach((locker) => {
      containerSlots.forEach((containerSlot) => {
        if (locker.index == containerSlot.slotIndex) {
          locker.isSlotFree =
            containerSlot.isSlotFree &&
            (!containerSlot.slotType ||
              containerSlot.slotType == SlotType.DROPOFF);
          locker.id = containerSlot.slotId;
          locker.containerCode = +containerSlot.containerCode;
        }
      });
    });
  }

  selectTakeDropoffSlot() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;
    if (activeUser.dropoffDeliveries) {
      activeUser.dropoffDeliveries.forEach((delivery) => {
        if (delivery.id == this.deliveryId) {
          delivery.positions.forEach((deliveryPosition) => {
            if (deliveryPosition.id == this.deliveryPositionId) {
              this.deliveryPosition = deliveryPosition;
              this.description = this.deliveryPosition.description;
            }
          });
        }
      });
    }

    this.lockers.forEach((locker) => {
      if (locker.index == this.deliveryPosition.slotIndex) {
        locker.selected = true;
      }
    });
  }

  cancel(): void {
    this.store.dispatch(new Retailer.ActionSelection());
  }

  selectPickupSlot() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;
    if (activeUser.pickupDeliveries) {
      activeUser.pickupDeliveries.forEach((delivery) => {
        if (delivery.id == this.deliveryId) {
          delivery.positions.forEach((deliveryPosition) => {
            if (deliveryPosition.id == this.deliveryPositionId) {
              this.deliveryPosition = deliveryPosition;
              this.description = this.deliveryPosition.description;
            }
          });
        }
      });
    }

    this.lockers.forEach((locker) => {
      if (locker.index == this.deliveryPosition.slotIndex) {
        locker.selected = true;
      }
    });
  }

  selectSlotForRentStart() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;
    if (activeUser.rentSlots) {
      activeUser.rentSlots.forEach((delivery) => {
        if (delivery.id == this.deliveryId) {
          delivery.positions.forEach((deliveryPosition) => {
            if (deliveryPosition.id == this.deliveryPositionId) {
              this.deliveryPosition = deliveryPosition;
              this.description = this.deliveryPosition.description;
            }
          });
        }
      });
    }

    this.lockers.forEach((locker) => {
      if (locker.index == this.deliveryPosition.slotIndex) {
        locker.selected = true;
      }
    });
  }

  selectSlotForRentFinish() {
    let activeUser =
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser;
    if (activeUser.activeRents) {
      activeUser.activeRents.forEach((delivery) => {
        if (delivery.id == this.deliveryId) {
          delivery.positions.forEach((deliveryPosition) => {
            if (deliveryPosition.id == this.deliveryPositionId) {
              this.deliveryPosition = deliveryPosition;
              this.description = this.deliveryPosition.description;
            }
          });
        }
      });
    }

    this.lockers.forEach((locker) => {
      if (locker.index == this.deliveryPosition.slotIndex) {
        locker.selected = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  ngOnInit(): void {
    this.idle.watch();

    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.lockerColClass = this.getColClass(
        this.store.selectSnapshot<CoreStateModel>(CoreState).config
          .lockerColumns
      );
      this.lockers = JSON.parse(
        JSON.stringify(
          this.store.selectSnapshot<CoreStateModel>(CoreState).config.lockers
        )
      );
    }

    if (this.deliveryAction == 'load') {
      this.showSlotsForLoading();
    } else if (this.deliveryAction == 'dropoff') {
      this.showSlotsForDropOff();
    } else if (this.deliveryAction == 'take-dropoff') {
      this.selectTakeDropoffSlot();
    } else if (this.deliveryAction == 'start-rent') {
      this.selectSlotForRentStart();
    } else if (this.deliveryAction == 'finish-rent') {
      this.reopenOption = true;
      this.completeButtonTitle = 'LOCKER_RENT_OB.FINISH_RENT';
      this.selectSlotForRentFinish();
    } else {
      this.selectPickupSlot();
    }
  }

  selectSlot(slot: any) {
    if (slot.isSlotFree) {
      this.selectedSlotForLoad = slot;

      this.lockers.forEach((locker) => {
        if (locker.index == slot.index) {
          locker.activated = true;
        } else {
          locker.activated = false;
        }
      });
    }
  }

  reopenLocker() {
    this.selected = this.deliveryPosition.slotIndex;
    let containerCode = this.deliveryPosition.containerCode;
    this.store
      .dispatch(new Locker.OpenLocker(this.selected, containerCode))
      .subscribe(
        (x) => {
          this.loaderActive = false;
          this.store.dispatch(
            new Navigate([
              '/locker/delivery',
              'release',
              'reopen-rent',
              this.deliveryId,
              this.deliveryPosition.id,
              this.selectedSlotForLoad ? this.selectedSlotForLoad.id : 0,
              this.selected,
              '',
            ])
          );
        },
        (error: MachineError) => {
          this.loaderActive = false;
          this.store
            .dispatch(new Core.Error(error, ModuleType.RETAILER))
            .subscribe(() => {
              setTimeout(() => {
                this.store.dispatch(new Navigate(['/']));
              }, 10000);
            });
        }
      );
  }

  openLocker() {
    console.log('selected slot', this.selectedSlotForLoad);
    console.log(
      'user data',
      this.store.selectSnapshot<CoreStateModel>(CoreState).activeUser
    );
    let containerCode: any;
    if (
      this.deliveryAction == 'dropoff' ||
      this.deliveryAction == 'finish-rent'
    ) {
      if (this.selectedSlotForLoad || this.deliveryAction == 'finish-rent') {
        this.store.dispatch(
          new Navigate([
            '/locker',
            'delivery',
            'locker-message',
            this.deliveryAction,
            this.deliveryId,
            this.selectedSlotForLoad
              ? this.selectedSlotForLoad.id
              : this.deliveryPosition.id,
            this.selectedSlotForLoad
              ? this.selectedSlotForLoad.index
              : this.deliveryPosition.slotIndex,
            this.selectedSlotForLoad
              ? this.selectedSlotForLoad.containerCode
              : this.deliveryPosition.containerCode,
          ])
        );
      }

      return;
    }

    if (this.deliveryAction == 'load') {
      if (!this.selectedSlotForLoad) {
        return;
      }
      this.selected = this.selectedSlotForLoad.index;
      containerCode = this.selectedSlotForLoad.containerCode;
    } else if (this.deliveryAction == 'take-dropoff') {
      this.selected = this.deliveryPosition.slotIndex;
      containerCode = this.deliveryPosition.containerCode;
    } else {
      this.selected = this.deliveryPosition.slotIndex;
      containerCode = this.deliveryPosition.containerCode;
    }

    this.loaderActive = true;

    this.store
      .dispatch(new Locker.OpenLocker(this.selected, containerCode))
      .subscribe(
        (x) => {
          this.loaderActive = false;
          this.store.dispatch(
            new Navigate([
              '/locker/delivery',
              'release',
              this.deliveryAction,
              this.deliveryId,
              this.deliveryPosition.id,
              this.selectedSlotForLoad ? this.selectedSlotForLoad.id : 0,
              this.selected,
              '',
            ])
          );
        },
        (error: MachineError) => {
          if (error.errorNumber == 1 && this.deliveryAction == 'start-rent') {
            this.lockerHttpSerice
              .blockLockerOb(this.deliveryPosition, error.message)
              .subscribe(() => {
                this.loaderActive = false;
                this.store
                  .dispatch(new Core.Error(error, ModuleType.RETAILER))
                  .subscribe(() => {
                    this.store.dispatch(new Core.ReloadLockerData()).pipe;
                    setTimeout(() => {
                      this.store.dispatch(
                        new Navigate([
                          '/locker',
                          'delivery',
                          'deliveries',
                          'start-rent',
                        ])
                      );
                    }, 5000);
                  });
              });
          } else {
            this.loaderActive = false;
            this.store
              .dispatch(new Core.Error(error, ModuleType.RETAILER))
              .subscribe(() => {
                setTimeout(() => {
                  this.store.dispatch(new Navigate(['/']));
                }, 10000);
              });
          }
        }
      );
  }

  getSlotClass(item: any) {
    if (this.deliveryAction == 'load' || this.deliveryAction == 'dropoff') {
      if (item.activated) {
        return 'locker-inner--activated bg-active';
      }

      return item.isSlotFree ? 'locker-inner--free' : '';
    } else {
      return item.selected ? 'locker-inner--selected' : '';
    }
  }

  getColClass(lockerColumns: number): string {
    return 'grid-cols-custom-' + lockerColumns;
  }
}
