import { Component, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-print-bill',
  templateUrl: './print-bill.component.html',
  styleUrls: ['./print-bill.component.scss']
})
export class PrintBillComponent implements OnInit {
  theme: string;
  source: string;

  constructor(
    private store: Store,
    private route: ActivatedRoute) {
    this.theme = localStorage.getItem('theme') ?? 'default';

    this.route.params.subscribe(params => {
      this.source = params.source;
    });
  }

  ngOnInit(): void {
  }

  printConfirm() {
    console.warn('PRINT bill');
    // this.store.dispatch(new Navigate(['/retailer', 'retrieve-wwks', this.source]));
  }
  printSkip() {
    console.warn('SKIP Printing bill');
    // this.store.dispatch(new Navigate(['/retailer', 'retrieve-wwks', this.source]));
  }


}
