import { Component, OnInit, HostBinding } from '@angular/core';
import { arrayBuffer } from 'stream/consumers';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { GlobalEnums } from '../../../state/core.state';
import { LockerSkiHttpService } from 'src/app/locker/services/locker-ski-http.service';
import { LockerSki } from 'src/app/locker/state/locker-ski.actions';

@Component({
  selector: 'app-setup-locker-selection',
  templateUrl: './setup-locker-selection.component.html',
  styleUrls: ['./setup-locker-selection.component.scss']
})
export class SetupLockerSelectionComponent implements OnInit {
  @HostBinding('class') class = 'w-full h-full flex flex-col items-center';
  theme: string;
  activeRents: any[] = [];

  lockerLimit = GlobalEnums.skiLockerHoursLimit;

  constructor(private store: Store,
    private lockerHttpService: LockerSkiHttpService) {
    this.theme = localStorage.getItem('theme') ?? 'default';
  }

  ngOnInit(): void {
    this.lockerHttpService.getMachingeData().subscribe({
      next: (result) => {
        let currentTime = new Date();

        let rents = result.active_rents;
        if (rents && rents.length > 0) {
          rents.forEach(rent => {
            this.activeRents.push({
              rentedHours: this.calculateRentedHours(currentTime, rent.start_date),
              trayNumbers: this.getTrayNumbers(rent.rent_items),
              cardNumber: this.getCardNumber(rent.transaction_data),
              originalRent: rent
            })
          });
        }
      },
      error: (e) => console.error(e)
    });
  }

  getCardNumber(transactionData: any) {
    if (transactionData && transactionData.maskedcard) {
      return transactionData.maskedcard.substr(-4);
    } else {
      return '';
    }
  }

  getTrayNumbers(rentItems: any) {
    if (rentItems && rentItems.length > 0) {
      let result = null;

      rentItems.forEach(rentItem => {
        if (rentItem.locker_rent_item_status_id == 1) {
          if (result == null) { result = ''; } else { result += ', ' }

          result += rentItem.slot_index;
        }
      });

      return result;
    } else {
      return '';
    }
  }

  calculateRentedHours(currentTime: Date, startDate: any) {
    let startDateParsed = new Date(startDate);

    return Math.ceil(Math.abs(currentTime.getTime() - startDateParsed.getTime()) / 36e5);
  }

  selectItem(item: any): void {
    let rentForCurrentCard: any = item.originalRent;

    this.store.dispatch(new LockerSki.ManualReclaim(rentForCurrentCard));    
  }
}
