import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';

import { Locker } from '../../../state/locker.actions';
import { LockerState, LockerStateModel } from '../../../state/locker.state';
import { Idle } from '@ng-idle/core';
import Keyboard from 'simple-keyboard';
import { ControlService } from 'src/app/core/services/control.service';
import { JsonPipe } from '@angular/common';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';

@Component({
  selector: 'ft-start-scanner',
  templateUrl: './start-scanner.component.html',
  styleUrls: ['./start-scanner.component.scss'],
})
export class RentStartScannerComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  fullHdLandscape: boolean = false;

  public keyboard: Keyboard;
  public value: string = '';
  public submitted = false;
  public loginFailed = false;
  public error: string;
  public loaderActive: boolean = false;
  public code: string = '';
  public theme: string;
  @ViewChild('cardInput', { read: ElementRef }) cardInput: ElementRef;

  constructor(
    private store: Store,
    private idle: Idle,
    private controlService: ControlService
  ) {
    this.theme = localStorage.getItem('theme') ?? 'default';

    this.fullHdLandscape =
      JSON.parse(localStorage.getItem('fullHdLandscape')) ?? true;

    console.warn('this.fullHdLandscape', this.fullHdLandscape);
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  ngAfterViewInit() {
    setTimeout(() => this.cardInput.nativeElement.focus(), 1000);

    this.keyboard = new Keyboard('.simple-keyboard-pickup', {
      onChange: (input) => this.onChange(input),
      // onKeyPress: button => this.onKeyPress(button)
    });

    this.keyboard.setOptions({
      physicalKeyboardHighlightPress: true,
      physicalKeyboardHighlight: true,
      layout: {
        default: [
          '1 2 3 4 5 6 7 8 9 0 ß',
          'Q W E R T Z U I O P Ü',
          'A S D F G H J K L Ö Ä',
          'Y X C V B N M {space} {bksp}',
        ],
        shift: [
          '1 2 3 4 5 6 7 8 9 0 ß',
          'Q W E R T Z U I O P Ü',
          'A S D F G H J K L Ö Ä',
          'Y X C V B N M {space} {bksp}',
        ],
      },
      display: {
        '{bksp}': '    ',
        '{space}': 'SPACE',
        ß: '   ',
      },
      buttonTheme: [
        {
          class: 'backspace-white-letter',
          buttons: '{bksp}',
        },
        {
          class: 'ss-white-letter',
          buttons: 'ß',
        },
        {
          class: 'space-white-letter',
          buttons: '{space}',
        },
      ],
    });
  }

  onBlurKeyboard(event) {
    this.cardInput.nativeElement.focus();
  }

  onChange = (input: string) => {
    this.value = input;
  };

  public loginWithCard(): void {
    this.loaderActive = !this.loaderActive;

    this.store.dispatch(new Locker.CheckRentCode(this.value)).subscribe(
      () => {
        const order =
          this.store.selectSnapshot<LockerStateModel>(LockerState).order;
        const rentAction =
          this.store.selectSnapshot<LockerStateModel>(LockerState).rentAction;

        if (order === null) {
          this.controlService
            .log(
              'ERROR',
              'Rent - loginWithCard - Incorrect code: ' + this.value
            )
            .subscribe();

          this.showError('Incorrect code');
          return;
        }

        if (rentAction == 'take') {
          let orderFrom: Date = this.getDateUtc(order.dateFrom);
          let orderTo: Date = this.getDateUtc(order.dateTo);
          let currentDate = new Date();

          if (currentDate < orderFrom || currentDate > orderTo) {
            this.controlService
              .log(
                'ERROR',
                'Rent - loginWithCard - Incorrect dates: ' +
                  JSON.stringify(order)
              )
              .subscribe();

            this.showError('Incorrect dates');
            return;
          }
        }

        this.loaderActive = false;

        this.controlService
          .log(
            'INFO',
            'Rent - loginWithCard - correct: ' + JSON.stringify(order)
          )
          .subscribe();

        this.store.dispatch(new Navigate(['/locker', 'rent', 'code-correct']));
      },
      (error: any) => {
        this.controlService
          .log(
            'ERROR',
            'Rent - loginWithCard - system error: ' + JSON.stringify(error)
          )
          .subscribe();

        this.showError('system error');
      }
    );
  }

  private showError(errorMessage: string) {
    this.loaderActive = false;
    this.loginFailed = true;
    this.value = '';
    this.keyboard.setInput('');
  }

  onEnter(): void {
    this.loginWithCard();
  }

  cancel(): void {
    this.store.dispatch(new Locker.ResetRentProcess());
  }

  errorClose(): void {
    this.loaderActive = false;
    this.loginFailed = false;
    this.error = '';
    this.code = '';
    this.value = '';
    this.keyboard.setInput('');
    this.cardInput.nativeElement.focus();
  }

  getDateUtc(dateString: any) {
    let apiVersion =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    if (apiVersion == 2) {
      return new Date(dateString);
    } else {
      return new Date(dateString + 'z');
    }
  }
}
