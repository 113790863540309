import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RetailerState } from './state/retailer.state';
import { NgxsModule } from '@ngxs/store';

import { RetailerSelectionComponent } from './components/retailer-selection/retailer-selection.component';
import { SharedModule } from '../shared/shared.module';
import { ProductComponent } from './components/product/product.component';
import { RouterModule, Routes } from '@angular/router';
import { PaymentComponent } from './components/payment/payment.component';
import { ReleaseComponent } from './components/release/release.component';
import { FinishOrderComponent } from './components/finish-order/finish-order.component';
import { FinishOrderConfirmComponent } from './components/finish-order-confirm/finish-order-confirm.component';
import { PickupComponent } from './components/pickup/pickup.component';
import { FormsModule } from '@angular/forms';
import { RetrieveComponent } from './components/retrieve/retrieve.component';
import { RetrieveWwksComponent } from './components/retrieve-wwks/retrieve-wwks.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { PickupDetailsComponent } from './components/pickup-details/pickup-details.component';
import { ProductInfoComponent } from './components/product-info/product-info.component';
import { CartComponent } from './components/cart/cart.component';
import { TotalSelectionComponent } from './components/total-selection/total-selection.component';
import { CategoriesSelectionComponent } from './components/categories-selection/categories-selection.component';
import { PrintBillComponent } from './components/print-bill/print-bill.component';
import { LockerState } from '../locker/state/locker.state';

export const routes: Routes = [
  {
    path: 'product/:id',
    component: ProductComponent,
  },
  {
    path: 'payment',
    component: PaymentComponent,
  },
  {
    path: 'release',
    component: ReleaseComponent,
  },
  {
    path: 'retrieve',
    component: RetrieveComponent,
  },
  {
    path: 'retrieve-wwks/:source',
    component: RetrieveWwksComponent,
  },
  {
    path: 'finish',
    component: FinishOrderComponent,
  },
  {
    path: 'finish-confirm/:source/:productsTaken',
    component: FinishOrderConfirmComponent,
  },
  {
    path: 'pickup',
    component: PickupComponent,
  },
  {
    path: 'pickup-detail',
    component: PickupDetailsComponent,
  },
  {
    path: 'selection',
    component: RetailerSelectionComponent,
  },
  {
    path: 'categories',
    component: CategoriesSelectionComponent,
  },
  {
    path: 'cart',
    component: CartComponent,
  },
  {
    path: 'info',
    component: ProductInfoComponent,
  },
  {
    path: 'total-selection',
    component: TotalSelectionComponent,
  },
  {
    path: 'print-bill',
    component: PrintBillComponent,
  },
];

@NgModule({
  declarations: [
    RetailerSelectionComponent,
    ProductComponent,
    PaymentComponent,
    ReleaseComponent,
    FinishOrderComponent,
    FinishOrderConfirmComponent,
    PickupComponent,
    RetrieveComponent,
    RetrieveWwksComponent,
    PickupDetailsComponent,
    ProductInfoComponent,
    CartComponent,
    TotalSelectionComponent,
    CategoriesSelectionComponent,
    PrintBillComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgxsModule.forFeature([RetailerState, LockerState]),
    FormsModule,
    NgImageSliderModule,
    SharedModule,
  ],
  exports: [RetailerSelectionComponent],
})
export class RetailerModule {
  constructor() {}
}
