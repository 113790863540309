import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';
import { environment } from 'src/environments/environment';

interface CachedImage {
  filename: string;
  blob: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private httpClient: HttpClient, private store: Store) { }

  private _cachedImages: CachedImage[] = [];

  getImage(filename: string): Observable<string> {
    const index = this._cachedImages.findIndex(image => image.filename === filename);

    if (index > -1) { 
      const image = this._cachedImages[index];
      return of(image.blob);
    }

    let apiVersion = this.store.selectSnapshot<CoreStateModel>(CoreState).config.apiVersion;

    let url: string;

    if (apiVersion == 2) {
      url = environment.configApi + `/machine/images/` + filename;
    } else {
      url = environment.imageApi + filename;
    }

    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      map(blob => {
        if(blob.type == "text/plain") {
          return null;
        } else {
          return URL.createObjectURL(blob);
        }        
      })
    ).pipe(      
      tap(blob => {
        if(blob != null) {
          this._cachedImages.push({ filename, blob });
        }        
      })
    );
  }
}
