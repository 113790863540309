import { EventEmitter, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { TokenState } from "../state/token.state";

export interface Message {
    source: string;
    content: string;
}

@Injectable()
export class WebsocketService extends EventEmitter {
    private subject: AnonymousSubject<MessageEvent>;

    constructor(private store: Store) {
        super();
    }

    public connect() {
        const token = this.store.selectSnapshot<any>(TokenState.get('token'));

        if (!this.subject) {
            let url = environment.websocketApi + '/ws/' + token;
            this.subject = this.create(url);
        }

        this.subject.pipe(
            map(
                (response: MessageEvent): Message => {
                    return JSON.parse(response.data);
                }
            )
        ).subscribe({
            next: (mesage) => {
                this.emit(mesage);
            },
            error: (message) => console.log(message)
        });
    }

    public sendMessage(command: string, messageData: any) {
        if (this.subject) {
            let message: any = {
                command: command,
                data: messageData
            };

            this.subject.next(message);
        } else {
            console.log('No WS connection');
        }
    }

    private create(url): AnonymousSubject<MessageEvent> {
        let ws = new WebSocket(url);
        let observable = new Observable((obs: Observer<MessageEvent>) => {
            ws.onmessage = obs.next.bind(obs);
            ws.onerror = obs.error.bind(obs);
            ws.onclose = (e) => {
                if (e.wasClean) {
                    console.log('wasClean');
                    obs.complete();
                } else {
                    console.log('!wasClean');
                    console.log(e);
                    obs.complete();
                }

                this.subject = null;

                setTimeout(x => {
                    console.log('Reconnecting');
                    this.connect();
                }, 15000);
            };
            //

            return ws.close.bind(ws);
        });

        let observer = {
            error: null,
            complete: null,
            next: (data: Object) => {
                if (ws.readyState === WebSocket.OPEN) {
                    console.log('Message sent to websocket: ', data);
                    ws.send(JSON.stringify(data));
                } else {
                    console.log('Socket closed');
                }
            }
        };

        return new AnonymousSubject<MessageEvent>(observer, observable);
    }
}