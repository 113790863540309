<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen text-white bg-primaryx"
  >
    <app-header [showSearch]="false" class="w-full"></app-header>
    <div
      class="flex flex-col items-center justify-center flex-1 w-full text-center px-27"
    >
      <div
        class="flex flex-col items-center justify-center w-full h-auto mx-auto mb-10 text-white wow pulse animated"
        data-wow-delay="0"
        data-wow-duration="1.5s"
        data-wow-iteration="2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="211.825"
          height="189.363"
          viewBox="0 0 211.825 189.363"
        >
          <g
            id="Group_7449"
            data-name="Group 7449"
            transform="translate(0 115.902)"
          >
            <path
              id="Path_13622"
              data-name="Path 13622"
              d="M211.43,22.556l-17.92-99.1c0-.025-.011-.051-.015-.074V-95.966a23.569,23.569,0,0,0-2.4-9.729c-3.291-6.581-9.515-10.206-17.529-10.206H19.934a23.569,23.569,0,0,0-9.728,2.4C3.625-110.206,0-103.98,0-95.966V4.741a23.569,23.569,0,0,0,2.4,9.728A17.808,17.808,0,0,0,15.09,24.182l5.932,32.805c1.031,5.7,6.3,16.472,18.838,16.474h0a24.554,24.554,0,0,0,4.325-.4L195.361,45.72a23.563,23.563,0,0,0,9.145-4.1c5.892-4.41,8.35-11.182,6.924-19.068M9.058-95.966c0-4.5,1.629-7.55,4.98-9.318a14.814,14.814,0,0,1,5.9-1.56H173.561c4.5,0,7.55,1.629,9.316,4.98a14.813,14.813,0,0,1,1.56,5.9v13.443H9.058Zm1.56,106.6a14.782,14.782,0,0,1-1.56-5.9V-59.094H184.437V4.741c0,4.5-1.629,7.548-4.98,9.316a14.781,14.781,0,0,1-5.919,1.56H19.934c-4.5,0-7.548-1.629-9.316-4.98M199.274,34.222a14.771,14.771,0,0,1-5.526,2.585L42.576,64.143a15.4,15.4,0,0,1-2.714.26h0c-7.934,0-9.749-8.13-9.924-9.027l-5.552-30.7H173.561a23.54,23.54,0,0,0,9.728-2.4C189.87,18.98,193.5,12.755,193.5,4.741V-25.72l9.02,49.887c.8,4.431-.258,7.72-3.242,10.055"
              fill="currentColor"
            />
          </g>
        </svg>
      </div>

      <h1 class="font-bold">
        {{ "RETAILER_PAYMENT.TITLE" | translate }}
      </h1>

      <h2 class="mx-auto mt-2">
        {{ "RETAILER_PAYMENT.MESSAGE" | translate }}
      </h2>
    </div>
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'default'">
  <div class="flex flex-col items-start justify-between w-full h-screen">
    <div
      class="flex flex-row justify-center first-block bg-primaryx-bg wow bounceInDown animated"
      data-wow-delay="0"
      data-wow-duration="1s"
    >
      <div
        class="absolute bottom-0 flex flex-col items-center justify-end w-full h-full -mb-16"
      >
        <app-cloud
          class="flex flex-col justify-end w-full h-full"
          [ngClass]="fullHdHalf ? '-mb-40' : fullHd ? ' -mb-36' : ' -mb-24'"
        ></app-cloud>
        <div
          class="absolute flex flex-col items-center justify-center w-64 h-64 mx-auto -ml-6 bg-white rounded-full drop-shadow-xl text-primaryx"
          [ngClass]="
            fullHdHalf
              ? 'mb-4 -ml-6'
              : fullHd
              ? ' ml-[-30px] mb-[-4px]'
              : '-ml-6 -mb-12 '
          "
          style="border-radius: 9999px !important"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50%"
            height="50%"
            viewBox="0 0 135.07 124.263"
            class="wow pulse animated"
            data-wow-delay="1s"
            data-wow-duration="2s"
            data-wow-iteration="5"
          >
            <g
              id="Group_7291"
              data-name="Group 7291"
              transform="translate(0 46)"
            >
              <path
                id="Path_13538"
                data-name="Path 13538"
                d="M126.965,18.833V5.272a21.547,21.547,0,0,0-21.557-21.557H21.557a8.1,8.1,0,0,1-8.045-8.48,8.313,8.313,0,0,1,8.442-7.729h83.454a8.037,8.037,0,0,1,7.121,4.347,6.829,6.829,0,0,0,6.062,3.736,6.789,6.789,0,0,0,5.97-9.938A21.545,21.545,0,0,0,105.408-46H21.557A21.558,21.558,0,0,0,0-24.443V56.706A21.547,21.547,0,0,0,21.557,78.263h83.851a21.547,21.547,0,0,0,21.557-21.557V43.145H113.458V56.706a8.07,8.07,0,0,1-8.05,8.05H21.557a8.07,8.07,0,0,1-8.05-8.05V-4.348a21.445,21.445,0,0,0,8.05,1.569h83.851a8.07,8.07,0,0,1,8.05,8.05V18.833Z"
                fill="#4a5568"
              />
              <path
                id="Path_13539"
                data-name="Path 13539"
                d="M77.538-25H38.592A11.592,11.592,0,0,0,27-13.406V3.926A11.592,11.592,0,0,0,38.592,15.521H77.538A11.594,11.594,0,0,0,89.132,3.926V-13.406A11.594,11.594,0,0,0,77.538-25M75.625,2.014H40.507V-11.493H75.625Z"
                transform="translate(45.938 35.729)"
                fill="currentColor"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center text-center middle-block">
      <div
        class="w-9/12 mx-auto wow fadeIn animated"
        data-wow-delay="0.5s"
        data-wow-duration="1s"
      >
        <h1
          class="self-end mx-auto font-bold text-center title text-primaryx-dark"
        >
          {{ "RETAILER_PAYMENT.TITLE" | translate }}
        </h1>
        <h2 class="self-end mx-auto text-center title text-primaryx-dark">
          {{ "RETAILER_PAYMENT.MESSAGE" | translate }}
        </h2>
      </div>
    </div>

    <div
      class="flex flex-col items-start justify-start third-block bg-primaryx-bg wow bounceInUp animated"
      data-wow-delay="0"
      data-wow-duration="1s"
    ></div>
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'nexus'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen px-6 bg-white"
  >
    <!-- infowindow header -->
    <div
      class="flex items-center min-h-[30%] max-h-[30%] overflow-hidden justify-center w-full px-8 py-18 bg-nex-gray-light rounded-b-4xl relative z-10 wow bounceInDown animated"
      data-wow-delay="0ms"
      data-wow-duration=".4s"
    >
      <!-- sign -->
      <div
        class="flex items-center justify-center mx-auto rounded-full w-80 h-80 bg-white-20 wow bounceIn animated"
        data-wow-delay="0"
        data-wow-duration="1s"
      >
        <div
          class="flex items-center justify-center w-64 h-64 rounded-full bg-white-40 wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            class="flex flex-col items-center justify-center w-48 h-48 mx-auto bg-white rounded-full text-primaryx wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <svg
              width="50%"
              height="50%"
              viewBox="0 0 200 200"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="wow pulse animated"
              data-wow-delay="1s"
              data-wow-duration="2s"
              data-wow-iteration="5"
            >
              <path
                d="M46.804 23.2194C21.4956 25.4336 2.77404 47.7451 4.98824 73.0535L14.3184 179.698C14.7741 184.906 17.2803 189.721 21.2856 193.081C25.2909 196.442 30.4672 198.074 35.6759 197.619L162.835 186.494C168.043 186.038 172.858 183.532 176.218 179.526C179.579 175.521 181.211 170.345 180.756 165.136L179.706 153.141L173.787 85.4817L172.737 73.4863C172.282 68.2776 169.775 63.4633 165.77 60.1025C161.765 56.7417 156.588 55.1095 151.38 55.5653L148.581 55.8101C148.33 55.8559 148.074 55.8904 147.815 55.9131L48.5176 64.6005C43.9982 64.9959 40.0141 61.6528 39.6187 57.1334C39.2233 52.6141 42.5664 48.6299 47.0858 48.2345L140.628 40.0506L139.027 21.7474C138.71 18.132 135.523 15.4574 131.908 15.7738L46.804 23.2194Z"
                fill="#0074C2"
              />
              <path
                d="M182.429 101.217L136.604 105.226C132.988 105.542 130.285 108.405 130.566 111.62L132.604 134.905C132.885 138.12 136.044 140.469 139.659 140.153L185.484 136.144C189.1 135.828 191.803 132.965 191.521 129.75L189.484 106.465C189.203 103.25 186.044 100.901 182.429 101.217Z"
                fill="#B2BEC3"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M142.06 56.4165L140.628 40.0504L47.0858 48.2343C42.5664 48.6297 39.2233 52.6139 39.6187 57.1332C40.014 61.6526 43.9982 64.9957 48.5176 64.6003L142.06 56.4165Z"
                fill="#B2BEC3"
              />
            </svg>
          </div>
        </div>
      </div>
      <!-- end sign -->
    </div>
    <!-- end infowindow header -->

    <!-- infowindow msg part -->
    <div
      class="flex flex-col vert-shadow items-center justify-center flex-1 w-11/12 mx-auto pb-24 pt-28 px-8 h-auto bg-nex-gray-light-ultra relative -top-12 z-5 rounded-b-4xl wow bounceInDown animated"
      data-wow-delay="200ms"
      data-wow-duration=".4s"
    >
      <!-- msg -->
      <div
        class="w-full h-auto flex flex-col items-center justify-center wow fadeIn animated"
        data-wow-delay="1s"
        data-wow-duration="1s"
      >
        <h1
          class="text-6xl font-bold text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="100ms"
          data-wow-duration="1s"
        >
          {{ "RETAILER_PAYMENT.TITLE" | translate }}
        </h1>

        <h2
          class="text-4xl font-medium text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="10ms"
          data-wow-duration="1s"
        >
          {{ "RETAILER_PAYMENT.MESSAGE" | translate }}
        </h2>
      </div>
      <!-- end msg -->
    </div>
    <!-- end infowindow msg part -->

    <!-- infowindow empty space at the bottom -->
    <div class="w-full h-52 block"></div>
    <!-- end infowindow empty space at the bottom -->
  </div>
  <div
    class="hidden xflex flex-col items-start justify-between w-full h-screen"
  >
    <div
      class="flex flex-row justify-center first-block bg-primaryx-bg wow bounceInDown animated"
      data-wow-delay="0"
      data-wow-duration="1s"
    >
      <div
        class="absolute bottom-0 flex flex-col items-center justify-end w-full h-full -mb-16"
      >
        <app-cloud
          class="flex flex-col justify-end w-full h-full"
          [ngClass]="fullHdHalf ? '-mb-40' : fullHd ? ' -mb-36' : ' -mb-24'"
        ></app-cloud>
        <div
          class="absolute flex flex-col items-center justify-center w-64 h-64 mx-auto -ml-6 bg-white rounded-full drop-shadow-xl text-primaryx"
          [ngClass]="
            fullHdHalf
              ? 'mb-4 -ml-6'
              : fullHd
              ? ' ml-[-30px] mb-[-4px]'
              : '-ml-6 -mb-12 '
          "
          style="border-radius: 9999px !important"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50%"
            height="50%"
            viewBox="0 0 135.07 124.263"
            class="wow pulse animated"
            data-wow-delay="1s"
            data-wow-duration="2s"
            data-wow-iteration="5"
          >
            <g
              id="Group_7291"
              data-name="Group 7291"
              transform="translate(0 46)"
            >
              <path
                id="Path_13538"
                data-name="Path 13538"
                d="M126.965,18.833V5.272a21.547,21.547,0,0,0-21.557-21.557H21.557a8.1,8.1,0,0,1-8.045-8.48,8.313,8.313,0,0,1,8.442-7.729h83.454a8.037,8.037,0,0,1,7.121,4.347,6.829,6.829,0,0,0,6.062,3.736,6.789,6.789,0,0,0,5.97-9.938A21.545,21.545,0,0,0,105.408-46H21.557A21.558,21.558,0,0,0,0-24.443V56.706A21.547,21.547,0,0,0,21.557,78.263h83.851a21.547,21.547,0,0,0,21.557-21.557V43.145H113.458V56.706a8.07,8.07,0,0,1-8.05,8.05H21.557a8.07,8.07,0,0,1-8.05-8.05V-4.348a21.445,21.445,0,0,0,8.05,1.569h83.851a8.07,8.07,0,0,1,8.05,8.05V18.833Z"
                fill="#4a5568"
              />
              <path
                id="Path_13539"
                data-name="Path 13539"
                d="M77.538-25H38.592A11.592,11.592,0,0,0,27-13.406V3.926A11.592,11.592,0,0,0,38.592,15.521H77.538A11.594,11.594,0,0,0,89.132,3.926V-13.406A11.594,11.594,0,0,0,77.538-25M75.625,2.014H40.507V-11.493H75.625Z"
                transform="translate(45.938 35.729)"
                fill="currentColor"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center text-center middle-block">
      <div
        class="w-9/12 mx-auto wow fadeIn animated"
        data-wow-delay="0.5s"
        data-wow-duration="1s"
      >
        <h1
          class="self-end mx-auto font-bold text-center title text-primaryx-dark"
        >
          {{ "RETAILER_PAYMENT.TITLE" | translate }}
        </h1>
        <h2 class="self-end mx-auto text-center title text-primaryx-dark">
          {{ "RETAILER_PAYMENT.MESSAGE" | translate }}
        </h2>
      </div>
    </div>

    <div
      class="flex flex-col items-start justify-start third-block bg-primaryx-bg wow bounceInUp animated"
      data-wow-delay="0"
      data-wow-duration="1s"
    ></div>
  </div>
</ng-container>
