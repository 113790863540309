import { Item } from 'src/app/core/models/item';
import { Slot } from 'src/app/core/models/slot';
import { PaymentResult } from '../models/payment-result';
import { Product } from '../models/product';

export namespace Retailer {
  export class HealthCheck {
    static readonly type = '[Retailer] HealthCheck';
    constructor() {}
  }

  export class GetTemperature {
    static readonly type = '[Retailer] GetTemperature';
    constructor() {}
  }

  export class Load {
    static readonly type = '[Retailer] Load';
    constructor() {}
  }

  export class SelectCategory {
    static readonly type = '[Retailer] SelectCategory';
    constructor(public id: number) {}
  }

  export class SelectProduct {
    static readonly type = '[Retailer] SelectProduct';
    constructor(public id: number) {}
  }

  export class BuyProduct {
    static readonly type = '[Retailer] BuyProduct';
    constructor(public id: number) {}
  }

  export class BuyCart {
    static readonly type = '[Retailer] BuyCart';
    constructor(public preorderType: string) {}
  }

  export class CheckPickupCode {
    static readonly type = '[Retailer] CheckPickupCode';
    constructor(public code: string) {}
  }

  export class PickupProduct {
    static readonly type = '[Retailer] PickupProduct';
    constructor() {}
  }

  export class ReleaseProductNew {
    static readonly type = '[Retailer] ReleaseProductNew';
    constructor(
      public slot: Slot,
      public product: Product,
      public container: any,
      public customerCharged: boolean
    ) {}
  }

  export class ReleaseProduct {
    static readonly type = '[Retailer] ReleaseProduct';
    constructor() {}
  }

  export class PrintReceipt {
    static readonly type = '[Retailer] PrintReceipt';
    constructor(public item: Item, public paymentResult: PaymentResult) {}
  }

  export class PrintExternalPaymentReceipt {
    static readonly type = '[Retailer] PrintExternalPaymentReceipt';
    constructor(public data: any, public paymentResult: PaymentResult) {}
  }

  export class PrintReceiptCart {
    static readonly type = '[Retailer] PrintReceiptCart';
    constructor(public paymentResult: PaymentResult) {}
  }

  export class PrintReceiptCartPreorder {
    static readonly type = '[Retailer] PrintReceiptCartPreorder';
    constructor(public articles: any[], public totalPrice: any) {}
  }

  export class FinishOrder {
    static readonly type = '[Retailer] FinishOrder';
    constructor() {}
  }

  export class Cancel {
    static readonly type = '[Retailer] Cancel';
    constructor() {}
  }

  export class Reset {
    static readonly type = '[Retailer] Reset';
    constructor() {}
  }

  export class SetPrintReceipt {
    static readonly type = '[Retailer] SetPrintReceipt';
    constructor() {}
  }

  export class ActionSelection {
    static readonly type = '[Retailer] ActionSelection';
    constructor() {}
  }

  export class ProductSelection {
    static readonly type = '[Retailer] ProductSelection';
    constructor() {}
  }

  export class Search {
    static readonly type = '[Retailer] Search';
    constructor(public search: string) {}
  }

  export class Configure {
    static readonly type = '[Retailer] Configure';
  }

  export class AddProductToCart {
    static readonly type = '[Retailer] AddProductToCart';
    constructor(public id: number, public quantity: number) {}
  }

  export class RemoveProductFromCart {
    static readonly type = '[Retailer] RemoveProductFromCart';
    constructor(public id: number) {}
  }

  export class RemoveAllProductInstancesFromCart {
    static readonly type = '[Retailer] RemoveAllProductInstancesFromCart';
    constructor(public id: number) {}
  }

  export class EmptyCart {
    static readonly type = '[Retailer] EmptyCart';
    constructor() {}
  }

  export class OutputWWKSArticles {
    static readonly type = '[Retailer] OutputWWKSArticles';
    constructor(public articles: any, public actionType: any) {}
  }

  export class OutputWWKSArticlesSimple {
    static readonly type = '[Retailer] OutputWWKSArticlesSimple';
    constructor(public articles: any, public actionType: any) {}
  }

  export class OutputProductsMqtt {
    static readonly type = '[Retailer] OutputProductsMqtt';
    constructor(public products: any) {}
  }
  export class MonitorOuttakeMqtt {
    static readonly type = '[Retailer] MonitorOuttakeMqtt';
    constructor(public autoLock: any) {}
  }
}
