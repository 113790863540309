import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostBinding,
} from '@angular/core';
import { Store } from '@ngxs/store';
import {
  CoreState,
  CoreStateModel,
  GlobalEnums,
} from 'src/app/core/state/core.state';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Navigate } from '@ngxs/router-plugin';
import { LangService } from 'src/app/core/services/lang.service';
import { Retailer } from 'src/app/retailer/state/retailer.actions';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @HostBinding('class') class =
    'w-side-normal h-screen bg-transparent relative z-10 py-10 flex flex-col items-start justify-between';

  public GlobalEnums = GlobalEnums;
  logo: string;
  currentRoute: string;
  count: number = 1;
  openSetupCount = 0;
  hideSearch: boolean = false;
  multiLanguage: boolean = false;
  currentLang: string;
  fullHd: boolean = false;
  showHomeBtn: boolean = false;

  public availableLanguages = [];

  @Input() backBtnCloseInfoPanel: boolean;

  @Input() rightHand: boolean;
  @Input() infoPanel: boolean = false;
  @Input() fullHdHalf: boolean = false;
  @Input() productPage: boolean = false;
  @Input() hideSearchButton: boolean = false;
  @Input() hideCategories: boolean = false;
  @Input() hideBackBtn: boolean = false;
  @Input() useShoppingCart: boolean = false;
  @Input() statusCartMessage: boolean = false;
  @Input() cartQuantity: number = 0;

  @Input() menuPanelOpened: boolean = false;
  @Output() menuPanelStatus: EventEmitter<boolean> = new EventEmitter();

  @Input() searchPanelOpened: boolean = false;
  @Output() searchPanelStatus: EventEmitter<boolean> = new EventEmitter();
  @Output() rightHandStatus: EventEmitter<boolean> = new EventEmitter();
  @Output() infoPanelStatus: EventEmitter<boolean> = new EventEmitter();
  @Output() resetCategoryStatus: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private store: Store,
    private router: Router,
    private location: Location,
    private langService: LangService
  ) {
    this.currentLang = this.langService.getLanguage();
    this.availableLanguages = this.langService.getAvailableLanguages();

    this.fullHd = JSON.parse(localStorage.getItem('fullhd')) ?? false;
    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      this.logo =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo;
      this.hideSearch =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.hideSearch;
      this.multiLanguage =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.multiLanguage;
    }

    this.currentRoute = this.router.url;
    if (this.currentRoute === '/retailer/selection') {
      this.showHomeBtn = true;
    }
    console.log('back button', this.hideBackBtn);
  }

  ngOnInit(): void {}

  openMenuPanel() {
    this.menuPanelOpened = !this.menuPanelOpened;
    this.menuPanelStatus.emit(this.menuPanelOpened);
  }

  openSearchPanel() {
    this.searchPanelOpened = !this.searchPanelOpened;
    this.searchPanelStatus.emit(this.searchPanelOpened);
  }

  openCart() {
    this.router.navigate(['/retailer/cart']);
  }

  openPickup() {
    this.router.navigate(['/retailer/pickup']);
  }

  backPage(route: string) {
    if (route === '/retailer/selection') {
      // this.router.navigate(['/core/selection']);
      this.router.navigate(['/']);
    } else {
      this.location.back();
    }
  }
  goToHome() {
    this.router.navigate(['/']);
  }
  goToMainCategory() {
    this.resetCategoryStatus.emit(true);
  }
  changeHand() {
    this.rightHand = !this.rightHand;
    this.rightHandStatus.emit(this.rightHand);

    if (this.rightHand) {
      localStorage.setItem('hand', 'right-hand');
    } else {
      localStorage.setItem('hand', 'left-hand');
    }
  }

  backCloseAction() {
    this.infoPanel = false;
    this.infoPanelStatus.emit(false);
  }

  setPublicLanguage() {
    switch (this.currentLang) {
      case 'de':
        this.langService.setPublicLanguage('en');
        this.langService.getNewLanguage('en');
        this.langService.setLanguage();
        this.langService.emitChangeLang('en');
        break;
      case 'en':
        this.langService.setPublicLanguage('de');
        this.langService.getNewLanguage('de');
        this.langService.setLanguage();
        this.langService.emitChangeLang('de');
        break;
    }

    this.currentLang = this.langService.getLanguage();
  }

  openSetup() {
    this.openSetupCount++;

    if (this.openSetupCount === this.GlobalEnums.openSetupCount) {
      this.store.dispatch(new Navigate(['/core', 'setup']));
    }
  }
}
