import { ModuleType } from "../state/core.state";

export class MachineError {
    errorNumber: number;
    module: ModuleType;
    message: string;
    fatal: boolean;

    constructor(errorNumber: number, module: ModuleType, message: string, fatal: boolean) {
        this.errorNumber = errorNumber;
        this.module = module;
        this.message = message;
        this.fatal = fatal;
    }
}
