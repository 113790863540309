import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { PaymentResult } from 'src/app/retailer/models/payment-result';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private httpClient: HttpClient) {}

  authorization(price: number): Observable<PaymentResult> {
    return this.httpClient
      .post(environment.paymentApi + '/payment/authorization', {
        amount: price,
        timeout: 120000,
      })
      .pipe(map((res) => res as PaymentResult));
  }

  finishAuthorization(
    price: number,
    referenceData: any
  ): Observable<PaymentResult> {
    let request = null;
    if (referenceData && referenceData.serviceId != null)
      request = referenceData.serviceId;
    return this.httpClient
      .post(environment.paymentApi + '/payment/finishAuthorization', {
        amount: price,
        refId: request,
      })
      .pipe(map((res) => res as PaymentResult));
  }

  revertAuthorization(referenceData: any): Observable<PaymentResult> {
    let request = null;
    if (referenceData && referenceData.transactionReference != null)
      request = referenceData.transactionReference;
    return this.httpClient
      .post(environment.paymentApi + '/payment/revertAuthorization', {
        refId: request,
      })
      .pipe(map((res) => res as PaymentResult));
  }
}
