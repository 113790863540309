<ng-container *ngIf="theme === 'default'">
  <div class="flex flex-col items-start justify-between w-full h-screen">
    <div
      class="flex flex-row justify-center first-block bg-primaryx-bg wow bounceInDown animated"
      data-wow-delay="0"
      data-wow-duration="1s"
    >
      <div
        class="absolute bottom-0 flex flex-col items-center justify-end w-full h-full -mb-16"
      >
        <!-- <app-cloud
          class="flex flex-col justify-end w-full h-full"
          [ngClass]="fullHdHalf ? '-mb-40' : fullHd ? ' -mb-36' : ' -mb-24'"
        ></app-cloud> -->
        <div
          *ngIf="!finished"
          id="countdown"
          class="absolute flex flex-col items-center justify-center mx-auto font-extrabold text-center bg-white rounded-full w-32 h-32 drop-shadow-xl text-primaryx text-5xl wow bounceOut animated"
          [ngClass]="
            fullHdHalf ? 'mb-1' : fullHd ? ' ml-[-30px] mb-[-4px]' : '-mb-13'
          "
          style="border-radius: 9999px !important"
          data-wow-delay="30s"
          data-wow-duration="1s"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-1 -1 34 34"
            *ngIf="countdown >= 0"
          >
            <circle
              cx="16"
              cy="16"
              r="15.9155"
              class="progress-bar__background"
            />

            <circle
              cx="16"
              cy="16"
              r="15.9155"
              class="progress-bar__progress"
              style="
            stroke-dashoffset:{{ 100 - (countdown - 0.1) * 100 }};
            transition: stroke-dashoffset {{ countdownFull }}s ease-in-out;"
            />
          </svg>
          <span
            class="absolute block w-full h-auto text-gray wow pulse animated"
            data-wow-delay="0"
            data-wow-duration="1s"
            data-wow-iteration="30"
          >
            {{ countdown }}
          </span>
          <!-- <div
          class="relative w-full h-full overflow-hidden bg-white rounded-full  wow pulse animated"
          data-wow-delay="1s"
          data-wow-duration="2s"
          data-wow-iteration="90"
        >
          <img
            [src]="gifSrc"
            class="absolute top-0 right-0"
            style="top: 64px; left: -39px"
          />
        </div> -->
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center text-center middle-block space-y-4">
      <!-- illustration animation -->
      <div
        class="w-9/12 mx-auto flex flex-row items-center justify-center wow fadeIn animated"
        data-wow-delay="0.2s"
        data-wow-duration="1s"
      >
        <!-- <img src="/assets/animation/door-slideup.gif" /> -->
        <img src="/assets/animation/door-push.gif" />
      </div>
      <!-- end illustration animation -->
      <div
        class="w-9/12 mx-auto wow fadeIn animated"
        data-wow-delay="0.5s"
        data-wow-duration="1s"
      >
        <h1 class="self-end mx-auto font-bold text-center title text-gray">
          {{ "RETAILER_RETRIEVE.MESSAGE" | translate }}
        </h1>

        <h2 class="self-end mx-auto mt-2 text-center title text-gray">
          {{ "RETAILER_RETRIEVE.MESSAGE_3" | translate }}
        </h2>
      </div>
    </div>

    <div
      class="flex flex-col items-start justify-start third-block bg-primaryx-bg wow bounceInUp animated"
      data-wow-delay="0"
      data-wow-duration="1s"
    >
      <div
        *ngIf="!finished"
        class="w-4/12 mx-auto flex flex-row items-center justify-center space-x-4"
      >
        <button
          class="w-6/12 mx-auto btn btn-action btn-action--buy-big-ultra bg-success border-success active:border-active active:bg-active group"
          [ngClass]="fullHdHalf && 'btn-action--middle'"
          (pointerup)="skipTimerOnClick()"
          [touchClick]
        >
          <span class="label text-white">{{
            "RETAILER_RETRIEVE.TAKEN" | translate
          }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'nexus'">
  <div
    class="flex flex-col items-start justify-between w-full h-screen px-6 bg-white"
  >
    <!-- infowindow header -->
    <div
      class="flex flex-col items-stretch min-h-[40%] max-h-[40%] justify-between w-full px-8 pt-18 bg-nex-gray-light rounded-b-4xl relative z-10 wow bounceInDown animated"
      data-wow-delay="0ms"
      data-wow-duration=".4s"
    >
      <!-- msg 2 -->
      <div
        class="w-full h-auto flex-1 flex flex-col items-center justify-center wow fadeIn animated"
        data-wow-delay="1s"
        data-wow-duration="1s"
      >
        <img src="/assets/animation/door-push.gif" />
      </div>
      <!-- end msg 2 -->
      <!-- screen sign symbol -->
      <div class="w-full h-auto self-end justify-self-end">
        <!-- sign -->
        <div
          class="-mb-37 flex items-center justify-center mx-auto rounded-full w-80 h-80 bg-white-20 wow bounceIn animated"
          data-wow-delay="0"
          data-wow-duration="1s"
        >
          <div
            class="flex items-center justify-center w-64 h-64 rounded-full bg-white-40 wow bounceIn animated"
            data-wow-delay="0"
            data-wow-duration="1s"
          >
            <div
              class="flex flex-col items-center justify-center w-48 h-48 mx-auto bg-white rounded-full text-white wow bounceIn animated"
              data-wow-delay="0"
              data-wow-duration="1s"
            >
              <div
                *ngIf="!finished"
                id="countdown-nex"
                class="absolute flex flex-col items-center justify-center mx-auto font-extrabold text-center bg-white rounded-full w-40 h-40 text-nex-green text-5xl wow bounceOut animated"
                data-wow-delay="30s"
                data-wow-duration="1s"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1 -1 34 34"
                  *ngIf="countdown >= 0"
                >
                  <circle
                    cx="16"
                    cy="16"
                    r="15.9155"
                    class="progress-bar__background"
                  />

                  <circle
                    cx="16"
                    cy="16"
                    r="15.9155"
                    class="progress-bar__progress"
                    style="
            stroke-dashoffset:{{ 100 - (countdown - 0.1) * 100 }};
            transition: stroke-dashoffset {{ countdownFull }}s ease-in-out;"
                  />
                </svg>
                <span
                  class="absolute block w-full h-auto text-nex-green wow pulse animated"
                  data-wow-delay="0"
                  data-wow-duration="1s"
                  data-wow-iteration="30"
                >
                  {{ countdown }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- end sign -->
      </div>
      <!-- end screen sign symbol -->
    </div>
    <!-- end infowindow header -->

    <!-- infowindow msg part -->
    <div
      class="flex flex-col vert-shadow items-center justify-center flex-1 w-11/12 mx-auto pb-24 pt-28 px-8 h-auto bg-nex-gray-light-ultra relative -top-12 z-5 rounded-b-4xl wow bounceInDown animated"
      data-wow-delay="200ms"
      data-wow-duration=".4s"
    >
      <!-- msg 2 -->
      <div
        class="w-full h-auto flex flex-col items-center justify-center wow fadeIn animated"
        data-wow-delay="1s"
        data-wow-duration="1s"
      >
        <h1
          class="text-6xl font-bold text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="10ms"
          data-wow-duration="1s"
        >
          {{ "RETAILER_RETRIEVE.MESSAGE" | translate }}
        </h1>
        <h1
          class="text-6xl font-medium text-center text-nex-gray-dark wow fadeIn animated"
          data-wow-delay="10ms"
          data-wow-duration="1s"
        >
          {{ "RETAILER_RETRIEVE.MESSAGE_3" | translate }}
        </h1>
      </div>
      <!-- end msg 2 -->
    </div>
    <!-- end infowindow msg part -->

    <!-- infowindow empty space at the bottom -->
    <div class="w-full h-24 block"></div>
    <!-- end infowindow empty space at the bottom -->

    <div
      *ngIf="!finished"
      class="w-11/12 mx-auto h-24.2 flex flex-row items-center justify-between"
    >
      <div
        class="h-full flex-1 flex flex-row space-x-4 items-center justify-center rounded-t-4xl bg-nex-gray-light"
      >
        <!-- BUTTON -->
        <button
          (click)="skipTimerOnClick()"
          class="max-w-64 w-auto h-20 bg-nex-green flex-row items-center justify-start flex text-white active:bg-nex-green/70 rounded-xl border-2 border-transparent active:border-nex-green active:shadow-inner-light"
        >
          <div class="p-4 w-16 h-16 justify-start items-start flex">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9998 29.3337C8.63584 29.3337 2.6665 23.3643 2.6665 16.0003C2.6665 8.63633 8.63584 2.66699 15.9998 2.66699C23.3638 2.66699 29.3332 8.63633 29.3332 16.0003C29.3332 23.3643 23.3638 29.3337 15.9998 29.3337ZM14.6705 21.3337L24.0972 11.9057L22.2132 10.0203L14.6705 17.563L10.8985 13.791L9.01317 15.6763L14.6705 21.3337Z"
                fill="currentColor"
              />
            </svg>
          </div>

          <div
            class="pr-6 w-auto flex-1 h-full justify-start flex flex-row items-center text-2xl font-medium"
          >
            {{ "RETAILER_RETRIEVE.TAKEN" | translate }}
          </div>
        </button>
        <!-- end BUTTON -->
      </div>
    </div>
  </div>
</ng-container>
