<ng-container *ngIf="theme === 'pickup_landscape' && 'pickup_portrait'">
  <div
    class="flex flex-row items-center justify-between w-full px-10 bg-white h-25 bg-sanusx-lightX"
  >
    <!-- logo -->
    <div class="flex flex-row justify-start w-3/12 text-left">
      <img
        (click)="openSetup()"
        class="h-24 max-h-24"
        *ngIf="logo"
        [src]="logo"
      />
    </div>
    <!-- END:logo -->

    <ng-container *ngIf="doubleLogos">
      <!-- logo -->
      <div class="flex flex-row justify-end w-2/12 ml-auto text-right">
        <img class="h-24 max-h-24" *ngIf="logo2" [src]="logo2" />
      </div>
      <!-- END:logo -->
    </ng-container>
    <ng-container *ngIf="!doubleLogos">
      <!-- search -->
      <div
        class="flex items-center justify-start w-7/12 text-center"
        *ngIf="showSearch"
      >
        <form
          (keydown.enter)="$event.preventDefault()"
          class="flex flex-row items-center justify-center w-full mx-auto text-2xl text-center uppercase outline-none text-primaryx-dark focus:none"
        >
          <input
            (keyup)="search($event)"
            type="text"
            class="w-full px-4 py-2 font-bold tracking-wide text-left outline-none h-13 min-h-13 max-h-13 text-primaryx-dark focus:none"
            placeholder="{{ 'COMMON.SEARCH_PLACEHOLDER' | translate }}"
            autofocus
          />
          <div
            class="flex items-center justify-center text-white w-18 h-13 bg-primaryx-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28.725"
              height="28.131"
              viewBox="0 0 28.725 28.131"
            >
              <path
                id="Path_13616"
                data-name="Path 13616"
                d="M18.056,3.8l7.692,7.692a1.744,1.744,0,0,0,2.467-2.467L20.43,1.241A11.106,11.106,0,0,0,22.317-4.97,11.158,11.158,0,0,0,11.158-16.127,11.158,11.158,0,0,0,0-4.97,11.158,11.158,0,0,0,11.158,6.188,11.111,11.111,0,0,0,18.056,3.8Zm-6.9-.823A7.949,7.949,0,0,1,3.21-4.97a7.948,7.948,0,0,1,7.949-7.947A7.948,7.948,0,0,1,19.107-4.97,7.949,7.949,0,0,1,11.158,2.979Z"
                transform="translate(0 16.127)"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </div>
        </form>
      </div>
      <!-- END search -->

      <!-- buttons -->
      <div class="flex flex-row justify-center w-4/12 pl-6 text-center">
        <button
          class="btn-sanusx btn-back bg-tertiary active:bg-active"
          (click)="backToSelection()"
          *ngIf="showBackButton"
        >
          <span class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.125"
              height="26.25"
              viewBox="0 0 13.125 26.25"
            >
              <path
                id="Path_13621"
                data-name="Path 13621"
                d="M0-2.687,13.125,10.438v-26.25Z"
                transform="translate(0 15.812)"
                fill="currentColor"
              />
            </svg>
          </span>
          <span class="label text-primaryx">
            {{ "COMMON.RESTART" | translate }}
          </span>
        </button>
      </div>
      <!-- END buttons -->
    </ng-container>
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="flex flex-row items-center justify-between w-full px-10 bg-white h-25 bg-sanusx-lightX drop-shadow-xl rounded-b-xl"
  >
    <!-- logo -->
    <div class="flex flex-row justify-start w-3/12 text-left">
      <img
        (click)="openSetup()"
        class="h-22 max-h-22"
        *ngIf="logo"
        [src]="logo"
      />
    </div>
    <!-- END:logo -->

    <!-- person photo -->
    <ng-container *ngIf="personPhoto">
      <div class="flex-1">
        <div
          class="flex flex-col items-center justify-center mx-auto rounded-full w-52 h-52 bg-sanusx-light wow slideInDown animated mt-22"
          style="border-radius: 9999px !important"
        >
          <img
            *ngIf="personPhoto"
            [src]="
              (personPhoto != null ? personPhoto : '/assets/welcome-person.png')
                | safe
            "
            loading="lazy"
            class="mx-auto rounded-full max-h-44 max-w-44 w-44 h-44"
            style="border-radius: 9999px !important"
          />
        </div>
      </div>
    </ng-container>
    <!-- end person photo -->
    <ng-container *ngIf="doubleLogos">
      <!-- logo -->
      <div class="flex flex-row justify-end w-3/12 ml-auto text-right">
        <img class="h-22 max-h-22" *ngIf="logo2" [src]="logo2" />
      </div>
      <!-- END:logo -->
    </ng-container>
    <ng-container *ngIf="!doubleLogos">
      <!-- search -->
      <div
        class="flex items-center justify-start w-7/12 text-center"
        *ngIf="showSearch"
      >
        <form
          (keydown.enter)="$event.preventDefault()"
          class="flex flex-row items-center justify-center w-full mx-auto text-2xl text-center uppercase outline-none text-primaryx-dark focus:none"
        >
          <input
            (keyup)="search($event)"
            type="text"
            class="w-full px-4 py-2 font-bold tracking-wide text-left outline-none h-13 min-h-13 max-h-13 text-primaryx-dark focus:none"
            placeholder="{{ 'COMMON.SEARCH_PLACEHOLDER' | translate }}"
            autofocus
          />
          <div
            class="flex items-center justify-center text-white w-18 h-13 bg-primaryx-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28.725"
              height="28.131"
              viewBox="0 0 28.725 28.131"
            >
              <path
                id="Path_13616"
                data-name="Path 13616"
                d="M18.056,3.8l7.692,7.692a1.744,1.744,0,0,0,2.467-2.467L20.43,1.241A11.106,11.106,0,0,0,22.317-4.97,11.158,11.158,0,0,0,11.158-16.127,11.158,11.158,0,0,0,0-4.97,11.158,11.158,0,0,0,11.158,6.188,11.111,11.111,0,0,0,18.056,3.8Zm-6.9-.823A7.949,7.949,0,0,1,3.21-4.97a7.948,7.948,0,0,1,7.949-7.947A7.948,7.948,0,0,1,19.107-4.97,7.949,7.949,0,0,1,11.158,2.979Z"
                transform="translate(0 16.127)"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </div>
        </form>
      </div>
      <!-- END search -->
    </ng-container>
    <!-- buttons -->
    <div class="flex flex-row justify-end w-4/12 space-x-4 text-center pl-6x">
      <ng-container *ngIf="!doubleLogos">
        <button
          class="btn-sanusx btn-back bg-tertiary items-center justify-center border-2 border-gray-light-50 active:bg-active"
          (click)="backToSelection()"
          *ngIf="showBackButton"
        >
          <span class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.125"
              height="26.25"
              viewBox="0 0 13.125 26.25"
            >
              <path
                id="Path_13621"
                data-name="Path 13621"
                d="M0-2.687,13.125,10.438v-26.25Z"
                transform="translate(0 15.812)"
                fill="currentColor"
              />
            </svg>
          </span>
          <span class="label text-primaryx">
            {{ "COMMON.BACK" | translate }}
          </span>
        </button>
      </ng-container>
      <!-- lang switcher -->
      <ng-container *ngIf="multiLanguage">
        <div class="flex flex-col items-center justify-center h-12 w-18">
          <div
            class="flex flex-col items-center justify-center h-12 p-1 border-2 cursor-pointer w-18 border-gray-light-50"
            (click)="setPublicLanguage()"
          >
            <img
              src="/assets/lang/gb_2.png"
              class="object-cover h-full"
              *ngIf="currentLang === 'de'"
            />
            <img
              src="/assets/lang/de_2.png"
              class="object-cover h-full"
              *ngIf="currentLang === 'en'"
            />
          </div>
        </div>
      </ng-container>
      <!-- END lang switcher -->
    </div>
    <!-- END buttons -->
  </div>
</ng-container>
<!---

    theme separator

  -->
<ng-container *ngIf="theme === 'default'">
  <form
    (keydown.enter)="$event.preventDefault()"
    class="w-9/12 mx-auto text-center uppercase border-t-0 border-b-8 border-l-0 border-r-0 outline-none text-7xl text-primaryx-dark border-gray-ultra focus:none"
    *ngIf="showSearch"
  >
    <input
      (keyup)="search($event)"
      type="text"
      class="text-center uppercase outline-none focus:none"
      placeholder="{{ 'COMMON.SEARCH_PLACEHOLDER' | translate }}"
      autofocus
    />
  </form>
  <!-- lang switcher -->
  <ng-container *ngIf="multiLanguage && showLangFlags">
    <div class="flex flex-col items-center justify-center h-12 w-18">
      <div
        class="flex flex-col items-center justify-center h-12 p-1 border-2 cursor-pointer w-18 border-gray-light-50"
        (click)="setPublicLanguage()"
      >
        <img
          src="/assets/lang/gb_2.png"
          class="object-cover h-full"
          *ngIf="currentLang === 'de'"
        />
        <img
          src="/assets/lang/aut_2.png"
          class="object-cover h-full"
          *ngIf="currentLang === 'en'"
        />
      </div>
    </div>
  </ng-container>
  <!-- END lang switcher -->
</ng-container>
