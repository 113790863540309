<div
  class="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-screen bg-gray-light-80 backdrop-blur-sm z-90"
>
  <div
    class="w-9/12 h-[50vh] mx-auto flex flex-col justify-start pt-27 pb-16 px-10 drop-shadow-xl bg-white wow bounceIn rounded-xl"
    [ngClass]="theme === 'ski' ? 'h-[80vh]' : 'h-[50vh]'"
    data-wow-delay="0s"
    data-wow-duration=".3s"
  >
    <div class="w-full h-auto mb-27">
      <div
        class="w-27 h-27 flex items-center justify-center mx-auto {{
          iconCssClass !== '' ? iconCssClass : 'text-active'
        }}"
      >
        <ng-container *ngIf="iconname !== 'null'">
          <app-svg-stripe [icon]="iconname"></app-svg-stripe>
        </ng-container>
      </div>
    </div>

    <div
      class="flex flex-col items-stretch justify-between flex-1 w-full h-full mx-auto space-y-4 text-4xl font-semibold leading-normal text-center text-gray wow fadeInUp"
      data-wow-delay="0"
      data-wow-duration="0.5s"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
