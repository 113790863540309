import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-ski-msg',
  templateUrl: './ski-msg.component.html',
  styleUrls: ['./ski-msg.component.scss']

})
export class SkiMsgComponent implements OnInit {
  @HostBinding('class') class = 'flex flex-row items-center justify-center w-full h-auto';

  @Input() msg: string = "";
  @Input() msgType: string = ""; // warning, error, info, danger, success
  @Input() icon: string = ""; // warning, error, info, danger, success
  constructor() {

  }

  ngOnInit(): void {
    switch (this.msgType) {
      case "warning":
        this.icon = 'danger';
        break;
      case "error":
        this.icon = 'error';
        break;
      case "info":
        this.icon = 'information';
        break;
      case "danger":
        this.icon = 'danger';
        break;
      case "success":
        this.icon = 'checkmark';
        break;
    }
  }

}
