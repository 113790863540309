<button
  (click)="onClickButton($event)"
  class="btn w-full group h-24 px-8 flex flex-row items-center justify-between rounded-xl relative"
  [disabled]="disabled"
>
  <div class="w-12 h-12 block mr-9 z-up relative z-20">
    <app-svg-stripe [icon]="iconname"></app-svg-stripe>
  </div>
  <div
    class="uppercase items-center flex flex-1 text-2xl font-medium text-primaryx text-left z-up relative z-20"
  >
    {{ label }}
    <ng-container *ngIf="disabled">
      <span
        class="px-4 py-2 rounded-full bg-white-70 opacity-60 ml-auto mr-12"
        >{{ disabledReason }}</span
      >
    </ng-container>
  </div>
  <div
    class="flex text-2.5xl text-primaryx items-center flex-row space-x-4 z-up relative z-20"
  >
    <span class="w-6 h-6">
      <app-svg-stripe [icon]="'fourelement'"></app-svg-stripe>
    </span>
    <span>{{ lockernumber }}</span>
  </div>
  <div
    class="absolute z-10 rounded-xl left-0 top-0 w-full h-full drop-shadow-md bg-gradient-to-br from-white via-white to-gray-ultra group-focus:outline-none group-focus:bg-white ring-0 group-disabled:opacity-30 group-disabled:grayscale"
  ></div>
</button>
