import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  private intervalId;
  constructor(private http: HttpClient, private router: Router) { }

  public initVersionCheck() {
    if (environment.production) {
      this.intervalId = setInterval(() => this.checkVersion(), 60000);
    }
  }

  public checkVersion() {
    console.log('Checking new version');
    // timestamp these requests to invalidate caches
    this.http.get('/version.json' + '?t=' + new Date().getTime()).subscribe(
      (response: any) => {
        console.log(response);
        const hash = response.hash;
        const hashChanged = this.hasHashChanged(this.currentHash, hash);
        // If new version, do something
        if (hashChanged) {
          console.log('Found new version');

          setTimeout(() => {
            if (this.noCurentActivity()) {
              clearInterval(this.intervalId);
              window.location.reload();
            }
          }, 55000);
        }
      },
      (err) => {
        console.error(err, 'Could not get version from server - error');
      }
    );
  }

  noCurentActivity() {
    return (
      this.router.url === '/core/screensaver' ||
      this.router.url === '/core/error' ||
      this.router.url === '/core/selection-buttons' ||
      this.router.url === '/core/out-of-order'
    );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash, newHash) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }

    return currentHash !== newHash;
  }
}
