import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Retailer } from 'src/app/retailer/state/retailer.actions';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { Core } from '../../state/core.actions';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent implements OnInit, OnDestroy {
  appIdle = environment.appIdle;
  subs = new SubSink();

  constructor(private store: Store, idle: Idle, private router: Router) {
    console.log('app-v-3.0.0');
    if (this.appIdle) {
      idle.setIdle(30);
      idle.setTimeout(10);
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    }
    this.subs.add(idle.onTimeout.subscribe(() => {
      this.store.dispatch(new Navigate(['/core/screensaver'])).subscribe(() => {
        this.store.dispatch(new Retailer.Reset());
      });
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


}
