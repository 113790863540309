import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Item } from 'src/app/core/models/item';
import { PaymentResult } from 'src/app/retailer/models/payment-result';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  constructor(private httpClient: HttpClient, private store: Store) {}

  print(data: string): Observable<boolean> {
    return this.httpClient
      .post(environment.printerApi + '/print', { print: data })
      .pipe(
        map((res: any) => {
          return res.success === 'true' || res.success === true;
        })
      );
  }

  printPreorder(data: any): Observable<boolean> {
    return this.httpClient
      .post(environment.controlApi + '/printer/print-order', data)
      .pipe(
        map((res: any) => {
          return res.success === 'true' || res.success === true;
        })
      );
  }

  printV2(data: any): Observable<boolean> {
    return this.httpClient
      .post(environment.controlApi + '/printer/print', data)
      .pipe(
        map((res: any) => {
          return res.success === 'true' || res.success === true;
        })
      );
  }

  generateReceiptFromItem(
    item: Item,
    paymentResult: PaymentResult,
    printHeader: string,
    printFooter: string
  ): string {
    var date = new Date();
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var hh = String(date.getHours()).padStart(2, '0');
    var MM = String(date.getMinutes()).padStart(2, '0');
    var ss = String(date.getSeconds()).padStart(2, '0');

    let receipt = [];
    receipt.push('** KUNDENBELEG **');
    receipt.push(printHeader);
    receipt.push('');
    receipt.push('VERKAUF');
    receipt.push(
      dd +
        '.' +
        mm +
        '.' +
        date.getFullYear() +
        '     ' +
        hh +
        ':' +
        MM +
        ':' +
        ss
    );
    receipt.push('');
    receipt.push(item.product.name);
    receipt.push(' '); //"GEN.NR.: " + paymentResult.approvalNumber);
    receipt.push('BETRAG:          EURO ' + item.product.grossPrice.toFixed(2));
    receipt.push(' ');
    receipt.push('Gesamt Netto     EURO ' + item.product.price.toFixed(2));
    receipt.push(
      'USt.   ' +
        item.product.vatPercent +
        '%     EURO ' +
        item.product.vatAmount.toFixed(2)
    );
    receipt.push('');

    let terminalDataRespone;
    console.log('terminalData' + paymentResult);

    if (paymentResult.data != null) {
      terminalDataRespone = paymentResult.data;
    } else {
      terminalDataRespone = paymentResult;
    }

    if ('TIM' == paymentResult.paymentType) {
      let value = this.getTimPrintData(paymentResult);
      receipt.push(value);
    } else if ('ADYEN' == paymentResult.paymentType) {
      receipt.push(paymentResult.data.printData);
    } else if (
      'ZVT' == paymentResult.paymentType &&
      paymentResult.data != null &&
      paymentResult.data.printData
    ) {
      receipt.push(paymentResult.data.printData);
    } else {
      this.addTerminalData(receipt, terminalDataRespone);
    }

    receipt.push(printFooter);

    return receipt.join('\n');
  }

  generateReceiptFromExternalPayment(
    data: any,
    paymentResult: PaymentResult,
    printHeader: string,
    printFooter: string
  ): string {
    var date = new Date();
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var hh = String(date.getHours()).padStart(2, '0');
    var MM = String(date.getMinutes()).padStart(2, '0');
    var ss = String(date.getSeconds()).padStart(2, '0');

    let receipt = [];
    receipt.push('** KUNDENBELEG **');
    receipt.push(printHeader);
    receipt.push('');
    receipt.push('VERKAUF');
    receipt.push(
      dd +
        '.' +
        mm +
        '.' +
        date.getFullYear() +
        '     ' +
        hh +
        ':' +
        MM +
        ':' +
        ss
    );

    let totalBrutto: number = 0,
      totalNetto: number = 0;
    let totalTaxes = new Map();

    let vatPercent = data.productVatPercentage;
    let vatAmount = (data.productGrossPrice - data.productNetPrice).toFixed(2);
    totalBrutto += data.productGrossPrice;
    totalNetto += data.productNetPrice;

    if (!totalTaxes.get(vatPercent)) {
      totalTaxes.set(vatPercent, +vatAmount);
    } else {
      totalTaxes.set(vatPercent, totalTaxes.get(vatPercent) + +vatAmount);
    }

    receipt.push('');
    receipt.push(data.description);
    // receipt.push(" ");//"GEN.NR.: " + paymentResult.approvalNumber);
    receipt.push('BETRAG:          EURO ' + data.productGrossPrice.toFixed(2));

    let serviceVatPercent = data.serviceFeeVatPercentage;
    let serviceVatAmount = (
      data.serviceFeeGrossPrice - data.serviceFeeNetPrice
    ).toFixed(2);
    totalBrutto += data.serviceFeeGrossPrice;
    totalNetto += data.serviceFeeNetPrice;

    if (!totalTaxes.get(serviceVatPercent)) {
      totalTaxes.set(serviceVatPercent, +serviceVatAmount);
    } else {
      totalTaxes.set(
        serviceVatPercent,
        totalTaxes.get(serviceVatPercent) + +serviceVatAmount
      );
    }

    receipt.push('');
    receipt.push('Servicegebühr');
    // receipt.push(" ");//"GEN.NR.: " + paymentResult.approvalNumber);
    receipt.push(
      'BETRAG:          EURO ' + data.serviceFeeGrossPrice.toFixed(2)
    );

    // receipt.push(" ");
    // receipt.push("Gesamt Netto     EURO " + cartItem.product.price.toFixed(2));
    // receipt.push("USt.   " + cartItem.product.vatPercent + "%     EURO " + (cartItem.product.grossPrice - cartItem.product.price).toFixed(2));

    receipt.push('');
    receipt.push('Gesamt');
    receipt.push(' '); //"GEN.NR.: " + paymentResult.approvalNumber);
    receipt.push('BETRAG:          EURO ' + totalBrutto.toFixed(2));
    receipt.push(' ');
    receipt.push('Netto     EURO ' + totalNetto.toFixed(2));
    totalTaxes.forEach((value: any, key: any, map: any) => {
      receipt.push('USt.   ' + key + '%     EURO ' + value.toFixed(2));
    });

    let terminalDataRespone;

    if (paymentResult.data != null) {
      terminalDataRespone = paymentResult.data;
    } else {
      terminalDataRespone = paymentResult;
    }

    this.addTerminalData(receipt, terminalDataRespone);

    receipt.push(printFooter);

    console.log(receipt);

    return receipt.join('\n');
  }

  generateReceiptFromCart(
    cart: any[],
    paymentResult: PaymentResult,
    printHeader: string,
    printFooter: string
  ): string {
    var date = new Date();
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var hh = String(date.getHours()).padStart(2, '0');
    var MM = String(date.getMinutes()).padStart(2, '0');
    var ss = String(date.getSeconds()).padStart(2, '0');

    let receipt = [];
    receipt.push('** KUNDENBELEG **');
    receipt.push(printHeader);
    receipt.push('');
    receipt.push('VERKAUF');
    receipt.push(
      dd +
        '.' +
        mm +
        '.' +
        date.getFullYear() +
        '     ' +
        hh +
        ':' +
        MM +
        ':' +
        ss
    );

    let totalBrutto: number = 0,
      totalNetto: number = 0;
    let totalTaxes = new Map();

    cart.forEach((cartItem) => {
      let vatPercent = cartItem.product.vatPercent;
      let vatAmount = cartItem.product.vatAmount.toFixed(2);
      totalBrutto += +cartItem.product.grossPrice.toFixed(2);
      totalNetto += +cartItem.product.price.toFixed(2);

      if (!totalTaxes.get(vatPercent)) {
        totalTaxes.set(vatPercent, +vatAmount);
      } else {
        totalTaxes.set(vatPercent, totalTaxes.get(vatPercent) + +vatAmount);
      }

      receipt.push(cartItem.product.name);
      // receipt.push(" ");//"GEN.NR.: " + paymentResult.approvalNumber);
      receipt.push(
        'BETRAG:          EURO ' + cartItem.product.grossPrice.toFixed(2)
      );
      // receipt.push(" ");
      // receipt.push("Gesamt Netto     EURO " + cartItem.product.price.toFixed(2));
      // receipt.push("USt.   " + cartItem.product.vatPercent + "%     EURO " + (cartItem.product.grossPrice - cartItem.product.price).toFixed(2));
      receipt.push('');
    });

    receipt.push('Gesamt');
    receipt.push(' '); //"GEN.NR.: " + paymentResult.approvalNumber);
    receipt.push('BETRAG:          EURO ' + totalBrutto.toFixed(2));
    receipt.push(' ');
    receipt.push('Netto:           EURO ' + totalNetto.toFixed(2));
    totalTaxes.forEach((value: any, key: any, map: any) => {
      receipt.push('USt.   ' + key + '%     EURO ' + value.toFixed(2));
    });
    receipt.push('');

    let terminalDataRespone;

    if (paymentResult.data != null) {
      terminalDataRespone = paymentResult.data;
    } else {
      terminalDataRespone = paymentResult;
    }
    console.log('terminalData' + terminalDataRespone);
    if (paymentResult.paymentType == 'TIM') {
      let printData = this.getTimPrintData(paymentResult);
      receipt.push(printData);
    } else if (
      'ZVT' == paymentResult.paymentType &&
      paymentResult.data.printData
    ) {
      receipt.push(paymentResult.data.printData);
    } else {
      this.addTerminalData(receipt, terminalDataRespone);
    }
    receipt.push(printFooter);

    return receipt.join('\n');
  }

  private addTerminalData(receipt: any[], terminalDataRespone: PaymentResult) {
    receipt.push(' ');
    receipt.push('Terminal ID: ' + terminalDataRespone.terminalId);
    receipt.push('Vu-Nr: ' + terminalDataRespone.vuNumber);
    receipt.push('Beleg-Nr: ' + terminalDataRespone.receiptNumber);
    receipt.push('Trace-Nr: ' + terminalDataRespone.traceNumber);
    receipt.push('' + terminalDataRespone.cardName);
    receipt.push('AID: ' + terminalDataRespone.aid);
    receipt.push('Nr: ' + terminalDataRespone.sequenceNumber);
    receipt.push(' ');
  }

  private getTimPrintData(paymentResult: PaymentResult) {
    let printData: string;
    if (paymentResult.data.printData) {
      printData = paymentResult.data.printData.receipts.find(
        (x) => x.recipient == 'CARDHOLDER'
      ).value;
    }
    //finish data
    else {
      printData = paymentResult.data.receipts.find(
        (x) => x.recipient == 'CARDHOLDER'
      ).value;
    }
    printData = printData.replace(/  +/g, ' ');
    return printData;
  }

  generateReceiptFromCartPreorder(
    articles: any[],
    totalForPay: number,
    printHeader: string,
    printFooter: string
  ): any {
    let receiptItems = [];

    articles.forEach((article) => {
      receiptItems.push({
        itemName: article.product.name,
        price: this.round(article.product.grossPrice),
        netPrice: this.round(article.product.price),
        quantity: article.quantity,
        tax: article.product.vatPercent,
        taxAmount: this.round(
          article.product.grossPrice - article.product.price
        ),
      });
    });

    let result = {
      header: printHeader,
      footer: printFooter,
      vatNumber: '1234567890',
      terminalData: 'Terminal ID: 12345',
      totalAmount: totalForPay,
      items: receiptItems,
    };

    return result;
  }

  generateReceiptV2(
    item: Item,
    paymentResult: PaymentResult,
    printHeader: string,
    printFooter: string
  ): any {
    let receiptItems = [];
    let terminalData: string;
    let vatNumber: string;

    let temrinalDataRespone: PaymentResult;

    if (paymentResult.data != null) {
      temrinalDataRespone = paymentResult.data;
    } else {
      temrinalDataRespone = paymentResult;
    }
    vatNumber = ' ';

    if ('ADYEN' == paymentResult.paymentType) {
      terminalData = paymentResult.data.printData;
    } else if ('TIM' == paymentResult.paymentType) {
      terminalData = this.getTimPrintData(paymentResult);
    } else if (
      'ZVT' == paymentResult.paymentType &&
      paymentResult.data.printData
    ) {
      terminalData = paymentResult.data.printData;
    } else {
      terminalData =
        'Vu-Nr: ' +
        temrinalDataRespone.vuNumber +
        '\nBeleg - Nr: ' +
        temrinalDataRespone.receiptNumber +
        '\nTrace-Nr: ' +
        temrinalDataRespone.traceNumber +
        '\n' +
        temrinalDataRespone.cardName +
        '\nAID: ' +
        temrinalDataRespone.aid +
        '\nNr: ' +
        temrinalDataRespone.sequenceNumber;

      vatNumber = '' + temrinalDataRespone.receiptNumber;
    }

    receiptItems.push({
      itemName: item.product.name,
      price: +this.round(item.product.grossPrice).toFixed(2),
      netPrice: +this.round(item.product.price).toFixed(2),
      quantity: 1,
      tax: +this.round(item.product.vatPercent).toFixed(2),
      taxAmount: +this.round(
        item.product.grossPrice - item.product.price
      ).toFixed(2),
    });

    let result = {
      header: printHeader,
      footer: printFooter,
      vatNumber: vatNumber,
      terminalData: terminalData,
      totalAmount: +this.round(item.product.grossPrice).toFixed(2),
      items: receiptItems,
    };

    return result;
  }

  generateReceiptV2FromCart(
    cart: any[],
    paymentResult: PaymentResult,
    printHeader: string,
    printFooter: string
  ): any {
    let receiptItems = [];
    let terminalData: string;
    let vatNumber: string;

    let temrinalDataRespone: PaymentResult;

    if (paymentResult.data != null) {
      temrinalDataRespone = paymentResult.data;
    } else {
      temrinalDataRespone = paymentResult;
    }
    vatNumber = ' ';

    if ('ADYEN' == paymentResult.paymentType) {
      terminalData = paymentResult.data.printData;
    } else if ('TIM' == paymentResult.paymentType) {
      terminalData = this.getTimPrintData(paymentResult);
    } else if (
      'ZVT' == paymentResult.paymentType &&
      paymentResult.data.printData
    ) {
      terminalData = paymentResult.data.printData;
    } else {
      terminalData =
        'Vu-Nr: ' +
        temrinalDataRespone.vuNumber +
        '\nBeleg - Nr: ' +
        temrinalDataRespone.receiptNumber +
        '\nTrace-Nr: ' +
        temrinalDataRespone.traceNumber +
        '\n' +
        temrinalDataRespone.cardName +
        '\nAID: ' +
        temrinalDataRespone.aid +
        '\nNr: ' +
        temrinalDataRespone.sequenceNumber;

      vatNumber = '' + temrinalDataRespone.receiptNumber;
    }
    console.log('printing cart', cart);

    cart = cart.filter((item) => item.isReleased == true);

    let totalNetto: number = 0;

    cart.forEach((item) => {
      const index = receiptItems.findIndex((object) => {
        return object.id === item.product.id;
      });
      if (index == -1) {
        receiptItems.push({
          id: item.product.id,
          itemName: item.product.name,
          price: +this.round(item.product.grossPrice).toFixed(2),
          netPrice: +this.round(item.product.price).toFixed(2),
          quantity: 1,
          tax: +this.round(item.product.vatPercent).toFixed(2),
          taxAmount: +this.round(
            item.product.grossPrice - item.product.price
          ).toFixed(2),
        });
      } else {
        receiptItems[index].quantity += 1;
      }
      totalNetto += +item.product.grossPrice.toFixed(2);
    });

    console.log('print items', receiptItems);

    let result = {
      header: printHeader,
      footer: printFooter,
      vatNumber: vatNumber,
      terminalData: terminalData,
      totalAmount: +this.round(totalNetto).toFixed(2),
      items: receiptItems,
    };

    return result;
  }

  round(input: number) {
    return Math.round((input + Number.EPSILON) * 100) / 100;
  }
}
