<ng-container *ngIf="theme === 'sanusx'">
  <div
    class="w-full h-screen flex flex-col items-start justify-start bg-primaryx text-white box-main"
  >
    <app-header
      [showSearch]="false"
      [doubleLogos]="true"
      class="w-full wow fadeInDown"
    ></app-header>
    <div
      class="w-11/12 mx-auto flex flex-col flex-1 items-center justify-start text-center pt-16"
    >
      <div
        class="w-24 h-24 mb-14 relative flex items-center justify-center overflow-hidden bg-cover bg-no-repeat bg-center"
      >
        <app-svg-stripe [icon]="'outer'" class="z-0"></app-svg-stripe>
        <span class="flex items-center justify-center absolute z-5 w-12 h-12">
          <app-svg-stripe [icon]="'dropoff'" [white]="true"></app-svg-stripe>
        </span>
      </div>
      <h2
        *ngIf="action != 'finish-rent'"
        class="w-7/12 mx-auto text-5xl leading-normal font-bold text-white-70 wow fadeInUp"
        data-wow-delay="0"
        data-wow-duration="0.5s"
      >
        {{ "LOCKER_DELIVERY_LOCKER_MESSAGE.MESSAGE" | translate }}
      </h2>
      <h2
        *ngIf="action == 'finish-rent'"
        class="w-7/12 mx-auto text-5xl leading-normal font-bold text-white-70 wow fadeInUp"
        data-wow-delay="0"
        data-wow-duration="0.5s"
      >
        {{ "LOCKER_RENT_OB.ERROR_SELECTION" | translate }}
      </h2>

      <div
        class="w-11/12 mt-16 mb-12 mx-auto text-5xl font-bold text-primaryx-50 text-center focus:none outline-none wow fadeInUp"
        data-wow-delay="0"
        data-wow-duration="0.5s"
      >
        <div
          *ngIf="action == 'finish-rent'"
          class="w-11/12 mx-auto h-3/6 flex flex-col space-y-6"
        >
          <div *ngFor="let error of errors">
            <button
              (click)="onErrorSelect(error)"
              class="btn w-full group h-24 px-8 flex flex-row items-center justify-between rounded-xl relative"
              [disabled]="disabled"
            >
              <div class="w-12 h-12 block mr-9 z-up relative z-20">
                <app-svg-stripe [icon]="icon"></app-svg-stripe>
              </div>
              <div
                class="uppercase items-center flex flex-1 text-2xl font-medium text-primaryx text-left z-up relative z-20"
              >
                {{ error.description }}
              </div>
              <div
                class="flex text-2.5xl text-primaryx items-center flex-row space-x-4 z-up relative z-20"
              >
                <span class="w-6 h-6">
                  <app-svg-stripe [icon]="'information'"></app-svg-stripe>
                </span>
                <span>{{ error.id }}</span>
              </div>
              <div
                class="absolute z-10 rounded-xl left-0 top-0 w-full h-full drop-shadow-md bg-gradient-to-br from-white via-white to-gray-ultra group-focus:outline-none group-focus:bg-white ring-0 group-selected:opacity-30 group-selected:green"
              ></div>
            </button>
          </div>
        </div>
        <!--  -->
        <input
          *ngIf="action != 'finish-rent'"
          #messageInput
          type="text"
          autocomplete="off"
          placeholder="{{
            'LOCKER_DELIVERY_LOCKER_MESSAGE.INPUT_MESSAGE' | translate
          }}"
          class="w-full text-center text-3xl tracking-widest h-40 text-black locker-black placeholder-opacity-100 placeholder-black::placeholder font-normal rounded-xl drop-shadow-xl bg-white focus:none outline-none"
          maxlength="100"
          (keyup.enter)="onEnter()"
          (blur)="onBlurKeyboard($event)"
          value="{{ value }}"
          autofocus
        />
      </div>
      <div
        *ngIf="action != 'finish-rent'"
        class="w-11/12 flex flex-row wow fadeInUp"
        data-wow-delay="0"
        data-wow-duration="0.5s"
      >
        <div class="simple-keyboard-locker-message"></div>
      </div>
    </div>
    <div
      class="w-10/12 mx-auto h-auto flex flex-row space-x-16 items-center justify-between pt-4 pb-14 self-end"
    >
      <app-btn-sanusx
        (btnClick)="cancel()"
        [label]="'COMMON.BTN_CANCEL' | translate"
        buttonType="neutral"
        class="wow slideInLeft"
        data-wow-delay="0s"
        data-wow-duration="1s"
      >
      </app-btn-sanusx>

      <ng-container>
        <app-btn-sanusx
          (btnClick)="saveMessage()"
          [label]="'COMMON.BTN_SUBMIT' | translate"
          buttonType="secondary"
          class="wow fadeIn"
        >
        </app-btn-sanusx>
      </ng-container>
    </div>
  </div>
</ng-container>

<div
  class="w-screen h-screen bg-white-80 backdrop-blur-sm fixed z-90 top-0 left-0 flex flex-col items-center justify-center wow fadeIn animated"
  data-wow-delay="0s"
  data-wow-duration="0.2s"
  *ngIf="loaderActive"
>
  <div
    id="loader-sanusx"
    class="flex flex-grow flex-col items-center justify-center text-primaryx-50 relative z-20"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 40 40">
      <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

      <circle cx="16" cy="16" r="15.9155" class="progress-bar__progress" />
    </svg>
    <h3 class="uppercase tracking-wider my-10 text-gray font-semibold">
      {{ "COMMON.PLEASE_WAIT" | translate }}
    </h3>
  </div>
</div>
