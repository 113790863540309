import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MachineError } from 'src/app/core/models/error';

enum STATE {
  START = 'START',
  SHUTDOWN = 'SHUTDOWN',
  OK = 'OK',
  MAINTENANCE = 'MAINTENANCE',
  ERROR = 'ERROR',
  POWER_CUT = 'POWER_CUT',
  UNKNOWN = 'UNKNOWN',
}

interface Machine {
  id: number;
}
interface MachineState {
  name: STATE;
  label: string;
}

interface ReportMachine {
  machine: Machine;
  state: MachineState;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class LockerSkiHttpService {
  constructor(private http: HttpClient) {}

  public getMachingeData(): Observable<any> {
    const url = environment.configApi + `/machine/locker-rent/machine-data`;
    return this.http.get(url);
  }

  public storeLockerRent(data: any): Observable<any> {
    const url = environment.configApi + `/machine/locker-rent/store`;
    return this.http.post(url, data);
  }

  public reclaimLockerRentItems(
    slots: any[],
    lockerRentId: number
  ): Observable<any> {
    const url = environment.configApi + `/machine/locker-rent/reclaim-items`;
    return this.http.post(url, { slots: slots, lockerRentId: lockerRentId });
  }

  ping(machineError: MachineError) {
    let report: ReportMachine;

    if (machineError == null) {
      report = {
        machine: null,
        state: {
          name: STATE.OK,
          label: STATE.OK,
        },
        message: 'Everything ok',
      };
    } else {
      report = {
        machine: null,
        state: {
          name: STATE.MAINTENANCE,
          label: STATE.MAINTENANCE,
        },
        message:
          machineError.module +
          '_' +
          machineError.errorNumber +
          ' - ' +
          machineError.message,
      };
    }

    return this.http
      .post(environment.configApi + '/machine/report-machine', report)
      .pipe();
  }

  unlockSlots(locks: number[]): Observable<any> {
    return this.http.post(environment.controlApi + '/control/openLocks', {
      locks: locks,
    });
  }

  getClosedLocks(): Observable<any> {
    return this.http.post(environment.controlApi + '/control/closedLocks', {});
  }

  transaction(amount: number): Observable<any> {
    return this.http.post(environment.paymentApi + '/payment/transaction', {
      amount: amount,
    });
  }

  revertTransaction(data: any): Observable<any> {
    return this.http.post(
      environment.paymentApi + '/payment/revert-transaction',
      data
    );
  }

  captureTransaction(data: any): Observable<any> {
    return this.http.post(environment.paymentApi + '/payment/capture', data);
  }

  cardHash(): Observable<any> {
    return this.http.post(environment.paymentApi + '/payment/cardhash', {});
  }
}
