import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Observer, Subscriber, Subscription } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenState } from '../state/token.state';
import { IMqttMessage, IPublishOptions, MqttService } from 'ngx-mqtt';
import { Navigate } from '@ngxs/router-plugin';
import { ContentTxtService } from './contentTxt.service';
import { ControlService } from './control.service';
import { CoreStateModel, CoreState } from '../state/core.state';

export interface Message {
  source: string;
  content: string;
}

@Injectable()
export class MqttHelperService extends EventEmitter {
  public message: string;
  private controlTopic: string = 'app';
  private messageIdCounter = 1;

  constructor(
    private mqttService: MqttService,
    private store: Store,
    private controlService: ControlService
  ) {
    super();
  }

  public init() {
    console.log('mqtt init');
    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config
        .controlSoftwareType == 'mqtt'
    ) {
      this.mqttService.connect();
    }
  }

  public ngOnDestroy() { }
  public releaseProduct(productIds: number[]): Observable<any> {
    console.log('releaseProduct', productIds);

    const releasedProductIds = new Set<number>();

    return new Observable((subscriber) => {
      const timeoutTimer = setTimeout(() => {
        const unreleasedProductIds = productIds.filter(id => !releasedProductIds.has(id));
        if (unreleasedProductIds.length > 0) {
          console.log(`Timeout reached. Unreleased products: ${unreleasedProductIds}. Sending abort message.`);
          this.sendAbortMessage(unreleasedProductIds, subscriber);
        }
      }, 120000);

      const currentSubscription = this.mqttService
        .observe(this.controlTopic)
        .subscribe((message: IMqttMessage) => {
          const receivedMessage = JSON.parse(message.payload.toString());
          console.log('Received:', receivedMessage);

          if (receivedMessage['control_task'] === 'release_content') {
            this.manageResponseReleaseContent(
              receivedMessage,
              timeoutTimer,
              subscriber,
              currentSubscription,
              releasedProductIds,
              productIds
            );
          } else {
            console.log('Message not recognized');
          }
        });

      this.controlService
        .log('INFO', 'mqtt release content request: ' + productIds)
        .subscribe();

      this.unsafePublish('control', this.releaseContent('release_content', 'control', { tobacco24: productIds }));
    });
  }

  private manageResponseReleaseContent(
    receivedMessage: any,
    timeoutTimer: NodeJS.Timeout,
    subscriber: Subscriber<any>,
    currentSubscription: Subscription,
    releasedProductIds: Set<number>,
    allProductIds: number[]
  ) {
    const { state, product_id, message_id, errors } = receivedMessage;

    switch (state) {
      case 'started':
        console.log('Release process started successfully');
        break;

      case 'product_released':
        console.log('Product released', product_id);
        releasedProductIds.add(product_id);
        this.mqttService.unsafePublish('control', this.createAckMessage('release_content', 'product_released', message_id));
        if (this.allProductsReleased(allProductIds, releasedProductIds)) {
          console.log('All products have been released. Waiting for finished state...');
        }
        break;

      case 'finished':
        console.log('Release content finished');
        clearTimeout(timeoutTimer);
        this.mqttService.unsafePublish('control', this.createAckMessage('release_content', 'finished', message_id));
        subscriber.next({ success: true, message: receivedMessage, released: Array.from(releasedProductIds) });
        subscriber.complete();
        currentSubscription.unsubscribe();
        break;

      default:
        if (errors) {
          console.log('Error:', errors);
          clearTimeout(timeoutTimer);
          subscriber.next({ success: false, message: errors });
          subscriber.complete();
          currentSubscription.unsubscribe();
        }
    }
  }

  private sendAbortMessage(unreleasedProductIds: number[], subscriber: Subscriber<any>) {
    this.mqttService.unsafePublish('control', this.releaseContentAbort({ tobacco24: unreleasedProductIds }));
    const abortSubscription = this.mqttService
      .observe(this.controlTopic)
      .subscribe((message: IMqttMessage) => {
        const receivedMessage = JSON.parse(message.payload.toString());
        if (receivedMessage['control_task'] === 'abort' && receivedMessage['ackn'] === 'acknowledged') {
          console.log('Abort acknowledged by control system');
          subscriber.next({ success: false, message: receivedMessage });
          subscriber.complete();
          abortSubscription.unsubscribe();
        }
      });
  }

  private allProductsReleased(allProductIds: number[], releasedProductIds: Set<number>): boolean {
    return allProductIds.every(id => releasedProductIds.has(id));
  }



  public startAndMonitorOuttake(autolock: boolean): Observable<any> {
    console.log('unlockHatch');
    let lock = autolock ? 1 : 0;
    return new Observable((subscriber) => {
      let timeoutTimer = setTimeout(() => {
        subscriber.next({ success: true });
        subscriber.complete();
      }, 93000);

      let currentSubscription = this.mqttService
        .observe(this.controlTopic)
        .subscribe((message: IMqttMessage) => {
          let receivedMessage = JSON.parse(message.payload.toString());
          console.log('received: ');
          console.log(receivedMessage);
          this.manageResponseOutake(
            receivedMessage,
            timeoutTimer,
            subscriber,
            currentSubscription,
            autolock
          );
        });

      console.log('publishing');
      this.controlService.log('INFO', 'monitor outtake started').subscribe();
      this.unsafePublish(
        'control',
        this.createMonitorTakeout('monitor_take_out', lock)
      );
    });
  }

  private manageResponseOutake(
    receivedMessage: any,
    timeoutTimer: NodeJS.Timeout,
    subscriber: Subscriber<any>,
    currentSubscription: Subscription,
    autolock: boolean
  ) {
    if (
      receivedMessage['control_task'] === 'monitor_take_out' &&
      receivedMessage['state'] == 'started'
    ) {
      console.log('unlocked');
    } else if (
      receivedMessage['control_task'] === 'monitor_take_out' &&
      receivedMessage['state'] == 'door_opened'
    ) {
      console.log('door oppened');
    } else if (
      receivedMessage['control_task'] === 'monitor_take_out' &&
      receivedMessage['state'] == 'finished'
    ) {
      console.log('ok');
      this.mqttService.unsafePublish(
        'control',
        this.createAckMessage('monitor_take_out', 'finished', receivedMessage['message_id'])
      );
      clearTimeout(timeoutTimer);
      this.controlService.log('INFO', 'monitor outtake finished').subscribe();
      subscriber.next({ success: true, message: receivedMessage });
      subscriber.complete();
      currentSubscription.unsubscribe();
    } else {
      console.log('not expected');
    }
  }

  public lockHatch(): Observable<any> {
    console.log('lockHatch started');
    return new Observable((subscriber) => {
      let timeoutTimer = this.createTimer(15000, subscriber);

      let currentSubscription = this.mqttService
        .observe(this.controlTopic)
        .subscribe((message: IMqttMessage) => {
          let receivedMessage = JSON.parse(message.payload.toString());
          console.log('received: ');
          console.log(receivedMessage);

          if (
            receivedMessage['control_task'] === 'monitor_take_out' &&
            receivedMessage['state'] == 'started'
          ) {
            console.log('locked');
            clearTimeout(timeoutTimer);
            this.controlService.log('INFO', 'lock door finished').subscribe();

            subscriber.next({ success: true, message: receivedMessage });
            subscriber.complete();
            currentSubscription.unsubscribe();
          } else {
            console.log('lock hatch aborted');
          }
        });
      this.controlService.log('INFO', 'lock door started').subscribe();
      console.log('publishing');
      this.unsafePublish('control', this.createLockDoor('monitor_take_out'));
    });
  }

  public updateStock(data: any): Observable<any> {
    console.log('updateStock');
    return new Observable((subscriber) => {
      let timeoutTimer = this.createTimer(30000, subscriber);

      let currentSubscription = this.mqttService
        .observe(this.controlTopic)
        .subscribe((message: IMqttMessage) => {
          let receivedMessage = JSON.parse(message.payload.toString());
          console.log('received: ');
          console.log(receivedMessage);

          if (
            receivedMessage['control_task'] === 'update_storage' &&
            receivedMessage['state'] == 'finished'
          ) {
            console.log('ok');
            clearTimeout(timeoutTimer);

            subscriber.next({ success: true, message: receivedMessage });
            subscriber.complete();
            currentSubscription.unsubscribe();
          } else {
            console.log('Update storage failed');
          }
        });

      console.log('publishing');
      this.unsafePublish('control', this.sendData('update_storage', data));
    });
  }

  public healthCheck(): Observable<any> {
    console.log('healthCheck -');
    //! luki
    return new Observable((subscriber) => {
      let timeoutTimer = this.createTimer(5000, subscriber);

      let currentSubscription = this.mqttService
        .observe(this.controlTopic)
        .subscribe((message: IMqttMessage) => {
          let receivedMessage = JSON.parse(message.payload.toString());
          console.log('received: ');
          console.log(receivedMessage);

          if (
            receivedMessage['control_task'] === 'health_check' &&
            receivedMessage['ackn'] == 'acknowledged'
          ) {
            console.log('ok');
            clearTimeout(timeoutTimer);
            this.controlService
              .log('INFO', 'health check finished' + receivedMessage.toString())
              .subscribe();

            subscriber.next({ success: true, message: receivedMessage });
            subscriber.complete();
            currentSubscription.unsubscribe();
          } else {
            console.log('health check failed');
          }
        });

      console.log('publishing');
      this.controlService.log('INFO', 'health check started').subscribe();

      this.unsafePublish('control', this.createRequestMessage('health_check'));
    });
  }

  public openLocker(action: any): Observable<any> {
    console.log('openLocker');
    let data = this.prepareOpenLockerDataMqtt(action);
    return new Observable((subscriber) => {
      let timeoutTimer = this.createTimer(20000, subscriber);

      let currentSubscription = this.mqttService
        .observe(this.controlTopic)
        .subscribe((message: IMqttMessage) => {
          let receivedMessage = JSON.parse(message.payload.toString());
          console.log('received: ');
          console.log(receivedMessage);
          if (
            receivedMessage['control_task'] === 'pickup' &&
            receivedMessage['state'] == 'pickup_started'
          ) {
            console.log('unlocking started');
          } else if (
            receivedMessage['control_task'] === 'pickup' &&
            (receivedMessage['state'] == 'aborted' ||
              receivedMessage['state'] == 'door_stuck')
          ) {
            console.log('error: ' + receivedMessage['state']);
            clearTimeout(timeoutTimer);
            subscriber.next({
              success: false,
              message: receivedMessage['state'],
              controlError: 1,
            });
            subscriber.complete();
            currentSubscription.unsubscribe();
          } else if (
            receivedMessage['control_task'] === 'pickup' &&
            receivedMessage['state'] == 'door_opened'
          ) {
            console.log('ok');
            clearTimeout(timeoutTimer);

            subscriber.next({ success: true, message: receivedMessage });
            subscriber.complete();
            currentSubscription.unsubscribe();
          } else {
            console.log('Unknown message open locker');
          }
        });

      console.log('publishing');
      this.unsafePublish('control', this.openLockerMsg(data));
    });
  }

  public checkLockerDoor(): Observable<any> {
    console.log('checkForLockerClose');
    return new Observable((subscriber) => {
      let timeoutTimer = this.createTimer(30000, subscriber);

      let currentSubscription = this.mqttService
        .observe(this.controlTopic)
        .subscribe((message: IMqttMessage) => {
          let receivedMessage = JSON.parse(message.payload.toString());
          console.log('received: ');
          console.log(receivedMessage);
          if (
            receivedMessage['control_task'] === 'pickup' &&
            (receivedMessage['state'] == 'aborted' ||
              receivedMessage['state'] == 'door_stuck')
          ) {
            console.log('error: ' + receivedMessage['errors']);
            clearTimeout(timeoutTimer);
            subscriber.next({
              success: false,
              message: receivedMessage['errors'],
            });
            subscriber.complete();
            currentSubscription.unsubscribe();
          } else if (
            receivedMessage['control_task'] === 'pickup' &&
            receivedMessage['state'] == 'door_closed'
          ) {
            console.log('door_closed');
          } else if (
            receivedMessage['control_task'] === 'pickup' &&
            receivedMessage['state'] == 'finished'
          ) {
            if (
              receivedMessage['pickups'] &&
              receivedMessage['pickups']['officebutler24'][0].state == 'error'
            ) {
              clearTimeout(timeoutTimer);
              subscriber.next({
                success: false,
                message: receivedMessage['errors'],
              });
              subscriber.complete();
              currentSubscription.unsubscribe();
            } else {
              clearTimeout(timeoutTimer);

              subscriber.next({ success: true, message: receivedMessage });
              subscriber.complete();
              currentSubscription.unsubscribe();
            }

            console.log('publishing');
            this.unsafePublish(
              'control',
              this.lockerFinishedAck(receivedMessage['message_id'])
            );
          } else {
            console.log('Unknown message Pickup');
          }
        });
    });
  }
  public openMultipleLocker(action: any): Observable<any> {
    console.log('openMultiLocker');
    let data = this.prepareMultiOpenLockerDataMqtt(action);
    return new Observable((subscriber) => {
      let timeoutTimer = this.createTimer(10000, subscriber);

      let currentSubscription = this.mqttService
        .observe(this.controlTopic)
        .subscribe((message: IMqttMessage) => {
          let receivedMessage = JSON.parse(message.payload.toString());
          console.log('received: ');
          console.log(receivedMessage);
          if (
            receivedMessage['control_task'] === 'open_locker' &&
            receivedMessage['state'] == 'finished'
          ) {
            console.log('ok');
            clearTimeout(timeoutTimer);

            subscriber.next({ success: true, message: receivedMessage });
            subscriber.complete();
            currentSubscription.unsubscribe();
          } else {
            console.log('Unknown message open locker');
          }
        });

      console.log('publishing');
      this.unsafePublish('control', this.openMultiLockerMsg(data));
    });
  }


  public sendFailureMessage(failureTask: string): void {
    const failureMessage = this.createFailureMessage(failureTask);
    this.unsafePublish(this.controlTopic, JSON.stringify(failureMessage));
  }

  private createFailureMessage(failureTask: string): object {
    return {
      ackn: 'request',
      sender: 'app',
      receiver: 'control',
      message_id: this.messageIdCounter++,
      control_task: failureTask
    };
  }


  private prepareOpenLockerDataMqtt(action: any) {
    let devices: any[] = [];
    console.log('action', action);
    /* let device = {
      device_nr: +action.containerCode,
      slots: [+action.slotIndex],
    };*/
    devices.push('oppenning slot', +action.slotIndex);
    let data = { officebutler24: [+action.slotIndex] };
    console.log('data', data);
    return data;
  }

  private prepareMultiOpenLockerDataMqtt(action: any) {
    let devices: any[] = [];
    console.log('list', action);
    /* let device = {
      device_nr: +action.containerCode,
      slots: [+action.slotIndex],
    };*/
    devices.push('oppenning slot', action.slots);
    let data = { officebutler24: action.slots };
    console.log('data', data);
    return data;
  }

  private openMultiLockerMsg(lockerList: any) {
    return JSON.stringify({
      ackn: 'request',
      sender: 'app',
      receiver: 'control',
      message_id: this.messageIdCounter++,
      control_task: 'open_locker',
      slot_nrs: lockerList,
    });
  }

  private openLockerMsg(lockerList: any) {
    return JSON.stringify({
      ackn: 'request',
      sender: 'app',
      receiver: 'control',
      message_id: this.messageIdCounter++,
      control_task: 'pickup',
      slot_nrs: lockerList,
    });
  }
  private lockerFinishedAck(messageId: number) {
    return JSON.stringify({
      ackn: 'acknowledged',
      sender: 'app',
      receiver: 'control',
      message_id: messageId,
      control_task: 'pickup',
      state: 'finished',
    });
  }

  private unsafePublish(topic: string, message: string): void {
    this.mqttService.unsafePublish(topic, message, {
      qos: 0,
      retain: false,
    } as IPublishOptions);
  }

  private releaseContent(
    messageType: string,
    receiver: string,
    product_ids: any
  ) {
    return JSON.stringify({
      ackn: 'request',
      sender: 'app',
      receiver: 'control',
      message_id: this.messageIdCounter++,
      control_task: 'release_content',
      terminal_nr: 1,
      search_option: 'least_stock',
      product_ids: product_ids,
    });
  }


  private releaseContentAbort(product_ids: { tobacco24: number[] }): string {
    return JSON.stringify({
        ackn: 'request',
        sender: 'app',
        receiver: 'control',
        message_id: this.messageIdCounter++,
        control_task: 'abort',
        terminal_nr: 1,
        search_option: 'least_stock',
        product_ids,  // the structure { tobacco24: unreleasedProductIds }
    });
}

  private sendData(messageType: string, data: any) {
    return JSON.stringify({
      ackn: 'request',
      sender: 'app',
      receiver: 'control',
      message_id: this.messageIdCounter++,
      control_task: messageType,
      terminal_nr: 1,
      data: data,
    });
  }

  private createMonitorTakeout(messageType: string, autolock: number) {
    return JSON.stringify({
      ackn: 'request',
      sender: 'app',
      receiver: 'control',
      message_id: this.messageIdCounter++,
      control_task: messageType,
      terminal_nr: 1,
      auto_lock: autolock,
    });
  }

  private createLockDoor(messageType: string) {
    return JSON.stringify({
      ackn: 'request',
      sender: 'app',
      receiver: 'control',
      message_id: this.messageIdCounter++,
      control_task: messageType,
      terminal_nr: 1,
      state: 'lock_door',
    });
  }

  private createAckMessage(messageType: string, state: string, messageId: number) {
    return JSON.stringify({
      sender: 'app',
      receiver: 'control',
      message_id: messageId,
      ackn: 'acknowledged',
      control_task: messageType,
      state: state,
    });
  }

  private createRequestMessage(messageType: string) {
    return JSON.stringify({
      ackn: 'request',
      sender: 'app',
      receiver: 'control',
      message_id: this.messageIdCounter++,
      control_task: messageType,
    });
  }

  private createTimer(timeout: number, subscriber: Subscriber<any>) {
    return setTimeout(() => {
      subscriber.next({ success: false });
      subscriber.complete();
    }, timeout); // TODO
  }

  private checkIfAllProductsReleasedSuccessfuly(receivedMessage: any) {
    if (!receivedMessage.releases || !receivedMessage.releases) return false;

    return (
      receivedMessage.releases.filter((x) => x.state === 'error').length === 0
    );
  }
}
