import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreensaverComponent } from './components/screensaver/screensaver.component';
import { Routes, RouterModule } from '@angular/router';

import { CoreComponent } from './components/core/core.component';
import { SharedModule } from '../shared/shared.module';

import { SelectionComponent } from './components/selection/selection.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { TokenState } from './state/token.state';
import { environment } from 'src/environments/environment';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { CoreState } from './state/core.state';
import { RetailerModule } from '../retailer/retailer.module';
import { InitializationComponent } from './components/initialization/initialization.component';
import { ErrorComponent } from './components/error/error.component';

import { ControlState } from './state/control.state';
import { FormsModule } from '@angular/forms';
import { LockerModule } from '../locker/locker.module';
import { SetupModule } from './components/setup/setup.module';
import { SelectionFullComponent } from './components/selection-full/selection-full.component';
import { LoginUserComponent } from './components/login-user/login-user.component';
import { SelectionButtonsComponent } from './components/selection-buttons/selection-buttons.component';
import { LockerSkiState } from '../locker/state/locker-ski.state';
import { WebsocketService } from './services/websocket.service';
import { OutOfOrderComponent } from './components/out-of-order/out-of-order.component';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { MqttHelperService } from './services/mqtt.service';
import { LockerState } from '../locker/state/locker.state';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/core/screensaver',
  },
  {
    path: 'core',
    children: [
      {
        path: 'screensaver',
        component: ScreensaverComponent,
      },
      {
        path: 'selection',
        component: SelectionComponent,
      },
      {
        path: 'selection-full',
        component: SelectionFullComponent,
      },
      {
        path: 'selection-buttons',
        component: SelectionButtonsComponent,
      },
      {
        path: 'initialization',
        component: InitializationComponent,
      },
      {
        path: 'error',
        component: ErrorComponent,
      },
      {
        path: 'out-of-order',
        component: OutOfOrderComponent,
      },
      {
        path: 'login-user',
        component: LoginUserComponent,
      },
      {
        path: 'setup',
        loadChildren: () =>
          import('./components/setup/setup.module').then((m) => m.SetupModule),
      },
    ],
  },
  {
    path: 'retailer',
    loadChildren: () => RetailerModule,
  },
  {
    path: 'locker',
    loadChildren: () => LockerModule,
  },
];

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
];

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: '127.0.0.1',
  port: 9001,
  path: '/',
  clean: true,
  queueQoSZero: false,
  connectOnCreate: false,
};

@NgModule({
  declarations: [
    ScreensaverComponent,
    CoreComponent,
    SelectionComponent,
    InitializationComponent,
    ErrorComponent,
    SelectionFullComponent,
    SelectionButtonsComponent,
    LoginUserComponent,
    OutOfOrderComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    NgxsModule.forRoot(
      [CoreState, ControlState, TokenState, LockerSkiState, LockerState],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsStoragePluginModule.forRoot({
      key: [TokenState],
    }),
    NgxsRouterPluginModule.forRoot(),
    FormsModule,
    SharedModule,
    SetupModule,
    RetailerModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
  ],
  exports: [CoreComponent],
  providers: [httpInterceptorProviders, WebsocketService, MqttHelperService],
})
export class CoreModule {
  constructor() {}
}
