import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  LockerSkiState,
  LockerSkiStateModel,
} from 'src/app/locker/state/locker-ski.state';
import { LockerSki } from 'src/app/locker/state/locker-ski.actions';
import { Idle } from '@ng-idle/core';

@Component({
  selector: 'app-locker-selection',
  templateUrl: './locker-selection.component.html',
  styleUrls: ['./locker-selection.component.scss'],
})
export class SkiLockerSelectionComponent implements OnInit, OnDestroy {
  freeSlotsCount: number;

  lockers: Array<any>;

  constructor(private store: Store, private idle: Idle) {
    this.freeSlotsCount =
      this.store.selectSnapshot<LockerSkiStateModel>(
        LockerSkiState
      ).freeSlots.length;

    this.lockers = [
      {
        id: 0,
        number: 1,
        label: 'LOCKER_SKI.LOCKER',
        price: 1.8,
        enabled: this.freeSlotsCount >= 1,
        reservationAmount: 20,
      },
      {
        id: 1,
        number: 2,
        label: 'LOCKER_SKI.LOCKERS',
        price: 1.8,
        enabled: this.freeSlotsCount >= 2,
        reservationAmount: 30,
      },
      {
        id: 2,
        number: 3,
        label: 'LOCKER_SKI.LOCKERS',
        price: 1.8,
        enabled: this.freeSlotsCount >= 3,
        reservationAmount: 40,
      },
      {
        id: 3,
        number: 4,
        label: 'LOCKER_SKI.LOCKERS',
        price: 1.8,
        enabled: this.freeSlotsCount >= 4,
        reservationAmount: 49,
      },
    ];
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);

    switch (event.key) {
      case '3':
        if (this.lockers[0].enabled) {
          this.onSelectNumOfLockers(this.lockers[0]);
        }
        break;
      case '2':
        if (this.lockers[1].enabled) {
          this.onSelectNumOfLockers(this.lockers[1]);
        }
        break;
      case '7':
        if (this.lockers[2].enabled) {
          this.onSelectNumOfLockers(this.lockers[2]);
        }
        break;
      case '6':
        if (this.lockers[3].enabled) {
          this.onSelectNumOfLockers(this.lockers[3]);
        }
        break;
      case '5':
        this.onCancel();
        break;
    }
  }

  onSelectNumOfLockers(locker: any) {
    this.store.dispatch(
      new LockerSki.StartAuthorizationProcess(
        locker.number,
        locker.price,
        locker.reservationAmount
      )
    );
  }

  onCancel() {
    this.store.dispatch(new LockerSki.ResetRentProcess());
  }
}
