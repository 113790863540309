import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-svg-stripe',
  templateUrl: './svg-stripe.component.html',
  styleUrls: ['./svg-stripe.component.scss']
})
export class SvgStripeComponent implements OnInit {
  @HostBinding('class') class = 'w-full h-full';

  @Input() icon: string;
  @Input() white: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
