import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';

import { SafePipe } from './pipes/safe.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AutofocusDirective } from './directive/autofocus.directive';
import { CloudComponent } from './svg/cloud/cloud.component';
import { CloudBigComponent } from './svg/cloud-big/cloud-big.component';
import { LoaderMiniComponent } from './svg/loader-mini/loader-mini.component';
import { ButtonComponent } from './ui/button/button.component';
import { BtnSanusxComponent } from './ui/btn-sanusx/btn-sanusx.component';
import { BtnBigBoxComponent } from './ui/btn-big-box/btn-big-box.component';
import { SvgStripeComponent } from './svg/svg-stripe/svg-stripe.component';
import { PickupListItemComponent } from './ui/pickup-list-item/pickup-list-item.component';
import { ModalNotifyComponent } from './ui/modal-notify/modal-notify.component';
import { SkiBgComponent } from './ui/ski-bg/ski-bg.component';
import { HeaderSkiComponent } from './header-ski/header-ski.component';
import { ClockComponent } from './clock/clock.component';
import { BtnSkiComponent } from './ui/btn-ski/btn-ski.component';
import { SkiMsgComponent } from './ski-msg/ski-msg.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { ChunkPipe } from './pipes/chunk.pipe';
import { RemoveFormattingPipe } from './pipes/removeFormatting.pipe';
import { ProductCardComponent } from './product-card/product-card.component';
import { NexFooterComponent } from './nex/nex-footer/nex-footer.component';
import { NexCentralAdComponent } from './nex/nex-central-ad/nex-central-ad.component';
import { NexHeaderComponent } from './nex/nex-header/nex-header.component';
import { NexLangSwitcherComponent } from './nex/nex-lang-switcher/nex-lang-switcher.component';
import { TrustPipe } from './pipes/trust.pipe';
import { TouchClickDirective } from './directive/touchclick.directive';

@NgModule({
  declarations: [
    HeaderComponent,
    SafePipe,
    TrustPipe,
    SidebarComponent,
    AutofocusDirective,
    TouchClickDirective,
    CloudComponent,
    CloudBigComponent,
    LoaderMiniComponent,
    ButtonComponent,
    BtnSanusxComponent,
    BtnBigBoxComponent,
    SvgStripeComponent,
    PickupListItemComponent,
    ModalNotifyComponent,
    SkiBgComponent,
    HeaderSkiComponent,
    ClockComponent,
    BtnSkiComponent,
    EllipsisPipe,
    SkiMsgComponent,
    ChunkPipe,
    RemoveFormattingPipe,
    ProductCardComponent,
    NexFooterComponent,
    NexCentralAdComponent,
    NexHeaderComponent,
    NexLangSwitcherComponent,
  ],
  imports: [CommonModule, TranslateModule],
  exports: [
    HeaderComponent,
    SidebarComponent,
    AutofocusDirective,
    TouchClickDirective,
    SafePipe,
    TrustPipe,
    TranslateModule,
    CloudComponent,
    CloudBigComponent,
    LoaderMiniComponent,
    ButtonComponent,
    BtnSanusxComponent,
    BtnBigBoxComponent,
    SvgStripeComponent,
    PickupListItemComponent,
    ModalNotifyComponent,
    SkiBgComponent,
    HeaderSkiComponent,
    ClockComponent,
    BtnSkiComponent,
    SkiMsgComponent,
    EllipsisPipe,
    ChunkPipe,
    RemoveFormattingPipe,
    ProductCardComponent,
    NexFooterComponent,
    NexCentralAdComponent,
    NexHeaderComponent,
    NexLangSwitcherComponent,
  ],
})
export class SharedModule {}
